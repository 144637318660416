import Spinner from '@/components/element/loading/Spinner';
import { Card, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { Suspense, memo } from 'react';
import AnimateHeight from 'react-animate-height';

interface VerifyCardProps {
	stepKey: string;
	isActive: boolean;
	title: string;
	onStepClick: (key: string) => void;
	children: React.ReactNode;
}

const VerifyCard = ({
	stepKey,
	isActive,
	title,
	onStepClick,
	children,
}: VerifyCardProps) => {
	return (
		<Card
			className={cn(
				'w-[700px] relative p-5',
				!isActive && 'cursor-pointer border border-slate-300',
			)}
			onClick={() => {
				if (!isActive) onStepClick(stepKey);
			}}
		>
			<CardContent className="px-0 py-0">
				<div className="text-slate-700 text-sm font-medium flex items-center w-full">
					<i className="bi-circle me-2"></i>
					<div className="flex items-center justify-between w-full">
						{title}
						<i
							className={cn(
								'bi-chevron-down ms-2 transition-transform',
								isActive ? 'rotate-180' : 'rotate-0',
							)}
						></i>
					</div>
				</div>
				<AnimateHeight height={isActive ? 'auto' : 0} duration={300}>
					<Suspense fallback={<Spinner />}>
						<div className="mt-5">{children}</div>
					</Suspense>
				</AnimateHeight>
			</CardContent>
		</Card>
	);
};

const MemoizedVerifyCard = memo(VerifyCard);
export default MemoizedVerifyCard;
