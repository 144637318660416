import { useMutation } from '@tanstack/react-query';
import {
	launchCommunityReferralProgram,
	updateReferralProgram,
} from '../services/community.service';
import { toast } from 'sonner';
import { queryClient } from '@/lib/react-query';
import {
	EligibilityCriteria,
	EnterpriseReferralProgramStatus,
	EnterpriseReferralRewardTypes,
	successfulReferralConditions,
} from '../types/customize-hub.enum';
import { useCommunityReferral } from './useCommunityReferral';
import { handleErrorMessage } from '@/utils/notifications';

const useCreateReferralProgram = ({
	items,
	setItems,
	formFields,
	setFormFields,
	successfulReferralItems,
	setSuccessfulReferralItems,
	setFormErrors,
}) => {
	const { resetForm } = useCommunityReferral();

	const launchReferral = useMutation({
		mutationFn: launchCommunityReferralProgram,
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: ['community-referral'],
			});
			resetForm();
		},
		onError: (error) => {
			resetForm();
			handleErrorMessage(error);
		},
	});
	const updateReferral = useMutation({
		mutationFn: updateReferralProgram,
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: ['community-referral'],
			});
			resetForm();
		},
		onError: (error) => {
			resetForm();
			handleErrorMessage(error);
		},
	});

	const addCriteria = () => {
		if (items >= 4) return;
		setFormFields((prev) => {
			if (prev?.referrerEligibilityConditions?.length <= 0) {
				prev.referrerEligibilityConditions = [];
			}
			const existingValues = new Set(
				prev.referrerEligibilityConditions.map((item) => item.condition),
			);
			const newCriteria = EligibilityCriteria.find(
				(criteria) => !existingValues.has(criteria.value),
			);

			if (newCriteria) {
				const tempCriteria = [
					...prev.referrerEligibilityConditions,
					{
						condition: newCriteria.value,
						value: 0,
					},
				];
				setItems((prevItems) => prevItems + 1);

				return {
					...prev,
					referrerEligibilityConditions: tempCriteria,
				};
			}

			return prev;
		});
	};
	const addSuccessfulReferralCriteria = () => {
		if (successfulReferralItems >= 3) return;
		setFormFields((prev) => {
			if (prev?.referredUserEligibilityConditions?.length <= 0) {
				prev.referredUserEligibilityConditions = [];
			}
			const existingValues = new Set(
				prev.referredUserEligibilityConditions.map((item) => item.condition),
			);
			const newCriteria = successfulReferralConditions.find(
				(criteria) => !existingValues.has(criteria.value),
			);

			if (newCriteria) {
				const tempCriteria = [
					...prev.referredUserEligibilityConditions,
					{
						condition: newCriteria.value,
						value: 0,
					},
				];
				setSuccessfulReferralItems((prevItems) => prevItems + 1);

				return {
					...prev,
					referredUserEligibilityConditions: tempCriteria,
				};
			}

			return prev;
		});
	};
	const handleRewardChange = (newVal: any) => {
		setFormFields((prev: any) => ({
			...prev,
			rewards: [
				{
					rewardType: EnterpriseReferralRewardTypes.Xp,
					value: newVal,
				},
			],
		}));
	};
	const launchReferralProgram = () => {
		if (!formFields.referredUserEligibilityConditions.length) {
			toast.error(
				'Please add at least one eligibility condition for successful referral',
			);
			return;
		}
		if (formFields._id) {
			updateReferral.mutateAsync({
				referralProgramId: formFields._id,
				referrerEligibilityConditions:
					formFields.referrerEligibilityConditions,
				referredUserEligibilityConditions:
					formFields.referredUserEligibilityConditions,
				rewards: formFields.rewards,
				status: formFields.status,
			});
		} else {
			launchReferral.mutateAsync({
				referrerEligibilityConditions:
					formFields.referrerEligibilityConditions,
				referredUserEligibilityConditions:
					formFields.referredUserEligibilityConditions,
				rewards: formFields.rewards,
				status: EnterpriseReferralProgramStatus.Active,
			});
		}

		resetForm();
	};

	return {
		addCriteria,
		addSuccessfulReferralCriteria,
		handleRewardChange,
		launchReferralProgram,
		isPending: launchReferral.isPending || updateReferral.isPending,
	};
};

export default useCreateReferralProgram;
