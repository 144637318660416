import { IEvent } from '@/features/events/types/events.type';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getAdminEvents } from '../services/admin.service';

export const useGetAdminEvents = ({ enterpriseId }) => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		eventStatus: 'ACTIVE, COMPLETED',
		publishNarrative: true,
	});
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 30,
	});
	const [sorting, setSorting] = useState([]);

	const getSortOrder = () => {
		if (sorting && sorting.length > 0) {
			return sorting[0].desc ? 'desc' : 'asc';
		}
		return 'desc';
	};

	const { data, isLoading, refetch } = useQuery<IEvent[]>({
		queryKey: [
			'admin',
			'events',
			{
				pagination,
				filters,
				search,
				sorting,
			},
		],
		queryFn: () =>
			getAdminEvents({
				page: Number(pagination.pageIndex + 1) ?? 1,
				limit: Number(pagination.pageSize) ?? 10,
				search: search?.trim(),
				eventStatus: filters.eventStatus,
				publishNarrative: filters.publishNarrative,
				enterpriseId: enterpriseId?.trim(),
			}),
	});

	return {
		search,
		setSearch,
		filters,
		setFilters,
		results: data || [],
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading: isLoading,
		refetch,
	};
};
