import React, { useState } from 'react';
import { useRewardStore } from './useRewardStore';

const useFilterRewardStore = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		status: [],
		sectionName: [],
	});
	const [isSearching, setIsSearching] = useState(false);

	const { rewardStore } = useRewardStore();

	const filteredData = rewardStore
		.map((section) => {
			const filteredRewards = section.rewards.filter((reward) => {
				const nameMatch =
					!search ||
					reward.name.toLowerCase().includes(search.toLowerCase());
				const statusMatch =
					!filters?.status.length ||
					filters.status.includes(reward.status);

				return nameMatch && statusMatch;
			});

			if (
				(filters?.sectionName.length &&
					!filters.sectionName.includes(section.name)) ||
				filteredRewards.length === 0
			) {
				return null;
			}

			return {
				...section,
				rewards: filteredRewards,
			};
		})
		.filter((section) => section !== null);

	return {
		search,
		filters,
		setSearch,
		setFilters,
		isSearching,
		filteredData,
	};
};

export default useFilterRewardStore;
