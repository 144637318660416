import { Badge } from '@/components/ui/badge';
import { Checkbox } from '@/components/ui/checkbox';
import { Select, SelectContent, SelectItem } from '@/components/ui/select';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { SelectTrigger, SelectValue } from '@radix-ui/react-select';
import { useMemo } from 'react';
import { ITaskInputsProps } from './TaskInputs';

const TaskInputTwitterTags = ({
	adminInput,
	taskId,
	editTaskAdminInput,
}: ITaskInputsProps) => {
	return (
		<>
			<div className="relative flex items-center w-full">
				<div
					onClick={() => {
						editTaskAdminInput(
							taskId,
							adminInput.key,
							adminInput.value === 0 ? 3 : 0,
						);
					}}
					className="cursor-pointer flex items-center"
				>
					<Checkbox
						checked={
							typeof adminInput.value === 'number' &&
							adminInput.value > 0
						}
						className=""
					/>
					<div className="text-sm font-medium mx-2">
						{adminInput.label}
					</div>
				</div>
				{typeof adminInput.value === 'number' && adminInput.value > 0 ? (
					<SelectTags
						value={adminInput.value as number}
						setValue={(v) => {
							editTaskAdminInput(taskId, adminInput.key, v);
						}}
					/>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default TaskInputTwitterTags;

const SelectTags = ({
	value,
	setValue,
}: {
	value: number;
	setValue: (number) => void;
}) => {
	const options = useMemo(() => {
		return Array.from({ length: 6 }, (_, i) => ({
			value: i + 1,
			label: `${i + 1} Tags`,
		}));
	}, []);
	return (
		<Select
			value={value?.toString()}
			onValueChange={(v) => {
				setValue(Number(v));
			}}
		>
			<SelectTrigger>
				<Badge
					variant="secondary"
					className="border border-slate-200 me-3 pe-0"
				>
					<SelectValue />
					<CaretSortIcon className="ml-1 h-4 w-4 shrink-0 opacity-50" />
				</Badge>
			</SelectTrigger>
			<SelectContent>
				{options.map((i) => (
					<SelectItem key={i.value} value={i.value?.toString()}>
						{i.label}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};
