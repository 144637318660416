import InputText from '@/components/element/inputs/InputText';
import { Card, CardContent } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import {
	IEditingTasks,
	useCreateCampaignTasks,
} from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { TaskKeys, TemplateFamily } from '@/features/campaigns/types';
import { MetricBasedXpFunction } from '@/features/campaigns/types/tasks.enums';
import { cn } from '@/lib/utils';
import { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import GraphComponent from './features/GraphComponent';
import LearningContent from './features/LearningContent';
import MaxRetriesConfig from './features/MaxRetriesConfig';
import RecurringConfig from './features/RecurringConfig';
import SelectMetricsGraph from './features/SelectMetricsGraph';
import TaskToggleFeatures from './features/TaskToggleFeatures';
import TaskHeader from './layout/TaskHeader';
import TaskParams from './params/TaskParams';

const TaskCard = ({
	task,
	taskIndex,
}: {
	task: IEditingTasks['task'];
	taskIndex: number;
}) => {
	const [isEditingTitle, setIsEditingTitle] = useState(false);
	const { isReordering } = useCreateCampaign();
	const { editTask, makeTaskActive, errors, deleteTask } =
		useCreateCampaignTasks();

	useEffect(() => {
		if (!task) return;
		if (!task.adminInputs.length) return;
		const isTaskTitleConfig = task?.adminInputs?.find(
			(input) => input.key === TaskKeys.IsTaskTitle,
		);
		const isTaskTitleEditRequired = [
			TemplateFamily.Forms,
			TemplateFamily.Custom,
			TemplateFamily.Others,
			TemplateFamily.Wallet,
		].includes(task?.templateFamily);
		if (isTaskTitleEditRequired && !isTaskTitleConfig) {
			setIsEditingTitle(true);
		}
	}, [task]);

	const isError =
		errors?.[task.taskId]?.description ||
		errors?.[task.taskId]?.customInitiationURL ||
		Object.values(errors?.[task?.taskId]?.adminInputs ?? [])?.filter((i) => i)
			.length > 0;
	const isEditing = task?.isEditing && !isReordering;

	const showMetricBasedXp = useMemo(() => {
		return (
			task?.featureApplicability?.metricBasedXp &&
			task.appliedFeatures?.metricBasedXp?.enabled
		);
	}, [task?.featureApplicability, task?.appliedFeatures]);

	return (
		<Card
			className={cn(
				'mb-5 w-[600px] relative ',
				isEditing ? '' : 'cursor-pointer',
				isError && !isEditing ? 'border-destructive rounded-lg' : '',
			)}
			onClick={() => {
				makeTaskActive(task.taskId);
			}}
		>
			<CardContent className=" px-0 py-0">
				<TaskHeader
					{...task}
					isEditingTitle={isEditingTitle}
					setIsEditingTitle={setIsEditingTitle}
					isReordering={isReordering}
					taskIndex={taskIndex}
				/>
				<AnimateHeight height={isEditing ? 'auto' : 0} duration={300}>
					<div className=" border-t py-4  px-5">
						<div className="text-xs ">{task.adminHelper}</div>
						<AnimateHeight
							height={
								task.isDisabled ? 0 : isEditingTitle ? 'auto' : 0
							}
							duration={300}
							className="mt-4 mb-5"
						>
							<Label className="mb-1 text-xs text-muted-foreground">
								Task Title
							</Label>
							<InputText
								value={task.description}
								setValue={(e) => {
									editTask(task.taskId, 'EDIT_NAME', e);
								}}
								placeholder="Task Name"
								error={errors?.[task.taskId]?.description}
								errorText="Task description is required. This is what your users will see. "
							/>
						</AnimateHeight>
						{task.adminInputs?.length === 0 && (
							<div className="-mb-6"></div>
						)}
						<TaskParams {...task} />
					</div>
					<AnimateHeight
						height={task.learningContent.enabled ? 'auto' : 0}
						duration={300}
					>
						<LearningContent
							slides={task.learningContent.slides}
							taskId={task.taskId}
						/>
					</AnimateHeight>
					<AnimateHeight
						height={
							task.appliedFeatures?.recurrance?.enabled ? 'auto' : 0
						}
						duration={300}
					>
						<RecurringConfig
							recurrenceConfig={task.recurrenceConfig}
							taskId={task.taskId}
						/>
					</AnimateHeight>
					<AnimateHeight
						height={
							task.appliedFeatures?.maxFailedCount?.enabled
								? 'auto'
								: 0
						}
						duration={300}
					>
						<MaxRetriesConfig
							maxFailedCountConfig={task.maxFailedCountConfig}
							taskId={task.taskId}
							taskXP={task?.xp}
						/>
					</AnimateHeight>
					<AnimateHeight
						height={showMetricBasedXp ? 'auto' : 0}
						duration={300}
					>
						<SelectMetricsGraph
							metricBasedXpConfig={task.metricBasedXpConfig}
							taskId={task.taskId}
						/>
					</AnimateHeight>

					<AnimateHeight
						height={
							task.appliedFeatures.metricBasedXp.enabled &&
							task.metricBasedXpConfig?.functionType &&
							task.metricBasedXpConfig?.functionType !==
								MetricBasedXpFunction.None
								? 'auto'
								: 0
						}
						duration={300}
					>
						<GraphComponent
							metricBasedXpConfig={task.metricBasedXpConfig}
							task={task}
						/>
					</AnimateHeight>

					{!task.isDisabled && <TaskToggleFeatures task={task} />}
				</AnimateHeight>
			</CardContent>
		</Card>
	);
};
export default TaskCard;
