import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import { useRewardStore } from '../hooks/useRewardStore';
import RewardDetails from './RewardDetails';
import useCreateNewRewards from '../hooks/useCreateNewRewards';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import { Media } from '@/components/element/media/Media';

const CreateNewReward = ({ open, setOpen, steps, setSteps }) => {
	const { resetForm, formFields, setFormFields, formErrors, setFormErrors } =
		useRewardStore();

	useLockBodyScroll(false);

	const { handleNext, loading } = useCreateNewRewards({
		steps,
		setSteps,
		resetForm,
		setOpen,
	});

	useEffect(() => {
		setFormErrors((prev: any) => ({
			...prev,
			image: '',
		}));
	}, [formFields.image, formFields.imageFile, setFormErrors]);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[570px] px-0 pb-0 overflow-visible">
				<DialogHeader className="border-b pb-3 px-5">
					<DialogTitle>
						{formFields?.editMode
							? 'Update Reward Eligibility'
							: 'Add a new reward'}
					</DialogTitle>
					<DialogDescription>
						{formFields?.editMode
							? steps === 2
								? "You can't edit this section after reward is launched"
								: ''
							: 'Configure the reward and type of reward you want to offer.'}
					</DialogDescription>
				</DialogHeader>
				{steps === 1 && <RewardDetails />}
				{!formFields?.editMode && steps === 2 && (
					<div className="py-5 mx-6 gap-4 text-left">
						<DropzoneWithReposition
							file={formFields.imageFile}
							required
							clearSelection={() => {
								if (formFields.editMode) return;
								setFormFields({
									...formFields,
									image: '',
									imageFile: null,
								});
							}}
							setFile={(file: File, fileLink: string) => {
								setFormFields({
									...formFields,
									imageFile: file,
									image: fileLink,
								});
							}}
							fileLink={formFields.image}
							allowedFiles={['images', 'videos']}
							description="Recommended size: 400x400px"
							label="Upload Reward Image"
							cta="Upload the image you want to display for this reward"
							aspectRatio={1 / 1}
							className="w-full"
							customHeight="!max-h-[25rem]"
							dropzoneClass="bg-muted !h-[25rem] mt-4"
							error={formErrors['image']}
							errorText={formErrors['image']}
							onImageReposition={({ x, y }) => {
								if (formFields.editMode) return;
								setFormFields({
									...formFields,
									imagePosition: { x, y },
								});
							}}
							disabled={formFields.editMode}
							lockBodyScroll={false}
							showPlaceHolderImage
						/>
					</div>
				)}
				<DialogFooter className="flex justify-between border-t px-5 pt-3 pb-3 w-full sm:justify-between">
					{steps !== 1 ? (
						<Button
							variant="ghost"
							onClick={() =>
								setSteps((prev) => (prev === 0 ? 0 : prev - 1))
							}
						>
							Back
						</Button>
					) : (
						<div></div>
					)}
					<div className="space-x-2 flex items-center">
						<Button
							variant="outline"
							onClick={() => {
								resetForm();
								setOpen(false);
								setSteps(1);
							}}
						>
							Cancel
						</Button>
						<Button onClick={() => handleNext()} disabled={loading}>
							{loading && steps === 2 ? (
								<i className="bi-arrow-clockwise animate-spin me-2"></i>
							) : null}
							{formFields?.editMode
								? 'Update Reward Details'
								: steps === 1
									? 'Next'
									: 'Create Reward'}
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CreateNewReward;
