import { DataTable } from '@/components/element/data-table/DataTable';
import useGetSearchQuests from '../hooks/admin-cms-search-quests/useGetSearchQuests';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { Button } from '@/components/ui/button';
import useSearchQuestBanners from '../hooks/admin-cms-search-quests/useSearchQuestBanners';
import { SearchAllQuests } from '../components/content-management-system/SearchQuests';
import { DataTableWithDnd } from '@/components/element/data-table/DataTableWithDnD';

const AdminSearchQuests = () => {
	const {
		showCampaignList,
		setShowCampaignList,
		details,
		setDetails,
		saveSearchQuests,
		isSubmitting,
	} = useSearchQuestBanners();
	const { columns, searchQuests, isLoading, handleReorder, addQuest } =
		useGetSearchQuests({
			details,
			setDetails,
		});

	if (isLoading) return <FullPageLoading />;

	return (
		<div className="flex justify-center relative items-center">
			<div className="mb-5 w-full relative">
				<h4 className="text-xl font-medium">
					Manage Quest Banners on search
				</h4>
				<p className="text-muted-foreground text-xs mt-1 mb-8">
					Set the quest banners to be displayed on the search.
				</p>
				<DataTableWithDnd
					data={searchQuests}
					columns={columns}
					totalCount={searchQuests?.length || 0}
					onReorder={handleReorder}
					isLoading={isLoading}
				/>

				<div className="flex items-center gap-4 my-8">
					<Button onClick={() => setShowCampaignList(true)}>
						<span>+ Add a quest</span>
					</Button>
					<Button
						onClick={saveSearchQuests}
						variant="outline"
						disabled={isSubmitting}
					>
						{isSubmitting ? (
							<i className="bi-arrow-clockwise animate-spin me-2"></i>
						) : null}
						<span>Save Changes</span>
					</Button>
				</div>
			</div>

			<SearchAllQuests
				open={showCampaignList}
				setOpen={setShowCampaignList}
				onSelect={addQuest}
				searchQuests={searchQuests}
			/>
		</div>
	);
};

export default AdminSearchQuests;
