import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { truncateString } from '@/utils/parsers';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

export interface IWinner {
	username: string;
	avatar: string;
	xp: number;
	twitter: string;
	discord: string;
	completedAt: Date;
	tier?: string;
	[key: `tier_${string}`]: string;
}

const columnHelper = createColumnHelper<IWinner>();

export const winnersColumns = (selectedRow): ColumnDef<IWinner>[] =>
	[
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" className="" />
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					<Avatar className="h-7 w-7 me-2">
						<AvatarImage src={row.original.avatar} alt="@intract" />
						<AvatarFallback name={row.original.avatar} />
					</Avatar>
					{truncateString(row.original.username, 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'xp',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="XPs Earned"
					className=" max-w-[200px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center justify-center">
					{row.original.xp}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'tier',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Tier"
					className=" w-[80px]"
				/>
			),
			cell: ({ row }) => {
				const tierKeys = Object.keys(row.original).filter((key) =>
					key.startsWith('tier_'),
				);

				// Extract and join all tier values with ' | ' delimiter
				const tierValues = tierKeys
					?.map((key) => row.original[key])
					?.join(' | ');

				const prettyTierKeys = tierKeys
					?.map((key) => key.replace('tier_', ''))
					?.join(' | ');

				return (
					<div className="flex items-center gap-2">
						<TooltipWrapper tooltip={prettyTierKeys ?? ''} align="start">
							<div className="flex items-center">
								{tierValues ?? 'NA'}
							</div>
						</TooltipWrapper>
					</div>
				);
			},
			enableSorting: false,
			enableHiding: false,
		},
		columnHelper.accessor('twitter', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Twitter"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{truncateString(row.getValue('twitter'), 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		}),
		columnHelper.accessor('discord', {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Discord"
					className=" w-[100px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{truncateString(row.getValue('discord'), 20)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		}),
		{
			accessorKey: 'completedAt',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Completed at"
					className=" max-w-[200px]"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center ">
					{dayjs(row.original.completedAt).fromNow()}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
	].filter((i) => i);
