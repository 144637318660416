import { Button } from '@/components/ui/button';
import AddContentDialog from '@/features/campaigns/components/create-campaign/tasks/components/LearningContent/AddTaskContent';
import { useLoyaltyTasks } from '@/features/loyalty-campaigns/hooks/tasks/useLoyaltyTasks';
import { useState } from 'react';
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import InputSelect from '@/components/element/inputs/InputSelect';
import MetricBasedXp from '../MetricBasedXp';

const LoyaltyTaskFeatures = () => {
	const { template, task, setTask, errors, setErrors } = useLoyaltyTasks();
	const [open, setOpen] = useState(false);

	return (
		<div className="pb-4 h-full ">
			<div className="mt-8 max-h-[500px] overflow-y-auto">
				<div className="mb-10 flex items-center justify-between space-x-8">
					<div>
						<div className="text-sm font-medium">
							Add Educational Content Slides:
						</div>
						<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
							Add content slides in your task to help users understand.
						</p>
						{/* <div className="text-xs text-violet-800 hover:underline cursor-pointer mt-1">
							See how it looks.
						</div> */}
					</div>
					<Button
						variant="outline"
						onClick={() => {
							if (!task?.slides || task.slides?.length === 0) {
								setTask((prev) => ({
									...prev,
									slides: [{ title: '', content: '' }],
								}));
							}
							setOpen(true);
						}}
						className="min-w-[200px] justify-between pe-2 font-normal"
					>
						{task.slides?.length
							? `${task.slides?.length} Slides Added`
							: 'Add Slides'}
						<i className="bi-plus-circle-dotted me-2"></i>
					</Button>
				</div>
				{task?.featureApplicability?.recurrence &&
				!task?.featureApplicability?.metricBasedXp ? (
					<div className="mb-10 flex items-center justify-between space-x-8">
						<div className="flex-grow">
							<Label className="block mb-1">Set Recurrence</Label>
							<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
								Set the frequency of the task so users can complete
								the task multiple times.
							</p>
						</div>
						<div>
							<Select
								disabled={
									!template?.featureApplicability?.recurrence
								}
								value={
									task?.recurrenceConfig?.frequencyInDays
										? task?.recurrenceConfig?.frequencyInDays?.toString()
										: ''
								}
								onValueChange={(value) => {
									if (value === '0') {
										setTask((prev) => ({
											...prev,
											recurrenceConfig: {
												frequencyInDays: 0,
												isRecurring: false,
											},
										}));
									} else {
										setTask((prev) => ({
											...prev,
											maxFailedCountConfig: {
												maxFailedCount: 0,
												isMaxFailedCountEnabled: false,
												isDiminishingXpEnabled: false,
											},
											recurrenceConfig: {
												frequencyInDays: +value,
												isRecurring: true,
											},
										}));
									}
								}}
							>
								<SelectTrigger className=" bg-white min-w-[200px] ">
									<SelectValue
										placeholder={
											template?.featureApplicability
												?.recurrence
												? 'Select Frequency'
												: 'Cannot be set'
										}
									/>
								</SelectTrigger>
								<SelectContent>
									<SelectItem value={'0'}>None</SelectItem>
									<SelectItem value={'1'}>Daily</SelectItem>
									<SelectItem value={'7'}>Weekly</SelectItem>
									<SelectItem value={'30'}>Monthly</SelectItem>
								</SelectContent>
							</Select>
						</div>
					</div>
				) : null}

				{template?.featureApplicability?.maxFailedCount &&
					!task?.featureApplicability?.recurrence &&
					!task.featureApplicability?.metricBasedXp && (
						<div className="mb-10 flex items-center justify-between space-x-8">
							<div className="flex-grow">
								<Label className="block mb-1">
									Reduce XPs for wrong attempts
								</Label>
								<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
									Set max retries that a user can do. Each failure
									reduces user's XP proptionally.
								</p>
							</div>
							<div>
								<InputSelect
									className="bg-white min-w-[200px]"
									value={task?.maxFailedCountConfig.maxFailedCount?.toString()}
									error={errors?.maxFailedCountConfig}
									errorText={
										errors?.maxFailedCountConfig ||
										'Please select max attempts'
									}
									placeholder="Select Max Attempts"
									setValue={(value) => {
										if (errors?.maxFailedCountConfig) {
											setErrors((p) => ({
												...p,
												maxFailedCountConfig: false,
											}));
										}
										if (value === '0') {
											setTask((prev) => ({
												...prev,
												maxFailedCountConfig: {
													maxFailedCount: 0,
													isMaxFailedCountEnabled: false,
													isDiminishingXpEnabled: false,
												},
											}));
										} else
											setTask((prev) => ({
												...prev,
												maxFailedCountConfig: {
													maxFailedCount: +value,
													isMaxFailedCountEnabled: true,
													isDiminishingXpEnabled: true,
												},
												recurrenceConfig: {
													frequencyInDays: 0,
													isRecurring: false,
												},
											}));
									}}
									options={Array(11)
										.fill(0)
										.map((_, i) => {
											return {
												value: i === 0 ? '0' : i.toString(),
												label:
													i === 0
														? 'Select Max Attempts'
														: i.toString(),
											};
										})
										.filter(({ value }) => {
											const i = parseInt(value);
											return i > 0
												? task?.xp && task.xp % i === 0
												: true;
										})}
								/>
							</div>
						</div>
					)}

				{task.featureApplicability?.metricBasedXp &&
				!task?.featureApplicability?.recurrence ? (
					<MetricBasedXp />
				) : null}
			</div>
			<AddContentDialog
				setOpen={setOpen}
				open={open}
				slides={task.slides}
				setSlides={(slides) => {
					setTask((prev) => ({
						...prev,
						slides,
					}));
				}}
			/>
		</div>
	);
};

export default LoyaltyTaskFeatures;
