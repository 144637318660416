import Spinner from '@/components/element/loading/Spinner';
import PaginationComponent from '@/components/element/pagination/PaginationComponent';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { Badge } from '@/components/ui/badge';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { adjustedIntercomLauncherPosition } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { useEffect, useRef } from 'react';
import { KOLCard } from '../components/KOLCard';
import KOLFilters from '../components/KOLFilters';
import { IKols } from '../hooks/useKOLMarketplace';
import {
	useKOLData,
	useKOLFilters,
	useKOLPagination,
} from '../hooks/useKOLPagination';
import useIntercomLauncherPosition from '@/hooks/useIntercomPosition';

const INITIAL_PAGE_SIZE = 10;
const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];

const KOLMarketplaceRoutes = () => {
	const { user } = useAuth();
	const {
		currentPage,
		pageSize,
		setCurrentPage,
		handlePageChange,
		handlePageSizeChange,
	} = useKOLPagination(INITIAL_PAGE_SIZE);
	const { search, setSearch, filters, setFilters, debouncedSearch } =
		useKOLFilters();
	const { kolsData, totalPages, totalCount, status, refetch, isFetching } =
		useKOLData(debouncedSearch, filters, pageSize, currentPage);

	const prevFiltersRef = useRef({ search: '', filters: {} });

	useIntercomLauncherPosition();

	useEffect(() => {
		const prevFilters = prevFiltersRef.current;
		if (
			search !== prevFilters.search ||
			JSON.stringify(filters) !== JSON.stringify(prevFilters.filters)
		) {
			setCurrentPage(1);
			prevFiltersRef.current = { search, filters };
		}
	}, [search, filters, setCurrentPage]);

	useEffect(() => {
		analytics.track(TrackingEvents.KOLMarketplaceViewed, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	}, [user]);

	useEffect(() => {
		refetch();
	}, [pageSize, currentPage, debouncedSearch, filters, refetch]);

	if (status === 'pending' && !isFetching) return <FullPageLoading />;

	return (
		<div className="relative">
			<Badge className="rounded-full px-3" variant="outline">
				<i className="bi-stars me-1 text-yellow-600"></i>
				Build your Hype Tribe
			</Badge>
			<div className="text-2xl font-medium tracking-tight mt-4">
				KOLs Marketplace
			</div>
			<div className="text-md mt-2 mb-4">
				You need a
				<span className="px-1 text-violet-600 font-medium">
					right partner
				</span>
				to reach the world
			</div>
			<div className="sticky top-[64.5px] py-4 z-[40] bg-white">
				<KOLFilters
					search={search}
					filters={filters}
					setSearch={setSearch}
					setFilters={setFilters}
				/>
			</div>
			<div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-8">
				{kolsData?.map((kol: IKols) => <KOLCard key={kol._id} kol={kol} />)}
			</div>
			{isFetching ? (
				<div className="flex items-center justify-center w-full">
					<Spinner />
				</div>
			) : kolsData?.length === 0 ? (
				<div className="flex items-center justify-center w-full">
					<div className="text-sm font-normal text-slate-600">
						No KOLs found.
					</div>
				</div>
			) : null}

			{kolsData?.length > 0 && (
				<div className="mt-5 p-0">
					<PaginationComponent
						currentPage={currentPage}
						totalPages={totalPages}
						totalCount={totalCount}
						pageSize={pageSize}
						pageSizeOptions={PAGE_SIZE_OPTIONS}
						onPageChange={handlePageChange}
						onPageSizeChange={handlePageSizeChange}
						isLoading={isFetching}
					/>
				</div>
			)}
		</div>
	);
};

export default KOLMarketplaceRoutes;
