import { useQuery } from '@tanstack/react-query';
import { getReferral } from '../services/community.service';

export const useGetCommunityReferral = () => {
	const referralQuery = useQuery({
		queryKey: ['community-referral'],
		queryFn: () => getReferral(),
	});

	return {
		isLoading: referralQuery.isLoading,
		data: referralQuery.data || [],
	};
};
