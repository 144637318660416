import Spinner from '@/components/element/loading/Spinner';
import { Input } from '@/components/ui/input';
import { useCreateCampaignValidateAdminInputs } from '@/features/campaigns/hooks/create/useCreateCampaignValidateAdminInputs';
import { cn } from '@/lib/utils';
import { ITaskInputsProps } from './TaskInputs';
import { AdminInputType, TaskKeys } from '@/features/campaigns/types';
import { completeAndSanitizeUrl } from '@/utils/parsers';

const TaskInputString = ({
	adminInput,
	taskId,
	error,
	setErrors,
	editTaskAdminInput,
	templateFamily,
	templateType,
}: ITaskInputsProps) => {
	const { isLoading, validateTaskAdminInput, message } =
		useCreateCampaignValidateAdminInputs(
			taskId,
			adminInput.value,
			adminInput.key,
			setErrors,
		);

	return (
		<>
			<div className="relative">
				<div className="text-sm font-medium mb-1">{adminInput.label}</div>
				{adminInput.description && (
					<div className="text-xs text-muted-foreground mb-1">
						{adminInput.description}
					</div>
				)}
				<Input
					placeholder={adminInput.placeholder}
					type={
						adminInput.inputType === AdminInputType.InputNumber
							? 'number'
							: 'text'
					}
					value={adminInput.value as string}
					className={cn(error && 'border-red-500')}
					onChange={(e) => {
						editTaskAdminInput(taskId, adminInput.key, e.target.value);
					}}
					onBlur={() => {
						if (
							adminInput.key === TaskKeys.DiscordInviteLink ||
							adminInput.key === TaskKeys.TeleGramGroupLink ||
							adminInput.key === TaskKeys.WebsiteUrl
						) {
							const sanitizedValue = completeAndSanitizeUrl(
								adminInput.value as string,
							);
							editTaskAdminInput(
								taskId,
								adminInput.key,
								sanitizedValue,
							);
						}
						if (adminInput.inputType === AdminInputType.InputString) {
							validateTaskAdminInput(
								adminInput.inputType,
								templateFamily,
								templateType,
							);
						}
					}}
				/>
				<div className="absolute top-0 right-0 h-full flex items-center pr-2">
					{isLoading && <Spinner />}
				</div>
			</div>
			{error && (
				<div className="text-red-500 text-xs mt-1">
					{message || 'This field is required'}
				</div>
			)}
		</>
	);
};

export default TaskInputString;
