import StatusBadge from '@/components/element/badges/StatusBadge';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { QUEST_URL } from '@/config';
import { convertToTitleCase } from '@/utils/parsers';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { IAdminEnterprises } from '../../types/admin.types';

export const enterprisesColumns = (options: any): ColumnDef<IAdminEnterprises>[] => {
	const cols: ColumnDef<IAdminEnterprises>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ row }) =>
				row.getValue('name') ? (
					<div className="flex items-center py-1 max-w-[450px] truncate">
						<Avatar className="h-7 w-7 me-2">
							<AvatarImage src={row.original.logo} alt="@shadcn" />
							<AvatarFallback name={row.original.name} />
						</Avatar>
						{row.getValue('name')}
					</div>
				) : (
					<div className="text-muted-foreground"></div>
				),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'emails',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Emails" />
			),
			cell: ({ row }) => (
				<div className="flex items-center py-1 max-w-[450px] truncate">
					{row.original.emails?.length > 0 && (
						<Badge variant="secondary" className="me-1">
							{row.original.emails?.[0]}
						</Badge>
					)}
					{row.original.emails?.length > 1 && (
						<div className="text-muted-foreground">
							+{row.original.emails?.length - 1} more
						</div>
					)}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'leadType',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Lead Type" />
			),
			cell: ({ row }) => (
				<Badge variant="secondary">
					{convertToTitleCase(row.getValue('leadType'))}
				</Badge>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'onboardingStage',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Onboarding Status" />
			),
			cell: ({ row }) => (
				<div className="flex items-center py-1 max-w-[450px] truncate">
					<Badge variant="secondary">
						{convertToTitleCase(row.getValue('onboardingStage'))}
					</Badge>
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'approvalStatus',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Approval Status" />
			),
			cell: ({ row }) => (
				<StatusBadge status={row?.original?.approvalStatus} />
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'createdAt',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Sign Up Date" />
			),
			cell: ({ row }) => (
				<div className="flex items-center py-1 max-w-[450px] truncate">
					{dayjs(row.getValue('createdAt')).fromNow()}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => (
				<div className="flex items-center gap-2">
					<Button
						size="icon"
						variant="ghost"
						onClick={(e) => {
							{
								e.stopPropagation();
								window.open(
									`${QUEST_URL}/project/${row.original._id}`,
									'_blank',
								);
							}
						}}
					>
						<i className="bi-box-arrow-in-up-right"></i>
					</Button>
					<DataTableRowActions row={row} options={options} />
				</div>
			),
		},
	];
	return cols;
};
