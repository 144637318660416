import AboutHero from '../components/about/AboutHero';
import IntractCulture from '../components/about/IntractCulture';
import Investors from '../components/about/Investors';
import IntractMission from '../components/about/Mission';
import IntractCareers from '../components/about/IntractCareers';
import WhyExists from '../components/about/WhyExists';
import LandingLayout from '../components/layout/LandingLayout';

const AboutPage = () => {
	return (
		<LandingLayout>
			<AboutHero />
			<IntractMission />
			<WhyExists />
			<IntractCulture />
			{/* <Investors /> */}
			<IntractCareers />
		</LandingLayout>
	);
};

export default AboutPage;
