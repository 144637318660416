// export const payloadKeyMap = {
// 	wallet: 'wallet',
// 	address: 'wallet',
// 	twitter: 'twitter-handle',
// 	'twitter-id': 'twitter',
// 	'twitter-username': 'twitter-handle',
// 	discord: 'discord-handle',
// 	'discord-id': 'discord-id',
// 	'discord-username': 'discord-handle',
// 	discordHandle: 'discord-handle',
// 	telegram: 'telegram-id',
// 	email: 'email',
// };

/**
 *  key is what clients will add
 *  value is what is pre-defined in the system
 *
 */

export enum ApiDataEnums {
	TwitterId = 'twitter',
	TwitterHandle = 'twitterHandle',
	DiscordId = 'discord',
	DiscordUsername = 'discordUsername',
	TelegramId = 'telegram',
	WalletAddress = 'address',
	Email = 'email',
	InitiationUrl = 'initiationUrl',
	CampaignStartTimestamp = 'campaignStartTimestamp',
	EventStartTimestamp = 'startTimestamp',
	EventEndTimestamp = 'endTimestamp',
	WalletAddressNamespaceTag = 'walletAddressNamespaceTag',

	//internal use
	ConstantValue = 'constantValue',
}
export const iconKeyMap = {
	address: 'currency-bitcoin',
	twitter: 'twitter',
	twitterHandle: 'twitter',
	discordUsername: 'discord',
	discord: 'discord',
	telegram: 'telegram',
	startTimestamp: 'calendar2-date',
	endTimestamp: 'calendar2-date',
	email: 'envelope-at',
	default: 'braces-asterisk',
};

export const ApiValueKeys = [
	{
		value: ApiDataEnums.TwitterId,
		label: 'Twitter Id',
	},
	{
		value: ApiDataEnums.TwitterHandle,
		label: 'Twitter Handle',
	},
	{
		value: ApiDataEnums.DiscordId,
		label: 'Discord Id',
	},
	{
		value: ApiDataEnums.DiscordUsername,
		label: 'Discord Username',
	},
	{
		value: ApiDataEnums.TelegramId,
		label: 'Telegram Id',
	},
	{
		value: ApiDataEnums.WalletAddress,
		label: 'Wallet Address',
	},
	{
		value: ApiDataEnums.Email,
		label: 'Email',
	},
];

export enum CreateTaskAPISteps {
	Basic = 'Basic',
	Payload = 'Payload',
	Curl = 'Curl',
	Testing = 'Testing',
	ReTesting = 'ReTesting',
	Upsert = 'Upsert',
	EvaluateResponse = 'EvaluateResponse',
	ReadyToSave = 'ReadyToSave',
}

export enum TaskAPIStatus {
	Approved = 'APPROVED',
	Rejected = 'REJECTED',
	Submitted = 'SUBMITTED',
	UnderReview = 'UNDER_REVIEW',
	Draft = 'DRAFT',
}
export enum ApiMethod {
	Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Delete = 'DELETE',
}
export enum DataPassingMethod {
	QueryParams = 'QUERY_PARAMS',
	Body = 'BODY',
}
export enum RequestVerification {
	IntractVerified = 'INTRACT_VERIFIED',
	Fuse = 'FUSE',
	Custom = 'CUSTOM',
}
export enum ApiDataFieldsEnums {
	TwitterId = 'twitter',
	TwitterHandle = 'twitterHandle',
	DiscordId = 'discord',
	DiscordUsername = 'discordUsername',
	TelegramId = 'telegram',
	WalletAddress = 'address',
	Email = 'email',
	startTimestamp = 'startTimestamp',
	endTimestamp = 'endTimestamp',
}
export enum ApiVerification {
	EVM = 'evm',
	OffChain = 'offChain',
	NonEVM = 'nonEvm',
}
export enum ApiCredentialTypes {
	Rest = 'REST',
	GraphQl = 'GRAPHQL',
}

// export const dataFieldsMap = {
// 	twitter: 'TwitterId',
// 	twitterHandler: 'TwitterHandle',
// 	discord: 'DiscordId',
// 	discordUsername: 'DiscordUsername',
// 	telegram: 'TelegramId',
// 	address: 'WalletAddress',
// 	email: 'Email',
// 	startTimestamp: 'startTimestamp',
// 	endTimestamp: 'endTimestamp',
// };

export const dataFieldsMap = {
	twitter: 'twitter',
	twitterHandle: 'twitterHandle',
	discord: 'discord',
	discordUsername: 'discordUsername',
	telegram: 'telegram',
	address: 'address',
	email: 'Email',
	startTimestamp: 'startTimestamp',
	endTimestamp: 'endTimestamp',
};

export enum EvaluationFields {
	DataResult = 'result',
	ErrorCode = 'code',
	ErrorMessage = 'message',
	DataMetric = 'metric',
	DataMetricDataType = 'metricDataType',
}

export enum TestingStepMode {
	Initial = 'INITIAL',
	NeedsFormatting = 'NEEDS_FORMATTING',
	ReadyToSave = 'READY_TO_SAVE',
}

export enum ApiCreateSectionEnum {
	Details = 'details',
	Verify = 'verify',
}
