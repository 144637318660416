import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { IUserAffiliations } from '../types/admin-user-affiliation.types';
import { getAdminAffiliateProgram } from '../services/admin.service';
import { useDebounce } from '@/hooks/useDebounce';

export const useGetAdminAffiliateProgram = () => {
	const [search, setSearch] = useState('');
	const debounceSearch = useDebounce(search, 500);

	const { data, isLoading, refetch } = useQuery<IUserAffiliations[]>({
		queryKey: [
			'admin',
			'affiliate-program',
			{
				debounceSearch,
			},
		],
		queryFn: () => getAdminAffiliateProgram(debounceSearch),
	});

	const preprocessData = (data: IUserAffiliations[]) => {
		return (
			data &&
			data.map((user: IUserAffiliations) => {
				if (
					!user?.rewardEvents ||
					!Array.isArray(user.rewardEvents) ||
					user.rewardEvents.length === 0
				) {
					return { _id: user._id, rewardBreakdown: {} };
				}

				const rewardBreakdown: Record<string, any[]> = {};

				user.rewardEvents.forEach((event) => {
					const { eventType } = event;
					if (!rewardBreakdown[eventType]) {
						rewardBreakdown[eventType] = [];
					}

					const formattedRewards = event.reward.map((reward) => {
						const { rewardType, rewardAmount, metadata } = reward;
						if (metadata) {
							return {
								[rewardType]: rewardAmount,
								transactionHash: metadata.transactionHash,
							};
						} else {
							return { [rewardType]: rewardAmount };
						}
					});

					rewardBreakdown[eventType].push(...formattedRewards);
				});

				return {
					_id: user._id,
					rewardBreakdown,
				};
			})
		);
	};

	return {
		search,
		setSearch,
		results: data || [],
		isLoading,
		refetch,
		breakdown: preprocessData(data || []),
	};
};
