import { defineChain } from 'viem';

export const mint = defineChain({
	id: 185,
	name: 'MINT',
	nativeCurrency: { decimals: 18, name: 'ETH', symbol: 'ETH' },
	rpcUrls: {
		default: {
			http: ['https://global.rpc.mintchain.io	', 'https://rpc.mintchain.io'],
			webSocket: ['https://rpc.mintchain.io'],
		},
	},
	blockExplorers: {
		default: {
			name: 'MINT',
			url: 'https://explorer.mintchain.io',
		},
	},
	testnet: false,
});
