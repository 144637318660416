import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';

interface IDropdownOptions {
	type: string | 'item' | 'separator';
	label?: string;
	onClick?: any;
	icon?: string;
	disabled?: boolean;
}

const DotsDropdown = ({
	options,
	className,
}: {
	options: IDropdownOptions[];
	className?: string;
}) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					size="icon"
					className="data-[state=open]:bg-muted h-9 w-6"
				>
					<i className="bi-three-dots-vertical"></i>
					<span className="sr-only">Open menu</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className={cn('w-[160px]', className)}>
				{options.map((item, index) => {
					if (item.type === 'item') {
						return (
							<DropdownMenuItem
								key={`item-${index}-${item.label}`}
								onClick={item.onClick}
								disabled={item.disabled}
							>
								{item.icon && <i className={item.icon}></i>}
								{item.label}
							</DropdownMenuItem>
						);
					}
					if (item.type === 'separator') {
						return <DropdownMenuSeparator key={`separator-${index}`} />;
					}
					return null;
				})}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default DotsDropdown;
