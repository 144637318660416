import { ColumnDef } from '@tanstack/react-table';
import { IBanner } from './useCreateBannerSections';
import { Grip } from 'lucide-react';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { Media } from '@/components/element/media/Media';
import { useConfirm } from '@/hooks/useConfirm';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';

const useBannerColumns = ({
	handleBannerDelete,
	setFormFields,
	setOpenCreateBanner,
}) => {
	const { show } = useConfirm();

	const openDeleteConfirmation = async (id: string) => {
		const confirm = await show({
			title: 'Delete this banner?',
			subtitle: `Are you sure you want to delete banner from this enterprise?`,
			confirmText: 'Delete',
			cancelText: 'Cancel',
		});
		if (!confirm) {
			return;
		}

		handleBannerDelete(id);
	};

	const options = (row: any) => [
		{
			type: 'button',
			label: 'Edit',
			onClick: () => {
				setFormFields((prev) => ({
					...prev,
					_id: row?.original?._id,
					banner: row?.original?.banner,
					enterpriseId: row?.original?.enterpriseId,
					index: row?.original?.index,
					title: row?.original?.title,
					description: row?.original?.description,
					link: row?.original?.link,
					startDate: row?.original?.startDate,
					endDate: row?.original?.endDate,
				}));
				setOpenCreateBanner(true);
			},
		},
		{
			type: 'button',
			label: 'Delete',
			onClick: () => {
				openDeleteConfirmation(row?.original?._id);
			},
		},
	];

	const columns: ColumnDef<IBanner>[] = [
		{
			id: 'drag',
			cell: () => (
				<div className="cursor-move">
					<Grip className="size-4" />
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'banner',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Banner" />
			),
			cell: ({ row }) => (
				<div className="py-1 px-1">
					<Media
						src={row?.original?.banner}
						altText="banner"
						className="h-10 rounded-md shadow-sm"
					/>
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'title',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className="px-1"
				/>
			),
			cell: ({ row }) => (
				<div className="py-1 px-1">{row?.original?.title}</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => (
				<DataTableRowActions row={row} options={options(row) as any} />
			),
		},
	];
	return {
		columns,
	};
};

export default useBannerColumns;
