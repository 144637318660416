import { getUser } from '@/features/auth/services/auth.service';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { UserType } from '@/providers/AuthProvider';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import {
	checkIfTokenRewardTxnAlreadyDone,
	getCampaign,
	updateCampaignStatus,
} from '../../services/campaigns.service';
import { CampaignRewardCategory, ICampaign, Status } from '../../types';
import { useCampaignReport } from '../report/useCampaignReport';
import { useDepositToken } from './useDepositToken';

export const useCampaignLaunch = ({
	campaignId,
	startImmediately,
}: {
	campaignId: string;
	startImmediately: boolean;
	setOpen: (value: boolean) => void;
}) => {
	const navigate = useNavigate();
	const { botFilteringMethods } = useCampaignReport();

	const { data: campaign, isLoading: isCampaignLoading } = useQuery<ICampaign>({
		queryKey: ['campaign', campaignId],
		queryFn: () => getCampaign(campaignId),
		enabled: !!campaignId,
	});
	const tokenReward = campaign?.reward?.find(
		(reward) => reward.category === CampaignRewardCategory.Token,
	);
	const [step, setStep] = useState('confirmation');
	const [isLoading, setIsLoading] = useState(false);
	const {
		startProcess,
		status,
		isLoading: isDeposting,
	} = useDepositToken({
		campaignId: campaignId,
		tokenReward,
	});
	const [isTxnRequired, setIsTxnRequired] = useState(tokenReward ? true : false);

	const { data, error } = useQuery({
		queryKey: ['campaign', 'reward-txn', campaignId],
		queryFn: () => checkIfTokenRewardTxnAlreadyDone(campaignId),
		enabled:
			!!campaignId &&
			!!tokenReward &&
			!isCampaignLoading &&
			!campaign?.rewardTransaction?.txHash,
	});

	useEffect(() => {
		if (data?.logFound) {
			toast.error('Token deposit txn already done');
			setIsTxnRequired(false);
		}
	}, [data, error]);

	useEffect(() => {
		if (!campaign && tokenReward) {
			setIsTxnRequired(true);
		} else if (!campaign?.rewardTransaction?.txHash && tokenReward) {
			setIsTxnRequired(true);
		} else {
			setIsTxnRequired(false);
		}
	}, [campaign, tokenReward]);

	const launchCampaign = async () => {
		try {
			setIsLoading(true);

			await updateCampaignStatus(campaignId, {
				newStatus: Status.Active,
				includeCaptcha: botFilteringMethods?.includeCaptcha,
				includePOH: botFilteringMethods?.includePOH,
			});

			analytics.track(TrackingEvents.LaunchQuestCompleted, {
				campaignId,
				campaignStatus: startImmediately ? Status.Active : Status.Scheduled,
			});

			toast.success('Campaign has been launched successfully');

			await queryClient.invalidateQueries({
				queryKey: ['campaign', campaignId],
			});

			// Update the campaigns table data if it exists in the cache
			const existingTableData = queryClient.getQueryData<any[]>(['campaigns']);
			if (existingTableData) {
				queryClient.setQueryData(
					['campaigns'],
					existingTableData.map((item) =>
						item._id === campaignId
							? {
									...item,
									status: startImmediately
										? Status.Active
										: Status.Scheduled,
								}
							: item,
					),
				);
			}

			// Fetch updated user and check if triggerFlags is set
			const updatedUser: UserType = await getUser();
			queryClient.setQueryData(['user'], updatedUser);
			const triggerFlags = updatedUser?.triggerFlags;
			const shouldOpen =
				triggerFlags?.credits &&
				Object.values(triggerFlags.credits).some(Boolean);

			// Define navigation URL based on the credits flag
			if (!shouldOpen) {
				navigate(`/app/campaign/quests/${campaignId}?share-celebrate=true`);
			}

			await queryClient.invalidateQueries({ queryKey: ['enterprise'] });

			setIsLoading(false);
			// setOpen?.(false);
		} catch (err) {
			console.error(err);
			handleErrorMessage(err);
			setIsLoading(false);
		}
	};

	const handleNext = async () => {
		if (step === 'confirmation') {
			analytics.track(TrackingEvents.LaunchQuestConfirmed, {
				campaignId,
				isTokenReward: !!tokenReward,
				isTxnRequired: isTxnRequired,
			});
		}
		if (step === 'confirmation' && tokenReward && isTxnRequired) {
			setStep('token');
			return;
		} else if (step === 'confirmation') {
			await launchCampaign();
			return;
		} else if (step === 'token') {
			const res = await startProcess();
			if (res) {
				await launchCampaign();
			}
			return;
		}
	};

	const btnLabel = useMemo(() => {
		if (step === 'confirmation') {
			if (tokenReward && isTxnRequired) return 'Continue to Deposit Tokens';
			return 'Launch Campaign';
		}
		if (step === 'token') {
			return status;
		}
	}, [step, tokenReward, status, isTxnRequired]);

	return {
		step,
		setStep,
		handleNext,
		isLoading: isLoading || isDeposting,
		btnLabel,
		isCampaignLoading,
	};
};
