import { cn } from '@/lib/utils';

const LineTabs = ({
	options,
	selected,
	setSelected,
	className,
}: {
	options: { value: string; label: string; icon?: string; disabled?: boolean }[];
	selected: string;
	setSelected: (value: any) => void;
	className?: string;
}) => {
	return (
		<div className={className}>
			<ul className={`grid grid-cols-${options.length}`}>
				{options.map((i) => (
					<li
						key={i.value}
						className={cn(
							'text-center text-base tracking-[0.2px] font-normal pb-1 cursor-pointer w-24',
							selected === i.value
								? 'text-slate-900 border-b-2 border-purple-500'
								: 'text-slate-500 ',
							i.disabled && 'cursor-not-allowed opacity-50',
						)}
						onClick={() => !i.disabled && setSelected(i.value)}
					>
						{i.icon && <i className={cn(i.icon, 'me-2')}></i>}
						{i.label}
					</li>
				))}
			</ul>
		</div>
	);
};

export default LineTabs;
