import { Navigate, Route, Routes } from 'react-router-dom';
import CmsLayout from '../components/content-management-system/CmsLayout';
import AdminBanners from './admin-banners.page';
import AdminWidgets from './admin-widgets.page';
import AdminQOTD from './admin-qotd.page';
import AdminSearchQuests from './admin-search-quests.page';
import { WidgetCreateProvider } from '../providers/WidgetCreateProvider';

export default function AdminCMSRoute() {
	return (
		<CmsLayout>
			<Routes>
				<Route path="banners" element={<AdminBanners />} />
				<Route
					path="widgets"
					element={
						<WidgetCreateProvider>
							<AdminWidgets />
						</WidgetCreateProvider>
					}
				/>
				<Route path="quest-of-the-day" element={<AdminQOTD />} />
				<Route path="search-quests" element={<AdminSearchQuests />} />
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		</CmsLayout>
	);
}
