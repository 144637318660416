import leaderboard from '@/features/landing/assets/leaderboard2.png';
import rewards from '@/features/landing/assets/rewards.png';
import quests from '@/features/landing/assets/quests.png';
import LandingContainer from '../ui/LandingContainer';

const Hero2 = () => {
	return (
		<LandingContainer>
			<div className="mx-auto w-full max-w-screen-xl pt-0 md:pt-0 lg:pt-0 pb-7 md:pb-8 lg:pb-10 hidden md:flex">
				<div className="relative space-y-12 md:space-y-20 is-wide">
					<div className="relative z-10">
						<div
							className="is-wide flex flex-col md:flex-row gap-6"
							style={{ opacity: 1 }}
						>
							<div className="relative self-start overflow-hidden rounded-lg max-w-[235px] max-h-[595px] w-full md:w-auto border-4 border-violet-100 mx-auto md:mx-0">
								<img
									alt="Frame 1948758263"
									loading="eager"
									width={790}
									height={998}
									decoding="async"
									data-nimg={1}
									className="transition-opacity w-full self-start overflow-hidden rounded-xl opacity-100"
									src={rewards}
									style={{ color: 'transparent' }}
								/>
							</div>
							<div className="relative self-start overflow-hidden rounded-lg w-full  aspect-auto md:max-w-[41.6875rem] md:aspect-[667/450]">
								<div className="relative !h-full !w-full overflow-hidden rounded-xl ">
									<div
										className="md:mx-auto mx-4 aspect-auto md:aspect-[667/450]"
										style={{
											maxWidth: '41.6875rem',
										}}
									>
										<img
											alt="Frame 1948758263"
											loading="eager"
											width={790}
											height={998}
											decoding="async"
											data-nimg={1}
											className="transition-opacity w-full border-4 border-violet-100 self-start overflow-hidden rounded-xl opacity-100"
											src={quests}
											style={{ color: 'transparent' }}
										/>
									</div>
								</div>
							</div>
							<div className="relative self-start overflow-hidden rounded-lg max-w-[295px] max-h-[595px] w-full md:w-auto border-4 border-violet-100 mx-auto md:mx-0">
								<img
									alt="Frame 1948758263"
									loading="eager"
									width={790}
									height={998}
									decoding="async"
									data-nimg={1}
									className="transition-opacity w-full self-start overflow-hidden rounded-xl opacity-100"
									src={leaderboard}
									style={{ color: 'transparent' }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default Hero2;
