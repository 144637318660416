import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import LandingContainer from '../ui/LandingContainer';

const FAQs = () => {
	return (
		<div className="border-t border-gray-200 bg-white/10 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur py-10 mt-20">
			<LandingContainer className="mx-auto w-full max-w-screen-xl 5  my-20">
				<div className="grid grid-cols-1 gap-5 md:grid-cols-3">
					<div className="">
						<h2 className="font-display text-4xl font-extrabold text-black [text-wrap:balance] sm:text-5xl sm:leading-tight">
							Frequently Asked Questions
						</h2>
					</div>
					<div
						className="col-span-2 px-3 sm:px-0"
						data-orientation="vertical"
					>
						<Accordion type="single" collapsible className="w-full ">
							{faqs.map((faq, index) => (
								<AccordionItem value={`item-${index}`}>
									<AccordionTrigger className="text-left md:text-lg">
										{faq.question}
									</AccordionTrigger>
									<AccordionContent className="text-gray-500 transition-all sm:text-base">
										{faq.answer}
									</AccordionContent>
								</AccordionItem>
							))}
						</Accordion>
					</div>
				</div>
			</LandingContainer>
		</div>
	);
};

export default FAQs;

const faqs = [
	{
		question: 'Why Intract ?',
		answer: 'Intract excels in facilitating advanced on-chain interactions by focusing on real users rather than bots. Intract guarantees a larger user base through dedicated campaign success and comprehensive co-marketing support',
	},
	{
		question: 'What is the cost of launching a quest on Intract?',
		answer: 'We do not charge any fees for running quests, allowing you to focus on creating the most engaging and effective campaign possible without worrying about any other additional costs.',
	},
	{
		question: 'Will Intract assist in marketing our quest?',
		answer: 'Absolutely! Intract guarantees to attract more real users by offering top-notch dedicated campaign success and comprehensive co-marketing support. With access to our extensive network of over 12 million users and more than 1,000 key opinion leaders (KOLs), your quest is set to make a significant impact.',
	},
	{
		question:
			'How many users can a project expect to attract through their quest?',
		answer: 'The number of users a campaign attracts can vary greatly, influenced by factors such as the complexity and appeal of the campaign, as well as the type of the reward pool. While results can range from as few as 1,000 users to as many as 1 million, the exact number will depend on the specific characteristics of your campaign.',
	},
	{
		question: 'How do you verify if users are real?',
		answer: `We take user authenticity seriously, and we've got a few tricks up our sleeve to ensure everyone on our platform is the real deal. First off, we use some cool Proof of Humanity solutions like zkPass, Gitcoin, and Nomis. These help us verify that our users are who they say they are. But that's not all—we also check out users' social personas. By looking at their Twitter, Discord, and other accounts, we run our own algorithm to get a sense of how human they really are. Plus, we keep an eye on wallet activity to spot any suspicious behavior. And to top it off, we use advanced fingerprinting technology. This sophisticated system analyzes various device and browser characteristics to uniquely identify users and prevent multiple account fraud. It's a method trusted by banks and financial institutions, so you know it's solid. So, rest assured, we've got a robust system in place to keep things genuine and trustworthy on our platform.`,
	},
	{
		question:
			'How can we obtain co-marketing support to boost our campaign on Intract?',
		answer: 'You can secure co-marketing support by advancing through the tiers of the Intract Credit System. Each tier offers different levels of marketing support, enabling you to amplify your campaign’s visibility and success as you progress.',
	},
	{
		question: 'Where are the users on the Intract platform located?',
		answer: 'Intract boasts a diverse user base spread across more than 250 countries worldwide, top 10 being Vietnam, United States, Hong Kong, Nigeria, Ukraine, India, Indonesia, United Kingdom, Netherlands and Germany.',
	},
];
