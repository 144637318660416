import SectionWrapper from '../components/sections/SectionWrapper';
import ApisCreateLayout from '../layout/ApisCreateLayout';

const ApisCreatePage = () => {
	return (
		<ApisCreateLayout>
			<SectionWrapper />
		</ApisCreateLayout>
	);
};

export default ApisCreatePage;
