import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import useAcceptInvite from '@/features/auth/hooks/useAcceptInvite';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useAuth } from '@/hooks/useAuth';
import { useRouter } from '@/hooks/useRouter';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import { useCallback, useEffect, useState } from 'react';
import QuickAccess from '../components/home/QuickAccess';
import SuggestedActions from '../components/home/SuggestedActions';
import GlobalSearch from '../components/search/GlobalSearch';

const DashboardHomePage = () => {
	const { user, refetchUser } = useAuth();
	const [greetings, setGreetings] = useState('');
	const { enterprise } = useGetEnterprise();
	const { query } = useRouter();
	const [open, setOpen] = useState(false);

	const { acceptInvitation, checkHashValidity, isPending } =
		useAcceptInvite(query);

	useEffect(() => {
		if (!user) {
			refetchUser();
		}
	}, [user, refetchUser]);

	const changeGreetings = useCallback(() => {
		const hrs = new Date().getHours();
		if (hrs < 12) {
			setGreetings('☀️ Good morning');
		} else if (hrs >= 12 && hrs < 17) {
			setGreetings('☀️ Good afternoon');
		} else if (hrs >= 17 && hrs < 21) {
			setGreetings('🌙 Good evening');
		} else {
			setGreetings('🌖 Looks like you are burning midnight oil');
		}
	}, []);

	useEffect(() => {
		changeGreetings();
		const interval = setInterval(changeGreetings, 60000);
		return () => clearInterval(interval);
	}, [changeGreetings]);

	useEffect(() => {
		if (enterprise?._id) {
			analytics.track(TrackingEvents.EnterpriseApprovalStatus, {
				enterpriseId: enterprise._id,
				isApproved: enterprise.isApproved ?? null,
			});

			localStorage.removeItem('utmParams');
		}
	}, [enterprise]);

	useEffect(() => {
		const handleInvitation = async () => {
			if (query?.hash && !isPending) {
				try {
					await acceptInvitation();
				} catch (error) {
					handleErrorMessage(error);
				}
			}
		};

		handleInvitation();
	}, [query]);

	// useEffect(() => {
	// 	const triggerFlags = user?.triggerFlags;
	// 	if (triggerFlags.credits && typeof triggerFlags.credits === 'object') {
	// 		const shouldOpen = Object.values(triggerFlags.credits).some(
	// 			(value) => value === true,
	// 		);
	// 		if (shouldOpen) {
	// 			setOpen(true);
	// 		}
	// 	}
	// }, [user?.triggerFlags]);

	if (!user) {
		<FullPageLoading />;
	}

	return (
		<div>
			<div className="grid grid-cols-12 mt-5">
				<div className="col-span-2"></div>
				<div className="col-span-8 space-y-10 mb-20">
					<div className="col-span-4">
						<div className="text-2xl font-medium mb-10">
							{greetings}, {user?.name}
						</div>
					</div>
					<GlobalSearch />
					<SuggestedActions />
					<QuickAccess />
					{/* <Guides /> */}
				</div>
				<div className="col-span-2"></div>
			</div>
			{/* <CreditsRewarded
				open={open}
				setOpen={setOpen}
				triggerFlags={{ ...user?.triggerFlags?.credits }}
				refetchUser={refetchUser}
			/> */}
		</div>
	);
};

export default DashboardHomePage;
