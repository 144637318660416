import { useState } from 'react';
import useCreateBannerSections, {
	IBanner,
} from '../../../hooks/admin-cms-banners/useCreateBannerSections';
import { Button } from '@/components/ui/button';
import ChooseBannerCreateMethod from './ChooseBannerCreateMethod';
import CreateBanner from './CreateBanner';
import useBannerColumns from '@/features/admin/hooks/admin-cms-banners/useBannerColumns';
import useBannerCreate from '@/features/admin/hooks/admin-cms-banners/useBannerCreate';
import { DataTableWithDnd } from '@/components/element/data-table/DataTableWithDnD';

const HomepageBanner = () => {
	const {
		sections,
		setSections,
		isLoading,
		showBannerCreateMethod,
		setShowBannerCreateMethod,
		openCreateBanner,
		setOpenCreateBanner,
		handleReorder,
		handleBannerDelete,
		saveBannerOrder,
		isSubmitting,
	} = useCreateBannerSections();

	const {
		formFields,
		setFormFields,
		formErrors,
		handleSubmit,
		resetForm,
		isPending,
	} = useBannerCreate({
		setOpenCreateBanner,
	});

	const { columns } = useBannerColumns({
		handleBannerDelete,
		setFormFields,
		setOpenCreateBanner,
	});

	const [showChooseQuest, setShowChooseQuest] = useState(false);

	return (
		<>
			<DataTableWithDnd
				data={sections}
				columns={columns}
				totalCount={sections?.length || 0}
				isLoading={isLoading}
				onReorder={handleReorder}
			/>

			<div className="flex items-center gap-4 my-5">
				<Button onClick={() => setShowBannerCreateMethod(true)}>
					+ Add your banner
				</Button>
				<Button
					onClick={saveBannerOrder}
					variant="outline"
					disabled={isSubmitting}
				>
					{isSubmitting ? (
						<i className="bi-arrow-clockwise animate-spin me-2"></i>
					) : null}
					<span>Save Order</span>
				</Button>
			</div>

			<ChooseBannerCreateMethod
				open={showBannerCreateMethod}
				setOpen={setShowBannerCreateMethod}
				showChooseQuest={showChooseQuest}
				setShowChooseQuest={setShowChooseQuest}
				setOpenCreateBanner={setOpenCreateBanner}
				setFormFields={setFormFields}
				formFields={formFields}
				questOptions={sections}
			/>
			<CreateBanner
				open={openCreateBanner}
				setOpen={setOpenCreateBanner}
				formFields={formFields}
				setFormFields={setFormFields}
				formErrors={formErrors}
				handleSubmit={handleSubmit}
				resetForm={resetForm}
				isPending={isPending}
			/>
		</>
	);
};

export default HomepageBanner;
