export enum FilterMechanism {
	TxnTo = 'TXN_TO',
	TxnFrom = 'TXN_FROM',
	Event = 'EVENT',
	TxnToAndFuncSign = 'TXN_TO_AND_FUNC_SIGN',
	TxnToAndEvent = 'TXN_TO_AND_EVENT',
	TxnToAndFuncSignAndEvent = 'TXN_TO_AND_FUNC_SIGN_AND_EVENT',
	TxnFromAndFuncSign = 'TXN_FROM_AND_FUNC_SIGN',
	TxnFromAndEvent = 'TXN_FROM_AND_EVENT',
	TxnFromAndFuncSignAndEvent = 'TXN_FROM_AND_FUNC_SIGN_AND_EVENT',
}
export enum UserAddressSource {
	TxnFrom = 'TXN_FROM',
	TxnTo = 'TXN_TO',
	FuncCalldata = 'FUNC_CALLDATA',
	Event = 'EVENT',
	FuncCalldataArray = 'FUNC_CALLDATA_ARRAY',
}
export enum USDAmountSource {
	TransferLogs = 'TRANSFER_LOGS',
}
export enum TokenMovementDirection {
	Inflow = 'INFLOW',
	Outflow = 'OUTFLOW',
}
export enum QueryCategory {
	SingleTransaction = 'SINGLE_TRANSACTION',
	MultipleTransaction = 'MULTIPLE_TRANSACTION',
}
export enum QueryEligibleTokenAddressTag {
	Any = 'ANY',
	List = 'LIST',
}
export enum QueryConditionType {
	NoAmountCheck = 'NO_AMOUNT_CHECK',
	InflowAndOutflow = 'INFLOW_AND_OUTFLOW',
	InflowPlusOutflow = 'INFLOW_PLUS_OUTFLOW',
}

export enum DaveActionCreateSectionEnum {
	Details = 'details',
	Intractions = 'intractions',
}

export enum DaveQueryCreateSectionEnum {
	Filters = 'filters',
}

export enum PeggedToken {
	ETH = 'ETH',
	USD = 'USD',
	NONE = 'NONE',
}
