export enum CreditRequestTypeEnum {
	AirdropWidget = 'AIRDROP_WIDGET',
	BuzzingWidget = 'BUZZING_WIDGET',
	ReTweetTwitter = 'RETWEET_TWITTER',
	GeneralNotification = 'GENERAL_NOTIFICATION',
	LeaderBoardExport = 'LEADERBOARD_EXPORT',
	ChainIntegration = 'CHAIN_INTEGRATION',
	VerificationTwitter = 'VERIFICATION_TWITTER',
	SignupCredits = 'SIGNUP_CREDITS',
	PublishYourFirstQuest = 'PUBLISH_YOUR_FIRST_QUEST',
}

export enum CreditRequestBaseTypeEnum {
	Consume = 'CONSUME',
	Earn = 'EARN',
}

export enum CreditRequestStatusEnum {
	Pending = 'PENDING',
	Approved = 'APPROVED',
	Rejected = 'REJECTED',
}

export enum CreditBaseCtaEnum {
	Apply = 'APPLY',
	createQuest = 'CREATE_QUEST',
	viewQuests = 'VIEW_QUESTS',
	Claimed = 'CLAIMED',
	Applied = 'APPLIED',
	OptIn = 'OPT_IN',
	OptedIn = 'OPTED_IN',
}

export enum CreditTransactionTypeEnum {
	minimunBalanceRechargeCredit = 'MINIMUN_BALANCE_RECHARGE_CREDIT',
	minimunBalanceRechargeDebit = 'MINIMUN_BALANCE_RECHARGE_DEBIT',
	enterpriseLockedDebit = 'ENTERPRISE_LOCKED_DEBIT',
	enterpriseRejectionRefundCredit = 'ENTERPRISE_REJECTION_REFUND_CREDIT',
	enterpriseBenefitAcceptDebit = 'ENTERPRISE_BENEFIT_ACCEPT_DEBIT',
	enterpriseBenefitAcceptCredit = 'ENTERPRISE_BENEFIT_ACCEPT_CREDIT',
}

export enum DataExportTypeEnum {
	'Leaderboard' = 'LEADERBOARD',
	'Winners' = 'WINNERS',
}

export enum CreditRequestValueEnum {
	questId = 'QUEST_ID',
	questTitle = 'QUEST_TITLE',
	text = 'TEXT',
	link = 'LINK',
	DataExport = 'DATA_EXPORT',
	taskIds = 'TASK_IDS',
}
