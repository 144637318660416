import { Separator } from '@/components/plate-ui/separator';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div className="border rounded-md p-3 backdrop-filter bg-white ">
				<p className="text-sm leading-4 w-full flex items-center justify-between">
					XPs:
					<span className="!text-right text-sm leading-4 tracking-[0.117px] font-medium">
						{Math.round(payload[0].payload.y)}
					</span>
				</p>
				<Separator className="my-2" />
				<p className="text-sm w-full flex items-center justify-between">
					Volume:&nbsp;
					<span className="!text-right text-sm leading-4 tracking-[0.117px] font-medium">
						{payload[0].payload.x}
					</span>
				</p>
			</div>
		);
	}
	return null;
};

const generateLinearData = (m: number, b: number, maxXp: number) => {
	if (m === 0) return [];
	const data = [];
	const maxXValue = (maxXp - b) / m;
	const upperLimit = Math.ceil(maxXValue);
	for (let x = 0; x <= Math.ceil(maxXp / m + 10); x++) {
		let y = m * x + b;
		if (y >= maxXp) y = maxXp;
		data.push({ x, y });
	}
	return data;
};

const LinearGraph = ({ xpMultiplier, maxXP, width = 550, height = 300 }) => {
	const b = 0; // Y-intercept remains constant
	const data = generateLinearData(parseFloat(xpMultiplier), b, parseFloat(maxXP));

	return (
		<LineChart
			width={width}
			height={height}
			data={data}
			margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
			className="bg-slate-100 rounded-lg p-4"
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis type="number" dataKey="x" className="text-sm" />
			<YAxis className="text-sm" />
			<Tooltip content={CustomTooltip} />
			<Line type="linear" dataKey="y" stroke="#8884d8" dot={false} />
		</LineChart>
	);
};

const generateTieredData = (tiers) => {
	const data = [];
	if (!tiers || tiers.length === 0) return [{ x: 0, y: 0 }];

	// Check if the first tier's minMetric is not provided or less than 0
	if (!tiers[0].minMetric || parseFloat(tiers[0].minMetric) <= 0) {
		data.push({ x: 0, y: parseFloat(tiers[0].tierXp) });
	} else {
		data.push({ x: 0, y: 0 });
		data.push({ x: parseFloat(tiers[0].minMetric), y: 0 });
	}

	tiers.forEach((tier, index) => {
		data.push({
			x: parseFloat(tier.minMetric) || 0,
			y: parseFloat(tier.tierXp),
		});
		data.push({
			x: parseFloat(tier.maxMetric) || data[data.length - 1].x + 10,
			y: parseFloat(tier.tierXp),
		});

		if (
			tiers[index + 1] &&
			parseFloat(tier.maxMetric) < parseFloat(tiers[index + 1].minMetric)
		) {
			data.push({ x: parseFloat(tier.maxMetric), y: 0 });
			data.push({ x: parseFloat(tiers[index + 1].minMetric), y: 0 });
		}
	});

	const lastTier = tiers[tiers.length - 1];
	const lastMaxMetric = parseFloat(lastTier.maxMetric);
	const lastXp = parseFloat(lastTier.tierXp);

	if (!Number.isNaN(lastMaxMetric)) {
		data.push({
			x: lastMaxMetric,
			y: lastXp,
		});
		data.push({
			x: lastMaxMetric,
			y: lastXp,
		});
	} else {
		const lastX = data[data.length - 1]?.x || 0;
		data.push({
			x: lastX + 10,
			y: lastXp,
		});
	}

	data.sort((a, b) => a.x - b.x);

	return data;
};

const TieredGraph = ({ metricBasedXpConfig, width = 550, height = 300 }) => {
	const data = generateTieredData(metricBasedXpConfig?.tieredMetadata?.tiers);

	return (
		<LineChart
			width={width}
			height={height}
			data={data}
			margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
			className="bg-slate-100 rounded-lg p-4"
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis type="number" dataKey="x" />
			<YAxis />
			<Tooltip content={CustomTooltip} />
			<Line type="step" dataKey="y" stroke="#8884d8" strokeWidth={2} />
		</LineChart>
	);
};

const generateSigmoidData = (L: number, k: number, x0: number) => {
	const data = [];
	for (let x = 0; x <= x0 + 150; x += 5) {
		const y = L / (1 + Math.exp(-k * (x - x0)));
		data.push({ x, y });
	}
	return data;
};

const SigmoidGraph = ({
	optimalMetricValue,
	rateOfIncrease,
	maxXP,
	width = 550,
	height = 300,
}) => {
	const data = generateSigmoidData(
		parseFloat(maxXP),
		parseFloat(rateOfIncrease),
		parseFloat(optimalMetricValue),
	);
	return (
		<LineChart
			width={width}
			height={height}
			data={data}
			margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
			className="bg-slate-100 rounded-lg p-4"
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis
				dataKey="x"
				type="number"
				domain={[0, parseFloat(optimalMetricValue) + 150]}
			/>
			<YAxis domain={[0, parseFloat(maxXP)]} />
			<Tooltip content={CustomTooltip} />
			<Line type="monotone" dataKey="y" stroke="#8884d8" dot={false} />
		</LineChart>
	);
};

export { LinearGraph, TieredGraph, SigmoidGraph };
