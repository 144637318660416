import { Button } from '@/components/ui/button';

const AddWidgetButton = ({
	setShowAddWidget,
}: {
	setShowAddWidget: (show: boolean) => void;
}) => {
	return (
		<div>
			<Button
				variant="outline"
				className="w-full text-sm font-normal"
				onClick={() => setShowAddWidget(true)}
			>
				<i className="bi bi-plus-circle me-2"></i>Create New Widget
			</Button>
		</div>
	);
};

export default AddWidgetButton;
