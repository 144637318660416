import TabOptions from '@/components/element/tabs/TabOptions';
import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { ABIPreview } from '@/features/dave/components/contracts/ConfirmContractABI';
import { IDaveContracts } from '@/features/dave/types/dave.types';
import { parseJSON } from '@/utils/parsers';
import { useMemo, useState } from 'react';

function ContractABI({
	open,
	setOpen,
	selectedContract,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
	selectedContract: IDaveContracts;
}) {
	const [selected, setSelected] = useState('preview');

	const abi = useMemo(() => {
		if (!selectedContract || !selectedContract?.abi) return [];
		const abi = parseJSON(selectedContract?.abi || '[]');
		return [...abi];
	}, [selectedContract]);

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetContent className="min-w-[550px] p-5 pt-10 overflow-y-auto pb-10">
				<div className="mb-4 font-medium">
					Contract {selectedContract?.name} ABI
				</div>
				<div className="flex mb-10">
					<TabOptions
						className="w-full"
						selected={selected}
						setSelected={setSelected}
						options={[
							{
								label: 'Preview',
								value: 'preview',
							},
							{
								label: 'Raw',
								value: 'raw',
							},
						]}
					/>
				</div>
				{selected === 'preview' && (
					<ABIPreview abi={JSON.stringify(abi)} maxHeight="max-h-[30vh]" />
				)}
				{selected === 'raw' && (
					<div className="mt-5 rounded bg-muted p-4">
						<code
							className="font-mono text-sm font-medium"
							style={{
								whiteSpace: 'pre',
							}}
						>
							{JSON.stringify(abi, null, 4)}
						</code>
					</div>
				)}
			</SheetContent>
		</Sheet>
	);
}

export default ContractABI;
