import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { ErrorsState, IAPIFormFields, ParsedData } from '../types/task-apis.type';
import {
	ApiCreateSectionEnum,
	ApiCredentialTypes,
	ApiDataEnums,
	CreateTaskAPISteps,
} from '../types/task-apis.enum';
import { usePrompt } from '@/hooks/usePrompt';

const defaultValue: IAPIFormFields = {
	apiName: '',
	apiMethod: 'POST',
	apiEndpoint: '',
	apiPayloadMethod: 'BODY',
	apiVerificationAction: 'evm',
	apiPayload: [
		{
			key: 'address',
			value: ApiDataEnums.WalletAddress,
			testValue: '',
		},
	],
	apiHeaders: [
		{
			key: 'Authorization',
			value: '',
		},
	],
	isHeaderRequired: false,
	isRecurringTasksEnabled: false,
	isMetricBasedTaskEnabled: false,
	isApiTestDone: false,
	isApiSchemaValid: false,
	responseSchema: {},
	_id: '',
	apiCredentialsType: ApiCredentialTypes.Rest,
	apiConstantDataFields: [],
	graphQlQuery: '',
	apiOutputExpressions: {
		result: {
			expression: 'return response?.data?.result',
		},
		code: {
			expression: '',
		},
		message: {
			expression: '',
		},
	},
};

interface ConnectAPIState {
	formFields: IAPIFormFields;
	setFormFields: Dispatch<SetStateAction<IAPIFormFields>>;
	formErrors: any;
	setFormErrors: any;
	resetForm: any;
	isLoading: boolean;
	setIsLoading: any;
	step: CreateTaskAPISteps;
	setStep: Dispatch<SetStateAction<CreateTaskAPISteps>>;
	parsedData: ParsedData;
	setParsedData: Dispatch<SetStateAction<ParsedData>>;
	evaluationErrors: ErrorsState;
	setEvaluationErrors: Dispatch<SetStateAction<ErrorsState>>;
	evaluatedFields: Record<string, boolean>;
	setEvaluatedFields: Dispatch<SetStateAction<Record<string, boolean>>>;
	dialogMaxWidth: string;
	setDialogMaxWidth: Dispatch<SetStateAction<string>>;
	setIsDirty: Dispatch<SetStateAction<boolean>>;
	section: ApiCreateSectionEnum;
	setSection: Dispatch<SetStateAction<ApiCreateSectionEnum>>;
	contextualSuggestions: string | null;
	setContextualSuggestions: Dispatch<SetStateAction<string | null>>;
	completedSteps: CreateTaskAPISteps[];
	setCompletedSteps: Dispatch<SetStateAction<CreateTaskAPISteps[]>>;
	validateRequiredSteps: any;
	animateWiggle: boolean;
}

const initialState: ConnectAPIState = {
	formFields: defaultValue,
	setFormFields: () => {},
	formErrors: {},
	setFormErrors: () => {},
	resetForm: () => {},
	isLoading: false,
	setIsLoading: () => {},
	step: CreateTaskAPISteps.Basic,
	setStep: () => {},
	parsedData: null,
	setParsedData: () => {},
	evaluationErrors: {},
	setEvaluationErrors: () => {},
	evaluatedFields: {},
	setEvaluatedFields: () => {},
	dialogMaxWidth: '35rem',
	setDialogMaxWidth: () => {},
	setIsDirty: () => {},
	section: ApiCreateSectionEnum.Details,
	setSection: () => {},
	contextualSuggestions: null,
	setContextualSuggestions: () => {},
	completedSteps: [],
	setCompletedSteps: () => {},
	validateRequiredSteps: () => {},
	animateWiggle: false,
};

export const ConnectAPIContext = createContext<ConnectAPIState>(initialState);

export function ConnectAPIProvider({ children }) {
	const [formFields, setFormFields] = useState<IAPIFormFields>(defaultValue);
	const [formErrors, setFormErrors] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState<CreateTaskAPISteps>(CreateTaskAPISteps.Basic);
	const [parsedData, setParsedData] = useState<ParsedData | null>(null);
	const [evaluationErrors, setEvaluationErrors] = useState<ErrorsState>({});
	const [evaluatedFields, setEvaluatedFields] = useState<Record<string, boolean>>(
		{},
	);
	const [dialogMaxWidth, setDialogMaxWidth] = useState(
		initialState.dialogMaxWidth,
	);
	const [isDirty, setIsDirty] = useState(false);
	const [section, setSection] = useState<ApiCreateSectionEnum>(
		ApiCreateSectionEnum.Details,
	);
	const [contextualSuggestions, setContextualSuggestions] = useState<
		string | null
	>(null);
	const [completedSteps, setCompletedSteps] = useState<CreateTaskAPISteps[]>([]);
	const [animateWiggle, setAnimateWiggle] = useState(false);

	usePrompt({ isDirty });

	const resetForm = () => {
		setFormFields(defaultValue);
		setFormErrors({});
		setStep(CreateTaskAPISteps.Basic);
		setParsedData(null);
		setEvaluationErrors({});
		setEvaluatedFields({});
		setDialogMaxWidth(initialState.dialogMaxWidth);
		setIsDirty(false);
		setSection(ApiCreateSectionEnum.Details);
		setContextualSuggestions(null);
	};

	const validateRequiredSteps = () => {
		const requiredSteps = [
			CreateTaskAPISteps.Basic,
			CreateTaskAPISteps.Curl,
			CreateTaskAPISteps.Payload,
		];

		const missingSteps = requiredSteps.filter(
			(step) => !completedSteps.includes(step),
		);

		if (missingSteps.length > 0) {
			setContextualSuggestions('Please complete adding the API details first');

			setAnimateWiggle(false);
			requestAnimationFrame(() => setAnimateWiggle(true));

			return false;
		}
		return true;
	};

	// useEffect(() => {
	// 	validateRequiredSteps();
	// }, [completedSteps]);

	return (
		<ConnectAPIContext.Provider
			value={{
				formFields,
				setFormFields,
				formErrors,
				setFormErrors,
				resetForm,
				isLoading,
				setIsLoading,
				step,
				setStep,
				parsedData,
				setParsedData,
				evaluationErrors,
				setEvaluationErrors,
				evaluatedFields,
				setEvaluatedFields,
				dialogMaxWidth,
				setDialogMaxWidth,
				setIsDirty,
				section,
				setSection,
				contextualSuggestions,
				setContextualSuggestions,
				completedSteps,
				setCompletedSteps,
				validateRequiredSteps,
				animateWiggle,
			}}
		>
			{children}
		</ConnectAPIContext.Provider>
	);
}
