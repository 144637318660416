import { useMemo, useState } from 'react';
import { IAdminDaveActions } from '../types/admin.types';
import { getAdminDaveActions } from '../services/admin.service';
import { useQuery } from '@tanstack/react-query';

export const useGetAdminDaveActions = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		chains: [],
	});
	const { data, isLoading, refetch } = useQuery<IAdminDaveActions[]>({
		queryKey: ['admin', 'enterprises'],
		queryFn: () => getAdminDaveActions(),
	});

	const results = useMemo(() => {
		if (!data) return [];
		else
			return data
				.filter((item) => {
					return (
						item?.enterprise?.name
							?.toLowerCase()
							?.includes(search.toLowerCase()) ||
						item?.enterprise?._id
							?.toLowerCase()
							?.includes(search?.toLowerCase()) ||
						item?.name?.toLowerCase()?.includes(search?.toLowerCase())
					);
				})
				.sort((a, b) => {
					return (
						new Date(b.createdAt).getTime() -
						new Date(a.createdAt).getTime()
					);
				});
	}, [data, search]);

	return {
		search,
		setSearch,
		filters,
		setFilters,
		results: results || [],
		isLoading: isLoading,
		refetch,
	};
};
