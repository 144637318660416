import { defineChain } from 'viem';

export const xProtocolTestnet = defineChain({
	id: 83144,
	name: 'XProtocol Testnet',
	nativeCurrency: { name: 'KICK', symbol: 'KICK', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc.testnet.xprotocol.org'],
		},
	},
	blockExplorers: {
		default: {
			name: 'XProtocol Testnet Explorer',
			url: 'https://explorer.testnet.xprotocol.org',
		},
	},
	testnet: true,
});
