import RewardStorePage from './reward-store.page';

const RewardStoreRoute = () => {
	return (
		<div>
			<RewardStorePage />
		</div>
	);
};

export default RewardStoreRoute;
