import { useEffect, useMemo, useState } from 'react';
import {
	updateTaskAdminInputs,
	updateTaskSlides,
} from '../../services/campaigns.service';
import { useCampaignReport } from './useCampaignReport';
import { queryClient } from '@/lib/react-query';
import { AdminInputType, ITask } from '../../types';
import { toast } from 'sonner';
import { useTaskTemplates } from '../create/useTaskTemplates';
import { usePrompt } from '@/hooks/usePrompt';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { convertArrayToCommaSeparatedString } from '@/utils/parsers';
import { EditLiveTaskSteps } from '../../types/tasks.enums';
import { useMutation } from '@tanstack/react-query';

export const useEditLiveTask = (task: ITask, setOpen: any) => {
	const { campaign } = useCampaignReport();
	const { rawTemplates, isLoading: isTemplatesLoading } = useTaskTemplates();
	const [task_, setTask_] = useState({
		description: task?.description,
		adminInputs: task?.adminInputs,
		customInitiationURL: task?.customInitiationURL,
		logo: {
			type: 'icon',
			value: 'bi-award',
		},
		slides: task?.slides || [],
		requiredLogins: task?.requiredLogins,
	});
	const [isDirty, setIsDirty] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({
		customInitiationURL: '',
		description: '',
	});
	const [step, setStep] = useState<EditLiveTaskSteps>(
		EditLiveTaskSteps.TaskParams,
	);

	const template = useMemo(() => {
		return rawTemplates.find(
			(template) => template.templateType === task?.templateType,
		);
	}, [rawTemplates, task]);

	useEffect(() => {
		if (!task || !template) return;
		setTask_({
			description: task.description,
			adminInputs: template.adminInputs?.map((input) => {
				const taskInput = task.adminInputs.find(
					(taskInput) => taskInput.key === input.key,
				);
				return {
					...input,
					value: taskInput?.value ?? '',
				};
			}),
			customInitiationURL: task.customInitiationURL,
			logo: task.logo,
			slides: task.slides || [],
			requiredLogins: task.requiredLogins,
		});
	}, [template, task]);

	const taskSlidesMutation = useMutation({
		mutationFn: updateTaskSlides,
		onSuccess: () => {
			toast.success('Slides updated successfully');
			queryClient.invalidateQueries({
				queryKey: ['campaign', campaign._id],
			});
		},
		onError: (err) => {
			handleErrorMessage(err);
		},
	});

	const validateTask = () => {
		const newErrors = {
			description: '',
			customInitiationURL: '',
		};
		if (!task.description)
			newErrors['description'] =
				'Please add task description. This is what your users will see. ';
		if (template?.isCustomInitiationURLAllowed && task.customInitiationURL) {
			const isValidURL = new RegExp(/^(https):\/\/[^ "]+$/).test(
				task.customInitiationURL,
			);
			if (!isValidURL) {
				newErrors.customInitiationURL =
					'Invalid URL, please enter a valid URL.';
			} else {
				newErrors.customInitiationURL = '';
			}
		}
		const isValid = !Object.values(newErrors).some((error) => error);
		setErrors(newErrors);
		return isValid;
	};

	const updateTask = async () => {
		try {
			if (!validateTask()) return;

			if (step === EditLiveTaskSteps.TaskParams) {
				setStep(EditLiveTaskSteps.TaskFeatures);
				return;
			}
			setIsLoading(true);
			task_.adminInputs = task_.adminInputs.map((i) => {
				if (i.inputType === AdminInputType.InputMultiSelectAsync) {
					return {
						...i,
						value: convertArrayToCommaSeparatedString(
							i.value as string[],
						),
					};
				}
				return i;
			});

			await updateTaskAdminInputs({
				campaignId: campaign._id,
				taskId: task._id,
				taskMetadata: {
					description: task_.description,
					adminInputs: task_.adminInputs,
					customInitiationURL: task_.customInitiationURL,
					logo: task_.logo,
					requiredLogins: task_.requiredLogins,
				},
			});

			analytics.track(TrackingEvents.QuestTaskUpdated, {
				campaignId: campaign?._id,
				campaignName: campaign?.name,
				templateType: template?.templateType,
				templateFamily: template?.templateFamily,
			});

			if (
				step === EditLiveTaskSteps.TaskFeatures &&
				task_?.slides?.length > 0
			) {
				taskSlidesMutation.mutateAsync({
					campaignId: campaign._id,
					taskId: task._id,
					slides: task_.slides,
				});
			}
			if (taskSlidesMutation.isError) {
				setIsLoading(false);
				return;
			}
			setIsDirty(false);
			setOpen(false);
			toast.success('Task updated successfully');
			queryClient.invalidateQueries({
				queryKey: ['campaign', campaign._id],
			});
			setIsLoading(false);
			setStep(EditLiveTaskSteps.TaskParams);
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading(false);
			console.log(err);
		}
	};

	const editTaskAdminInput = (_: any, key: string, value: string | number) => {
		setIsDirty(true);
		const updatedTask = { ...task_ };
		const adminInputs = updatedTask.adminInputs.map((input) => {
			if (input.key === key) {
				input.value = value;
			}
			return input;
		});
		setTask_((prev) => ({ ...prev, adminInputs }));
	};

	const onClose = () => {
		setOpen(false);
		setStep(EditLiveTaskSteps.TaskParams);
	};

	usePrompt({ isDirty });

	return {
		errors,
		setErrors,
		editTaskAdminInput,
		isTemplatesLoading,
		template,
		setTask: setTask_,
		task: task_,
		isDirty,
		isLoading: isLoading || taskSlidesMutation.isPending,
		onClose,
		updateTask,
		setIsDirty,
		step,
		setStep,
	};
};
