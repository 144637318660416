import { useMemo, useState } from 'react';
import { useCreateEvent } from './useCreateEvent';
import { v4 as uuidv4 } from 'uuid';
import { DNDType } from '@/components/element/dnd/DnDKanban';
import { useCampaigns } from '@/features/campaigns/hooks/report/useCampaigns';
import { IEventCampaigns } from '../../types/events.type';
import { Status } from '@/features/campaigns/types';

export const useCreateEventSections = () => {
	const { sections, setSections, setErrors } = useCreateEvent();
	const [showSearchCampaigns, setShowSearchCampaigns] = useState(false);
	const { campaigns } = useCampaigns();
	const [sectionId, setSectionId] = useState('');

	const addSection = () => {
		const uniqueId = uuidv4();
		setSections((prev) => [
			...prev,
			{
				_id: uniqueId,
				title: 'Untitled Section',
				index: prev.length,
				campaigns: [],
			},
		]);
	};

	const addCampaign = (campaignId: string) => {
		setErrors((prev) => ({
			...prev,
			campaigns: false,
			sections: {
				...prev.sections,
				campaigns: false,
			},
		}));
		const campaign = campaigns?.find((i) => i._id === campaignId);
		const campaign_: IEventCampaigns = {
			_id: campaignId,
			name: campaign?.name || '',
			banner: campaign?.banner || '',
			status: campaign?.status,
			isComingSoon: campaign?.status !== Status.Active,
			startDate: campaign?.startDate,
			endDate: campaign?.endDate,
		};
		if (sectionId) {
			const newSections = [...sections];
			const index = newSections.findIndex((i) => i._id === sectionId);
			newSections[index].campaigns.push(campaign_);
			setSections(newSections);
		} else
			setSections([
				{
					_id: uuidv4(),
					title: 'Untitled Section',
					campaigns: [campaign_],
					index: 0,
				},
			]);
	};

	const handleReorderContainers = (newContainers: any) => {
		const newSections = [];
		for (const container of newContainers) {
			newSections.push({
				_id: container._id,
				name: container.name,
				campaigns: container.items,
				title: container.title,
			});
		}
		setSections(newSections);
	};

	const containers_: DNDType[] = useMemo(
		() =>
			sections.map((i) => ({
				...i,
				id: `container-${i._id}`,
				items: i.campaigns.map((campaign) => ({
					id: `item-${campaign._id}`,
					...campaign,
				})),
			})),
		[sections],
	);

	const editItem = (taskId: string, actionType: string) => {
		const newSections = [...sections];
		const index = newSections.findIndex((i) =>
			i.campaigns.some((i) => i._id === taskId),
		);
		if (actionType === 'DELETE') {
			newSections[index].campaigns = newSections[index].campaigns.filter(
				(i) => i._id !== taskId,
			);
			setSections(newSections);
		}
	};

	const editContainer = (sectionId: string, actionType: string, value: string) => {
		const newSections = [...sections];
		const index = newSections.findIndex((i) => i._id === sectionId);
		if (actionType === 'EDIT_NAME') {
			newSections[index].title = value;
			setSections(newSections);
		}
		if (actionType === 'DELETE') {
			newSections.splice(index, 1);
			setSections(newSections);
		}
	};

	return {
		containers_,
		handleReorderContainers,
		addSection,
		addCampaign,
		editItem,
		editContainer,
		showSearchCampaigns,
		setShowSearchCampaigns,
		sectionId,
		setSectionId,
		sections,
	};
};
