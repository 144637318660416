import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { ILeaderboardUserDetails } from '../../types/leaderboard.types';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

const UserProfile = ({
	profile,
	totalQuests,
	totalXp,
}: {
	profile: ILeaderboardUserDetails['profile'];
	totalQuests: number;
	totalXp: number;
}) => {
	return (
		<>
			<img
				src="https://images.unsplash.com/photo-1528731708534-816fe59f90cb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
				alt="user"
				className="h-32 w-full object-cover dark:hidden"
			/>
			<img
				src="https://images.unsplash.com/photo-1637946175559-22c4fe13fc54?q=80&w=2127&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
				alt="user"
				className="h-32 w-full object-cover dark:block hidden"
			/>
			<div className="px-8">
				<Avatar className="size-24 -mt-12 border-4 border-white">
					<AvatarImage src={profile.avatar} alt="@username" />
					<AvatarFallback name={profile?.username} />
				</Avatar>
				<div className="flex justify-between items-start">
					<div>
						<div className="text-xl font-medium truncate max-w-[280px]">
							{profile.username}
						</div>
						<div className="text-sm text-muted-foreground">
							@{profile.twitter}
						</div>
					</div>
					<div className="flex items-center text-xs space-x-2 mt-3">
						<Badge variant="outline">
							<i className="bi-stars me-1"></i>
							{totalXp} XPs
						</Badge>
						<Badge variant="outline">
							<i className="bi-view-list me-1"></i>
							{totalQuests} Quests
						</Badge>
						<Badge variant="outline">
							{!profile.humanProbability && profile.humanProbability !== 0? "-" : profile.humanProbability + "%" + " Humanity"}
						</Badge>
					</div>
				</div>
				<div className="flex flex-wrap items-center text-xs space-x-2 mt-5">
					{profile?.twitter && (
						<>
							<div>
								<i className="bi-twitter me-1"></i>
								{profile.twitter}
							</div>
							<div>
								<i className="bi-dot mx-1"></i>
							</div>
						</>
					)}
					{profile?.discord && (
						<>
							<div>
								<i className="bi-discord me-1"></i>
								{profile?.discord}
							</div>
							<div>
								<i className="bi-dot mx-1"></i>
							</div>
						</>
					)}
					{profile?.telegram && (
						<>
							<div>
								<i className="bi-telegram me-1"></i>
								{profile?.telegram}
							</div>
						</>
					)}
				</div>
				<div className="flex flex-wrap items-center text-xs space-x-2 mt-5">
					{profile.userAddresses &&
						profile.userAddresses.map((i) => (
							<>
								<TooltipWrapper tooltip={i}>
									<i className="bi-wallet-fill me-1"></i>
									{i.slice(0, 10)}...{i.slice(-10)}
								</TooltipWrapper>
							</>
						))}
				</div>
			</div>
		</>
	);
};

export default UserProfile;
