import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';

const ChainBadge = ({
	chainId,
	className,
	hideName = false,
	showAdditionalChains = false,
	fetchEnterpriseChains = false,
	showNonEVM = false,
}: {
	chainId: number;
	className?: string;
	hideName?: boolean;
	showAdditionalChains?: boolean;
	fetchEnterpriseChains?: boolean;
	showNonEVM?: boolean;
}) => {
	const { getChainName, getNativeChainLogo } = useGetSupportedChains({
		showAdditionalChains,
		fetchEnterpriseChains,
		showNonEVM,
	});

	return (
		<div className={`py-1 flex items-center ${className} font-normal`}>
			<img
				src={getNativeChainLogo(chainId)}
				className="size-5 rounded-full object-contain me-2 border border-black/5"
			/>
			{!hideName && getChainName(chainId)}
		</div>
	);
};

export default ChainBadge;
