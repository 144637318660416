import { DataTable } from '@/components/element/data-table/DataTable';
import PageHeader from '@/components/layout/PageHeader';
import { Status } from '@/features/campaigns/types';
import { useAuth } from '@/hooks/useAuth';
import { useState } from 'react';
import { toast } from 'sonner';
import { campaignColumns } from '../components/campaigns/CampaignColumns';
import CampaignDetailsDialog from '../components/campaigns/CampaignDetailsDialog';
import CampaignEndDateDialog from '../components/campaigns/CampaignEndDateDialog';
import CampaignFilters from '../components/campaigns/CampaignFilters';
import CampaignPauseDialog from '../components/campaigns/CampaignPauseDialog';
import { useAdminCampaignsActions } from '../hooks/useAdminCampaignsActions';
import { useAdminEnterpriseActions } from '../hooks/useAdminEnterpriseActions';
import { useGetAdminCampaigns } from '../hooks/useGetAdminCampaigns';
import { IAdminCampaigns } from '../types/admin.types';
import { QUEST_URL } from '@/config';

const AdminCampaigns = () => {
	const { user } = useAuth();
	const [selected, setSelected] = useState<IAdminCampaigns>(null);
	const [showDateChangeOption, setShowDateChangeOption] = useState(false);
	const [showPauseOption, setShowPauseOption] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const {
		search,
		setSearch,
		filters,
		setFilters,
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading,
		results,
		refetch,
		count,
	} = useGetAdminCampaigns();

	const { ghostLogin, removeGhostLogin } = useAdminEnterpriseActions({ refetch });
	const { togglePublishStatus } = useAdminCampaignsActions({ refetch });

	const columns = campaignColumns([
		{
			type: 'button',
			label: 'Publish Campaign',
			onClick: (row: any) => {
				togglePublishStatus(row._id, true);
			},
		},
		{
			type: 'button',
			label: 'Unpublish Status',
			onClick: (row: any) => {
				togglePublishStatus(row._id, false);
			},
		},
		{
			type: 'button',
			label: 'Edit Campaign Date',
			onClick: (row: any) => {
				setSelected(row);
				setShowDateChangeOption(true);
			},
			show: (row: any) => row.status === Status.Active,
		},
		{
			type: 'button',
			label: 'Pause/Unpause Status',
			onClick: (row: any) => {
				setSelected(row);
				setShowPauseOption(true);
			},
			show: (row: any) =>
				row.status === Status.Active || row.status === Status.Paused,
		},
		{
			type: 'button',
			label: 'View Campaign',
			onClick: (row: any) => {
				window.open(`${QUEST_URL}/quest/${row?._id}`, '_blank');
			},
		},
		{
			type: 'button',
			label: 'Add Ghost Login',
			onClick: (row: any) => {
				ghostLogin(row?.enterpriseId);
			},
			show: (row: any) => {
				return !user.enterprises
					?.map((i) => i._id)
					.includes(row.enterpriseId);
			},
		},
		{
			type: 'button',
			label: 'Remove Ghost Login',
			onClick: (row: any) => {
				removeGhostLogin(row?.enterpriseId);
			},
			show: (row: any) =>
				user?.enterprises?.map((i) => i._id)?.includes(row.enterpriseId),
		},
		{
			type: 'separator',
		},
		{
			type: 'button',
			label: 'Copy Campaign ID',
			onClick: (row: any) => {
				navigator.clipboard.writeText(row._id);
				toast.success('Campaign ID copied to clipboard');
			},
		},
	]);

	return (
		<div>
			<PageHeader
				title="Campaigns Approval"
				description="Manage campaigns, their approvals, etc"
			/>
			<CampaignFilters
				search={search}
				filters={filters}
				setSearch={setSearch}
				setFilters={setFilters}
			/>
			<div className="mt-6">
				<DataTable
					data={results}
					columns={columns}
					totalCount={count || 100}
					isLoading={isLoading}
					isServerSide={true}
					pagination={pagination}
					setPagination={setPagination}
					sorting={sorting}
					setSorting={setSorting}
				/>
			</div>
			<CampaignDetailsDialog
				open={showDetails}
				setOpen={setShowDetails}
				campaign={selected}
			/>
			<CampaignEndDateDialog
				open={showDateChangeOption}
				setOpen={setShowDateChangeOption}
				campaign={selected}
				setCampaign={setSelected}
				refetch={refetch}
			/>
			<CampaignPauseDialog
				open={showPauseOption}
				setOpen={setShowPauseOption}
				campaign={selected}
				setCampaign={setSelected}
				refetch={refetch}
			/>
		</div>
	);
};

export default AdminCampaigns;
