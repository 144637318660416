import { Input } from '@/components/plate-ui/input';
import { Button } from '@/components/ui/button';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { ApiDataEnums } from '../types/task-apis.enum';

export const CustomPayload = ({ items, setItems, index }) => {
	const { formErrors, formFields, setFormFields, setFormErrors } = useConnectAPI();

	const handleInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setFormErrors((prev: any) => ({
			...prev,
			[formFields?.apiConstantDataFields[index][name]]: '',
		}));

		setFormFields((prev: any) => ({
			...prev,
			apiConstantDataFields: prev.apiConstantDataFields.map(
				(field: any, idx: number) =>
					idx === index
						? { ...field, [name]: value, testValue: value }
						: field,
			),
		}));
	};

	const removeCustomPayload = () => {
		if (items > 0) {
			setItems((prevItems: number) => prevItems - 1);
		}

		if (items <= 1) {
			setFormFields((prev: any) => ({
				...prev,
				isCustomConstantPayloadRequired: false,
			}));
		}

		setFormFields((prev: any) => {
			const removedConstant = prev.apiConstantDataFields[index];
			return {
				...prev,
				apiConstantDataFields: prev.apiConstantDataFields.filter(
					(_: any, idx: number) => idx !== index,
				),
				apiPayload: prev.apiPayload.map((item) => {
					if (
						item.key === removedConstant.key &&
						item.value === ApiDataEnums.ConstantValue
					) {
						return {
							...item,
							value: '',
						};
					}
					return item;
				}),
			};
		});
	};

	return (
		<div className="flex gap-2 mt-3">
			<div className="flex items-center flex-grow space-x-2 sm:space-x-0">
				<Input
					className={`h-9 `}
					name="key"
					value={formFields?.apiConstantDataFields[index]?.key || ''}
					onChange={handleInputs}
				/>
			</div>
			<div className="flex grow">
				<Input
					className={`h-9 ${
						formErrors[formFields?.apiConstantDataFields[index]?.key]
							? 'border-destructive'
							: ''
					}`}
					name={'value'}
					value={formFields?.apiConstantDataFields[index]?.value}
					onChange={handleInputs}
				/>
			</div>
			<div className="">
				<Button
					variant="outline"
					size="sm"
					className="w-9 h-9"
					onClick={removeCustomPayload}
				>
					<i className="bi bi-x-lg"></i>
				</Button>
			</div>
		</div>
	);
};
