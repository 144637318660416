import PageHeader from '@/components/layout/PageHeader';
import { Button } from '@/components/ui/button';
import { QUEST_URL } from '@/config';
import { links } from '@/config/links';
import { useAuth } from '@/hooks/useAuth';
import React, { useState } from 'react';
import { useRewardStore } from '../hooks/useRewardStore';

const RewardStoreHeader = () => {
	const [open, setOpen] = useState(false);
	const { user } = useAuth();
	const { rewardStore } = useRewardStore();
	return (
		<PageHeader
			title="Reward Store"
			description="Set up your reward store to reward your community members for their contributions."
			learn={rewardStore?.length > 0 ? '' : links.docs.rewardStore}
		>
			{rewardStore?.length > 0 ? (
				<Button
					onClick={() => {
						window.open(
							`${QUEST_URL}/project/${user?.enterpriseSlug}?tab=2`,
							'_blank',
						);
					}}
					className="mr-2"
				>
					<i className="bi-box-arrow-up-right me-2"></i>
					Open Community Hub
				</Button>
			) : null}
		</PageHeader>
	);
};

export default RewardStoreHeader;
