import InputSelect from '@/components/element/inputs/InputSelect';
import InputText from '@/components/element/inputs/InputText';
import { EnterpriseTokenStatus } from '@/features/dashboard/types/enterprise.types';
import { tokenStatus } from '@/features/onboarding/types/onboarding-options';
import AnimateHeight from 'react-animate-height';

const EditEnterpriseData = ({ isDataEditable, details, setDetails }) => {
	return (
		<AnimateHeight
			height={isDataEditable === 'tokenStatus' ? 'auto' : 0}
			duration={300}
			className="my-2"
		>
			<div className="space-y-4">
				<InputSelect
					label="What is your token status?"
					placeholder="Select token status"
					options={tokenStatus}
					value={details.tokenStatus}
					setValue={(e) =>
						setDetails((p) => ({
							...p,
							tokenStatus: e,
						}))
					}
					errorText="Please select a category"
					className="text-left"
					placeholderClassName="!text-muted-foreground"
				/>
				{details.tokenStatus === EnterpriseTokenStatus.Listed ? (
					<div className="flex gap-4 w-full">
						<InputText
							label="Chain Id"
							placeholder="1234"
							value={details?.tokenDetails?.tokenChainId}
							setValue={(e) => {
								const numericValue = Number(e);
								if (Number.isNaN(numericValue)) {
									// If the value is not a number, return early
									return;
								}
								setDetails((p) => ({
									...p,
									tokenDetails: {
										...p.tokenDetails,
										tokenChainId: numericValue,
									},
								}));
							}}
							className="text-left w-[20%]"
						/>
						<InputText
							label="Token Address"
							placeholder="0xb851220202C019d1645CB9f75dcc5ef7a66aaca9"
							value={details?.tokenDetails?.tokenAddress}
							setValue={(e) =>
								setDetails((p) => ({
									...p,
									tokenDetails: {
										...p.tokenDetails,
										tokenAddress: e,
									},
								}))
							}
							className="text-left w-[80%]"
						/>
					</div>
				) : null}
			</div>
		</AnimateHeight>
	);
};

export default EditEnterpriseData;
