import PageHeader from '@/components/layout/PageHeader';
import React, { useState } from 'react';
import ExternalAPIsTable from '../components/external-apis/ExternalAPIsTable';
import AddExternalAPI from '../components/external-apis/AddExternalAPI';
import { links } from '@/config/links';
import { Link } from 'react-router-dom';
import NoDataCard from '@/components/element/cards/NoDataCard';
import useExternalAPIs from '../hooks/useExternalAPIs';

const ExternalAPIs = () => {
	const [open, setOpen] = useState(false);
	const [step, setStep] = useState(1); // 1 for API Name, 2 after token is generated

	const { apiData, isLoading } = useExternalAPIs({ setOpen, setStep });

	return (
		<div>
			<PageHeader
				title="API Auth Tokens"
				description="In this section, you can manage the API Auth Tokens that you have created to this dashboard."
				icon="cpu-fill"
				btnOnClick={() => setOpen(true)}
				btnLabel={apiData && apiData?.length ? 'Add Auth Token' : ''}
				learn={!apiData?.length ? links.docs?.apiTokensDocs : ''}
			/>

			{apiData && apiData?.length > 0 ? (
				<>
					<p className="text-sm text-muted-foreground mt-4">
						For more information on how to use the API, see our&nbsp;
						<Link
							to={links.docs?.apiDocs}
							target="_blank"
							className="underline"
						>
							documentation.
						</Link>
					</p>

					<ExternalAPIsTable setOpen={setOpen} setStep={setStep} />
				</>
			) : (
				!isLoading && (
					<NoDataCard
						title="No API Token created"
						description="You have not created any API Tokens yet."
						btnLabel="Add API Token"
						btnOnClick={() => setOpen(true)}
						className="mt-10"
						image=""
					/>
				)
			)}

			<AddExternalAPI
				open={open}
				setOpen={setOpen}
				step={step}
				setStep={setStep}
			/>
		</div>
	);
};

export default ExternalAPIs;
