import { useContext } from 'react';
import { ConnectAPIContext } from '../providers/ConnectAPIProvider';

export const useConnectAPI = () => {
	const context = useContext(ConnectAPIContext);

	if (context === undefined) {
		throw new Error('useConnectAPI must be used within a ConnectAPIProvider');
	}
	return context;
};
