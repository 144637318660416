import { useState } from 'react';
import { useCampaignReport } from './useCampaignReport';
import {
	bulkUpdateSubmissionStatus,
	updateSubmissionStatus,
} from '../../services/campaigns.service';
import { IQuestPOWSubmissions, POWVerifyStatus } from '../../types';
import { handleErrorMessage } from '@/utils/notifications';
import { queryClient } from '@/lib/react-query';

export const useCampaignSubmissionsApprovals = (queryKey: any[], id: string) => {
	const [isActionLoading, setIsActionLoading] = useState({
		approval: false,
		reject: false,
		approvalAll: false,
		rejectAll: false,
		rejectSelected: false,
		approvalSelected: false,
	});
	const handleReview = async (
		selectedRowId: string,
		selectedTaskId: string,
		isApproved: boolean,
		customXP: number,
	) => {
		if (!selectedRowId) return;
		try {
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approval' : 'reject']: true,
			}));
			await updateSubmissionStatus(id, {
				results: [
					{
						submissionId: selectedRowId,
						status: isApproved
							? POWVerifyStatus.Verified
							: POWVerifyStatus.Failed,
						customXp: parseInt(customXP.toString()),
					},
				],
				taskId: selectedTaskId,
			});
			const data = (await queryClient.getQueryData(queryKey)) as {
				submissions: IQuestPOWSubmissions[];
				count: number;
			};
			if (data) {
				await queryClient.setQueryData(queryKey, {
					...data,
					submissions: data.submissions.map(
						(submission: IQuestPOWSubmissions) => {
							if (submission._id === selectedRowId) {
								return {
									...submission,
									status: isApproved
										? POWVerifyStatus.Verified
										: POWVerifyStatus.Failed,
									customXp: parseInt(customXP.toString()),
								};
							}
							return submission;
						},
					),
				});
			}
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approval' : 'reject']: false,
			}));
		} catch (err) {
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approval' : 'reject']: false,
			}));
			handleErrorMessage(err);
		}
	};

	const handleReviewAll = async (
		selectedTaskId: string,
		isApproved: boolean,
		setCursor?: any,
	) => {
		try {
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approvalAll' : 'rejectAll']: true,
			}));
			setCursor();
			await bulkUpdateSubmissionStatus(id, {
				taskId: selectedTaskId,
				action: isApproved
					? POWVerifyStatus.Verified
					: POWVerifyStatus.Failed,
			});
			queryClient.invalidateQueries({
				queryKey: queryKey,
			});
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approvalAll' : 'rejectAll']: false,
			}));
		} catch (err) {
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approvalAll' : 'rejectAll']: false,
			}));
			handleErrorMessage(err);
		}
	};

	const handleReviewSelected = async (
		selectedTaskId: string,
		ids: { _id: string; status: POWVerifyStatus }[],
		isApproved: boolean,
	) => {
		try {
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approvalSelected' : 'rejectSelected']: true,
			}));
			await updateSubmissionStatus(id, {
				results: ids.map((id) => ({
					submissionId: id._id,
					status: isApproved
						? POWVerifyStatus.Verified
						: POWVerifyStatus.Failed,
				})),
				taskId: selectedTaskId,
			});
			const data = queryClient.getQueryData(queryKey) as {
				submissions: IQuestPOWSubmissions[];
				count: number;
			};
			if (data) {
				await queryClient.setQueryData(queryKey, {
					...data,
					submissions: data.submissions.map(
						(submission: IQuestPOWSubmissions) => {
							if (ids.map((i) => i._id).includes(submission._id)) {
								const submissionStatus = ids.find(
									(i) => i._id === submission._id,
								)?.status;
								if (submissionStatus === POWVerifyStatus.Verified)
									return submission;
								return {
									...submission,
									status: isApproved
										? POWVerifyStatus.Verified
										: POWVerifyStatus.Failed,
								};
							}
							return submission;
						},
					),
				});
			}
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approvalSelected' : 'rejectSelected']: false,
			}));
		} catch (err) {
			setIsActionLoading((prev) => ({
				...prev,
				[isApproved ? 'approvalSelected' : 'rejectSelected']: false,
			}));
			handleErrorMessage(err);
		}
	};

	return {
		isActionLoading,
		handleReview,
		handleReviewAll,
		handleReviewSelected,
	};
};
