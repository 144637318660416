import { CreditRequestStatusEnum } from '@/features/intract-credits/types/credits.enums';
import { useConfirm } from '@/hooks/useConfirm';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';
import { updateRequests } from '../../services/admin.service';

const useAdminCreditsMutation = ({ setOpen }) => {
	const { show } = useConfirm();
	const [rejectionReason, setRejectionReason] = useState('');
	const [errors, setErrors] = useState({});

	const creditRequestMutation = useMutation({
		mutationFn: updateRequests,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'credit-requests'],
			});
			toast.success('Request updated successfully');
			handleReset();
		},
		onError: () => {
			handleErrorMessage('Error updating request');
			setRejectionReason('');
		},
	});

	const handleReset = () => {
		setErrors({});
		setRejectionReason('');
		setOpen(false);
	};

	const updateRequestStatus = ({
		id,
		status,
		rejectionReason,
	}: {
		id: string;
		status: CreditRequestStatusEnum;
		rejectionReason?: string;
	}) => {
		if (status === CreditRequestStatusEnum.Rejected && !rejectionReason) {
			setErrors({
				rejectionReason: 'Rejection reason is required',
			});
			return;
		}
		creditRequestMutation.mutateAsync({
			requestId: id,
			status,
			rejectionReason,
		});
	};

	const openConfirmation = async (
		id: string,
		status: CreditRequestStatusEnum,
		requestText: string,
	) => {
		const confirm = await show({
			title: 'Approve this Credit Request',
			subtitle: `Are you sure you want to approve this request for ${requestText} ?`,
			confirmText: 'Confirm',
			cancelText: 'Cancel',
		});

		if (!confirm) {
			return;
		}

		updateRequestStatus({
			id,
			status,
		});
	};

	return {
		updateRequestStatus,
		isSubmitting: creditRequestMutation.isPending,
		openConfirmation,
		rejectionReason,
		setRejectionReason,
		errors,
	};
};

export default useAdminCreditsMutation;
