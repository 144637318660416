import MultiSelect from '@/components/element/inputs/MultiSelect';
import Spinner from '@/components/element/loading/Spinner';
import { TaskKeys, TemplateType } from '@/features/campaigns/types';
import { axios } from '@/lib/axios';
import { toTitleCase } from '@/utils/parsers';
import { useEffect, useMemo, useState } from 'react';
import { ITaskInputsProps } from './TaskInputs';

const TaskInputMultiSelectAsync = ({
	adminInput,
	taskId,
	error,
	editTaskAdminInput,
	templateType,
	deleteTask,
	isUpdate,
	src,
}: ITaskInputsProps) => {
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoading2, setIsLoading2] = useState(false);

	const isIntractMedallion = useMemo(() => {
		return adminInput.key === TaskKeys.IntractMedallions;
	}, [adminInput]);

	useEffect(() => {
		fetchOptions();
	}, [adminInput.optionsFrom]);

	useEffect(() => {
		if (Array.isArray(adminInput.value)) {
			// console.log('adminInput.value', adminInput.value);
			adminInput.value = adminInput.value
				.map((item) => (typeof item === 'string' ? item : item.value))
				.filter((item) => item !== '');
		}
	}, [adminInput]);

	const fetchOptions = () => {
		setIsLoading2(true);
		axios
			.get(adminInput.optionsFrom)
			.then(({ data }) => {
				setIsLoading(false);
				setIsLoading2(false);
				if (data.length === 0) {
					editTaskAdminInput(taskId, 'disabled', true);
				}

				if (isIntractMedallion) {
					data = data.map((item) => ({
						label: item.medallionHubName,
						_id: `${item.medallionHubId} | ${item.medallionId}`,
					}));
				}

				setOptions(
					data.map(
						(option: { label: string; name: string; _id: string }) => ({
							label: (
								<div className="">
									{option.label}{' '}
									<div>
										<div className="text-xs">{option.name}</div>
									</div>
								</div>
							),
							value: option._id,
							name: option.name,
						}),
					),
				);
			})
			.catch((error) => {
				console.error(error);
				setIsLoading2(false);
			});
	};

	if (isLoading) {
		return (
			<div className="flex justify-center items-center py-10">
				<Spinner />
			</div>
		);
	}

	return (
		<>
			<div className="relative">
				<div className="text-sm font-medium mb-1">{adminInput.label}</div>
				{adminInput.description && (
					<div className="text-xs text-muted-foreground mb-1">
						{adminInput.description}
					</div>
				)}
				<MultiSelect
					value={adminInput.value as string[]}
					options={options}
					setValue={(newVal: any) => {
						editTaskAdminInput(taskId, adminInput.key, newVal);
					}}
					placeholder={adminInput.placeholder}
					disabled={!options || options.length === 0}
					popoverClassName="w-[fit] p-0"
					maxLimit={2}
					withoutPortal
				/>
				{error && (
					<div className="text-xs text-destructive mt-1">
						{error || 'This field is required'}
					</div>
				)}
			</div>
		</>
	);
};

export default TaskInputMultiSelectAsync;
