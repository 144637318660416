import { queryClient } from '@/lib/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import {
	fetchCampaignTasks,
	pauseCampaignAndTasks,
} from '../services/admin.service';
import { handleErrorMessage } from '@/utils/notifications';
import { useQuery } from '@tanstack/react-query';

type UpdateAllowedFor = 'CAMPAIGN' | 'TASK';
type UpdateStatus = 'PAUSED' | 'ACTIVE';

const initialFormFields = {
	updateType: 'CAMPAIGN',
	updateData: [
		{
			pauseReason: '',
			status: 'PAUSED',
			id: '', // This will be set based on campaign or tasks
		},
	],
};

const useAdminCampaignPause = ({ campaign, setCampaign, refetch, setOpen }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [openAccordion, setOpenAccordion] = useState('');
	const [formFields, setFormFields] = useState(initialFormFields);
	const [formErrors, setFormErrors] = useState<any>({});

	const { data, isLoading: isFetchingData } = useQuery({
		queryKey: ['admin', 'campaign-tasks', campaign?._id],
		queryFn: () => fetchCampaignTasks(campaign?._id),
		enabled: !!campaign?._id,
	});

	const resetForm = () => {
		setFormFields(initialFormFields);
		setOpen(false);
	};

	const editDetails = (key: string, value: string, taskId: string | null) => {
		try {
			setFormFields((prev) => {
				const newFormFields = { ...prev };
				if (key === 'updateType') {
					newFormFields[key] = value;
				} else if (taskId) {
					// Update a specific task
					newFormFields.updateData = newFormFields.updateData.map(
						(item) => {
							if (item.id === taskId) {
								return { ...item, [key]: value };
							}
							return item;
						},
					);
				} else {
					// Update all paused tasks
					newFormFields.updateType === 'TASK'
						? (newFormFields.updateData = newFormFields.updateData.map(
								(item) => {
									if (item.status === 'PAUSED') {
										return { ...item, [key]: value };
									}
									return item;
								},
							))
						: //update all campaign
							(newFormFields.updateData = newFormFields.updateData.map(
								(item) => {
									return {
										...item,
										[key]: value,
										status:
											campaign?.status === 'PAUSED'
												? 'ACTIVE'
												: 'PAUSED',
									};
								},
							));
				}
				return newFormFields;
			});
		} catch (error) {
			console.log(error);
		}
	};

	const initializeForm = () => {
		if (formFields.updateType === 'CAMPAIGN' && campaign) {
			setFormFields({
				updateType: 'CAMPAIGN',
				updateData: [
					{
						pauseReason: campaign.pauseReason || '',
						status: campaign.status || 'PAUSED',
						id: campaign._id || '',
					},
				],
			});
		} else if (formFields.updateType === 'TASK' && data) {
			const updatedVal = data.map((task) => ({
				pauseReason: task.pauseReason,
				status: task.status,
				id: task._id,
			}));
			setFormFields((prev) => ({
				...prev,
				updateData: updatedVal,
			}));
		}
	};
	const validateForm = () => {
		const errors: any = {};
		if (!formFields.updateType) {
			errors.updateType = 'Please select update type';
		}

		if (
			formFields.updateData.some(
				(item) => item.status === 'PAUSED' && !item.pauseReason,
			)
		) {
			errors.pauseReason = 'Pause reason is required when status is PAUSED';
		}
		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};
	const preparePayload = () => {
		if (formFields.updateType === 'CAMPAIGN') {
			return [
				{
					...formFields.updateData[0],
					id: campaign?._id,
					status: campaign?.status === 'PAUSED' ? 'ACTIVE' : 'PAUSED',
				},
			];
		} else {
			return formFields.updateData;
		}
	};
	const handleCampaignAndTasksPause = async () => {
		if (!validateForm()) return;
		setIsLoading(true);

		try {
			await pauseCampaignAndTasks(campaign?._id, {
				updateType: formFields.updateType,
				updateData: preparePayload(),
			});
			resetForm();
		} catch (error) {
			handleErrorMessage(error);
		} finally {
			setIsLoading(false);
			await queryClient.invalidateQueries({
				queryKey: ['admin', 'campaigns'],
			});
			await queryClient.invalidateQueries({
				queryKey: ['admin', 'campaign-tasks', campaign?._id],
			});
			refetch();
		}
	};
	const handleAccordionChange = (value: string) => {
		setOpenAccordion((prevValue) => (prevValue === value ? null : value));
	};

	useEffect(() => {
		initializeForm();
	}, [data, campaign, formFields.updateType]);

	useEffect(() => {
		setFormErrors({});
	}, [formFields]);

	useMemo(() => {
		setOpenAccordion('pauseReason');
	}, [formErrors.pauseReason]);

	return {
		editDetails,
		handleCampaignAndTasksPause,
		isLoading,
		openAccordion,
		setOpenAccordion,
		campaignTasks: data,
		isFetchingData,
		formFields,
		formErrors,
		handleAccordionChange,
	};
};

export default useAdminCampaignPause;
