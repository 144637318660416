import { defineChain } from 'viem';

export const bob = defineChain({
	id: 60808,
	name: 'BOB',
	nativeCurrency: { decimals: 18, name: 'Ether', symbol: 'ETH' },
	rpcUrls: {
		default: {
			http: ['https://rpc.gobob.xyz'],
			webSocket: ['https://rpc.gobob.xyz'],
		},
	},
	blockExplorers: {
		default: {
			name: 'BOB',
			url: 'https://explorer.gobob.xyz',
		},
	},
	testnet: false,
});
