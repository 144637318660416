import { defineChain } from 'viem';

export const abstractTestnet = defineChain({
	id: 11124,
	name: 'Abstract Testnet',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://api.testnet.abs.xyz'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Abstract Testnet Explorer',
			url: 'https://explorer.testnet.abs.xyz',
		},
	},
	testnet: true,
});
