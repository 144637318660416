import { useConfirm } from '@/hooks/useConfirm';
import { useLoyaltyTasks } from './useLoyaltyTasks';
import { toast } from 'sonner';
import {
	changeLoyaltyTaskStatus,
	deleteLoyaltyTask,
} from '../../services/loyalty.service';
import { queryClient } from '@/lib/react-query';
import { useLoyaltyCampaign } from '../useLoyaltyCampaign';
import {
	MetricBasedXpFunction,
	TaskStatus,
} from '@/features/campaigns/types/tasks.enums';

export const useLoyaltyTasksEdit = () => {
	const { id } = useLoyaltyCampaign();
	const { setIsDirty, task, setTask } = useLoyaltyTasks();
	const { show } = useConfirm();

	const editTask = (key: any, value: any) => {
		setIsDirty(true);
		setTask((prev) => ({ ...prev, [key]: value }));
	};

	const editTaskAdminInput = (
		_taskId: string,
		key: string,
		value: string | number | boolean,
	) => {
		if (key === 'disabled') {
			setTask((p) => ({
				...p,
				isDisabled: true,
			}));
			return;
		}
		setIsDirty(true);
		const updatedTask = { ...task };

		if (!updatedTask.featureApplicability) {
			updatedTask.featureApplicability = {
				recurrence: false,
				metricBasedXp: false,
			};
		}

		if (key === 'recurrance') {
			updatedTask.featureApplicability.recurrence = value as boolean;
			updatedTask.featureApplicability.metricBasedXp = false;
		} else if (key === 'metricBasedXp') {
			updatedTask.featureApplicability.metricBasedXp = value as boolean;
			updatedTask.featureApplicability.recurrence = false;
		}
		const adminInputs = updatedTask.adminInputs.map((input) => {
			if (input.key === key) {
				input.value = value;
			}
			return input;
		});
		setTask({ ...updatedTask, adminInputs });
	};

	const deleteTask = async (taskId: string) => {
		try {
			const res = await show({
				title: 'Delete Task',
				subtitle: 'Are you sure you want to delete this task?',
				confirmText: 'Delete',
				cancelText: 'Cancel',
			});
			if (!res) return;
			toast.promise(
				Promise.all([
					deleteLoyaltyTask({ taskId, campaignId: id }).then((res) => {
						console.log(res);
						queryClient.invalidateQueries({
							queryKey: ['campaign', 'loyalty-quests'],
						});
					}),
				]),
				{
					loading: 'Deleting task...',
					success: 'Task deleted successfully',
					error: 'Failed to delete task',
				},
			);
		} catch (err) {
			console.log(err);
		}
	};

	const completeTask = async (taskId: string) => {
		try {
			const res = await show({
				title: 'Complete Task',
				subtitle: 'Users won’t be able to complete this task anymore. ',
				confirmText: 'Complete',
				cancelText: 'Cancel',
			});
			if (!res) return;
			toast.promise(
				Promise.all([
					changeLoyaltyTaskStatus({
						taskId,
						campaignId: id,
						status: TaskStatus.Active,
						isComplete: true,
					}).then((res) => {
						console.log(res);
						queryClient.invalidateQueries({
							queryKey: ['campaign', 'loyalty-quests'],
						});
					}),
				]),
				{
					loading: 'Marking task as completed...',
					success: 'Task marked as completed',
					error: 'Failed to mark task compelte',
				},
			);
		} catch (err) {
			console.log(err);
		}
	};
	const makeScheduledTaskActive = async (taskId: string) => {
		try {
			const res = await show({
				title: 'Pubishing Task',
				subtitle: 'This will make task active for your users immediately',
				confirmText: 'Publish',
				cancelText: 'Cancel',
			});
			if (!res) return;
			toast.promise(
				Promise.all([
					changeLoyaltyTaskStatus({
						taskId,
						campaignId: id,
						status: TaskStatus.Active,
						startImmediately: true,
					}).then((res) => {
						console.log(res);
						queryClient.invalidateQueries({
							queryKey: ['campaign', 'loyalty-quests'],
						});
					}),
				]),
				{
					loading: 'Making task active...',
					success: 'Task made active successfully',
					error: 'Failed to delete task',
				},
			);
		} catch (err) {
			console.log(err);
		}
	};
	const sendDiscordNotification = async (taskId: string) => {};

	const handleMetricXpUpdate = (
		taskId: string,
		newValue: number,
		property:
			| 'xp'
			| 'xpMultiplier'
			| 'tierMinMetric'
			| 'tierMaxMetric'
			| 'tierXp'
			| 'addMetricTiers'
			| 'removeMetricTiers'
			| 'optimalMetricValue'
			| 'rateOfIncrease',
		index?: number,
	) => {
		setTask((prev) => {
			const editingTask = structuredClone(prev);
			if (editingTask._id === taskId) {
				if (property === 'xp') {
					editingTask.xp = newValue;
				} else if (property === 'xpMultiplier') {
					if (editingTask.metricBasedXpConfig) {
						editingTask.metricBasedXpConfig.linearMetadata.xpMultiplier =
							newValue;
					}
				} else if (property === 'tierMinMetric') {
					if (
						editingTask.metricBasedXpConfig &&
						editingTask.metricBasedXpConfig.tieredMetadata &&
						editingTask.metricBasedXpConfig.tieredMetadata.tiers &&
						index !== undefined
					) {
						editingTask.metricBasedXpConfig.tieredMetadata.tiers[
							index
						].minMetric = newValue;
					}
				} else if (property === 'tierMaxMetric') {
					if (
						editingTask.metricBasedXpConfig &&
						editingTask.metricBasedXpConfig.tieredMetadata &&
						editingTask.metricBasedXpConfig.tieredMetadata.tiers &&
						index !== undefined
					) {
						editingTask.metricBasedXpConfig.tieredMetadata.tiers[
							index
						].maxMetric = newValue;
					}
				} else if (property === 'tierXp') {
					if (
						editingTask.metricBasedXpConfig &&
						editingTask.metricBasedXpConfig.tieredMetadata &&
						editingTask.metricBasedXpConfig.tieredMetadata.tiers &&
						index !== undefined
					) {
						editingTask.metricBasedXpConfig.tieredMetadata.tiers[
							index
						].tierXp = newValue;
					}
				} else if (property === 'addMetricTiers') {
					if (!editingTask.metricBasedXpConfig) {
						editingTask.metricBasedXpConfig = {
							tieredMetadata: { tiers: [] },
							functionType: MetricBasedXpFunction.Tiered,
						};
					} else if (!editingTask.metricBasedXpConfig.tieredMetadata) {
						editingTask.metricBasedXpConfig.tieredMetadata = {
							tiers: [],
						};
					}
					editingTask.metricBasedXpConfig.tieredMetadata.tiers.push({
						minMetric: newValue,
						maxMetric: newValue,
						tierXp: newValue,
					});
				} else if (property === 'removeMetricTiers') {
					if (
						editingTask.metricBasedXpConfig &&
						editingTask.metricBasedXpConfig.tieredMetadata &&
						editingTask.metricBasedXpConfig.tieredMetadata.tiers
					) {
						editingTask.metricBasedXpConfig.tieredMetadata.tiers.splice(
							newValue,
							1,
						);
					}
				} else if (property === 'optimalMetricValue') {
					if (
						editingTask.metricBasedXpConfig &&
						editingTask.metricBasedXpConfig.sigmoidMetadata
					) {
						editingTask.metricBasedXpConfig.sigmoidMetadata.optimalMetricValue =
							newValue;
					}
				} else if (property === 'rateOfIncrease') {
					if (
						editingTask.metricBasedXpConfig &&
						editingTask.metricBasedXpConfig.sigmoidMetadata
					) {
						editingTask.metricBasedXpConfig.sigmoidMetadata.rateOfIncrease =
							newValue;
					}
				}
			}
			return editingTask;
		});
	};

	return {
		editTaskAdminInput,
		editTask,
		deleteTask,
		completeTask,
		sendDiscordNotification,
		makeScheduledTaskActive,
		handleMetricXpUpdate,
	};
};
