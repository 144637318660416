import { queryClient } from '@/lib/react-query';
import { useEffect, useState } from 'react';
import { updateNftContract } from '../services/nfts.service';
import { useMutation } from '@tanstack/react-query';
import { ERC1155RewardABI } from '@/config/abi/ERC1155RewardABI';
import { toast } from 'sonner';
import { useCreateTxn } from '@/hooks/useCreateTxn';
import { handleErrorMessage } from '@/utils/notifications';
import { NftStatus } from '@/features/reward-hub/types/rewards.enum';
import { useAccount } from 'wagmi';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useCreateNftTxn = ({ open }: { open?: boolean }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { address } = useAccount();
	const { startTxn } = useCreateTxn();

	useEffect(() => {
		setIsLoading(false);
	}, [open]);

	const updateMutation = useMutation({
		onSuccess: async () => {
			analytics.track(TrackingEvents.DeployNFTCompleted, {});
			queryClient.invalidateQueries({ queryKey: ['nfts'] });
		},
		mutationFn: updateNftContract,
		onError: () => {
			toast.error('An error occurred while deploying the contract');
		},
	});

	const handleTxnFailure = async (
		address: string,
		contractId: string,
		err: string,
	) => {
		setIsLoading(false);
		if (err) {
			handleErrorMessage(err);
			return;
		}
		await updateMutation.mutateAsync({
			rewardNftId: contractId,
			creatorAddress: address,
			status: NftStatus.TxFailed,
		});
	};

	const handleTxnSuccess = async (
		receipt: any,
		contractId: string,
		creatorAddress: string,
		txnHash: string,
	) => {
		try {
			const blockHash = receipt.blockHash;
			const blockNumber = receipt.blockNumber;
			const decodedLog = receipt?.decodedLogs?.find(
				(i) => i.name === 'ERC1155CollectionLaunch',
			);
			const tokenId = +decodedLog?.args[0]?.hex;
			if (tokenId) {
				await updateMutation.mutateAsync({
					rewardNftId: contractId,
					tokenId: tokenId?.toString(),
					blockHash: blockHash,
					blockNumber: Number(blockNumber),
					transactionHash: txnHash,
					creatorAddress,
					status: NftStatus.TxConfirmed,
				});
			} else {
				throw new Error(`Couldn't create contract, please try again`);
			}
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			handleErrorMessage(error);
		}
	};

	const mintNftTxn = async (
		contractAddress: string,
		chainId: number,
		contractId: string,
	) => {
		try {
			setIsLoading(true);
			const txn: any = await startTxn({
				chainId,
				contractAddress: contractAddress,
				ABI: ERC1155RewardABI,
				fnName: 'createCollection',
				args: ['1'],
			});
			if (!txn || !txn?.transactionHash || !txn.receipt) {
				throw new Error(
					txn?.shortMessage ||
						'NFT transaction failed, please reach out to support',
				);
			}
			setIsLoading(false);
			await handleTxnSuccess(
				txn.receipt,
				contractId,
				txn.account,
				txn.transactionHash,
			);
		} catch (err) {
			setIsLoading(false);
			handleTxnFailure(address, contractId, err);
			console.log(err);
			return false;
		}
	};

	return {
		startTxn: mintNftTxn,
		txnLoading: isLoading,
	};
};

// const decodeLogs = (
// 	logs: {
// 		data: string;
// 		topics: string[];
// 	}[],
// ) => {
// 	try {
// 		let tokenId = '';
// 		const iface = new ethers.utils.Interface(ERC1155RewardABI);

// 		const decodedLogs = [];
// 		for (const log of logs) {
// 			try {
// 				const decodedLog = iface.parseLog(log);
// 				decodedLogs.push(decodedLog);
// 			} catch (err) {
// 				console.log(err);
// 			}
// 		}
// 		for (const log of decodedLogs) {
// 			if (log.name === 'ERC1155CollectionLaunch') {
// 				tokenId = log.args[0]?.toString();
// 				console.log(log, 'decoded log');
// 				console.log(tokenId, 'tokenid', typeof tokenId);
// 			}
// 		}
// 		return { tokenId };
// 	} catch (error) {
// 		return { tokenId: '' };
// 	}
// };
