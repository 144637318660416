import { DataTable } from '@/components/element/data-table/DataTable';
import { columns } from './LeaderboardColumns';
import { useMemo } from 'react';
import UserDialog from '../user-dialog/UserDialog';
import { useLeaderboardUser } from '../../hooks/useLeaderboardUser';
import TaskInformation from '../TaskInformation';

const LeaderboardTable = ({
	isLoading,
	users,
	totalCount,
	showCampaignXps,
	pagination,
	setPagination,
	sorting,
	setSorting,
	showHumanProbability,
	showReferredUsersCount,
	showIsBlacklisted,
	showTiers,
}: {
	isLoading: boolean;
	users: any[];
	totalCount: number;
	showCampaignXps?: boolean;
	pagination: any;
	setPagination: any;
	sorting: any;
	setSorting: any;
	showIsBlacklisted?: boolean;
	showHumanProbability?: boolean;
	showReferredUsersCount?: boolean;
	showTiers?: boolean;
}) => {
	const {
		handleRowClick,
		showUser,
		setShowUser,
		userProfile,
		isUserProfileLoading,
		showTaskInfo,
		setShowTaskInfo,
		selectedUserId,
		setSelectedUserId,
	} = useLeaderboardUser();

	const leaderboardColumns = useMemo(() => {
		return columns(
			showCampaignXps,
			setShowTaskInfo,
			setSelectedUserId,
			showIsBlacklisted,
			showHumanProbability,
			showReferredUsersCount,
			showTiers,
		);
	}, [
		showCampaignXps,
		setShowTaskInfo,
		setSelectedUserId,
		showIsBlacklisted,
		showHumanProbability,
		showReferredUsersCount,
		showTiers,
	]);

	return (
		<div>
			<DataTable
				onRowClick={handleRowClick}
				data={users ?? []}
				columns={leaderboardColumns}
				totalCount={totalCount}
				isLoading={isLoading}
				isServerSide={true}
				pagination={pagination}
				setPagination={setPagination}
				sorting={sorting}
				setSorting={setSorting}
			/>
			<UserDialog
				open={showUser}
				setOpen={setShowUser}
				userProfile={userProfile}
				isUserProfileLoading={isUserProfileLoading}
			/>
			<TaskInformation
				open={showTaskInfo}
				setOpen={setShowTaskInfo}
				userTasks={users.filter((item) => item._id === selectedUserId) || []}
			/>
		</div>
	);
};

export default LeaderboardTable;
