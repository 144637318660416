import { useEffect, useMemo, useState } from 'react';
import { DaveQueryCreateSectionEnum } from '../../types/dave.enum';
import { handleErrorMessage } from '@/utils/notifications';
import { createQuery, updateQuery } from '../../services/dave.service';
import { queryClient } from '@/lib/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useCreateQueryProvider } from './useCreateQueryProvider';
import { useConfirm } from '@/hooks/useConfirm';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useCreateQueryNavigation = () => {
	const navigate = useNavigate();
	const { section, setSection, setErrors, details, id } = useCreateQueryProvider();
	const [isLoading, setIsLoading] = useState(false);

	const forwardLabel = useMemo(() => {
		return 'Save Query';
		// if (section === DaveQueryCreateSectionEnum.Testing) return 'Save Action';
		// return 'Next';
	}, [section]);

	useEffect(() => {
		setErrors({
			name: false,
			conditions: details.queryList.map((cond) => ({
				id: cond.id,
				actionTag: false,
				inflowAddressesList: false,
				outflowAddressesList: false,
				inflowAmount: false,
				outflowAmount: false,
				countCondition: false,
			})),
		});
	}, [details, setErrors]);

	const validate = () => {
		let isValid = true;
		if (section === DaveQueryCreateSectionEnum.Filters) {
			const newErrors = details.queryList.map((q) => {
				const e_ = {
					id: q.id,
					actionTag: !q.actionTag,
					inflowAmount: q.inflowAmount < 0,
					outflowAmount: q.outflowAmount < 0,
					countCondition: q.countCondition < 0,
				};
				isValid =
					isValid &&
					!e_.actionTag &&
					!e_.inflowAmount &&
					!e_.outflowAmount &&
					!e_.countCondition;
				return e_;
			});
			setErrors((p) => ({ ...p, conditions: newErrors }));
			if (!details.name) {
				isValid = false;
				setErrors((p) => ({ ...p, name: true }));
			}
		}

		return isValid;
	};

	const moveForward = () => {
		if (!validate()) return;
		saveQuery();
		// if (section === DaveQueryCreateSectionEnum.Filters) {
		// 	setSection(DaveQueryCreateSectionEnum.Testing);
		// }
		// if (section === DaveQueryCreateSectionEnum.Testing) {
		// 	saveQuery();
		// }
	};
	const moveBack = () => {
		// if (section === DaveQueryCreateSectionEnum.Testing) {
		// 	setSection(DaveQueryCreateSectionEnum.Filters);
		// }
	};

	const { show } = useConfirm();

	const saveQuery = async () => {
		try {
			if (id) {
				const res_ = await show({
					title: 'Are you sure you want to update this queyr?',
					subtitle:
						'This query will be updated. Updating the query will not affect the already verified tasks and only the new tasks will be verified as per new query rules. Please reach out to the support team if you have any queries.',
					confirmText: 'Update',
					cancelText: 'Cancel',
					type: 'warning',
				});
				if (!res_) {
					return;
				}
			}

			setIsLoading(true);
			const queryListWithoutIds = details?.queryList?.map(
				({ id, ...rest }) => rest,
			);

			const payload = {
				name: details?.name?.trim(),
				description: details?.description,
				requiredTrueCount:
					details?.logicSelector === 'and' ? details?.queryList.length : 1,
				queryList: queryListWithoutIds,
			};
			let res: any;
			if (id) {
				res = await updateQuery(payload, id);
				queryClient.invalidateQueries({
					queryKey: ['dave-queries', id],
				});
			} else {
				res = await createQuery(payload);
			}
			analytics.track(TrackingEvents.DaveQueryAdded, {
				numConditions: details?.queryList.length,
			});
			queryClient.invalidateQueries({
				queryKey: ['dave', 'queries'],
			});
			toast.success(
				'Query created successfully, you can now go and create a task',
			);
			navigate(`/app/tools/dave/queries`);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			handleErrorMessage(err);
		}
	};

	return {
		moveForward,
		moveBack,
		forwardLabel,
		isLoading,
	};
};
