import { Label } from '@/components/ui/label';
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';
import { MetricBasedXpFunction } from '@/features/campaigns/types/tasks.enums';
import { useLoyaltyTasks } from '@/features/loyalty-campaigns/hooks/tasks/useLoyaltyTasks';
import AnimateHeight from 'react-animate-height';
import LoyaltyMetricsXpGraph from './steps/LoyaltyMetricsXpGraph';

const MetricBasedXp = () => {
	const { template, task, setTask, errors, setErrors, editTask } =
		useLoyaltyTasks();

	return (
		<>
			<div className="mb-10 flex items-center justify-between space-x-8">
				<div className="flex-grow">
					<Label className="block mb-1">Set XP based on Volume</Label>
					<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
						Choose the graph type to distribute XP
					</p>
				</div>
				<div>
					<Select
						value={
							task?.metricBasedXpConfig?.functionType
								? task?.metricBasedXpConfig?.functionType
								: ''
						}
						onValueChange={(newValue) => {
							editTask('EDIT_METRIC_BASED_XP', newValue);
						}}
						disabled={!template?.featureApplicability?.metricBasedXp}
					>
						<SelectTrigger className=" bg-white min-w-[200px] ">
							<SelectValue placeholder={'Select Graph'} />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={'NONE'}>None</SelectItem>
							<SelectItem value={'LINEAR'}>Linear</SelectItem>
							<SelectItem value={'TIERED'}>Tiered</SelectItem>
							<SelectItem value={'SIGMOID'}>Sigmoid</SelectItem>
						</SelectContent>
					</Select>
				</div>
			</div>
			<AnimateHeight
				height={
					task?.metricBasedXpConfig?.functionType !==
					MetricBasedXpFunction.None
						? 'auto'
						: 0
				}
				duration={500}
			>
				<LoyaltyMetricsXpGraph
					metricBasedXpConfig={task?.metricBasedXpConfig}
					task={task}
				/>
			</AnimateHeight>
		</>
	);
};

export default MetricBasedXp;
