import React from 'react';
import { useRewardStore } from './useRewardStore';
import { IReward } from '../types/reward-store.type';

const useRewardCriteria = () => {
	const { formFields, setFormFields, setFormErrors, quests } = useRewardStore();

	const removeCriteria = (key: string, attribute: string) => {
		try {
			const updated = formFields?.[attribute]?.filter(
				(item) => item.eligibilityType !== key,
			);
			setFormFields({
				...formFields,
				[attribute]: updated,
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleChange = (newVal: any, attribute: string, idx: number) => {
		try {
			if (!/^\d*$/.test(newVal) && newVal !== '') {
				return;
			} else {
				setFormErrors((prev: any) => ({
					...prev,
					[formFields?.[attribute]?.[idx]?.eligibilityType]: '',
				}));

				const tempCriteria = [...(formFields?.[attribute] ?? [])];
				tempCriteria[idx] = {
					...tempCriteria[idx],
					minCount: newVal,
				};
				setFormFields({
					...formFields,
					[attribute]: tempCriteria,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleConditionChange = (newVal: any, attribute: string, idx: number) => {
		try {
			const tempCondition = [...(formFields?.[attribute] ?? [])];
			tempCondition[idx] = {
				...tempCondition[idx],
				eligibilityType: newVal,
			};

			// setFormFields((prev: IReward) => ({
			// 	...prev,
			// 	[attribute]: tempCondition,
			// }));
			setFormFields({
				...formFields,
				[attribute]: tempCondition,
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleCampaignChange = (
		newVal: string[],
		attribute: string,
		idx: number,
	) => {
		setFormErrors((prev: any) => ({
			...prev,
			[formFields?.[attribute]?.[idx]?.eligibilityType]: '',
		}));

		const newValue = newVal.map((campaignId) => ({
			campaignId,
			campaignName: quests.find((campaign) => campaign.value === campaignId)
				?.label,
		}));
		const tempCriteria = [...(formFields?.[attribute] ?? [])];
		tempCriteria[idx] = {
			...tempCriteria[idx],
			campaignIds: newValue.map((campaign) => campaign.campaignId),
		};
		setFormFields({
			...formFields,
			[attribute]: tempCriteria,
		});
	};

	return {
		removeCriteria,
		handleChange,
		handleConditionChange,
		handleCampaignChange,
	};
};

export default useRewardCriteria;
