import { Button } from '@/components/ui/button';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { useState } from 'react';
import CampaignNarrativeText from './CampaignNarrativeText';
import CampaignSEOConfig from './CampaignSEOConfig';
import CampaignTwitterShare from './CampaignTwitterShare';

const CampaignAdvancedConfiguration = () => {
	const [open, setOpen] = useState('');

	const { details, setDetails } = useCreateCampaign();

	const configuration = [
		// {
		// 	title: 'SEO Setting',
		// 	description: `Customize your quest page's search engine visibility by editing the title, description, and keywords.`,
		// 	icon: 'globe',
		// 	onClick: () => setOpen('seo'),
		// 	isConfigured: details?.seoMetadata?.title,
		// },
		{
			title: 'Social Sharing Options',
			description: `Optimize how your quest is shared by users on social platforms by customizing the placeholder tweet.`,
			icon: 'share',
			onClick: () => setOpen('share'),
			isConfigured: details?.twitterShare,
		},
		{
			title: 'Spotlight Text',
			description: `Add important details to your quest page. Use this section to convey key messages, instructions or incentives.`,
			icon: 'star-fill',
			onClick: () => setOpen('spotlight'),
			isConfigured: details?.narrativeText,
		},
	];

	return (
		<div className="mt-16">
			<div className="text-sm font-medium">Advanced Configuration</div>
			<p className="text-xs text-muted-foreground mb-5">
				Customize your campaign with advanced settings
			</p>
			<div className="bg-white rounded-xl ">
				{configuration.map((i) => (
					<div
						className="flex justify-between items-start border-b py-5 px-4"
						key={i.title}
					>
						<i
							className={`text-muted-foreground text-xl bi-${i.icon} me-4`}
						/>
						<div className="pe-10">
							<div className="text-sm font-medium">{i.title}</div>
							<div className="text-xs text-muted-foreground">
								{i.description}
							</div>
						</div>
						<div>
							<Button size="sm" variant="outline" onClick={i.onClick}>
								{i.isConfigured && (
									<i className="bi-check-circle-fill me-1"></i>
								)}
								{i.isConfigured ? 'Configured' : 'Configure'}
							</Button>
						</div>
					</div>
				))}
			</div>
			<CampaignNarrativeText
				open={open === 'spotlight'}
				setOpen={setOpen}
				narrativeText={details.narrativeText}
				setNarrativeText={(e: string) => {
					setDetails((prev) => ({
						...prev,
						narrativeText: e,
					}));
				}}
			/>

			<CampaignTwitterShare
				open={open === 'share'}
				setOpen={setOpen}
				twitterShare={details.twitterShare}
				setTwitterShare={(e: string) => {
					setDetails((prev) => ({
						...prev,
						twitterShare: e,
					}));
				}}
			/>
			<CampaignSEOConfig
				open={open === 'seo'}
				setOpen={setOpen}
				seo={details.seoMetadata}
				setSeo={(e: any) => {
					setDetails((prev) => ({
						...prev,
						seoMetadata: e,
					}));
				}}
				name={details?.name}
				description={details?.description}
				banner={details?.bannerLink}
			/>
		</div>
	);
};

export default CampaignAdvancedConfiguration;
