import React from 'react';
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grip } from 'lucide-react';

interface DraggableProps {
	id: string;
	children: React.ReactNode;
}

const DraggableItem: React.FC<DraggableProps> = ({ id, children }) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable(
		{ id },
	);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div ref={setNodeRef} style={style} className="flex items-center">
			<div {...attributes} {...listeners} className="mb-1.5">
				<Grip
					size={32}
					className="cursor-move mr-2 p-2 text-slate-600 hover:rounded-full hover:bg-slate-100"
				/>
			</div>
			{children}
		</div>
	);
};

interface DraggableReorderListProps {
	items: { id: string }[];
	onReorder: (newOrder: { id: string }[]) => void;
	children: React.ReactNode[];
}

const DraggableReorderList: React.FC<DraggableReorderListProps> = ({
	items,
	onReorder,
	children,
}) => {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (active.id !== over?.id) {
			const oldIndex = items.findIndex((item) => item.id === active.id);
			const newIndex = items.findIndex((item) => item.id === over?.id);

			const newOrder = arrayMove(items, oldIndex, newIndex);
			onReorder(newOrder);
		}
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
		>
			<SortableContext items={items} strategy={verticalListSortingStrategy}>
				{React.Children.map(children, (child, index) => (
					<DraggableItem key={items[index].id} id={items[index].id}>
						{child}
					</DraggableItem>
				))}
			</SortableContext>
		</DndContext>
	);
};

export default DraggableReorderList;
