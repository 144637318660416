import { Button } from '@/components/ui/button';
import CampaignCreateMode from './CampaignCreateMode';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { cn } from '@/lib/utils';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { CreateCampaignSections } from '@/features/campaigns/types';
import { Fragment } from 'react/jsx-runtime';
import GuideVideoDialog from '@/features/dave/components/dialogs/GuideVideoDialog';
import { useEffect, useState } from 'react';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const CampaignCreateSection = () => {
	const [showGuide, setShowGuide] = useState(false);

	const { section, setSection, errors } = useCreateCampaign();
	const sections = [
		{
			title: 'Details',
			value: CreateCampaignSections.Details,
			icon: 'bank',
		},
		{
			title: 'Tasks',
			value: CreateCampaignSections.Tasks,
			icon: 'view-list',
		},
		{
			title: 'Rewards',
			value: CreateCampaignSections.Rewards,
			icon: 'box-fill',
		},
	];

	useEffect(() => {
		analytics.track(TrackingEvents.CreateQuestSectionViewed, {
			section,
		});
	}, [section]);

	const hasErrors = (errors, path) => {
		const keys = path ? path.split('.') : [];

		let current = errors;

		for (const key of keys) {
			if (current === undefined || current === null) {
				return false;
			}
			current = current[key] || current[key.toLowerCase()];
		}

		if (typeof current !== 'object' || current === null) {
			return !!current;
		}

		return Object.values(current).some((value) => {
			if (typeof value === 'object' && value !== null) {
				return hasErrors(value, ''); // Keep the path as empty string to avoid passing nested keys
			}
			return !!value;
		});
	};

	return (
		<>
			<div className="flex justify-center items-center space-x-4">
				{sections.map((i, index) => (
					<Fragment key={i.value}>
						<TooltipWrapper
							tooltip={
								hasErrors(errors, i.value.toLowerCase())
									? 'Some of the details are added wrongly'
									: ''
							}
						>
							<Button
								size="sm"
								className={cn(
									'rounded-full px-4',
									section !== i.value
										? ''
										: 'border-purple-700 bg-purple-100 text-purple-800  hover:bg-purple-100  hover:text-purple-800',
									hasErrors(errors, i.value.toLowerCase())
										? 'border-red-200 border'
										: 'border-transparent',
								)}
								variant="secondary"
								onClick={() => setSection(i.value)}
							>
								{hasErrors(errors, i.value.toLowerCase()) ? (
									<i className="bi bi-exclamation-triangle-fill me-2 text-red-500"></i>
								) : (
									<i className={`bi bi-${i.icon} me-2`}></i>
								)}
								{i.title}
							</Button>
						</TooltipWrapper>
						{sections.length !== index + 1 && (
							<div>
								<i className="bi bi-chevron-right text-muted-foreground"></i>
							</div>
						)}
					</Fragment>
				))}
			</div>
			{/* <div className="absolute start-0 ms-5 flex gap-4">
				<Button
					size="sm"
					variant="outline"
					onClick={() => {
						setShowGuide(true);
					}}
				>
					<i className="bi bi-play-circle me-1"></i>
					Tutorial
				</Button>
			</div> */}

			{/* <CampaignCreateMode /> */}
			<GuideVideoDialog
				open={showGuide}
				setOpen={setShowGuide}
				embed="https://www.youtube.com/embed/WeF-X9Iro-o?si=22UnSZubUt3kugt7"
			/>
		</>
	);
};

export default CampaignCreateSection;
