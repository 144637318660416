import { useState } from 'react';
import { setUpCommunityProfileAPIV2 } from '../services/onboarding.service';
import { useAuth } from '@/hooks/useAuth';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { stripURLs, validateCompleteURL } from '@/utils/parsers';
import { EnterpriseTokenStatus } from '@/features/dashboard/types/enterprise.types';

type Community = {
	name: string;
	twitter: string;
	tokenStatus?: string;
	tokenDetails?: {
		tokenAddress: string;
		tokenChainId: number | null;
	};
	referralCode?: string;
};

export const useOnboarding = () => {
	const { refetchUser } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [community, setCommunity] = useState<Community>({
		name: '',
		twitter: '',
		tokenStatus: '',
		tokenDetails: {
			tokenAddress: '',
			tokenChainId: null,
		},
		referralCode: '',
	});
	const [errors, setErrors] = useState({
		name: false,
		twitter: false,
	});
	const [openAccordian, setOpenAccordion] = useState<string | null>(null);

	const handleAccordionChange = (value: string) => {
		setOpenAccordion((prevValue) => (prevValue === value ? null : value));
	};
	const handleCommunitySetup = async () => {
		try {
			setIsLoading(true);
			if (!community.name || !community.twitter) {
				if (!community.name) setErrors((prev) => ({ ...prev, name: true }));
				if (!community.twitter)
					setErrors((prev) => ({ ...prev, twitter: true }));

				setIsLoading(false);
				return;
			}
			const completeURL = validateCompleteURL(
				'https://www.twitter.com/' +
					community.twitter?.substring(
						community.twitter?.lastIndexOf('/') + 1,
					),
			);

			const strippedURL = stripURLs({
				twitter: completeURL,
			});

			const payload: Community = {
				name: community.name,
				twitter: strippedURL.twitter,
				tokenStatus: community.tokenStatus || EnterpriseTokenStatus.Unknown,
				referralCode: community.referralCode,
			};

			if (community.tokenStatus === EnterpriseTokenStatus.Listed) {
				payload.tokenDetails = community.tokenDetails;
			}

			await setUpCommunityProfileAPIV2(payload);
			setCommunity({
				...community,
				twitter: strippedURL.twitter,
			});
			analytics.track(TrackingEvents.OnboardingCommunityUpdatedV2, {
				enterpriseName: community.name,
			});
			if (localStorage.getItem('referral-code')) {
				localStorage.removeItem('referral-code'); //remove used up referral code
			}
			setIsLoading(false);
			await refetchUser();
			setIsLoading(false);
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading(false);
			console.log(err);
		}
	};

	return {
		handleCommunitySetup,
		community,
		setCommunity,
		isLoading,
		errors,
		setErrors,
		openAccordian,
		setOpenAccordion,
		handleAccordionChange,
	};
};
