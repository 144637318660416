import { useDebounce } from '@/hooks/useDebounce';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { IAdminCreditRequests, IAdminCredits } from '../../types/admin-credit.types';
import { adminCreditRequests } from '../../services/admin.service';

const useGetAdminCredits = () => {
	const [search, setSearch] = useState({
		value: '',
	});
	const [filters, setFilters] = useState({
		status: [],
		requestType: [],
	});
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const debounceSearch = useDebounce(search?.value, 500);

	const { data, isLoading, refetch } = useQuery<IAdminCreditRequests>({
		queryKey: [
			'admin',
			'credit-requests',
			{
				pagination,
				debounceSearch,
				filters,
			},
		],
		queryFn: () =>
			adminCreditRequests({
				search: {
					value: debounceSearch,
				},
				filters: {
					status: filters.status,
					requestType: filters.requestType,
				},
				page: Number(pagination.pageIndex + 1) ?? 1,
				limit: Number(pagination.pageSize) ?? 10,
			}),
	});

	return {
		search,
		setSearch,
		filters,
		setFilters,
		isLoading,
		creditRequests: data?.data?.creditRequests || [],
		totalCount: data?.data?.totalCount || 0,
		refetch,
		pagination,
		setPagination,
	};
};

export default useGetAdminCredits;
