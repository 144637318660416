import { axios } from '@/lib/axios';

export const postGoogleForm = async (data: any) => {
	try {
		await axios.post('/boosters/kols-partnership', data);
	} catch (error) {
		console.log(error);
	}
};

export const getKols = async ({
	limit,
	page,
	search,
	channels,
	followers,
	language,
	price,
	region,
}: {
	limit: number;
	page: number;
	search?: string;
	channels?: string[];
	followers?: string[];
	language?: string[];
	price?: string[];
	region?: string[];
}) => {
	const searchParam = search ? `&search=${encodeURIComponent(search)}` : '';
	const channelsParam =
		channels && channels.length > 0 ? `&channels=${channels.join(',')}` : '';
	const followersParam =
		followers && followers.length > 0 ? `&followers=${followers.join(',')}` : '';
	const languageParam =
		language && language.length > 0 ? `&language=${language.join(',')}` : '';
	const priceParam = price && price.length > 0 ? `&price=${price.join(',')}` : '';
	const regionParam =
		region && region.length > 0 ? `&region=${region.join(',')}` : '';

	const { data } = await axios.get(
		`/boosters/kols?limit=${limit}&page=${page}${searchParam}${channelsParam}${followersParam}${languageParam}${priceParam}${regionParam}`,
	);
	return data;
};
