import { memo } from 'react';
import StepCard from './StepCard';
import { useConnectAPI } from '../../hooks/useConnectAPI';
import en from '../../../../locales/en.json';
import { GetStepsConfig } from './StepsConfig';

const DetailsSection = () => {
	const {
		step,
		setStep,
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		parsedData,
		setParsedData,
		completedSteps,
	} = useConnectAPI();

	const { taskApiIntegration } = en;

	const stepsConfig = GetStepsConfig({
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		parsedData,
		setParsedData,
	});

	return (
		<div className="space-y-5">
			{stepsConfig.map(({ key, component }) => (
				<StepCard
					key={key}
					stepKey={key}
					isActive={step === key}
					title={taskApiIntegration[key.toLowerCase()]?.title}
					onStepClick={(key: string) => setStep(key as any)}
					completedSteps={completedSteps}
				>
					{component}
				</StepCard>
			))}
		</div>
	);
};

const MemoizedDetailsSection = memo(DetailsSection);
export default MemoizedDetailsSection;
