import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import InputText from '@/components/element/inputs/InputText';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import { SelectTimeStamp } from '@/components/element/inputs/input-duration/SelectTimeStamp';
import Spinner from '@/components/element/loading/Spinner';
import { QuillEditor } from '@/components/element/rich-text-editor/QuillEditor';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { useEditCamapignMetadata } from '@/features/campaigns/hooks/report/useEditCamapignMetadata';
import moment from 'moment';
import { useMemo } from 'react';

const QuestMetadataUpdate = ({ open, setOpen }) => {
	const {
		details,
		errors,
		handleDetailsEdit,
		endDate,
		updateMetadata,
		isLoading,
		canEditEndDate,
	} = useEditCamapignMetadata(setOpen);

	const minEndTimestamp = useMemo(() => {
		const timezone = details.timezone;
		const date = new Date() > endDate ? new Date() : endDate;
		if (details.endDate) {
			if (
				moment.tz(details.endDate, timezone).format('Do MMM YYYY') ===
				moment.tz(date, timezone).format('Do MMM YYYY')
			) {
				return moment.tz(date, timezone).format('hh:mm a');
			}
		}
	}, [details.endDate, details.timezone, endDate]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[585px] p-0 overflow-visible">
				<div className="p-6 pb-0 overflow-x-hidden">
					<DialogHeader className="mb-8">
						<DialogTitle className="text-xl font-medium">
							Edit Campaign
						</DialogTitle>
						<div className="text-sm text-muted-foreground mt-2">
							Edit your campaign details here
						</div>
					</DialogHeader>
					<div className="space-y-8 max-h-[28rem] overflow-auto px-1">
						<div className="grid grid-cols-12 gap-4 second-element">
							<InputText
								label="Title"
								placeholder="Launch on Intract"
								className="col-span-8"
								value={details.name}
								setValue={(e) => handleDetailsEdit('name', e)}
								error={errors?.name}
								inputClassName="bg-white dark:bg-black"
								errorText="Please enter a valid title"
							/>
							<div className="col-span-4">
								<Label className="font- mb-2 block">Chain</Label>
								<SelectNetwork2
									value={details.network}
									setValue={({ chainId }) =>
										handleDetailsEdit('network', chainId)
									}
									withoutPortal
								/>
							</div>
						</div>
						<div className="mt-10">
							<QuillEditor
								value={details.description}
								setValue={(e) => handleDetailsEdit('description', e)}
								placeholder="Describe your campaign here..."
								error={errors?.description}
								errorText="Please enter a valid description"
							/>
						</div>
						<div className="mt-10">
							<InputText
								label="Spotlight Text"
								placeholder="Launch on Intract"
								className="col-span-8"
								value={details?.narrativeTexts[0]?.text}
								setValue={(e) =>
									handleDetailsEdit('narrativeTexts', [
										{
											text: e,
											key: details?.narrativeTexts[0]?.key,
										},
									])
								}
							/>
						</div>

						<div
							className={
								canEditEndDate
									? ''
									: 'pointer-events-none opacity-60'
							}
						>
							<Label className="mb-2 block">Campaign End Date</Label>
							<div className="text-xs text-muted-foreground mb-4">
								Campaign end date can only be increased. For
								campaigns with token reward, end date is not allowed
								to change to keep the reward distribution fair.
							</div>
							<SelectTimeStamp
								isStart={false}
								isContinous={false}
								setIsContinous={() => {}}
								value={details.endDate}
								setValue={(e) => handleDetailsEdit('endDate', e)}
								fromDate={
									new Date() > endDate ? new Date() : endDate
								}
								timezone={details.timezone}
								minTimestamp={minEndTimestamp}
							/>
							{errors.endDate && (
								<div className="text-red-500 text-sm mt-1">
									Please enter a valid end date
								</div>
							)}
						</div>
					</div>
				</div>
				<DialogFooter className="w-full space-x-2 border-t py-3 px-9">
					<Button onClick={() => updateMetadata()} disabled={isLoading}>
						{isLoading && <Spinner className="me-2" />}
						<span>Edit Campaign</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default QuestMetadataUpdate;
