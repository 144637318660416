import Blogs from '../components/homepage/Blogs';
import Customers from '../components/homepage/Customers';
import Hero from '../components/homepage/Hero';
import Numbers from '../components/homepage/Numbers';
import Testimonials from '../components/homepage/Testimonials';
import LandingLayout from '../components/layout/LandingLayout';
import FeatureComparison from '../components/ppc/FeatureComparison';
import PPCHero from '../components/ppc/PPCHero';

const AdOptimisedSignUpPage = () => {
	return (
		<LandingLayout>
			<PPCHero />
			<Customers />
			<Numbers />
			{/* <FeatureComparison /> */}
			<Testimonials />
			{/* <Blogs /> */}
		</LandingLayout>
	);
};

export default AdOptimisedSignUpPage;
