import React, {
	useEffect,
	useMemo,
	useState,
	useCallback,
	Dispatch,
	SetStateAction,
} from 'react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
	CommandSeparator,
	CommandInput,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { cn } from '@/lib/utils';
import { CheckIcon } from '@radix-ui/react-icons';
import ChainBadge from '../badges/ChainBadge';
import { additionalChainsNotInDB } from '@/utils/chains/additionalChainsNotInDB';
import { Separator } from '@/components/plate-ui/separator';

interface Network {
	name: string;
	value: number;
	chainId: number;
	img: string;
	type: string;
}

interface SelectNetworksTagProps {
	value: number[];
	setValue: (updatedValues: {
		chainIds: number[];
		customChainIds: number[];
	}) => void;
	error?: boolean | string;
	errorMsg?: string;
	allowedChainIds?: number[];
	placeholder?: string;
	isMulti?: boolean;
	defaultAll?: boolean;
	withoutPortal?: boolean;
	disabled?: boolean;
	showNonEVM?: boolean;
	fetchEnterpriseChains?: boolean;
	addCustomChain?: boolean;
	setShowAddCustomChain?: Dispatch<SetStateAction<boolean>>;
}

export function SelectNetworksTag({
	value,
	setValue,
	error,
	errorMsg,
	allowedChainIds,
	placeholder = 'Select Chain',
	isMulti = true,
	defaultAll = true,
	withoutPortal,
	disabled,
	showNonEVM,
	fetchEnterpriseChains,
	addCustomChain,
	setShowAddCustomChain,
}: SelectNetworksTagProps) {
	const { chains } = useGetSupportedChains({
		showAdditionalChains: true,
		fetchEnterpriseChains,
	});

	const mergeChains = useCallback(
		(originalChains: any[], newChains: any[]): any[] => {
			if (!originalChains) return newChains;
			const existingChainNames = new Set(
				originalChains.map((chain) => chain.chainName),
			);
			const filteredNewChains = newChains.filter(
				(chain) => !existingChainNames.has(chain.chainName),
			);
			return [...originalChains, ...filteredNewChains];
		},
		[],
	);

	const finalChainsArray = useMemo(
		() => mergeChains(chains, additionalChainsNotInDB),
		[chains, mergeChains],
	);

	const networks = useMemo(() => {
		if (!finalChainsArray) return [];
		const groupedNetworks = [
			{
				title: 'Mainnet',
				networks: finalChainsArray
					.filter((chain) => !chain.isTestnet && !chain.isCustom)
					?.map((chain) => ({
						name: chain.chainName,
						value: Number(chain.chainId),
						chainId: Number(chain.chainId),
						img: chain.chainLogo,
						type: 'MAINNET',
					})),
			},
			{
				title: 'Custom Enterprise Chains',
				networks: finalChainsArray
					.filter((chain) => chain.isCustom && !chain.isTestnet)
					.map((chain) => ({
						name: chain.chainName,
						value: Number(chain.chainId),
						chainId: Number(chain.chainId),
						img: chain.chainLogo,
						type: 'CUSTOM',
					})),
			},
			{
				title: 'EVM Testnets',
				networks: finalChainsArray
					.filter((chain) => chain.isTestnet)
					.map((chain) => ({
						name: chain.chainName,
						value: Number(chain.chainId),
						chainId: Number(chain.chainId),
						img: chain.chainLogo,
						type: 'EVM_TESTNET',
					})),
			},
		];

		return groupedNetworks
			.map((network) => ({
				...network,
				networks: network.networks
					.map((i) => ({
						name: i.name,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.img,
						type:
							i.type ??
							network?.title?.toUpperCase()?.replace(' ', '_'),
					}))
					.filter(
						(net) =>
							!allowedChainIds ||
							(allowedChainIds.length === 0 && defaultAll) ||
							allowedChainIds.includes(net?.chainId),
					),
			}))
			.filter((network) => network.networks.length > 0);
	}, [finalChainsArray, allowedChainIds, defaultAll]);

	const [selectedNetworks, setSelectedNetworks] = useState<Network[]>([]);

	useEffect(() => {
		if (!Array.isArray(value)) return;

		const selected = value.map((chainId) => {
			const found = networks
				.flatMap((group) => group.networks)
				.find((net) => net.chainId === chainId);
			return found || { name: '', value: chainId, chainId, img: '', type: '' };
		});
		setSelectedNetworks(selected);
	}, [value, networks]);

	const handleSelect = useCallback(
		(option: Network) => {
			setSelectedNetworks((prev) => {
				const newValues = prev.some((net) => net.chainId === option.chainId)
					? prev.filter((net) => net.chainId !== option.chainId)
					: [...prev, option];

				const chainIds = newValues
					.filter((net) => net.type !== 'CUSTOM')
					.map((net) => net.chainId);
				const customChainIds = newValues
					.filter((net) => net.type === 'CUSTOM')
					.map((net) => net.chainId);

				setValue({ chainIds, customChainIds });
				return newValues;
			});
		},
		[setValue],
	);

	const isSelected = useCallback(
		(chainId: number) => selectedNetworks.some((net) => net.chainId === chainId),
		[selectedNetworks],
	);

	if (!isMulti) return null;

	return (
		<>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						className={cn(
							'w-full text-muted-foreground justify-between px-3',
							error ? 'border-destructive' : '',
						)}
						disabled={disabled}
					>
						{selectedNetworks.length === 0 ? (
							<>
								<span>{placeholder}</span>
								<i className="bi-chevron-expand"></i>
							</>
						) : (
							<div className="hidden space-x-1 lg:flex truncate">
								{selectedNetworks.slice(0, 2).map((option) => (
									<Badge
										key={option.chainId}
										variant="secondary"
										className="rounded-sm px-1 font-normal truncate"
									>
										<ChainBadge
											chainId={option.chainId}
											showAdditionalChains
											fetchEnterpriseChains={
												fetchEnterpriseChains
											}
											className="max-w-[10rem] truncate"
										/>
									</Badge>
								))}
								{selectedNetworks.length > 2 && (
									<Badge
										variant="secondary"
										className="rounded-sm px-1 font-normal"
									>
										+{selectedNetworks.length - 2} selected
									</Badge>
								)}
							</div>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent
					align="start"
					className="p-0 relative w-[18rem] h-[24rem] overflow-y-auto"
					withoutPortal={withoutPortal}
				>
					<Command>
						<CommandInput
							placeholder={placeholder}
							className="p-2 border-none focus:ring-0 focus:outline-none rounded-lg"
						/>
						<Separator />
						<CommandList>
							<CommandEmpty>No such chain found</CommandEmpty>
							{networks.map((network) => (
								<CommandGroup key={network.title}>
									<CommandItem className="hover:!bg-transparent cursor-default">
										{network.title}
									</CommandItem>
									{network.networks.map((option) => (
										<CommandItem
											key={option.chainId}
											onSelect={() => handleSelect(option)}
											className="cursor-pointer"
										>
											<div
												className={cn(
													'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
													isSelected(option.chainId)
														? 'bg-primary text-primary-foreground'
														: 'opacity-50 [&_svg]:invisible',
												)}
											>
												{isSelected(option.chainId) && (
													<CheckIcon
														className="size-4"
														aria-hidden="true"
													/>
												)}
											</div>
											{option.img && (
												<>
													<img
														src={option.img}
														alt=""
														className="h-5 w-5 me-2 rounded-lg object-cover"
													/>
													<span>{option.name}</span>
												</>
											)}
										</CommandItem>
									))}
								</CommandGroup>
							))}
							{selectedNetworks.length > 0 && (
								<div className="fixed bottom-10 bg-white w-[99.3%] -mb-1 ">
									<Separator />
									<CommandGroup>
										<CommandItem
											onSelect={() =>
												setValue({
													chainIds: [],
													customChainIds: [],
												})
											}
											className="justify-center text-center hover:bg-white"
										>
											<i className="bi bi-x-circle me-2"></i>{' '}
											Clear Selection
										</CommandItem>
									</CommandGroup>
								</div>
							)}
							{addCustomChain ? (
								<div className="fixed bottom-0 bg-white w-[99.3%] rounded-b-lg hover:bg-white">
									<Separator />
									<Button
										variant="ghost"
										className="w-full text-sm font-normal "
										onClick={() => setShowAddCustomChain(true)}
									>
										<i className="bi bi-plus-circle me-2"></i>{' '}
										Add Custom Chain
									</Button>
								</div>
							) : null}
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
			{error && (
				<p className="text-destructive text-xs mt-1">{errorMsg || error}</p>
			)}
		</>
	);
}
