import RewardStore from '../components/RewardStore';
import RewardStoreHeader from '../components/RewardStoreHeader';
import { RewardStoreProvider } from '../providers/reward-store.provider';

const RewardStorePage = () => {
	return (
		<RewardStoreProvider>
			<RewardStoreHeader />
			<RewardStore />
		</RewardStoreProvider>
	);
};

export default RewardStorePage;
