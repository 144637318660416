import React, { useState } from 'react';
import RewardStoreTable from './RewardStoreTable';
import InputText from '@/components/element/inputs/InputText';
import useFilterRewardStore from '../hooks/useFilterRewardStore';
import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { RewardStatus } from '../types/reward-store.enums';
import { useRewardStore } from '../hooks/useRewardStore';

const RewardStoreAnalytics = () => {
	const { search, setSearch, filters, setFilters, filteredData } =
		useFilterRewardStore();
	const { rewardStore } = useRewardStore();

	const statusOptions = [
		{
			value: RewardStatus.Active,
			label: <StatusBadge status={RewardStatus.Active} className="text-sm" />,
		},
		{
			value: RewardStatus.Inactive,
			label: (
				<StatusBadge status={RewardStatus.Inactive} className="text-sm" />
			),
		},
	];

	return (
		<div className="space-y-10">
			<div className="my-6 ">
				<div className="flex space-x-4 mb-4">
					<InputText
						value={search}
						setValue={setSearch as any}
						prepend={
							<i className="bi-search px-3 text-muted-foreground text-sm"></i>
						}
						placeholder="Search Rewards"
						className="w-[300px] lg:w-[300px]"
						inputClassName="h-8 "
					/>
					<FacetedFilter
						title="Reward Status"
						options={statusOptions}
						selectedValues={filters.status}
						setSelectedValues={(values) =>
							setFilters((prev) => ({
								...prev,
								status: values,
							}))
						}
					/>
					<FacetedFilter
						title="Section Name"
						options={rewardStore.map((data) => ({
							value: data.name,
							label: data.name,
						}))}
						selectedValues={filters.sectionName}
						setSelectedValues={(values) =>
							setFilters((prev) => ({
								...prev,
								sectionName: values,
							}))
						}
					/>
				</div>
			</div>
			<RewardStoreTable filteredRewardStore={filteredData} />
		</div>
	);
};

export default RewardStoreAnalytics;
