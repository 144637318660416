const gradientClassMap: { [key: string]: string } = {
	blue: 'from-blue-500 to-violet-700',
	pink: 'from-pink-500 to-pink-700',
};

const SectionHeader = ({
	title,
	description,
	className,
	titleGradient,
}: {
	title: string;
	description?: string;
	className?: string;
	titleGradient?: string;
}) => {
	const renderTitle = () => {
		if (!titleGradient) {
			return <>{title}</>;
		}

		const parts = title.split('**');
		return (
			<>
				{parts.map((part, index) => {
					if (index % 2 === 0) {
						return <span key={index}>{part}</span>;
					} else {
						return (
							<span
								key={index}
								className={`bg-gradient-to-r ${gradientClassMap[titleGradient]} bg-clip-text text-transparent`}
							>
								{part}
							</span>
						);
					}
				})}
			</>
		);
	};

	return (
		<div className={`mx-auto max-w-md text-center sm:max-w-xl ${className}`}>
			<h2 className="font-display text-4xl font-extrabold leading-tight text-black sm:text-5xl sm:leading-tight">
				{renderTitle()}
			</h2>
			{description && (
				<p className="mt-1 text-gray-600 sm:text-lg">{description}</p>
			)}
		</div>
	);
};

export default SectionHeader;
