export const aboutPage = {
	hero: {
		title: 'Empowering web3 builders to build human on-chain communities.',
		description:
			'We believe in creating authentic long lasting communities on the internet. We take pride to match make builders with their true fans, keeping it fun & educational on the way.',
		img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/22efcefacba647ab816f9fad0e6593e6.png',
	},
	mission: {
		title: 'OUR MISSION',
		description:
			'Our mission is to empower builders in creating global communities of their true fans. While democratizing access of financial opportunities to anyone on the internet, regardless of where they come from.',
	},
	whyWeExist: {
		title: 'Why Intract Exists.',
		description: [
			`Intract was founded by entrepreneurs frustrated with the lack of transparency and fairness in the digital web, controlled by a few companies. These companies send short term users & bots to small businesses for millions in ad spends, exploit user data, underpay creators while people in developing countries suffer from hyperinflation and oppressive regimes. This inspired us to create Intract.`,
			`Navigating web3 to find new wealth opportunities involves a steep learning curve and significant risks. Businesses struggle to grow their communities, often wasting resources on unaligned users and bots.`,
			`Thus, we built a leading learn-and-earn platform for web3, open and accessible to all. Businesses can identify their true fans and build engaged communities. Digital friends like you and me can discover ways to contribute to global business opportunities and earn fairly.`,
		],
		cta: 'Ready to build your community of true fans?',
		ctaLink: '/auth/register',
	},
	culture: {
		title: 'One Innovative Web3 Foundation. **Three core values.**',
		description: '',
		culture: [
			{
				title: 'Dedicated Human Support',
				description:
					'We provide personalized success support from real people. Our team is always ready to assist and ensure you achieve your goals.',
				img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/69eca338799e4804912c26c88c99e340.png',
				height: 'h-16',
				width: 'w-16',
			},
			{
				title: 'Co-Marketing Partnership',
				description: `We act as an extension of your marketing team. Offering collaborative marketing support to boost your brand's reach and impact.`,
				img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/24da86fffdad4f7793de9ec521ddf344.png',
				width: 'w-[72px]',
				height: 'h-[16]',
			},

			{
				title: 'Data-Driven Play books',
				description:
					'Our strategies are backed by robust data. We provide you with practical, evidence-based playbooks to enhance your success.',
				img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/8be829d0828047e3bf0e1b1e00a2e723.png',
				height: 'h-16',
				width: 'w-[53px]',
			},
		],
	},
	investors: {
		title: 'Backed by industry leaders.',
		investors: [
			{
				name: 'Alpha Wave',
				link: 'https://alphawave.com',
				linkLabel: 'alphawave.com',
				img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/Checkout.com-svg-1.svg',
			},
			{
				name: 'Alpha Wave',
				link: 'https://alphawave.com',
				linkLabel: 'alphawave.com',
				img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/Checkout.com-svg-1.svg',
			},
			{
				name: 'Alpha Wave',
				link: 'https://alphawave.com',
				linkLabel: 'alphawave.com',
				img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/Checkout.com-svg-1.svg',
			},
			{
				name: 'Alpha Wave',
				link: 'https://alphawave.com',
				linkLabel: 'alphawave.com',
				img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/Checkout.com-svg-1.svg',
			},
		],
	},
	careers: {
		badge: 'We’re hiring!',
		title: 'Careers at Intract.',
		description: 'Ready to join the web3 revolution? Consider joining us.',
	},
};
