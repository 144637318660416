import { Media } from '@/components/element/media/Media';
import { asset } from '@/config/asset-cdn';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { IEnterprise } from '@/features/dashboard/types/enterprise.types';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import { convertToSnakeUpperCase } from '@/utils/parsers';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import en from '../../../locales/en.json';
import { createRequest } from '../services/credits.service';
import { CreditBaseCtaEnum, CreditRequestValueEnum } from '../types/credits.enums';
import { ICreditBenefit } from '../types/credits.types';
import useGetCreditsRequests from './useGetCreditsRequests';
interface ModalConfig {
	title: JSX.Element;
	subtitle: string;
	type: 'homepage' | 'quest' | 'notification' | 'repost' | 'chain-integration';
	valueType: CreditRequestValueEnum[];
	inputPlaceholder?: string;
	disclaimer?: string;
}

export interface ISelectedBenefit extends ICreditBenefit {
	campaignId: string;
	notificationContent?: string;
	tweet?: string;
	campaignName?: string;
	optedIn?: boolean;
}

export const useCreditBenefitsModal = ({
	creditBenefits,
}: {
	creditBenefits: ICreditBenefit[];
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedBenefit, setSelectedBenefit] = useState<ISelectedBenefit | null>(
		null,
	);
	const [formErrors, setFormErrors] = useState({});
	const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);

	const { user } = useAuth();

	const { intractCredits } = en;
	const { applyConfirmation } = intractCredits.modals;

	const { refetch: refetchCreditRequests } = useGetCreditsRequests();
	const { refetch: refetchEnterprise } = useGetEnterprise();

	const getModalConfig = (benefit: ICreditBenefit): ModalConfig => {
		const showInputQuestion = benefit?.required?.find(
			(question) => !question.isHidden,
		);
		if (
			benefit.title.includes('Homepage') ||
			benefit.title.includes('Airdrop') ||
			benefit.title.includes('Buzzing')
		) {
			return {
				title: (
					<div className="flex items-center">
						{applyConfirmation?.homepageWidget?.title}&nbsp;
						<span className="flex items-center gap-1 bg-slate-100 rounded-full">
							<Media
								src={asset['credit-icon-primary']}
								altText="credit-icon"
								className="size-[22px]"
							/>
							<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
								{benefit.credits}
							</span>
						</span>
						&nbsp;?
					</div>
				),
				subtitle: applyConfirmation?.homepageWidget?.subtitle,
				type: 'homepage',
				valueType: [
					CreditRequestValueEnum.questId,
					CreditRequestValueEnum.questTitle,
				],
				inputPlaceholder: showInputQuestion?.placeholder,
			};
		}
		if (benefit.title.includes('Quest of the day')) {
			return {
				title: (
					<div className="flex items-center">
						{applyConfirmation?.QOD?.title}&nbsp;
						<span className="flex items-center gap-1 bg-slate-100 rounded-full">
							<Media
								src={asset['credit-icon-primary']}
								altText="credit-icon"
								className="size-[22px]"
							/>
							<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
								{benefit.credits}
							</span>
						</span>
						&nbsp;?
					</div>
				),
				subtitle: applyConfirmation?.QOD?.subtitle,
				type: 'quest',
				valueType: [
					CreditRequestValueEnum.questId,
					CreditRequestValueEnum.questTitle,
				],
				inputPlaceholder: showInputQuestion?.placeholder,
			};
		}
		if (benefit.title.includes('Notification')) {
			return {
				title: (
					<div className="flex items-center">
						{applyConfirmation?.notification?.title}&nbsp;
						<span className="flex items-center gap-1 bg-slate-100 rounded-full">
							<Media
								src={asset['credit-icon-primary']}
								altText="credit-icon"
								className="size-[22px]"
							/>
							<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
								{benefit.credits}
							</span>
						</span>
						&nbsp;?
					</div>
				),
				subtitle: applyConfirmation?.notification?.subtitle,
				type: 'notification',
				valueType: [
					CreditRequestValueEnum.questId,
					CreditRequestValueEnum.text,
					CreditRequestValueEnum.questTitle,
				],
				inputPlaceholder: showInputQuestion?.placeholder,
			};
		}
		if (benefit.title.includes('Chain Integration')) {
			return {
				title: (
					<div className="flex items-center">
						{applyConfirmation?.chainIntegration?.title}&nbsp;
						<span className="flex items-center gap-1 bg-slate-100 rounded-full">
							<Media
								src={asset['credit-icon-primary']}
								altText="credit-icon"
								className="size-[22px]"
							/>
							<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
								{benefit.credits}
							</span>
						</span>
						&nbsp;?
					</div>
				),
				subtitle: applyConfirmation?.chainIntegration?.subtitle,
				valueType: [],
				type: 'chain-integration',
				disclaimer:
					'Get your project approved to access Credits and its benefits.',
			};
		}

		return {
			title: (
				<div className="flex items-center">
					{applyConfirmation?.reTweet?.title}&nbsp;
					<span className="flex items-center gap-1 bg-slate-100 rounded-full">
						<Media
							src={asset['credit-icon-primary']}
							altText="credit-icon"
							className="size-[22px]"
						/>
						<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
							{benefit.credits}
						</span>
					</span>
					&nbsp;?
				</div>
			),
			subtitle: applyConfirmation?.reTweet?.subtitle,
			type: 'repost',
			valueType: [CreditRequestValueEnum.text],
			inputPlaceholder: 'Enter tweet/post link...',
			disclaimer:
				'Get your project approved to access Credits and its benefits.',
		};
	};

	const updateLocalState = (benefit: ICreditBenefit) => {
		const existingCreditsData = queryClient.getQueryData<{
			data: {
				consumeCredits: ICreditBenefit[];
				earnCredits: ICreditBenefit[];
			};
		}>(['credits', 'benefits']);

		if (existingCreditsData?.data) {
			const updatedConsumeCredits =
				existingCreditsData.data.consumeCredits.map((item) => {
					// Check if this is the selected benefit to be opted in
					if (item._id === benefit._id) {
						return {
							...item,
							enterpriseCurrentRequestStatus:
								CreditBaseCtaEnum.OptedIn,
						};
					}

					// For other items, retain their current `enterpriseCurrentRequestStatus`
					return item;
				});

			// Update the query data with the new state
			queryClient.setQueryData(['credits', 'benefits'], {
				data: {
					...existingCreditsData.data,
					consumeCredits: updatedConsumeCredits,
				},
			});
		}
	};

	const handleAction = (benefit: ICreditBenefit) => {
		// analytics.track(TrackingEvents.ApplyBenefitClicked, {
		// 	userId: user?._id,
		// 	enterpriseId: user?.enterpriseId,
		// 	creditsBenefit: benefit?.requestType,
		// });
		analytics.track(TrackingEvents.OptInClicked, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
			creditsBenefit: benefit?.requestType,
		});
		switch (
			benefit?.enterpriseCurrentRequestStatus ??
			convertToSnakeUpperCase(benefit?.baseCta)
		) {
			case CreditBaseCtaEnum.Apply:
				setSelectedBenefit((prev) => ({ ...prev, ...benefit }));
				setIsOpen(true);
				break;
			case CreditBaseCtaEnum.createQuest:
				window.open(
					'/app/campaign/quests/new?taskTemplate=TwitterFollow',
					'_blank',
				);
				break;
			case CreditBaseCtaEnum.viewQuests:
				window.open('/app/campaign/quests', '_blank');
				break;
			case CreditBaseCtaEnum.OptIn:
				updateLocalState(benefit);
				break;
		}
	};

	const availBenefit = useMutation({
		mutationFn: createRequest,
		onSuccess: () => {
			toast.success('Benefit request submitted successfully');
		},
		onError: (error) => {
			handleErrorMessage(error);
		},
	});

	const handleSubmit = async () => {
		setIsSubmittingRequest(true);
		if (!selectedBenefit) return;
		const errors = {};

		const config = getModalConfig(selectedBenefit);

		const payload = config?.valueType?.reduce((acc, valueType) => {
			let value: string;
			switch (valueType) {
				case CreditRequestValueEnum.questId:
					value = selectedBenefit.campaignId;
					break;
				case CreditRequestValueEnum.text:
					value =
						selectedBenefit.tweet || selectedBenefit.notificationContent;
					break;
				case CreditRequestValueEnum.questTitle:
					value = selectedBenefit.campaignName;
					break;
				default:
					value = null;
			}

			if (!value) {
				errors[valueType] = `${valueType} is required.`;
			}

			return { ...acc, [valueType]: value };
		}, {});

		setFormErrors(errors);

		if (Object.keys(errors).length > 0) {
			console.log('Validation failed:', errors);
			setIsSubmittingRequest(false);
			return; // Stop if there are validation errors
		}

		try {
			const currentEnterprise = queryClient.getQueryData<IEnterprise>([
				'enterprise',
			]);
			const currentBalance = currentEnterprise?.credits?.balanceCredits;

			const {
				data: { enterpriseDetails },
			} = await availBenefit.mutateAsync({
				requestType: selectedBenefit.requestType,
				details: Object.entries(payload).map(([key, value]) => ({
					key: key as CreditRequestValueEnum,
					value: value as string,
				})),
			});

			const newBalance = enterpriseDetails?.credits?.balanceCredits;

			if (currentBalance === newBalance) {
				// BE didn't update the balance, apply manual deduction
				const updatedEnterprise = {
					...enterpriseDetails,
					credits: {
						...enterpriseDetails.credits,
						balanceCredits:
							enterpriseDetails.credits.balanceCredits -
							selectedBenefit.credits,
					},
				};
				queryClient.setQueryData(['enterprise'], updatedEnterprise);
			} else {
				// BE did update, use their value
				queryClient.setQueryData(['enterprise'], enterpriseDetails);
			}

			await queryClient.invalidateQueries({
				queryKey: ['credits', 'benefits'],
			});
			refetchCreditRequests(); //calling in background to update the UI

			handleClose({
				track: false,
			});
		} catch (error) {
			console.error('Failed to submit benefit request:', error);
		} finally {
			analytics.track(TrackingEvents.ApplyNowConfirmationClicked, {
				userId: user?._id,
				enterpriseId: user?.enterpriseId,
				creditsBenefit: selectedBenefit?.requestType,
				campaignId: selectedBenefit?.campaignId,
			});
			setIsSubmittingRequest(false);
		}
	};

	const handleClose = ({ track = true }: { track?: boolean }) => {
		if (track) {
			analytics.track(TrackingEvents.CancelClicked, {
				userId: user?._id,
				enterpriseId: user?.enterpriseId,
				creditsBenefit: selectedBenefit?.requestType,
				campaignId: selectedBenefit?.campaignId,
			});
		}
		setIsOpen(false);
		setSelectedBenefit(null);
		setFormErrors({});
	};

	useEffect(() => {
		setFormErrors({});
	}, [
		selectedBenefit?.campaignId,
		selectedBenefit?.tweet,
		selectedBenefit?.notificationContent,
		selectedBenefit?.notificationContent,
	]);

	return {
		isOpen,
		selectedBenefit,
		setSelectedBenefit,
		handleAction,
		handleSubmit,
		handleClose,
		getModalConfig,
		isApplyingForBenefit: availBenefit.isPending,
		formErrors,
		isSubmittingRequest,
	};
};
