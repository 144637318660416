import {
	CreditRequestTypeEnum,
	CreditRequestValueEnum,
} from '@/features/intract-credits/types/credits.enums';
import { IAdminCredits } from '../../types/admin-credit.types';
import { convertToTitleCase } from '@/utils/parsers';
import { QUEST_URL } from '@/config';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

const useAdminCreditsRequestDetails = ({
	requestDetails,
}: {
	requestDetails: IAdminCredits;
}) => {
	const renderTypeSpecificDetails = () => {
		switch (requestDetails.requestType) {
			case CreditRequestTypeEnum.GeneralNotification:
				return (
					<div className="flex gap-2 items-start">
						{/* <i className="bi-bell w-4 h-4 text-slate-500 flex-shrink-0" /> */}
						<div className="w-full space-y-1">
							{requestDetails.details.map((detail, index) => (
								<div
									key={index}
									className="grid grid-cols-2 items-start"
								>
									<span className="text-sm font-medium min-w-[120px] capitalize">
										{detail.key === CreditRequestValueEnum.text
											? 'Notification Content'
											: convertToTitleCase(detail.key)}
										:
									</span>
									<TooltipWrapper
										tooltip={
											detail.key ===
											CreditRequestValueEnum.text
												? detail.value
												: ''
										}
										align="start"
									>
										<span className="flex items-center text-sm max-w-[15rem] break-words truncate">
											{convertToTitleCase(detail.value)}
											{detail.key ===
											CreditRequestValueEnum.questId ? (
												<i
													className="bi-box-arrow-up-right ms-2 text-xs cursor-pointer"
													onClick={() =>
														window.open(
															`${QUEST_URL}/quest/${detail.value}`,
															'_blank',
														)
													}
												></i>
											) : null}
										</span>
									</TooltipWrapper>
								</div>
							))}
						</div>
					</div>
				);

			case CreditRequestTypeEnum.ReTweetTwitter:
				return (
					<div className="flex gap-2">
						<div className="w-full">
							<span className="text-sm font-medium mb-1 flex items-center gap-2">
								{/* <i className="bi-twitter-x w-4 h-4 text-slate-500 flex-shrink-0" /> */}
								Tweet Link
							</span>
							<div className="flex items-center w-full gap-2">
								<p className="text-sm bg-white/80 px-3 py-1.5 rounded-md w-full">
									{
										requestDetails.details.find(
											(d) => d.key === 'TEXT',
										)?.value
									}
								</p>
							</div>
						</div>
					</div>
				);

			case CreditRequestTypeEnum.ChainIntegration:
				return (
					<div className="flex gap-2">
						<i className="bi-wallet w-4 h-4 text-slate-500 flex-shrink-0 mt-1" />
						<div>
							<span className="text-sm font-medium block">
								Chain Integration Request
							</span>
							<p className="text-sm text-slate-600">
								Standard chain integration setup
							</p>
						</div>
					</div>
				);

			default:
				if (requestDetails.details && requestDetails.details.length > 0) {
					return (
						<div className="space-y-2">
							{requestDetails.details.map((detail, index) => (
								<div key={index} className="flex items-start gap-2">
									<span className="text-sm font-medium min-w-[120px] capitalize">
										{convertToTitleCase(detail.key)}:
									</span>
									<span className="text-sm">
										{convertToTitleCase(detail.value)}
										{detail.key ===
										CreditRequestValueEnum.questId ? (
											<i
												className="bi-box-arrow-up-right ms-2 text-xs cursor-pointer"
												onClick={() =>
													window.open(
														`${QUEST_URL}/quest/${detail.value}`,
														'_blank',
													)
												}
											></i>
										) : null}
									</span>
								</div>
							))}
						</div>
					);
				}
				return null;
		}
	};
	return {
		renderTypeSpecificDetails,
	};
};

export default useAdminCreditsRequestDetails;
