import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	useReactTable,
	PaginationState,
	SortingState,
	getPaginationRowModel,
} from '@tanstack/react-table';
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DataTablePagination } from './components/data-table-pagination';
import { Skeleton } from '@/components/ui/skeleton';

interface DataTableWithDndProps<TData, TValue> {
	data: TData[];
	columns: any; // ColumnDef<TData, TValue>[];
	onReorder: any; // (newData: TData[]) => void;
	floatingBarContent?: JSX.Element | null;
	onRowClick?: (row: TData) => void;
	isServerSide?: boolean;
	totalCount: number;
	hidePagination?: boolean;
	isLoading?: boolean;
	enableRowSelection?: boolean;
	pagination?: PaginationState;
	setPagination?: (pagination: PaginationState) => void;
	sorting?: SortingState;
	setSorting?: (sorting: SortingState) => void;
	rowSelection?: Record<string, boolean>;
	setRowSelection?: (rowSelection: Record<string, boolean>) => void;
	FloatingBarContent?: any;
	selectedRow?: TData;
	defaultSort?: any;
}

export function DataTableWithDnd<TData, TValue>({
	data,
	columns,
	onReorder,
	isLoading,
	pagination,
	totalCount,
	setPagination,
	isServerSide,
}: DataTableWithDndProps<TData, TValue>) {
	const table = useReactTable({
		data,
		columns,
		...(isServerSide
			? {
					pageCount: Math.ceil(totalCount / pagination?.pageSize),
					state: {
						pagination,
					},
					onPaginationChange: setPagination,
				}
			: {}),
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		manualPagination: isServerSide ? true : false,
	});

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (active.id !== over?.id) {
			const oldIndex = data.findIndex(
				(item: any) => item.id === active.id || item._id === active.id,
			);
			const newIndex = data.findIndex(
				(item: any) => item.id === over?.id || item._id === over?.id,
			);

			const newData = arrayMove(data, oldIndex, newIndex);
			onReorder(newData);
		} else {
			console.log('going in else block, reorder failed');
		}
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
		>
			<div className="rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<TableHead key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								))}
							</TableRow>
						))}
					</TableHeader>
					{isLoading ? (
						<>
							{Array(pagination?.pageSize ?? 10)
								.fill(null)
								.map((_, index) => (
									<TableRow className="border-none" key={index}>
										<TableCell
											colSpan={columns.length}
											className="h-[25px] text-center"
										>
											<Skeleton className="h-[25px] w-full" />
										</TableCell>
									</TableRow>
								))}
						</>
					) : table.getRowModel().rows?.length ? (
						<TableBody>
							<SortableContext
								items={data.map((item: any) => item.id || item._id)}
								strategy={verticalListSortingStrategy}
							>
								{table.getRowModel().rows.map((row) => (
									<SortableTableRow
										key={row.id}
										row={row}
										columns={columns}
									/>
								))}
							</SortableContext>
						</TableBody>
					) : (
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									No results.
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</Table>
			</div>
			<div className="my-2.5">
				<DataTablePagination table={table} />
			</div>
		</DndContext>
	);
}

function SortableTableRow({ row, columns }: any) {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable(
		{ id: row.original.id || row.original._id },
	);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<TableRow ref={setNodeRef} style={style}>
			{row.getVisibleCells().map((cell: any, idx: number) => (
				<TableCell
					key={cell.id}
					{...(idx === 0 ? { ...attributes, ...listeners } : {})}
				>
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				</TableCell>
			))}
		</TableRow>
	);
}
