export const asset = {
	'credit-icon-primary':
		'https://static.highongrowth.xyz/enterprise/65f402cd6f492692b3b7b127/02c171e38f5148ee99d0c27366b2cd85.svg',
	savings:
		'https://static.highongrowth.xyz/enterprise/65f402cd6f492692b3b7b127/1e0debca3b1f44969f3f6c0ec4951c68.svg',
	'day-time':
		'https://static.highongrowth.xyz/enterprise/65f402cd6f492692b3b7b127/08ab0b612d8946089a3edbee8231fe9e.svg',
	'twitter-x':
		'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/87695bb9630640f9be7bd17927ba4767.svg',
	'credit-coming-soon':
		'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/b07b0b1b44b54ce795793c0b781f2cd7.svg',
};
