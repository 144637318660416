import { useEffect, useMemo, useState } from 'react';
import {
	EligibilityCriteria,
	EnterpriseReferralEligibilityConditions,
	EnterpriseReferralProgramStatus,
	EnterpriseReferralRewardTypes,
} from '../types/customize-hub.enum';
import { ICommunityReferral } from '../types/customize-hub.type';
import { useQuery } from '@tanstack/react-query';
import { getCampaigns } from '@/features/campaigns/services/campaigns.service';
import { ICampaign } from '@/features/campaigns/types';

const defaultValue: ICommunityReferral = {
	referralSessionsCount: 0,
	successReferralSessionsCount: 0,
	totalUniqueReferrerCount: 0,
	totalUniqueReferredUserCount: 0,

	_id: '',
	enterpriseId: '',
	referrerEligibilityConditions: [
		{
			condition: EnterpriseReferralEligibilityConditions.MinXpNeeded,
			value: 10,
		},
	],
	referredUserEligibilityConditions: [
		{
			condition:
				EnterpriseReferralEligibilityConditions.MinNumCampaignsCompleted,
			value: 5,
		},
	],
	rewards: [
		{
			rewardType: EnterpriseReferralRewardTypes.Xp,
			value: 10,
		},
	],
	status: EnterpriseReferralProgramStatus.Active,
};

export const useCommunityReferral = () => {
	const [formFields, setFormFields] = useState<ICommunityReferral>(defaultValue);
	const [formErrors, setFormErrors] = useState<any>({});
	const [items, setItems] = useState(0);
	const [successfulReferralItems, setSuccessfulReferralItems] = useState(0);
	const [loading, setLoading] = useState(false);
	const [campaigns, setCampaigns] = useState<
		{
			label: string;
			value: string;
		}[]
	>([]);

	const resetForm = () => {
		setFormFields(defaultValue);
		setFormErrors({});
		setItems(1);
		setSuccessfulReferralItems(1);
	};

	const query = useQuery<ICampaign[]>({
		queryKey: ['campaigns'],
		queryFn: () => getCampaigns(),
	});

	const formattedData = useMemo(() => {
		if (query.data) {
			return query.data.map((item) => ({
				label: item.name,
				value: item._id,
			}));
		}
		return [];
	}, [query.data]);

	useEffect(() => {
		if (formattedData.length > 0) {
			setCampaigns(formattedData);
		}
	}, [formattedData]);

	return {
		items,
		setItems,
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		resetForm,
		successfulReferralItems,
		setSuccessfulReferralItems,
		loading,
		campaigns,
		setLoading,
	};
};
