export const careersContent = {
	hero: {
		title: 'Contribute to the future of internet',
		description: `Seeking creative minds to help bring financial opportunities to underbanked communities through web3. Join us!`,
		link: '',
	},
	about: {
		title: 'We build a fairer internet. And people’s careers.',
		description: `We create amazing product-led communities, thanks to our incredible team. Learn more about our platform and customers through the link below. If you're excited to join the web3 revolution, check out our open roles.`,
		img1: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/team-4.jpg',
		img2: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/team-5.jpg',
		img3: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/team-6.jpg',
	},
	winningCulture: {
		title: 'Join a winning culture.',
		description:
			'We know our work culture is great. But it’s nice to be awarded for it.',
		img: 'https://canopywp.wpengine.com/wp-content/uploads/2024/01/team-5.jpg',
	},
	positions: {
		title: 'We are expanding!',
		description: 'Check out our open positions.',
		roles: [
			{
				group: 'Status Network',
				roles: [
					{
						description: 'L2 Lead Developer',
						link: 'https://google.com',
					},
					{
						description:
							'L2 Business Development &amp; Partnerships Lead (Status Network)',
						link: 'https://google.com',
					},
				],
			},
			{
				group: 'Status Network',
				roles: [
					{
						description: 'L2 Lead Developer',
						link: 'https://google.com',
					},
					{
						description:
							'L2 Business Development &amp; Partnerships Lead (Status Network)',
						link: 'https://google.com',
					},
				],
			},
		],
	},
};
