import { Media } from '@/components/element/media/Media';
import { Dispatch, SetStateAction } from 'react';

const WaitingForApproval = ({
	setOpen,
}: {
	setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	return (
		<div className="flex flex-col items-center justify-center space-y-5 px-6">
			<Media
				src="https://static.highongrowth.xyz/enterprise/66910984f04b49f32e42ad7b/d4d1b44e52d7477c8c9555bc55792cfe.svg"
				className="h-[11.75rem] aspect-auto"
			/>
			<div className="flex items-center flex-col space-y-1">
				<h4 className="font-medium text-base leading-5">
					Waiting For Approval...
				</h4>
				<p className="font-normal text-muted-foreground text-sm text-center">
					This process may take some time to complete. You can close this
					tab and return later if you wish.
				</p>
			</div>
			<div className="my-1 bg-warning/10 py-4 px-auto rounded-md flex flex-col items-center justify-center text-sm w-full space-y-1">
				<p>Want to get approved faster?</p>
				<p
					className="underline text-warningForeground text-sm cursor-pointer hover:opacity-80"
					onClick={() => {
						window?.Intercom('show');
						setOpen(false);
					}}
				>
					Contact Us
				</p>
			</div>
		</div>
	);
};

export default WaitingForApproval;
