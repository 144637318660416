import { Dispatch, SetStateAction, createContext, useState } from 'react';
import {
	CreateCampaignSections,
	ICampaign,
	ICampaignAddReward,
	ICreateCampaignDetails,
	ReferralRewardType,
} from '../types';
import dayjs from 'dayjs';
import { usePrompt } from '@/hooks/usePrompt';
import { useCreateCampaignInitialise } from '../hooks/create/useCreateCampaignInitialise';
import { IEditingTasks } from '../hooks/create/useCreateCampaignTasks';
interface ICreateCampaignErrors {
	details?: {
		name: boolean | string;
		description: boolean | string;
		bannerFile: boolean | string;
		duration: boolean | string;
		referral: {
			numberOfRequiredReferrals: boolean | string;
			rewardValue: boolean | string;
		};
		rewardType: boolean | string;
	};
	rewards?: {
		discord: {
			method: boolean | string;
			roleId: boolean;
			winners: boolean;
		};
		token: {
			method: boolean | string;
			chain: boolean;
			token: boolean;
			winners: boolean;
			reward: boolean;
			expected: boolean;
		};
		nft: {
			nftContract: boolean;
			winners: boolean;
			method: boolean | string;
		};
		whitelist: {
			name: boolean;
			image: boolean;
			winners: boolean;
			method: boolean | string;
		};
		officialPoints: {
			name: boolean;
			image: boolean;
			winners: boolean;
			method: string | boolean;
			pointsPerUser: boolean;
			enableCustomPoints: boolean;
		};
	};
	tasks?: {
		[key: string]: {
			description: boolean;
			customInitiationURL: boolean;
			adminInputs: {
				[key: string]: string;
			};
		};
	};
	sections?: any;
}

type CreateCampaignProviderState = {
	mode: 'simple' | 'advanced';
	setMode: (mode: 'simple' | 'advanced') => void;
	details: ICreateCampaignDetails;
	setDetails: Dispatch<SetStateAction<ICreateCampaignDetails>>;
	rewards: ICampaignAddReward[];
	setRewards: Dispatch<SetStateAction<ICampaignAddReward[]>>;
	tasks: IEditingTasks[];
	setTasks: Dispatch<SetStateAction<IEditingTasks[]>>;
	section: CreateCampaignSections;
	setSection: Dispatch<SetStateAction<CreateCampaignSections>>;
	setIsDirty: Dispatch<SetStateAction<boolean>>;
	isReordering: boolean;
	setIsReordering: Dispatch<SetStateAction<boolean>>;
	isInitalising: boolean;
	isUpdate: boolean;
	errors: ICreateCampaignErrors;
	setErrors: Dispatch<SetStateAction<ICreateCampaignErrors>>;
	contextualSuggestions: {
		id: string;
		element: string | JSX.Element | Element;
	};
	setContextualSuggestions: Dispatch<
		SetStateAction<{
			element: string | JSX.Element | Element;
			id: string;
		}>
	>;
	showLaunch: boolean;
	setShowLaunch: Dispatch<SetStateAction<boolean>>;
	setIsUpdate: Dispatch<SetStateAction<boolean>>;
	setCampaign: Dispatch<SetStateAction<ICampaign>>;
	campaign: ICampaign;
};

const initialDetailsState: ICreateCampaignDetails = {
	name: '',
	description: '',
	startDate: new Date(),
	endDate: dayjs().add(7, 'day').toDate(),
	network: 1,
	bannerFile: null,
	bannerLink: '',
	bannerPosition: {
		x: 0,
		y: 0,
	},
	startImmediately: true,
	noEndTime: false,
	timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	twitterShare: '',
	narrativeText: '',
	seoMetadata: {
		title: '',
		description: '',
		keywords: '',
		image: '',
		imageFile: null,
		isCustomImage: false,
	},
	referral: {
		referralTaskEnabled: false,
		numberOfRequiredReferrals: 1,
		rewardType: ReferralRewardType.Fixed,
		rewardValue: 0,
		referralTaskText: 'Refer friends to participate in quests',
	},
};

const initialState: CreateCampaignProviderState = {
	mode: 'simple',
	setMode: () => {},
	details: initialDetailsState,
	setDetails: () => {},
	rewards: [],
	setRewards: () => {},
	tasks: [],
	setTasks: () => {},
	section: CreateCampaignSections.Details,
	setSection: () => {},
	setIsDirty: () => {},
	isReordering: false,
	setIsReordering: () => {},
	isInitalising: false,
	isUpdate: false,
	errors: {
		rewards: {
			discord: {
				method: false,
				roleId: false,
				winners: false,
			},
			token: {
				method: false,
				chain: false,
				token: false,
				winners: false,
				reward: false,
				expected: false,
			},
			nft: {
				nftContract: false,
				winners: false,
				method: false,
			},
			whitelist: {
				name: false,
				image: false,
				winners: false,
				method: false,
			},
			officialPoints: {
				name: false,
				image: false,
				winners: false,
				method: false,
				pointsPerUser: false,
				enableCustomPoints: false,
			},
		},
	},
	setErrors: () => {},
	contextualSuggestions: {
		element: '',
		id: '',
	},
	setContextualSuggestions: () => {},
	showLaunch: false,
	setShowLaunch: () => {},
	setIsUpdate: () => {},
	setCampaign: () => {},
	campaign: null,
};

export const CreateCampaignContext =
	createContext<CreateCampaignProviderState>(initialState);

export function CreateCampaignProvider({ children, section, setSection }) {
	const [mode, setMode] = useState<'simple' | 'advanced'>('advanced');
	const [details, setDetails] =
		useState<ICreateCampaignDetails>(initialDetailsState);
	const [rewards, setRewards] = useState<ICampaignAddReward[]>([]);
	const [tasks, setTasks] = useState<IEditingTasks[]>([]);
	const [errors, setErrors] = useState<ICreateCampaignErrors>({});

	const [isDirty, setIsDirty] = useState(false);
	const [isReordering, setIsReordering] = useState(false);
	const [isInitalising, setIsInitalising] = useState(true);
	const [isUpdate, setIsUpdate] = useState(false);
	const [contextualSuggestions, setContextualSuggestions] = useState({
		element: '',
		id: '',
	});
	const [showLaunch, setShowLaunch] = useState(false);
	const [campaign, setCampaign] = useState<ICampaign>();

	useCreateCampaignInitialise({
		setDetails,
		setRewards,
		setTasks,
		setIsInitalising,
		setIsUpdate,
		setIsDirty,
		setCampaign,
	});
	usePrompt({ isDirty });

	return (
		<CreateCampaignContext.Provider
			value={{
				mode,
				setMode,
				details,
				setDetails,
				rewards,
				setRewards,
				tasks,
				setTasks,
				section,
				setSection,
				setIsDirty,
				isReordering,
				setIsReordering,
				isInitalising,
				errors,
				setErrors,
				contextualSuggestions,
				setContextualSuggestions,
				isUpdate,
				setShowLaunch,
				showLaunch,
				setIsUpdate,
				campaign,
				setCampaign,
			}}
		>
			{children}
		</CreateCampaignContext.Provider>
	);
}
