import { PaginationState, SortingState, flexRender } from '@tanstack/react-table';

import { Skeleton } from '@/components/ui/skeleton';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useDataTable } from '@/hooks/useDataTable';
import { cn } from '@/lib/utils';
import { type ColumnDef } from '@tanstack/react-table';
import { DataTableFloatingBar } from './components/data-table-floating-bar';
import { DataTablePagination } from './components/data-table-pagination';

interface DataTableProps<TData, TValue> {
	data: TData[];
	columns: ColumnDef<TData, TValue>[];
	floatingBarContent?: JSX.Element | null;
	onRowClick?: (row: TData) => void;
	isServerSide?: boolean;
	totalCount: number;
	hidePagination?: boolean;
	isLoading?: boolean;
	enableRowSelection?: boolean;
	pagination?: PaginationState;
	setPagination?: (pagination: PaginationState) => void;
	sorting?: SortingState;
	setSorting?: (sorting: SortingState) => void;
	rowSelection?: Record<string, boolean>;
	setRowSelection?: (rowSelection: Record<string, boolean>) => void;
	FloatingBarContent?: any;
	selectedRow?: TData;
	defaultSort?: any;
	enableContentScroll?: boolean;
}

export function DataTable<TData, TValue>({
	data,
	isServerSide,
	columns,
	onRowClick,
	defaultSort,
	totalCount,
	hidePagination,
	isLoading,
	enableRowSelection,
	pagination,
	setPagination,
	sorting,
	setSorting,
	rowSelection,
	setRowSelection,
	FloatingBarContent,
	selectedRow,
	enableContentScroll = false,
}: DataTableProps<TData, TValue>) {
	const { table } = useDataTable({
		data,
		columns,
		totalCount,
		enableRowSelection,
		isServerSide,
		pagination,
		setPagination,
		sorting,
		setSorting,
		rowSelection,
		setRowSelection,
		defaultSort,
	});

	return (
		<div className="w-full space-y-2.5 overflow-x-auto">
			<div className="rounded-md border max-w-[1180px] 2xl:max-w-full">
				<Table containerClassname="w-full overflow-x-auto">
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef
															.header,
														header.getContext(),
													)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					{isLoading ? (
						<>
							{Array(pagination?.pageSize ?? 10)
								.fill(null)
								.map((_, index) => (
									<TableRow className="border-none" key={index}>
										<TableCell
											colSpan={columns.length}
											className="h-[25px] text-center"
										>
											<Skeleton className="h-[25px] w-full" />
										</TableCell>
									</TableRow>
								))}
						</>
					) : (
						<TableBody>
							{table.getRowModel().rows?.length ? (
								table.getRowModel().rows.map((row) => (
									<TableRow
										key={row.id}
										data-state={
											row.getIsSelected() && 'selected'
										}
										onClick={() => {
											onRowClick?.(row.original);
										}}
										className={cn(
											onRowClick && 'cursor-pointer',
											selectedRow &&
												(selectedRow as any)?._id ===
													(row.original as any)?._id &&
												'bg-slate-100',
										)}
									>
										{row.getVisibleCells().map((cell) => (
											<TableCell key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</TableCell>
										))}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell
										colSpan={columns.length}
										className="h-24 text-center"
									>
										No results.
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					)}
				</Table>
			</div>
			{!hidePagination && (
				<div className="space-y-2.5">
					<DataTablePagination table={table} />
				</div>
			)}
			{FloatingBarContent && (
				<DataTableFloatingBar table={table}>
					{FloatingBarContent({ rowSelection, table })}
				</DataTableFloatingBar>
			)}
		</div>
	);
}
