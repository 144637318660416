import { Route, Routes } from 'react-router-dom';
import ApisCreatePage from './task-apis-create.page';
import ApisPage from './task-apis.page';
import { ConnectAPIProvider } from '../providers/ConnectAPIProvider';

const TaskAPiRoutes = () => {
	return (
		<Routes>
			<Route
				path=""
				element={
					<ConnectAPIProvider>
						<ApisPage />
					</ConnectAPIProvider>
				}
			/>
			<Route
				path="new"
				element={
					<ConnectAPIProvider>
						<ApisCreatePage />
					</ConnectAPIProvider>
				}
			/>
			<Route
				path=":id/update"
				element={
					<ConnectAPIProvider>
						<ApisCreatePage />
					</ConnectAPIProvider>
				}
			/>
		</Routes>
	);
};

export default TaskAPiRoutes;
