import { Separator } from '@/components/ui/separator';
import NavItem from './NavItem';
import { useEffect, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { dashboardNav } from './dashboardNav';
import { internalAdminNav } from './internalAdminNav';
import { BottomNavSection } from './BottomNavSection';
import { useLocation } from 'react-router-dom';
import { useGetEnterprise } from '../../hooks/useGetEnterprise';

const SideNav = () => {
	const location = useLocation();
	const { user, toggleAdminPanel, showAdminPanel } = useAuth();
	const [openVerifyProjects, setOpenVerifyProjects] = useState(false);
	const [showApprovalCompletionDialog, setShowApprovalCompletionDialog] =
		useState(false);

	return (
		<aside className="fixed top-14 z-30 hidden h-[calc(100vh-3.5rem)] w-full shrink-0 overflow-y-auto border-r py-1  md:sticky md:flex md:align-center md:flex-col ">
			<div className="bg-background">
				<div className="space-y-4 py-3">
					{!showAdminPanel &&
						dashboardNav.map(({ nav, title }, index) => (
							<>
								<div className="px-3" key={index}>
									<div className="px-3">
										{title && (
											<div className="text-[10px] font-medium tracking-wide uppercase text-muted-foreground mb-2">
												{title}
											</div>
										)}
									</div>
									{nav.map((item, index) => (
										<NavItem
											icon={item.icon}
											label={item.label}
											href={item.href}
											badge={item.badge}
											key={index}
											options={item.options}
											isNew={item.isNew}
										/>
									))}
								</div>
								{dashboardNav.length - 1 != index && <Separator />}
							</>
						))}
					{showAdminPanel &&
						internalAdminNav.map(({ nav, title }, index) => (
							<>
								<div className="px-3" key={index}>
									<div className="px-3">
										{title && (
											<div className="text-[10px] font-medium tracking-wide uppercase text-muted-foreground mb-2">
												{title}
											</div>
										)}
									</div>
									{nav.map((item, index) => (
										<NavItem
											icon={item.icon}
											label={item.label}
											href={item.href}
											badge={item.badge}
											key={index}
											options={item.options}
										/>
									))}
								</div>
								{internalAdminNav.length - 1 != index && (
									<Separator />
								)}
							</>
						))}
				</div>
			</div>
			<BottomNavSection
				openVerifyProjects={openVerifyProjects}
				setOpenVerifyProjects={setOpenVerifyProjects}
				showApprovalCompletionDialog={showApprovalCompletionDialog}
				setShowApprovalCompletionDialog={setShowApprovalCompletionDialog}
			/>
		</aside>
	);
};

export default SideNav;
