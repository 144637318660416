import { Checkbox } from '@/components/ui/checkbox';
import { BotFilteringMethod } from '@/features/campaigns/types';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

export const LaunchConfirmation = ({
	showRewardInfo,
	botFilteringMethods,
	setBotFilteringMethods,
	hasPoHTask,
	hasCaptchaTask,
}: {
	showRewardInfo?: boolean;
	botFilteringMethods: BotFilteringMethod;
	setBotFilteringMethods: Dispatch<SetStateAction<BotFilteringMethod>>;
	hasPoHTask: boolean;
	hasCaptchaTask: boolean;
}) => {
	return (
		<div>
			<div className="flex flex-col mb-8">
				<div className="h-14 w-14 mb-4 flex items-center bg-violet-400 border-violet-500 border justify-center rounded-lg me-3">
					<i className="bi-rocket-takeoff-fill text-white text-2xl"></i>
				</div>
				<div>
					<h3 className="text-xl font-medium ">Publish your Campaign</h3>
					<p className="text-sm text-slate-500">
						Before launching, please review and understand this following
						points!
					</p>
				</div>
			</div>

			<div className="py-3 border rounded-lg bg-slate-50 my-5 px-4 flex items-center text-slate-800">
				<div className="text-sm ">
					You will not be able to make any changes once a campaign is live.
					All participating users will be eligible for the reward.
				</div>
			</div>
			{showRewardInfo ? (
				<div className="py-3 border rounded-lg bg-slate-50 my-5 px-4 flex items-center text-slate-800">
					<div className="text-sm ">
						Rewards are unlimited in this campaign! Every user
						participating will receive their reward
					</div>
				</div>
			) : null}
			<div className="py-3 border rounded-lg bg-slate-50 my-5 px-4 flex items-center text-slate-800">
				<div className="text-sm ">
					Once launched, the campaign will become active at the scheduled
					start time. You can modify the start and end time at the last
					step again.
				</div>
			</div>
			<div className="py-3 border rounded-lg bg-slate-50 my-5 px-4 flex flex-col space-y-3 text-slate-800">
				<div className="text-sm ">
					Select measures to make sure that the bots don’t spoil your quest
					party
				</div>
				<div className="flex items-center">
					<Checkbox
						checked={botFilteringMethods?.includeCaptcha}
						onCheckedChange={(check) =>
							hasCaptchaTask
								? null
								: setBotFilteringMethods({
										...botFilteringMethods,
										includeCaptcha: !!check,
									})
						}
						className="me-2"
					/>
					<div className="text-sm text-slate-700 font-normal">
						Google Captcha (Good for basic filtering)
					</div>
				</div>
				<div className="flex items-center">
					<Checkbox
						checked={botFilteringMethods?.includePOH}
						onCheckedChange={(check) =>
							hasPoHTask
								? null
								: setBotFilteringMethods({
										...botFilteringMethods,
										includePOH: !!check,
									})
						}
						className="me-2"
					/>
					<div className="text-sm text-slate-700">
						Intract POH (For more advanced filtering)
						{/* Learn more&nbsp;
						<Link
							to="/"
							target="_blank"
							className="underline underline-offset-2"
						>
							here
						</Link>
						. ) */}
					</div>
				</div>
			</div>
		</div>
	);
};
