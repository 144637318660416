import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import InputText from '@/components/element/inputs/InputText';
import { IFormFields } from '@/features/admin/providers/WidgetCreateProvider';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import {
	HomepageWidgetSources,
	HomepageWidgetTypes,
} from '@/features/admin/types/cms.types';
import { convertToTitleCase } from '@/utils/parsers';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Checkbox } from '@/components/ui/checkbox';

const CreateNewWidget = ({
	show,
	setShow,
	isPending,
	createNewWidget,
	resetForm,
	formFields,
	setFormFields,
	formErrors,
	setFormErrors,
}: {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	isPending: boolean;
	createNewWidget: () => void;
	resetForm: () => void;
	formFields: IFormFields;
	setFormFields: Dispatch<SetStateAction<IFormFields>>;
	formErrors: any;
	setFormErrors: Dispatch<SetStateAction<any>>;
}) => {
	const widgetTypes = useMemo(() => {
		return Object.values(HomepageWidgetTypes).map((i) => ({
			label: convertToTitleCase(i),
			value: i,
			searchText: convertToTitleCase(i),
		}));
	}, []);

	const widgetSources = useMemo(() => {
		return Object.values(HomepageWidgetSources).map((i) => ({
			label: convertToTitleCase(i),
			value: i,
			searchText: convertToTitleCase(i),
		}));
	}, []);

	return (
		<Dialog open={show} onOpenChange={setShow}>
			<DialogContent className="sm:max-w-[550px] px-0 pb-0 overflow-visible">
				<DialogHeader className="border-b pb-3 px-5">
					<DialogTitle>Add a new Widget to Homepage</DialogTitle>
					<DialogDescription>
						Configure the widget you want to add to the homepage.
					</DialogDescription>
				</DialogHeader>
				<div className="space-y-5 py-2 mx-6 gap-4 ">
					<div className="flex gap-4 w-full">
						<InputText
							label="Title"
							value={formFields.title}
							setValue={(val) =>
								setFormFields({ ...formFields, title: val })
							}
							error={!!formErrors?.title}
							errorText={formErrors?.title}
							placeholder="Enter widget title"
							required
							inputClassName="w-[22.2rem]"
						/>
						<InputText
							label="Display Limit"
							value={formFields.displayLimit}
							setValue={(val) => {
								if (Number.isNaN(Number(val))) return;
								setFormFields({ ...formFields, displayLimit: val });
							}}
							error={!!formErrors?.displayLimit}
							errorText={formErrors?.displayLimit}
							placeholder="Enter limit"
							inputClassName="w-[8rem]"
							onFocus={(e) => e.target.select()}
						/>
					</div>
					<InputTextArea
						label="Description"
						value={formFields.description}
						setValue={(val) =>
							setFormFields({ ...formFields, description: val })
						}
						error={!!formErrors?.description}
						errorText={formErrors?.description}
						placeholder="Enter widget description"
					/>
					<div className="flex items-center">
						<Checkbox
							checked={formFields?.isLoyaltyWidget}
							onCheckedChange={(check) =>
								setFormFields({
									...formFields,
									isLoyaltyWidget: !!check,
								})
							}
							className="me-2"
						/>
						<div className="text-sm font-medium">
							Is widget for loyalty quest?
						</div>
					</div>
					<div className="flex gap-4 w-full mt-2">
						<InputSelect2
							label="Select Widget Type"
							options={widgetTypes}
							placeholder="Choose a widget type"
							value={formFields?.type}
							setValue={(newVal) =>
								setFormFields({
									...formFields,
									type: newVal as HomepageWidgetTypes,
								})
							}
							popoverClassName="w-[15.1rem]"
							className="w-full"
							required
						/>
						<InputSelect2
							label="Select Widget Source "
							options={widgetSources}
							placeholder="Choose a widget source"
							value={formFields?.source}
							setValue={(newVal) =>
								setFormFields({
									...formFields,
									source: newVal as HomepageWidgetSources,
								})
							}
							className="w-full"
							popoverClassName="w-[15.1rem]"
							required
						/>
					</div>
				</div>

				<DialogFooter className="flex justify-between border-t px-5 pt-3 pb-3 w-full sm:justify-between">
					<div></div>
					<div className="space-x-2 flex items-center">
						<Button
							variant="outline"
							onClick={() => {
								resetForm();
								setShow(false);
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={() => createNewWidget()}
							disabled={isPending}
						>
							{isPending ? (
								<i className="bi-arrow-clockwise animate-spin me-2"></i>
							) : null}
							<span>Create Widget</span>
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CreateNewWidget;
