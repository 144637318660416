import { useQuery } from '@tanstack/react-query';
import { getWidgets } from '../../services/cms.service';
import { useMemo, useState } from 'react';

const useAdminWidgets = () => {
	const [search, setSearch] = useState('');

	const { data: widgetData, isLoading } = useQuery({
		queryKey: ['cms-widgets'],
		queryFn: getWidgets,
	});

	const filteredWidgetData = useMemo(() => {
		if (!widgetData) return [];
		return widgetData.filter((i) => {
			if (search && !i.title.toLowerCase().includes(search.toLowerCase()))
				return false;
			return true;
		});
	}, [widgetData, search]);

	return {
		widgets: filteredWidgetData,
		isLoading: isLoading,
		search,
		setSearch,
	};
};

export default useAdminWidgets;
