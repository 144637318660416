import { Navigate, Route, Routes } from 'react-router-dom';
import CreditsOverview from '../components/CreditsOverview';

const IntractCreditsRoutes = () => {
	return (
		<Routes>
			<Route path="" element={<CreditsOverview />} />
			<Route path="*" element={<Navigate replace to="/" />} />
		</Routes>
	);
};

export default IntractCreditsRoutes;
