import React from 'react';
import { IOnboardingCommunity } from '../../hooks/useCommunityApproval';
import InputFile from '@/components/element/inputs/InputFile';
import InputText from '@/components/element/inputs/InputText';
import InputMultiSelect from '@/components/element/inputs/InputMultiSelect';
import { SelectNetworksTag } from '@/components/element/inputs/SelectNetworksTab';
import { goalOptions } from '@/features/onboarding/types/onboarding-options';

const CommunityDetails = ({
	community,
	setCommunity,
	errors,
	onOpenCustomChainDialog,
}: {
	community: IOnboardingCommunity;
	setCommunity: React.Dispatch<React.SetStateAction<IOnboardingCommunity>>;
	errors: {
		name: boolean;
		logo: boolean;
		chains: boolean;
		category: boolean;
		goal: boolean;
	};
	onOpenCustomChainDialog: () => void;
}) => {
	return (
		<>
			<InputFile
				label="Project Logo"
				required={true}
				placeholder="Elon Musk"
				value={community.logoFile}
				setValue={(e) =>
					setCommunity({
						...community,
						logoFile: e,
					})
				}
				defaultValue={community?.logoUrl}
				errorText="Please upload a logo for your community"
				error={errors.logo}
				acceptableFiles="image/*"
				description="Upload a logo. Recommended size is 200x200px"
			/>
			<InputMultiSelect
				value={community.category}
				setValue={(e) => setCommunity({ ...community, category: e })}
				options={categories}
				label="What describes you the best?"
				required={true}
				placeholder="Select Category"
				error={errors.category}
				withoutPortal
				errorText="Please select at least one category"
			/>
			{community.category?.includes('Others') && (
				<InputText
					label="Others"
					required={true}
					placeholder="Others"
					value={community.otherCategory}
					setValue={(e) =>
						setCommunity({
							...community,
							otherCategory: e,
						})
					}
					error={errors.category}
					errorText="Please enter your category"
				/>
			)}

			<div className="">
				<div className="text-sm font-medium mb-2">
					Supported Chain
					<span className="text-destructive ms-1">*</span>
				</div>
				<SelectNetworksTag
					placeholder="Select a chain"
					value={[...community.chainIds, ...community.customChainIds]}
					setValue={({ chainIds, customChainIds }) => {
						setCommunity((prev) => ({
							...prev,
							chainIds,
							customChainIds,
						}));
					}}
					error={errors?.chains}
					errorMsg={'Please select at least one chain'}
					isMulti
					withoutPortal
					fetchEnterpriseChains
					addCustomChain
					setShowAddCustomChain={onOpenCustomChainDialog}
				/>
			</div>
			{/* <InputMultiSelect
				label="What are your project's aim with Intract?"
				required={true}
				placeholder="Select your goal"
				value={community.goal}
				setValue={(e) =>
					setCommunity({
						...community,
						goal: e,
					})
				}
				error={errors.goal}
				options={goalOptions}
				popoverClassName="w-full"
				maxLimit={2}
				errorText="Please select at least one goal"
			/> */}
		</>
	);
};

export default CommunityDetails;

const categories_ = [
	'DeFi',
	'NFT',
	'Memecoins',
	'Gaming',
	'Dex',
	'Staking',
	'Bridge',
	'Collectibles',
	'DID',
	'Infrastructure',
	'Metaverse',
	'DAO',
	'Social',
	'DeSci',
	'Others',
];
const categories: {
	label: string;
	value: string;
}[] = categories_.map((category) => ({
	label: category,
	value: category,
}));
