import * as React from 'react';

import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

type Option = {
	value: string;
	label: string;
	searchKey: string[];
};

export function SelectTimezone({
	selected,
	setSelected,
	placeholder,
	disabled,
}: {
	selected: string | null;
	placeholder: string;
	setSelected: (option: string | null) => void;
	disabled?: boolean;
}) {
	const [open, setOpen] = React.useState(false);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					className="justify-start font-normal "
					disabled={disabled}
					size="sm"
				>
					<span>{selected ? <>{selected}</> : <>{placeholder}</>}</span>
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className="w-[200px] p-0 font-normal"
				align="start"
				withoutPortal
			>
				<Options
					setOpen={setOpen}
					setSelected={setSelected}
					options={tzOptions}
					placeholder={placeholder}
				/>
			</PopoverContent>
		</Popover>
	);
}

function Options({
	setOpen,
	setSelected,
	options,
	placeholder,
}: {
	setOpen: (open: boolean) => void;
	placeholder: string;
	setSelected: (status: string | null) => void;
	options: Option[];
}) {
	return (
		<Command
			filter={(value, search) => {
				const option = options.find((i) => i.value === value);
				if (!option) return 0;
				if (
					option.searchKey.some((key) =>
						key.toLowerCase().includes(search.toLowerCase()),
					)
				)
					return 1;
				if (option.label.toLowerCase().includes(search.toLowerCase()))
					return 1;
				return 0;
			}}
		>
			<CommandInput placeholder={placeholder} />
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				<CommandGroup>
					{options.map((option) => (
						<CommandItem
							key={option.value}
							value={option.value}
							onSelect={() => {
								setSelected(option.value);
								setOpen(false);
							}}
						>
							{option.label}
						</CommandItem>
					))}
				</CommandGroup>
			</CommandList>
		</Command>
	);
}

const tzOptions = [
	{
		value: 'Africa/Abidjan',
		label: 'Africa/Abidjan',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Accra',
		label: 'Africa/Accra',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Addis_Ababa',
		label: 'Africa/Addis_Ababa',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Algiers',
		label: 'Africa/Algiers',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Asmara',
		label: 'Africa/Asmara',
		searchKey: ['Asm'],
	},
	{
		value: 'Africa/Asmera',
		label: 'Africa/Asmera',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Bamako',
		label: 'Africa/Bamako',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Bangui',
		label: 'Africa/Bangui',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Banjul',
		label: 'Africa/Banjul',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Bissau',
		label: 'Africa/Bissau',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Blantyre',
		label: 'Africa/Blantyre',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Brazzaville',
		label: 'Africa/Brazzaville',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Bujumbura',
		label: 'Africa/Bujumbura',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Cairo',
		label: 'Africa/Cairo',
		searchKey: ['EST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Casablanca',
		label: 'Africa/Casablanca',
		searchKey: ['MDT', '(UTC)'],
	},
	{
		value: 'Africa/Ceuta',
		label: 'Africa/Ceuta',
		searchKey: ['RDT', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Conakry',
		label: 'Africa/Conakry',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Dakar',
		label: 'Africa/Dakar',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Dar_es_Salaam',
		label: 'Africa/Dar_es_Salaam',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Djibouti',
		label: 'Africa/Djibouti',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Douala',
		label: 'Africa/Douala',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/El_Aaiun',
		label: 'Africa/El_Aaiun',
		searchKey: ['MDT', '(UTC)'],
	},
	{
		value: 'Africa/Freetown',
		label: 'Africa/Freetown',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Gaborone',
		label: 'Africa/Gaborone',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Harare',
		label: 'Africa/Harare',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Johannesburg',
		label: 'Africa/Johannesburg',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Juba',
		label: 'Africa/Juba',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Kampala',
		label: 'Africa/Kampala',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Khartoum',
		label: 'Africa/Khartoum',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Kigali',
		label: 'Africa/Kigali',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Kinshasa',
		label: 'Africa/Kinshasa',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Lagos',
		label: 'Africa/Lagos',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Libreville',
		label: 'Africa/Libreville',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Lome',
		label: 'Africa/Lome',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Luanda',
		label: 'Africa/Luanda',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Lubumbashi',
		label: 'Africa/Lubumbashi',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Lusaka',
		label: 'Africa/Lusaka',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Malabo',
		label: 'Africa/Malabo',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Maputo',
		label: 'Africa/Maputo',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Maseru',
		label: 'Africa/Maseru',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Mbabane',
		label: 'Africa/Mbabane',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Mogadishu',
		label: 'Africa/Mogadishu',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Monrovia',
		label: 'Africa/Monrovia',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Nairobi',
		label: 'Africa/Nairobi',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Africa/Ndjamena',
		label: 'Africa/Ndjamena',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Niamey',
		label: 'Africa/Niamey',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Nouakchott',
		label: 'Africa/Nouakchott',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Ouagadougou',
		label: 'Africa/Ouagadougou',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Porto-Novo',
		label: 'Africa/Porto-Novo',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Sao_Tome',
		label: 'Africa/Sao_Tome',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Africa/Timbuktu',
		label: 'Africa/Timbuktu',
		searchKey: ['Tim'],
	},
	{
		value: 'Africa/Tripoli',
		label: 'Africa/Tripoli',
		searchKey: ['LST', '(UTC+02:00)'],
	},
	{
		value: 'Africa/Tunis',
		label: 'Africa/Tunis',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Africa/Windhoek',
		label: 'Africa/Windhoek',
		searchKey: ['NST', '(UTC+01:00)'],
	},
	{
		value: 'America/Adak',
		label: 'America/Adak',
		searchKey: ['Ada'],
	},
	{
		value: 'America/Anchorage',
		label: 'America/Anchorage',
		searchKey: ['AKDT', '(UTC-09:00)'],
	},
	{
		value: 'America/Anguilla',
		label: 'America/Anguilla',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Antigua',
		label: 'America/Antigua',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Araguaina',
		label: 'America/Araguaina',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/Buenos_Aires',
		label: 'America/Argentina/Buenos_Aires',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/Catamarca',
		label: 'America/Argentina/Catamarca',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/ComodRivadavia',
		label: 'America/Argentina/ComodRivadavia',
		searchKey: ['Arg'],
	},
	{
		value: 'America/Argentina/Cordoba',
		label: 'America/Argentina/Cordoba',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/Jujuy',
		label: 'America/Argentina/Jujuy',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/La_Rioja',
		label: 'America/Argentina/La_Rioja',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/Mendoza',
		label: 'America/Argentina/Mendoza',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/Rio_Gallegos',
		label: 'America/Argentina/Rio_Gallegos',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/Salta',
		label: 'America/Argentina/Salta',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/San_Juan',
		label: 'America/Argentina/San_Juan',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/San_Luis',
		label: 'America/Argentina/San_Luis',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/Tucuman',
		label: 'America/Argentina/Tucuman',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Argentina/Ushuaia',
		label: 'America/Argentina/Ushuaia',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Aruba',
		label: 'America/Aruba',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Asuncion',
		label: 'America/Asuncion',
		searchKey: ['PYT', '(UTC-04:00)'],
	},
	{
		value: 'America/Atikokan',
		label: 'America/Atikokan',
		searchKey: ['Ati'],
	},
	{
		value: 'America/Atka',
		label: 'America/Atka',
		searchKey: ['Atk'],
	},
	{
		value: 'America/Bahia',
		label: 'America/Bahia',
		searchKey: ['BST', '(UTC-03:00)'],
	},
	{
		value: 'America/Bahia_Banderas',
		label: 'America/Bahia_Banderas',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Barbados',
		label: 'America/Barbados',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Belem',
		label: 'America/Belem',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'America/Belize',
		label: 'America/Belize',
		searchKey: ['CAST', '(UTC-06:00)'],
	},
	{
		value: 'America/Blanc-Sablon',
		label: 'America/Blanc-Sablon',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Boa_Vista',
		label: 'America/Boa_Vista',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Bogota',
		label: 'America/Bogota',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/Boise',
		label: 'America/Boise',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'America/Buenos_Aires',
		label: 'America/Buenos_Aires',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Cambridge_Bay',
		label: 'America/Cambridge_Bay',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'America/Campo_Grande',
		label: 'America/Campo_Grande',
		searchKey: ['CBST', '(UTC-04:00)'],
	},
	{
		value: 'America/Cancun',
		label: 'America/Cancun',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Caracas',
		label: 'America/Caracas',
		searchKey: ['VST', '(UTC-04:30)'],
	},
	{
		value: 'America/Catamarca',
		label: 'America/Catamarca',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Cayenne',
		label: 'America/Cayenne',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'America/Cayman',
		label: 'America/Cayman',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/Chicago',
		label: 'America/Chicago',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Chihuahua',
		label: 'America/Chihuahua',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'America/Ciudad_Juarez',
		label: 'America/Ciudad_Juarez',
		searchKey: ['Ciu'],
	},
	{
		value: 'America/Coral_Harbour',
		label: 'America/Coral_Harbour',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/Cordoba',
		label: 'America/Cordoba',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Costa_Rica',
		label: 'America/Costa_Rica',
		searchKey: ['CAST', '(UTC-06:00)'],
	},
	{
		value: 'America/Creston',
		label: 'America/Creston',
		searchKey: ['UMST', '(UTC-07:00)'],
	},
	{
		value: 'America/Cuiaba',
		label: 'America/Cuiaba',
		searchKey: ['CBST', '(UTC-04:00)'],
	},
	{
		value: 'America/Curacao',
		label: 'America/Curacao',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Danmarkshavn',
		label: 'America/Danmarkshavn',
		searchKey: ['UTC', '(UTC)'],
	},
	{
		value: 'America/Dawson',
		label: 'America/Dawson',
		searchKey: ['UMST', '(UTC-07:00)'],
	},
	{
		value: 'America/Dawson_Creek',
		label: 'America/Dawson_Creek',
		searchKey: ['UMST', '(UTC-07:00)'],
	},
	{
		value: 'America/Denver',
		label: 'America/Denver',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'America/Detroit',
		label: 'America/Detroit',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Dominica',
		label: 'America/Dominica',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Edmonton',
		label: 'America/Edmonton',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'America/Eirunepe',
		label: 'America/Eirunepe',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/El_Salvador',
		label: 'America/El_Salvador',
		searchKey: ['CAST', '(UTC-06:00)'],
	},
	{
		value: 'America/Ensenada',
		label: 'America/Ensenada',
		searchKey: ['Ens'],
	},
	{
		value: 'America/Fort_Nelson',
		label: 'America/Fort_Nelson',
		searchKey: ['For'],
	},
	{
		value: 'America/Fort_Wayne',
		label: 'America/Fort_Wayne',
		searchKey: ['For'],
	},
	{
		value: 'America/Fortaleza',
		label: 'America/Fortaleza',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'America/Glace_Bay',
		label: 'America/Glace_Bay',
		searchKey: ['ADT', '(UTC-04:00)'],
	},
	{
		value: 'America/Godthab',
		label: 'America/Godthab',
		searchKey: ['GDT', '(UTC-03:00)'],
	},
	{
		value: 'America/Goose_Bay',
		label: 'America/Goose_Bay',
		searchKey: ['ADT', '(UTC-04:00)'],
	},
	{
		value: 'America/Grand_Turk',
		label: 'America/Grand_Turk',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Grenada',
		label: 'America/Grenada',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Guadeloupe',
		label: 'America/Guadeloupe',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Guatemala',
		label: 'America/Guatemala',
		searchKey: ['CAST', '(UTC-06:00)'],
	},
	{
		value: 'America/Guayaquil',
		label: 'America/Guayaquil',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/Guyana',
		label: 'America/Guyana',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Halifax',
		label: 'America/Halifax',
		searchKey: ['ADT', '(UTC-04:00)'],
	},
	{
		value: 'America/Havana',
		label: 'America/Havana',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Hermosillo',
		label: 'America/Hermosillo',
		searchKey: ['UMST', '(UTC-07:00)'],
	},
	{
		value: 'America/Indiana/Indianapolis',
		label: 'America/Indiana/Indianapolis',
		searchKey: ['Ind'],
	},
	{
		value: 'America/Indiana/Knox',
		label: 'America/Indiana/Knox',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Indiana/Marengo',
		label: 'America/Indiana/Marengo',
		searchKey: ['UEDT', '(UTC-05:00)'],
	},
	{
		value: 'America/Indiana/Petersburg',
		label: 'America/Indiana/Petersburg',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Indiana/Tell_City',
		label: 'America/Indiana/Tell_City',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Indiana/Vevay',
		label: 'America/Indiana/Vevay',
		searchKey: ['UEDT', '(UTC-05:00)'],
	},
	{
		value: 'America/Indiana/Vincennes',
		label: 'America/Indiana/Vincennes',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Indiana/Winamac',
		label: 'America/Indiana/Winamac',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Indianapolis',
		label: 'America/Indianapolis',
		searchKey: ['UEDT', '(UTC-05:00)'],
	},
	{
		value: 'America/Inuvik',
		label: 'America/Inuvik',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'America/Iqaluit',
		label: 'America/Iqaluit',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Jamaica',
		label: 'America/Jamaica',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/Jujuy',
		label: 'America/Jujuy',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Juneau',
		label: 'America/Juneau',
		searchKey: ['AKDT', '(UTC-09:00)'],
	},
	{
		value: 'America/Kentucky/Louisville',
		label: 'America/Kentucky/Louisville',
		searchKey: ['Ken'],
	},
	{
		value: 'America/Kentucky/Monticello',
		label: 'America/Kentucky/Monticello',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Knox_IN',
		label: 'America/Knox_IN',
		searchKey: ['Kno'],
	},
	{
		value: 'America/Kralendijk',
		label: 'America/Kralendijk',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/La_Paz',
		label: 'America/La_Paz',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Lima',
		label: 'America/Lima',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/Los_Angeles',
		label: 'America/Los_Angeles',
		searchKey: ['PST', '(UTC-08:00)'],
	},
	{
		value: 'America/Louisville',
		label: 'America/Louisville',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Lower_Princes',
		label: 'America/Lower_Princes',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Maceio',
		label: 'America/Maceio',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'America/Managua',
		label: 'America/Managua',
		searchKey: ['CAST', '(UTC-06:00)'],
	},
	{
		value: 'America/Manaus',
		label: 'America/Manaus',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Marigot',
		label: 'America/Marigot',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Martinique',
		label: 'America/Martinique',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Matamoros',
		label: 'America/Matamoros',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Mazatlan',
		label: 'America/Mazatlan',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'America/Mendoza',
		label: 'America/Mendoza',
		searchKey: ['AST', '(UTC-03:00)'],
	},
	{
		value: 'America/Menominee',
		label: 'America/Menominee',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Merida',
		label: 'America/Merida',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Metlakatla',
		label: 'America/Metlakatla',
		searchKey: ['Met'],
	},
	{
		value: 'America/Mexico_City',
		label: 'America/Mexico_City',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Miquelon',
		label: 'America/Miquelon',
		searchKey: ['Miq'],
	},
	{
		value: 'America/Moncton',
		label: 'America/Moncton',
		searchKey: ['ADT', '(UTC-04:00)'],
	},
	{
		value: 'America/Monterrey',
		label: 'America/Monterrey',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Montevideo',
		label: 'America/Montevideo',
		searchKey: ['MST', '(UTC-03:00)'],
	},
	{
		value: 'America/Montreal',
		label: 'America/Montreal',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Montserrat',
		label: 'America/Montserrat',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Nassau',
		label: 'America/Nassau',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/New_York',
		label: 'America/New_York',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Nipigon',
		label: 'America/Nipigon',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Nome',
		label: 'America/Nome',
		searchKey: ['AKDT', '(UTC-09:00)'],
	},
	{
		value: 'America/Noronha',
		label: 'America/Noronha',
		searchKey: ['U', '(UTC-02:00)'],
	},
	{
		value: 'America/North_Dakota/Beulah',
		label: 'America/North_Dakota/Beulah',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/North_Dakota/Center',
		label: 'America/North_Dakota/Center',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/North_Dakota/New_Salem',
		label: 'America/North_Dakota/New_Salem',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Nuuk',
		label: 'America/Nuuk',
		searchKey: ['Nuu'],
	},
	{
		value: 'America/Ojinaga',
		label: 'America/Ojinaga',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'America/Panama',
		label: 'America/Panama',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/Pangnirtung',
		label: 'America/Pangnirtung',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Paramaribo',
		label: 'America/Paramaribo',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'America/Phoenix',
		label: 'America/Phoenix',
		searchKey: ['UMST', '(UTC-07:00)'],
	},
	{
		value: 'America/Port-au-Prince',
		label: 'America/Port-au-Prince',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Port_of_Spain',
		label: 'America/Port_of_Spain',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Porto_Acre',
		label: 'America/Porto_Acre',
		searchKey: ['Por'],
	},
	{
		value: 'America/Porto_Velho',
		label: 'America/Porto_Velho',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Puerto_Rico',
		label: 'America/Puerto_Rico',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Punta_Arenas',
		label: 'America/Punta_Arenas',
		searchKey: ['Pun'],
	},
	{
		value: 'America/Rainy_River',
		label: 'America/Rainy_River',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Rankin_Inlet',
		label: 'America/Rankin_Inlet',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Recife',
		label: 'America/Recife',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'America/Regina',
		label: 'America/Regina',
		searchKey: ['CCST', '(UTC-06:00)'],
	},
	{
		value: 'America/Resolute',
		label: 'America/Resolute',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Rio_Branco',
		label: 'America/Rio_Branco',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'America/Rosario',
		label: 'America/Rosario',
		searchKey: ['Ros'],
	},
	{
		value: 'America/Santa_Isabel',
		label: 'America/Santa_Isabel',
		searchKey: ['PDT', '(UTC-08:00)'],
	},
	{
		value: 'America/Santarem',
		label: 'America/Santarem',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'America/Santiago',
		label: 'America/Santiago',
		searchKey: ['PSST', '(UTC-04:00)'],
	},
	{
		value: 'America/Santo_Domingo',
		label: 'America/Santo_Domingo',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Sao_Paulo',
		label: 'America/Sao_Paulo',
		searchKey: ['ESAST', '(UTC-03:00)'],
	},
	{
		value: 'America/Scoresbysund',
		label: 'America/Scoresbysund',
		searchKey: ['ADT', '(UTC-01:00)'],
	},
	{
		value: 'America/Shiprock',
		label: 'America/Shiprock',
		searchKey: ['Shi'],
	},
	{
		value: 'America/Sitka',
		label: 'America/Sitka',
		searchKey: ['AKDT', '(UTC-09:00)'],
	},
	{
		value: 'America/St_Barthelemy',
		label: 'America/St_Barthelemy',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/St_Johns',
		label: 'America/St_Johns',
		searchKey: ['NDT', '(UTC-03:30)'],
	},
	{
		value: 'America/St_Kitts',
		label: 'America/St_Kitts',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/St_Lucia',
		label: 'America/St_Lucia',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/St_Thomas',
		label: 'America/St_Thomas',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/St_Vincent',
		label: 'America/St_Vincent',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Swift_Current',
		label: 'America/Swift_Current',
		searchKey: ['CCST', '(UTC-06:00)'],
	},
	{
		value: 'America/Tegucigalpa',
		label: 'America/Tegucigalpa',
		searchKey: ['CAST', '(UTC-06:00)'],
	},
	{
		value: 'America/Thule',
		label: 'America/Thule',
		searchKey: ['ADT', '(UTC-04:00)'],
	},
	{
		value: 'America/Thunder_Bay',
		label: 'America/Thunder_Bay',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Tijuana',
		label: 'America/Tijuana',
		searchKey: ['PST', '(UTC-08:00)'],
	},
	{
		value: 'America/Toronto',
		label: 'America/Toronto',
		searchKey: ['EDT', '(UTC-04:00)'],
	},
	{
		value: 'America/Tortola',
		label: 'America/Tortola',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'America/Vancouver',
		label: 'America/Vancouver',
		searchKey: ['PST', '(UTC-08:00)'],
	},
	{
		value: 'America/Virgin',
		label: 'America/Virgin',
		searchKey: ['Vir'],
	},
	{
		value: 'America/Whitehorse',
		label: 'America/Whitehorse',
		searchKey: ['UMST', '(UTC-07:00)'],
	},
	{
		value: 'America/Winnipeg',
		label: 'America/Winnipeg',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'America/Yakutat',
		label: 'America/Yakutat',
		searchKey: ['AKDT', '(UTC-09:00)'],
	},
	{
		value: 'America/Yellowknife',
		label: 'America/Yellowknife',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'Antarctica/Casey',
		label: 'Antarctica/Casey',
		searchKey: ['WAST', '(UTC+08:00)'],
	},
	{
		value: 'Antarctica/Davis',
		label: 'Antarctica/Davis',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Antarctica/DumontDUrville',
		label: 'Antarctica/DumontDUrville',
		searchKey: ['WPST', '(UTC+10:00)'],
	},
	{
		value: 'Antarctica/Macquarie',
		label: 'Antarctica/Macquarie',
		searchKey: ['CPST', '(UTC+11:00)'],
	},
	{
		value: 'Antarctica/Mawson',
		label: 'Antarctica/Mawson',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Antarctica/McMurdo',
		label: 'Antarctica/McMurdo',
		searchKey: ['NZST', '(UTC+12:00)'],
	},
	{
		value: 'Antarctica/Palmer',
		label: 'Antarctica/Palmer',
		searchKey: ['PSST', '(UTC-04:00)'],
	},
	{
		value: 'Antarctica/Rothera',
		label: 'Antarctica/Rothera',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'Antarctica/South_Pole',
		label: 'Antarctica/South_Pole',
		searchKey: ['Sou'],
	},
	{
		value: 'Antarctica/Syowa',
		label: 'Antarctica/Syowa',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Antarctica/Troll',
		label: 'Antarctica/Troll',
		searchKey: ['Tro'],
	},
	{
		value: 'Antarctica/Vostok',
		label: 'Antarctica/Vostok',
		searchKey: ['CAST', '(UTC+06:00)'],
	},
	{
		value: 'Arctic/Longyearbyen',
		label: 'Arctic/Longyearbyen',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Asia/Aden',
		label: 'Asia/Aden',
		searchKey: ['AST', '(UTC+03:00)'],
	},
	{
		value: 'Asia/Almaty',
		label: 'Asia/Almaty',
		searchKey: ['CAST', '(UTC+06:00)'],
	},
	{
		value: 'Asia/Amman',
		label: 'Asia/Amman',
		searchKey: ['JST', '(UTC+03:00)'],
	},
	{
		value: 'Asia/Anadyr',
		label: 'Asia/Anadyr',
		searchKey: ['MST', '(UTC+12:00)'],
	},
	{
		value: 'Asia/Aqtau',
		label: 'Asia/Aqtau',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Aqtobe',
		label: 'Asia/Aqtobe',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Ashgabat',
		label: 'Asia/Ashgabat',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Ashkhabad',
		label: 'Asia/Ashkhabad',
		searchKey: ['Ash'],
	},
	{
		value: 'Asia/Atyrau',
		label: 'Asia/Atyrau',
		searchKey: ['Aty'],
	},
	{
		value: 'Asia/Baghdad',
		label: 'Asia/Baghdad',
		searchKey: ['AST', '(UTC+03:00)'],
	},
	{
		value: 'Asia/Bahrain',
		label: 'Asia/Bahrain',
		searchKey: ['AST', '(UTC+03:00)'],
	},
	{
		value: 'Asia/Baku',
		label: 'Asia/Baku',
		searchKey: ['ADT', '(UTC+04:00)'],
	},
	{
		value: 'Asia/Bangkok',
		label: 'Asia/Bangkok',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Barnaul',
		label: 'Asia/Barnaul',
		searchKey: ['Bar'],
	},
	{
		value: 'Asia/Beirut',
		label: 'Asia/Beirut',
		searchKey: ['MEDT', '(UTC+02:00)'],
	},
	{
		value: 'Asia/Bishkek',
		label: 'Asia/Bishkek',
		searchKey: ['CAST', '(UTC+06:00)'],
	},
	{
		value: 'Asia/Brunei',
		label: 'Asia/Brunei',
		searchKey: ['MPST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Calcutta',
		label: 'Asia/Calcutta',
		searchKey: ['IST', '(UTC+05:30)'],
	},
	{
		value: 'Asia/Chita',
		label: 'Asia/Chita',
		searchKey: ['YST', '(UTC+09:00)'],
	},
	{
		value: 'Asia/Choibalsan',
		label: 'Asia/Choibalsan',
		searchKey: ['UST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Chongqing',
		label: 'Asia/Chongqing',
		searchKey: ['Cho'],
	},
	{
		value: 'Asia/Chungking',
		label: 'Asia/Chungking',
		searchKey: ['Chu'],
	},
	{
		value: 'Asia/Colombo',
		label: 'Asia/Colombo',
		searchKey: ['SLST', '(UTC+05:30)'],
	},
	{
		value: 'Asia/Dacca',
		label: 'Asia/Dacca',
		searchKey: ['Dac'],
	},
	{
		value: 'Asia/Damascus',
		label: 'Asia/Damascus',
		searchKey: ['SDT', '(UTC+02:00)'],
	},
	{
		value: 'Asia/Dhaka',
		label: 'Asia/Dhaka',
		searchKey: ['BST', '(UTC+06:00)'],
	},
	{
		value: 'Asia/Dili',
		label: 'Asia/Dili',
		searchKey: ['JST', '(UTC+09:00)'],
	},
	{
		value: 'Asia/Dubai',
		label: 'Asia/Dubai',
		searchKey: ['AST', '(UTC+04:00)'],
	},
	{
		value: 'Asia/Dushanbe',
		label: 'Asia/Dushanbe',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Famagusta',
		label: 'Asia/Famagusta',
		searchKey: ['Fam'],
	},
	{
		value: 'Asia/Gaza',
		label: 'Asia/Gaza',
		searchKey: ['Gaz'],
	},
	{
		value: 'Asia/Harbin',
		label: 'Asia/Harbin',
		searchKey: ['Har'],
	},
	{
		value: 'Asia/Hebron',
		label: 'Asia/Hebron',
		searchKey: ['Heb'],
	},
	{
		value: 'Asia/Ho_Chi_Minh',
		label: 'Asia/Ho_Chi_Minh',
		searchKey: ['Ho_'],
	},
	{
		value: 'Asia/Hong_Kong',
		label: 'Asia/Hong_Kong',
		searchKey: ['CST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Hovd',
		label: 'Asia/Hovd',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Irkutsk',
		label: 'Asia/Irkutsk',
		searchKey: ['NAEST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Istanbul',
		label: 'Asia/Istanbul',
		searchKey: ['Ist'],
	},
	{
		value: 'Asia/Jakarta',
		label: 'Asia/Jakarta',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Jayapura',
		label: 'Asia/Jayapura',
		searchKey: ['JST', '(UTC+09:00)'],
	},
	{
		value: 'Asia/Jerusalem',
		label: 'Asia/Jerusalem',
		searchKey: ['JDT', '(UTC+02:00)'],
	},
	{
		value: 'Asia/Kabul',
		label: 'Asia/Kabul',
		searchKey: ['AST', '(UTC+04:30)'],
	},
	{
		value: 'Asia/Kamchatka',
		label: 'Asia/Kamchatka',
		searchKey: ['KDT', '(UTC+12:00)'],
	},
	{
		value: 'Asia/Karachi',
		label: 'Asia/Karachi',
		searchKey: ['PKT', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Kashgar',
		label: 'Asia/Kashgar',
		searchKey: ['Kas'],
	},
	{
		value: 'Asia/Kathmandu',
		label: 'Asia/Kathmandu',
		searchKey: ['NST', '(UTC+05:45)'],
	},
	{
		value: 'Asia/Katmandu',
		label: 'Asia/Katmandu',
		searchKey: ['Kat'],
	},
	{
		value: 'Asia/Khandyga',
		label: 'Asia/Khandyga',
		searchKey: ['YST', '(UTC+09:00)'],
	},
	{
		value: 'Asia/Kolkata',
		label: 'Asia/Kolkata',
		searchKey: ['IST', '(UTC+05:30)'],
	},
	{
		value: 'Asia/Krasnoyarsk',
		label: 'Asia/Krasnoyarsk',
		searchKey: ['NAST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Kuala_Lumpur',
		label: 'Asia/Kuala_Lumpur',
		searchKey: ['MPST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Kuching',
		label: 'Asia/Kuching',
		searchKey: ['MPST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Kuwait',
		label: 'Asia/Kuwait',
		searchKey: ['AST', '(UTC+03:00)'],
	},
	{
		value: 'Asia/Macao',
		label: 'Asia/Macao',
		searchKey: ['Mac'],
	},
	{
		value: 'Asia/Macau',
		label: 'Asia/Macau',
		searchKey: ['CST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Magadan',
		label: 'Asia/Magadan',
		searchKey: ['MST', '(UTC+12:00)'],
	},
	{
		value: 'Asia/Makassar',
		label: 'Asia/Makassar',
		searchKey: ['MPST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Manila',
		label: 'Asia/Manila',
		searchKey: ['MPST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Muscat',
		label: 'Asia/Muscat',
		searchKey: ['AST', '(UTC+04:00)'],
	},
	{
		value: 'Asia/Nicosia',
		label: 'Asia/Nicosia',
		searchKey: ['EEDT', '(UTC+02:00)'],
	},
	{
		value: 'Asia/Novokuznetsk',
		label: 'Asia/Novokuznetsk',
		searchKey: ['NCAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Novosibirsk',
		label: 'Asia/Novosibirsk',
		searchKey: ['NCAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Omsk',
		label: 'Asia/Omsk',
		searchKey: ['NCAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Oral',
		label: 'Asia/Oral',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Phnom_Penh',
		label: 'Asia/Phnom_Penh',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Pontianak',
		label: 'Asia/Pontianak',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Pyongyang',
		label: 'Asia/Pyongyang',
		searchKey: ['KST', '(UTC+09:00)'],
	},
	{
		value: 'Asia/Qatar',
		label: 'Asia/Qatar',
		searchKey: ['AST', '(UTC+03:00)'],
	},
	{
		value: 'Asia/Qostanay',
		label: 'Asia/Qostanay',
		searchKey: ['Qos'],
	},
	{
		value: 'Asia/Qyzylorda',
		label: 'Asia/Qyzylorda',
		searchKey: ['CAST', '(UTC+06:00)'],
	},
	{
		value: 'Asia/Rangoon',
		label: 'Asia/Rangoon',
		searchKey: ['MST', '(UTC+06:30)'],
	},
	{
		value: 'Asia/Riyadh',
		label: 'Asia/Riyadh',
		searchKey: ['AST', '(UTC+03:00)'],
	},
	{
		value: 'Asia/Saigon',
		label: 'Asia/Saigon',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Sakhalin',
		label: 'Asia/Sakhalin',
		searchKey: ['VST', '(UTC+11:00)'],
	},
	{
		value: 'Asia/Samarkand',
		label: 'Asia/Samarkand',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Seoul',
		label: 'Asia/Seoul',
		searchKey: ['KST', '(UTC+09:00)'],
	},
	{
		value: 'Asia/Shanghai',
		label: 'Asia/Shanghai',
		searchKey: ['CST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Singapore',
		label: 'Asia/Singapore',
		searchKey: ['MPST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Srednekolymsk',
		label: 'Asia/Srednekolymsk',
		searchKey: ['MST', '(UTC+12:00)'],
	},
	{
		value: 'Asia/Taipei',
		label: 'Asia/Taipei',
		searchKey: ['TST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Tashkent',
		label: 'Asia/Tashkent',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Tbilisi',
		label: 'Asia/Tbilisi',
		searchKey: ['GET', '(UTC+04:00)'],
	},
	{
		value: 'Asia/Tehran',
		label: 'Asia/Tehran',
		searchKey: ['IDT', '(UTC+03:30)'],
	},
	{
		value: 'Asia/Tel_Aviv',
		label: 'Asia/Tel_Aviv',
		searchKey: ['Tel'],
	},
	{
		value: 'Asia/Thimbu',
		label: 'Asia/Thimbu',
		searchKey: ['Thi'],
	},
	{
		value: 'Asia/Thimphu',
		label: 'Asia/Thimphu',
		searchKey: ['BST', '(UTC+06:00)'],
	},
	{
		value: 'Asia/Tokyo',
		label: 'Asia/Tokyo',
		searchKey: ['JST', '(UTC+09:00)'],
	},
	{
		value: 'Asia/Tomsk',
		label: 'Asia/Tomsk',
		searchKey: ['Tom'],
	},
	{
		value: 'Asia/Ujung_Pandang',
		label: 'Asia/Ujung_Pandang',
		searchKey: ['Uju'],
	},
	{
		value: 'Asia/Ulaanbaatar',
		label: 'Asia/Ulaanbaatar',
		searchKey: ['UST', '(UTC+08:00)'],
	},
	{
		value: 'Asia/Ulan_Bator',
		label: 'Asia/Ulan_Bator',
		searchKey: ['Ula'],
	},
	{
		value: 'Asia/Urumqi',
		label: 'Asia/Urumqi',
		searchKey: ['CAST', '(UTC+06:00)'],
	},
	{
		value: 'Asia/Ust-Nera',
		label: 'Asia/Ust-Nera',
		searchKey: ['VST', '(UTC+11:00)'],
	},
	{
		value: 'Asia/Vientiane',
		label: 'Asia/Vientiane',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Asia/Vladivostok',
		label: 'Asia/Vladivostok',
		searchKey: ['VST', '(UTC+11:00)'],
	},
	{
		value: 'Asia/Yakutsk',
		label: 'Asia/Yakutsk',
		searchKey: ['YST', '(UTC+09:00)'],
	},
	{
		value: 'Asia/Yangon',
		label: 'Asia/Yangon',
		searchKey: ['Yan'],
	},
	{
		value: 'Asia/Yekaterinburg',
		label: 'Asia/Yekaterinburg',
		searchKey: ['YEKT', '(UTC+05:00)'],
	},
	{
		value: 'Asia/Yerevan',
		label: 'Asia/Yerevan',
		searchKey: ['CST', '(UTC+04:00)'],
	},
	{
		value: 'Atlantic/Azores',
		label: 'Atlantic/Azores',
		searchKey: ['ADT', '(UTC-01:00)'],
	},
	{
		value: 'Atlantic/Bermuda',
		label: 'Atlantic/Bermuda',
		searchKey: ['ADT', '(UTC-04:00)'],
	},
	{
		value: 'Atlantic/Canary',
		label: 'Atlantic/Canary',
		searchKey: ['GDT', '(UTC)'],
	},
	{
		value: 'Atlantic/Cape_Verde',
		label: 'Atlantic/Cape_Verde',
		searchKey: ['CVST', '(UTC-01:00)'],
	},
	{
		value: 'Atlantic/Faeroe',
		label: 'Atlantic/Faeroe',
		searchKey: ['GDT', '(UTC)'],
	},
	{
		value: 'Atlantic/Faroe',
		label: 'Atlantic/Faroe',
		searchKey: ['Far'],
	},
	{
		value: 'Atlantic/Jan_Mayen',
		label: 'Atlantic/Jan_Mayen',
		searchKey: ['Jan'],
	},
	{
		value: 'Atlantic/Madeira',
		label: 'Atlantic/Madeira',
		searchKey: ['GDT', '(UTC)'],
	},
	{
		value: 'Atlantic/Reykjavik',
		label: 'Atlantic/Reykjavik',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Atlantic/South_Georgia',
		label: 'Atlantic/South_Georgia',
		searchKey: ['U', '(UTC-02:00)'],
	},
	{
		value: 'Atlantic/St_Helena',
		label: 'Atlantic/St_Helena',
		searchKey: ['GST', '(UTC)'],
	},
	{
		value: 'Atlantic/Stanley',
		label: 'Atlantic/Stanley',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'Australia/ACT',
		label: 'Australia/ACT',
		searchKey: ['ACT'],
	},
	{
		value: 'Australia/Adelaide',
		label: 'Australia/Adelaide',
		searchKey: ['CAST', '(UTC+09:30)'],
	},
	{
		value: 'Australia/Brisbane',
		label: 'Australia/Brisbane',
		searchKey: ['EAST', '(UTC+10:00)'],
	},
	{
		value: 'Australia/Broken_Hill',
		label: 'Australia/Broken_Hill',
		searchKey: ['CAST', '(UTC+09:30)'],
	},
	{
		value: 'Australia/Canberra',
		label: 'Australia/Canberra',
		searchKey: ['Can'],
	},
	{
		value: 'Australia/Currie',
		label: 'Australia/Currie',
		searchKey: ['TST', '(UTC+10:00)'],
	},
	{
		value: 'Australia/Darwin',
		label: 'Australia/Darwin',
		searchKey: ['ACST', '(UTC+09:30)'],
	},
	{
		value: 'Australia/Eucla',
		label: 'Australia/Eucla',
		searchKey: ['Euc'],
	},
	{
		value: 'Australia/Hobart',
		label: 'Australia/Hobart',
		searchKey: ['TST', '(UTC+10:00)'],
	},
	{
		value: 'Australia/LHI',
		label: 'Australia/LHI',
		searchKey: ['LHI'],
	},
	{
		value: 'Australia/Lindeman',
		label: 'Australia/Lindeman',
		searchKey: ['EAST', '(UTC+10:00)'],
	},
	{
		value: 'Australia/Lord_Howe',
		label: 'Australia/Lord_Howe',
		searchKey: ['Lor'],
	},
	{
		value: 'Australia/Melbourne',
		label: 'Australia/Melbourne',
		searchKey: ['AEST', '(UTC+10:00)'],
	},
	{
		value: 'Australia/NSW',
		label: 'Australia/NSW',
		searchKey: ['NSW'],
	},
	{
		value: 'Australia/North',
		label: 'Australia/North',
		searchKey: ['Nor'],
	},
	{
		value: 'Australia/Perth',
		label: 'Australia/Perth',
		searchKey: ['WAST', '(UTC+08:00)'],
	},
	{
		value: 'Australia/Queensland',
		label: 'Australia/Queensland',
		searchKey: ['Que'],
	},
	{
		value: 'Australia/South',
		label: 'Australia/South',
		searchKey: ['Sou'],
	},
	{
		value: 'Australia/Sydney',
		label: 'Australia/Sydney',
		searchKey: ['AEST', '(UTC+10:00)'],
	},
	{
		value: 'Australia/Tasmania',
		label: 'Australia/Tasmania',
		searchKey: ['Tas'],
	},
	{
		value: 'Australia/Victoria',
		label: 'Australia/Victoria',
		searchKey: ['Vic'],
	},
	{
		value: 'Australia/West',
		label: 'Australia/West',
		searchKey: ['Wes'],
	},
	{
		value: 'Australia/Yancowinna',
		label: 'Australia/Yancowinna',
		searchKey: ['Yan'],
	},
	{
		value: 'Brazil/Acre',
		label: 'Brazil/Acre',
		searchKey: ['Acr'],
	},
	{
		value: 'Brazil/DeNoronha',
		label: 'Brazil/DeNoronha',
		searchKey: ['DeN'],
	},
	{
		value: 'Brazil/East',
		label: 'Brazil/East',
		searchKey: ['Eas'],
	},
	{
		value: 'Brazil/West',
		label: 'Brazil/West',
		searchKey: ['Wes'],
	},
	{
		value: 'CET',
		label: 'CET',
		searchKey: ['CET'],
	},
	{
		value: 'CST6CDT',
		label: 'CST6CDT',
		searchKey: ['CDT', '(UTC-06:00)'],
	},
	{
		value: 'Canada/Atlantic',
		label: 'Canada/Atlantic',
		searchKey: ['Atl'],
	},
	{
		value: 'Canada/Central',
		label: 'Canada/Central',
		searchKey: ['Cen'],
	},
	{
		value: 'Canada/Eastern',
		label: 'Canada/Eastern',
		searchKey: ['Eas'],
	},
	{
		value: 'Canada/Mountain',
		label: 'Canada/Mountain',
		searchKey: ['Mou'],
	},
	{
		value: 'Canada/Newfoundland',
		label: 'Canada/Newfoundland',
		searchKey: ['New'],
	},
	{
		value: 'Canada/Pacific',
		label: 'Canada/Pacific',
		searchKey: ['Pac'],
	},
	{
		value: 'Canada/Saskatchewan',
		label: 'Canada/Saskatchewan',
		searchKey: ['Sas'],
	},
	{
		value: 'Canada/Yukon',
		label: 'Canada/Yukon',
		searchKey: ['Yuk'],
	},
	{
		value: 'Chile/Continental',
		label: 'Chile/Continental',
		searchKey: ['Con'],
	},
	{
		value: 'Chile/EasterIsland',
		label: 'Chile/EasterIsland',
		searchKey: ['Eas'],
	},
	{
		value: 'Cuba',
		label: 'Cuba',
		searchKey: ['Cub'],
	},
	{
		value: 'EET',
		label: 'EET',
		searchKey: ['EET'],
	},
	{
		value: 'EST',
		label: 'EST',
		searchKey: ['EST'],
	},
	{
		value: 'EST5EDT',
		label: 'EST5EDT',
		searchKey: ['EST'],
	},
	{
		value: 'Egypt',
		label: 'Egypt',
		searchKey: ['Egy'],
	},
	{
		value: 'Eire',
		label: 'Eire',
		searchKey: ['Eir'],
	},
	{
		value: 'Etc/GMT',
		label: 'Etc/GMT',
		searchKey: ['UTC', '(UTC)'],
	},
	{
		value: 'Etc/GMT+0',
		label: 'Etc/GMT+0',
		searchKey: ['GMT'],
	},
	{
		value: 'Etc/GMT+1',
		label: 'Etc/GMT+1',
		searchKey: ['CVST', '(UTC-01:00)'],
	},
	{
		value: 'Etc/GMT+10',
		label: 'Etc/GMT+10',
		searchKey: ['HST', '(UTC-10:00)'],
	},
	{
		value: 'Etc/GMT+11',
		label: 'Etc/GMT+11',
		searchKey: ['U', '(UTC-11:00)'],
	},
	{
		value: 'Etc/GMT+12',
		label: 'Etc/GMT+12',
		searchKey: ['DST', '(UTC-12:00)'],
	},
	{
		value: 'Etc/GMT+2',
		label: 'Etc/GMT+2',
		searchKey: ['U', '(UTC-02:00)'],
	},
	{
		value: 'Etc/GMT+3',
		label: 'Etc/GMT+3',
		searchKey: ['SEST', '(UTC-03:00)'],
	},
	{
		value: 'Etc/GMT+4',
		label: 'Etc/GMT+4',
		searchKey: ['SWST', '(UTC-04:00)'],
	},
	{
		value: 'Etc/GMT+5',
		label: 'Etc/GMT+5',
		searchKey: ['SPST', '(UTC-05:00)'],
	},
	{
		value: 'Etc/GMT+6',
		label: 'Etc/GMT+6',
		searchKey: ['CAST', '(UTC-06:00)'],
	},
	{
		value: 'Etc/GMT+7',
		label: 'Etc/GMT+7',
		searchKey: ['UMST', '(UTC-07:00)'],
	},
	{
		value: 'Etc/GMT+8',
		label: 'Etc/GMT+8',
		searchKey: ['GMT'],
	},
	{
		value: 'Etc/GMT+9',
		label: 'Etc/GMT+9',
		searchKey: ['GMT'],
	},
	{
		value: 'Etc/GMT-0',
		label: 'Etc/GMT-0',
		searchKey: ['GMT'],
	},
	{
		value: 'Etc/GMT-1',
		label: 'Etc/GMT-1',
		searchKey: ['WCAST', '(UTC+01:00)'],
	},
	{
		value: 'Etc/GMT-10',
		label: 'Etc/GMT-10',
		searchKey: ['WPST', '(UTC+10:00)'],
	},
	{
		value: 'Etc/GMT-11',
		label: 'Etc/GMT-11',
		searchKey: ['CPST', '(UTC+11:00)'],
	},
	{
		value: 'Etc/GMT-12',
		label: 'Etc/GMT-12',
		searchKey: ['U', '(UTC+12:00)'],
	},
	{
		value: 'Etc/GMT-13',
		label: 'Etc/GMT-13',
		searchKey: ['TST', '(UTC+13:00)'],
	},
	{
		value: 'Etc/GMT-14',
		label: 'Etc/GMT-14',
		searchKey: ['GMT'],
	},
	{
		value: 'Etc/GMT-2',
		label: 'Etc/GMT-2',
		searchKey: ['SAST', '(UTC+02:00)'],
	},
	{
		value: 'Etc/GMT-3',
		label: 'Etc/GMT-3',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Etc/GMT-4',
		label: 'Etc/GMT-4',
		searchKey: ['AST', '(UTC+04:00)'],
	},
	{
		value: 'Etc/GMT-5',
		label: 'Etc/GMT-5',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Etc/GMT-6',
		label: 'Etc/GMT-6',
		searchKey: ['CAST', '(UTC+06:00)'],
	},
	{
		value: 'Etc/GMT-7',
		label: 'Etc/GMT-7',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Etc/GMT-8',
		label: 'Etc/GMT-8',
		searchKey: ['MPST', '(UTC+08:00)'],
	},
	{
		value: 'Etc/GMT-9',
		label: 'Etc/GMT-9',
		searchKey: ['JST', '(UTC+09:00)'],
	},
	{
		value: 'Etc/GMT0',
		label: 'Etc/GMT0',
		searchKey: ['GMT'],
	},
	{
		value: 'Etc/Greenwich',
		label: 'Etc/Greenwich',
		searchKey: ['Gre'],
	},
	{
		value: 'Etc/UCT',
		label: 'Etc/UCT',
		searchKey: ['UCT'],
	},
	{
		value: 'Etc/UTC',
		label: 'Etc/UTC',
		searchKey: ['UTC'],
	},
	{
		value: 'Etc/Universal',
		label: 'Etc/Universal',
		searchKey: ['Uni'],
	},
	{
		value: 'Etc/Zulu',
		label: 'Etc/Zulu',
		searchKey: ['Zul'],
	},
	{
		value: 'Europe/Amsterdam',
		label: 'Europe/Amsterdam',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Andorra',
		label: 'Europe/Andorra',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Astrakhan',
		label: 'Europe/Astrakhan',
		searchKey: ['SAMT', '(UTC+04:00)'],
	},
	{
		value: 'Europe/Athens',
		label: 'Europe/Athens',
		searchKey: ['EEDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Belfast',
		label: 'Europe/Belfast',
		searchKey: ['Bel'],
	},
	{
		value: 'Europe/Belgrade',
		label: 'Europe/Belgrade',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Berlin',
		label: 'Europe/Berlin',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Bratislava',
		label: 'Europe/Bratislava',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Brussels',
		label: 'Europe/Brussels',
		searchKey: ['RDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Bucharest',
		label: 'Europe/Bucharest',
		searchKey: ['EEDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Budapest',
		label: 'Europe/Budapest',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Busingen',
		label: 'Europe/Busingen',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Chisinau',
		label: 'Europe/Chisinau',
		searchKey: ['EEDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Copenhagen',
		label: 'Europe/Copenhagen',
		searchKey: ['RDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Dublin',
		label: 'Europe/Dublin',
		searchKey: ['GDT', '(UTC)'],
	},
	{
		value: 'Europe/Gibraltar',
		label: 'Europe/Gibraltar',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Guernsey',
		label: 'Europe/Guernsey',
		searchKey: ['BST', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Helsinki',
		label: 'Europe/Helsinki',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Isle_of_Man',
		label: 'Europe/Isle_of_Man',
		searchKey: ['BST', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Istanbul',
		label: 'Europe/Istanbul',
		searchKey: ['TDT', '(UTC+03:00)'],
	},
	{
		value: 'Europe/Jersey',
		label: 'Europe/Jersey',
		searchKey: ['BST', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Kaliningrad',
		label: 'Europe/Kaliningrad',
		searchKey: ['KST', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Kiev',
		label: 'Europe/Kiev',
		searchKey: ['Kie'],
	},
	{
		value: 'Europe/Kirov',
		label: 'Europe/Kirov',
		searchKey: ['MSK', '(UTC+03:00)'],
	},
	{
		value: 'Europe/Kyiv',
		label: 'Europe/Kyiv',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Lisbon',
		label: 'Europe/Lisbon',
		searchKey: ['GDT', '(UTC)'],
	},
	{
		value: 'Europe/Ljubljana',
		label: 'Europe/Ljubljana',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/London',
		label: 'Europe/London',
		searchKey: ['BST', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Luxembourg',
		label: 'Europe/Luxembourg',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Madrid',
		label: 'Europe/Madrid',
		searchKey: ['RDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Malta',
		label: 'Europe/Malta',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Mariehamn',
		label: 'Europe/Mariehamn',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Minsk',
		label: 'Europe/Minsk',
		searchKey: ['MSK', '(UTC+03:00)'],
	},
	{
		value: 'Europe/Monaco',
		label: 'Europe/Monaco',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Moscow',
		label: 'Europe/Moscow',
		searchKey: ['MSK', '(UTC+03:00)'],
	},
	{
		value: 'Europe/Nicosia',
		label: 'Europe/Nicosia',
		searchKey: ['EEDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Oslo',
		label: 'Europe/Oslo',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Paris',
		label: 'Europe/Paris',
		searchKey: ['RDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Podgorica',
		label: 'Europe/Podgorica',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Prague',
		label: 'Europe/Prague',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Riga',
		label: 'Europe/Riga',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Rome',
		label: 'Europe/Rome',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Samara',
		label: 'Europe/Samara',
		searchKey: ['SAMT', '(UTC+04:00)'],
	},
	{
		value: 'Europe/San_Marino',
		label: 'Europe/San_Marino',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Sarajevo',
		label: 'Europe/Sarajevo',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Saratov',
		label: 'Europe/Saratov',
		searchKey: ['Sar'],
	},
	{
		value: 'Europe/Simferopol',
		label: 'Europe/Simferopol',
		searchKey: ['MSK', '(UTC+03:00)'],
	},
	{
		value: 'Europe/Skopje',
		label: 'Europe/Skopje',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Sofia',
		label: 'Europe/Sofia',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Stockholm',
		label: 'Europe/Stockholm',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Tallinn',
		label: 'Europe/Tallinn',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Tirane',
		label: 'Europe/Tirane',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Tiraspol',
		label: 'Europe/Tiraspol',
		searchKey: ['Tir'],
	},
	{
		value: 'Europe/Ulyanovsk',
		label: 'Europe/Ulyanovsk',
		searchKey: ['SAMT', '(UTC+04:00)'],
	},
	{
		value: 'Europe/Uzhgorod',
		label: 'Europe/Uzhgorod',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Vaduz',
		label: 'Europe/Vaduz',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Vatican',
		label: 'Europe/Vatican',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Vienna',
		label: 'Europe/Vienna',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Vilnius',
		label: 'Europe/Vilnius',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Volgograd',
		label: 'Europe/Volgograd',
		searchKey: ['MSK', '(UTC+03:00)'],
	},
	{
		value: 'Europe/Warsaw',
		label: 'Europe/Warsaw',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Zagreb',
		label: 'Europe/Zagreb',
		searchKey: ['CEDT', '(UTC+01:00)'],
	},
	{
		value: 'Europe/Zaporozhye',
		label: 'Europe/Zaporozhye',
		searchKey: ['FDT', '(UTC+02:00)'],
	},
	{
		value: 'Europe/Zurich',
		label: 'Europe/Zurich',
		searchKey: ['WEDT', '(UTC+01:00)'],
	},
	{
		value: 'GB',
		label: 'GB',
		searchKey: ['GB'],
	},
	{
		value: 'GB-Eire',
		label: 'GB-Eire',
		searchKey: ['GB-'],
	},
	{
		value: 'GMT',
		label: 'GMT',
		searchKey: ['GMT'],
	},
	{
		value: 'GMT+0',
		label: 'GMT+0',
		searchKey: ['GMT'],
	},
	{
		value: 'GMT-0',
		label: 'GMT-0',
		searchKey: ['GMT'],
	},
	{
		value: 'GMT0',
		label: 'GMT0',
		searchKey: ['GMT'],
	},
	{
		value: 'Greenwich',
		label: 'Greenwich',
		searchKey: ['Gre'],
	},
	{
		value: 'HST',
		label: 'HST',
		searchKey: ['HST'],
	},
	{
		value: 'Hongkong',
		label: 'Hongkong',
		searchKey: ['Hon'],
	},
	{
		value: 'Iceland',
		label: 'Iceland',
		searchKey: ['Ice'],
	},
	{
		value: 'Indian/Antananarivo',
		label: 'Indian/Antananarivo',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Indian/Chagos',
		label: 'Indian/Chagos',
		searchKey: ['CAST', '(UTC+06:00)'],
	},
	{
		value: 'Indian/Christmas',
		label: 'Indian/Christmas',
		searchKey: ['SAST', '(UTC+07:00)'],
	},
	{
		value: 'Indian/Cocos',
		label: 'Indian/Cocos',
		searchKey: ['MST', '(UTC+06:30)'],
	},
	{
		value: 'Indian/Comoro',
		label: 'Indian/Comoro',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Indian/Kerguelen',
		label: 'Indian/Kerguelen',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Indian/Mahe',
		label: 'Indian/Mahe',
		searchKey: ['MST', '(UTC+04:00)'],
	},
	{
		value: 'Indian/Maldives',
		label: 'Indian/Maldives',
		searchKey: ['WAST', '(UTC+05:00)'],
	},
	{
		value: 'Indian/Mauritius',
		label: 'Indian/Mauritius',
		searchKey: ['MST', '(UTC+04:00)'],
	},
	{
		value: 'Indian/Mayotte',
		label: 'Indian/Mayotte',
		searchKey: ['EAST', '(UTC+03:00)'],
	},
	{
		value: 'Indian/Reunion',
		label: 'Indian/Reunion',
		searchKey: ['MST', '(UTC+04:00)'],
	},
	{
		value: 'Iran',
		label: 'Iran',
		searchKey: ['Ira'],
	},
	{
		value: 'Israel',
		label: 'Israel',
		searchKey: ['Isr'],
	},
	{
		value: 'Jamaica',
		label: 'Jamaica',
		searchKey: ['Jam'],
	},
	{
		value: 'Japan',
		label: 'Japan',
		searchKey: ['Jap'],
	},
	{
		value: 'Kwajalein',
		label: 'Kwajalein',
		searchKey: ['Kwa'],
	},
	{
		value: 'Libya',
		label: 'Libya',
		searchKey: ['Lib'],
	},
	{
		value: 'MET',
		label: 'MET',
		searchKey: ['MET'],
	},
	{
		value: 'MST',
		label: 'MST',
		searchKey: ['MST'],
	},
	{
		value: 'MST7MDT',
		label: 'MST7MDT',
		searchKey: ['MDT', '(UTC-07:00)'],
	},
	{
		value: 'Mexico/BajaNorte',
		label: 'Mexico/BajaNorte',
		searchKey: ['Baj'],
	},
	{
		value: 'Mexico/BajaSur',
		label: 'Mexico/BajaSur',
		searchKey: ['Baj'],
	},
	{
		value: 'Mexico/General',
		label: 'Mexico/General',
		searchKey: ['Gen'],
	},
	{
		value: 'NZ',
		label: 'NZ',
		searchKey: ['NZ'],
	},
	{
		value: 'NZ-CHAT',
		label: 'NZ-CHAT',
		searchKey: ['NZ-'],
	},
	{
		value: 'Navajo',
		label: 'Navajo',
		searchKey: ['Nav'],
	},
	{
		value: 'PRC',
		label: 'PRC',
		searchKey: ['PRC'],
	},
	{
		value: 'PST8PDT',
		label: 'PST8PDT',
		searchKey: ['PST', '(UTC-08:00)'],
	},
	{
		value: 'Pacific/Apia',
		label: 'Pacific/Apia',
		searchKey: ['SST', '(UTC+13:00)'],
	},
	{
		value: 'Pacific/Auckland',
		label: 'Pacific/Auckland',
		searchKey: ['NZST', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Bougainville',
		label: 'Pacific/Bougainville',
		searchKey: ['Bou'],
	},
	{
		value: 'Pacific/Chatham',
		label: 'Pacific/Chatham',
		searchKey: ['Cha'],
	},
	{
		value: 'Pacific/Chuuk',
		label: 'Pacific/Chuuk',
		searchKey: ['Chu'],
	},
	{
		value: 'Pacific/Easter',
		label: 'Pacific/Easter',
		searchKey: ['Eas'],
	},
	{
		value: 'Pacific/Efate',
		label: 'Pacific/Efate',
		searchKey: ['CPST', '(UTC+11:00)'],
	},
	{
		value: 'Pacific/Enderbury',
		label: 'Pacific/Enderbury',
		searchKey: ['TST', '(UTC+13:00)'],
	},
	{
		value: 'Pacific/Fakaofo',
		label: 'Pacific/Fakaofo',
		searchKey: ['TST', '(UTC+13:00)'],
	},
	{
		value: 'Pacific/Fiji',
		label: 'Pacific/Fiji',
		searchKey: ['FST', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Funafuti',
		label: 'Pacific/Funafuti',
		searchKey: ['U', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Galapagos',
		label: 'Pacific/Galapagos',
		searchKey: ['CAST', '(UTC-06:00)'],
	},
	{
		value: 'Pacific/Gambier',
		label: 'Pacific/Gambier',
		searchKey: ['Gam'],
	},
	{
		value: 'Pacific/Guadalcanal',
		label: 'Pacific/Guadalcanal',
		searchKey: ['CPST', '(UTC+11:00)'],
	},
	{
		value: 'Pacific/Guam',
		label: 'Pacific/Guam',
		searchKey: ['WPST', '(UTC+10:00)'],
	},
	{
		value: 'Pacific/Honolulu',
		label: 'Pacific/Honolulu',
		searchKey: ['HST', '(UTC-10:00)'],
	},
	{
		value: 'Pacific/Johnston',
		label: 'Pacific/Johnston',
		searchKey: ['HST', '(UTC-10:00)'],
	},
	{
		value: 'Pacific/Kanton',
		label: 'Pacific/Kanton',
		searchKey: ['Kan'],
	},
	{
		value: 'Pacific/Kiritimati',
		label: 'Pacific/Kiritimati',
		searchKey: ['Kir'],
	},
	{
		value: 'Pacific/Kosrae',
		label: 'Pacific/Kosrae',
		searchKey: ['CPST', '(UTC+11:00)'],
	},
	{
		value: 'Pacific/Kwajalein',
		label: 'Pacific/Kwajalein',
		searchKey: ['U', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Majuro',
		label: 'Pacific/Majuro',
		searchKey: ['U', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Marquesas',
		label: 'Pacific/Marquesas',
		searchKey: ['Mar'],
	},
	{
		value: 'Pacific/Midway',
		label: 'Pacific/Midway',
		searchKey: ['U', '(UTC-11:00)'],
	},
	{
		value: 'Pacific/Nauru',
		label: 'Pacific/Nauru',
		searchKey: ['U', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Niue',
		label: 'Pacific/Niue',
		searchKey: ['U', '(UTC-11:00)'],
	},
	{
		value: 'Pacific/Norfolk',
		label: 'Pacific/Norfolk',
		searchKey: ['Nor'],
	},
	{
		value: 'Pacific/Noumea',
		label: 'Pacific/Noumea',
		searchKey: ['CPST', '(UTC+11:00)'],
	},
	{
		value: 'Pacific/Pago_Pago',
		label: 'Pacific/Pago_Pago',
		searchKey: ['U', '(UTC-11:00)'],
	},
	{
		value: 'Pacific/Palau',
		label: 'Pacific/Palau',
		searchKey: ['JST', '(UTC+09:00)'],
	},
	{
		value: 'Pacific/Pitcairn',
		label: 'Pacific/Pitcairn',
		searchKey: ['Pit'],
	},
	{
		value: 'Pacific/Pohnpei',
		label: 'Pacific/Pohnpei',
		searchKey: ['Poh'],
	},
	{
		value: 'Pacific/Ponape',
		label: 'Pacific/Ponape',
		searchKey: ['CPST', '(UTC+11:00)'],
	},
	{
		value: 'Pacific/Port_Moresby',
		label: 'Pacific/Port_Moresby',
		searchKey: ['WPST', '(UTC+10:00)'],
	},
	{
		value: 'Pacific/Rarotonga',
		label: 'Pacific/Rarotonga',
		searchKey: ['HST', '(UTC-10:00)'],
	},
	{
		value: 'Pacific/Saipan',
		label: 'Pacific/Saipan',
		searchKey: ['WPST', '(UTC+10:00)'],
	},
	{
		value: 'Pacific/Samoa',
		label: 'Pacific/Samoa',
		searchKey: ['Sam'],
	},
	{
		value: 'Pacific/Tahiti',
		label: 'Pacific/Tahiti',
		searchKey: ['HST', '(UTC-10:00)'],
	},
	{
		value: 'Pacific/Tarawa',
		label: 'Pacific/Tarawa',
		searchKey: ['U', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Tongatapu',
		label: 'Pacific/Tongatapu',
		searchKey: ['TST', '(UTC+13:00)'],
	},
	{
		value: 'Pacific/Truk',
		label: 'Pacific/Truk',
		searchKey: ['WPST', '(UTC+10:00)'],
	},
	{
		value: 'Pacific/Wake',
		label: 'Pacific/Wake',
		searchKey: ['U', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Wallis',
		label: 'Pacific/Wallis',
		searchKey: ['U', '(UTC+12:00)'],
	},
	{
		value: 'Pacific/Yap',
		label: 'Pacific/Yap',
		searchKey: ['Yap'],
	},
	{
		value: 'Poland',
		label: 'Poland',
		searchKey: ['Pol'],
	},
	{
		value: 'Portugal',
		label: 'Portugal',
		searchKey: ['Por'],
	},
	{
		value: 'ROC',
		label: 'ROC',
		searchKey: ['ROC'],
	},
	{
		value: 'ROK',
		label: 'ROK',
		searchKey: ['ROK'],
	},
	{
		value: 'Singapore',
		label: 'Singapore',
		searchKey: ['Sin'],
	},
	{
		value: 'Turkey',
		label: 'Turkey',
		searchKey: ['Tur'],
	},
	{
		value: 'UCT',
		label: 'UCT',
		searchKey: ['UCT'],
	},
	{
		value: 'US/Alaska',
		label: 'US/Alaska',
		searchKey: ['Ala'],
	},
	{
		value: 'US/Aleutian',
		label: 'US/Aleutian',
		searchKey: ['Ale'],
	},
	{
		value: 'US/Arizona',
		label: 'US/Arizona',
		searchKey: ['Ari'],
	},
	{
		value: 'US/Central',
		label: 'US/Central',
		searchKey: ['Cen'],
	},
	{
		value: 'US/East-Indiana',
		label: 'US/East-Indiana',
		searchKey: ['Eas'],
	},
	{
		value: 'US/Eastern',
		label: 'US/Eastern',
		searchKey: ['Eas'],
	},
	{
		value: 'US/Hawaii',
		label: 'US/Hawaii',
		searchKey: ['Haw'],
	},
	{
		value: 'US/Indiana-Starke',
		label: 'US/Indiana-Starke',
		searchKey: ['Ind'],
	},
	{
		value: 'US/Michigan',
		label: 'US/Michigan',
		searchKey: ['Mic'],
	},
	{
		value: 'US/Mountain',
		label: 'US/Mountain',
		searchKey: ['Mou'],
	},
	{
		value: 'US/Pacific',
		label: 'US/Pacific',
		searchKey: ['Pac'],
	},
	{
		value: 'US/Samoa',
		label: 'US/Samoa',
		searchKey: ['Sam'],
	},
	{
		value: 'UTC',
		label: 'UTC',
		searchKey: ['UTC'],
	},
	{
		value: 'Universal',
		label: 'Universal',
		searchKey: ['Uni'],
	},
	{
		value: 'W-SU',
		label: 'W-SU',
		searchKey: ['W-S'],
	},
	{
		value: 'WET',
		label: 'WET',
		searchKey: ['WET'],
	},
	{
		value: 'Zulu',
		label: 'Zulu',
		searchKey: ['Zul'],
	},
];
