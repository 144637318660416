import { useCreateEvent } from '@/features/events/hooks/create/useCreateEvent';
import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import InputText from '@/components/element/inputs/InputText';
import InputWrapper from '@/components/element/inputs/InputWrapper';
import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import { useEventSlug } from '@/features/events/hooks/create/useEventSlug';
import { Label } from '@/components/ui/label';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import { QuillEditor } from '@/components/element/rich-text-editor/QuillEditor';
import EventHosts from './EventHosts';
import EventCustomReward from './EventCustomReward';

const EventDetails = () => {
	const { details, setDetails, setIsDirty, errors, setErrors } = useCreateEvent();
	const { fetchSlug, slug, isLoading } = useEventSlug(details.title);

	const editDetails = (
		key: string,
		value: string | Date | boolean | { x: number; y: number },
	) => {
		try {
			const key_ = key === 'startDate' || key === 'endDate' ? 'duration' : key;
			setErrors((prev) => ({
				...prev,
				details: {
					...prev.details,
					[key_]: false,
				},
				sections: {
					...prev.sections,
					details: false,
				},
			}));
			setIsDirty(true);
			setDetails((prev) => ({ ...prev, [key]: value }));
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			<div className="flex justify-center  pt-10">
				<div className="mb-5 w-[600px] relative">
					<div className="grid grid-cols-12 gap-4 second-element">
						<InputText
							label="Event Title"
							placeholder="Defi Alliance"
							className="col-span-8"
							value={details.title}
							setValue={(e) => editDetails('title', e)}
							error={errors?.details?.title}
							inputClassName="bg-white dark:bg-black"
							errorText="Please enter a valid title"
							onBlur={() => {
								fetchSlug();
							}}
							required
						/>
						<div className="col-span-4">
							<Label className="font- mb-2 block">
								Chain<span className="text-red-400 ms-1">*</span>
							</Label>
							<SelectNetwork2
								value={details.network}
								setValue={(e) =>
									setDetails({
										...details,
										network: e.chainId as any,
									})
								}
							/>
						</div>
					</div>
					{details.title &&
						slug &&
						(isLoading ? (
							<></>
						) : (
							<div className="text-xs mt-2">
								You event link will be
								<span className="ms-1 hover:underline cursor-pointer">
									https://intract.io/event/
									{slug}
								</span>
							</div>
						))}
					<InputWrapper
						label="Event Description"
						className="mt-10"
						required
					>
						<QuillEditor
							value={details.description}
							setValue={(e: any) => editDetails('description', e)}
							placeholder="Enter your event description here"
							error={errors.details.description}
							errorText="Please enter a valid description"
						/>
					</InputWrapper>
					<DropzoneWithReposition
						className="mt-10"
						file={details.bannerFile}
						fileLink={details.bannerFileLink}
						clearSelection={() => {
							setDetails((p) => ({
								...p,
								bannerFile: null,
								bannerFileLink: '',
							}));
						}}
						setFile={(file: File | File[], fileLink: string) => {
							setIsDirty(true);
							setDetails((prev) => ({
								...prev,
								bannerFile: file,
								bannerFileLink: fileLink,
							}));
							setErrors((prev) => ({
								...prev,
								details: {
									...prev.details,
									banner: false,
								},
								sections: {
									...prev.sections,
									details: false,
								},
							}));
						}}
						description="Recommended size: 1200x400px"
						label="Banner"
						cta="Upload banner"
						aspectRatio={1200 / 400}
						error={errors?.details?.banner}
						onImageReposition={({ x, y }) => {
							editDetails('bannerPosition', { x, y });
						}}
						allowedFiles={['images']}
						maxSize={1}
						errorText="Please upload a valid image or video file."
						bannerPosition={{
							x: details?.bannerPosition?.x || 0,
							y: details?.bannerPosition?.y || 0,
						}}
						required
					/>
					<EventHosts
						projectsIncluded={details.projectsIncluded}
						setProjectsIncluded={(e) =>
							editDetails('projectsIncluded', e)
						}
					/>
					<div className="my-10">
						<div>
							<div className="mb-3">
								<div className="text-sm font-medium">
									<i className="bi-calendar me-2"></i>
									Event Durations
									<span className="text-red-400 ms-1">*</span>
								</div>
								<div className="text-xs text-muted-foreground mt-1">
									Set the duration of the event
								</div>
							</div>
							<InputDuration
								className=""
								startDate={details?.startDate}
								endDate={details?.endDate}
								setStartDate={(date) =>
									editDetails('startDate', date)
								}
								setEndDate={(date) => editDetails('endDate', date)}
								startImmediately={details?.startImmediately}
								setStartImmediately={(e) =>
									editDetails('startImmediately', e)
								}
								timezone={details?.timezone}
								setTimezone={(e) => editDetails('timezone', e)}
							/>
							{errors?.details?.duration && (
								<p className="text-red-500 text-xs mt-3">
									Please enter a valid duration.
								</p>
							)}
						</div>
					</div>
					<EventCustomReward
						reward={details.reward}
						setReward={(e) => editDetails('reward', e)}
					/>
				</div>
			</div>
		</div>
	);
};

export default EventDetails;
