import { useEffect, useState } from 'react';
import { postGoogleForm } from '../services/kol-marketplace.service';
import { useAuth } from '@/hooks/useAuth';
import { toast } from 'sonner';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export interface IKols {
	_id: string;
	name: string;
	description: string;
	avatar: string;
	region: string;
	language: string;
	total_followers: number;
	social_info: [
		{
			name: string;
			url: string;
			followers: number;
		},
	];
	price: number;
	hype_level: number;
	is_active: boolean;
}

const initialValue = {
	message: '',
	budget: 200,
	modeOfPayment: 'stables',
	category: 'tweet',
	isPreTokenPhase: false,
	tokenomics: '',
	kolName: '',
	kolId: '',
	telegram: '',
};

export default function useKOLMarketplace({ setOpen, kol }) {
	const [formData, setFormData] = useState(initialValue);
	const [formErrors, setFormErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const { user } = useAuth();

	const sendMessage = async () => {
		const error: any = {};
		if (!formData.message) {
			error.message = 'Message is required';
		}
		setFormErrors(error);
		if (Object.keys(error).length !== 0) {
			return;
		}
		try {
			setLoading(true);
			await postGoogleForm({
				...formData,
				enterprise_id: user?.enterpriseId,
			});
			analytics.track(TrackingEvents.BoostersRequestSubmitted, {
				booster: 'kol',
				kolId: kol?._id,
				kolName: kol?.name,
			});
			setLoading(false);
			setOpen(false);
			toast.success('Message sent successfully');
		} catch (error) {
			setLoading(false);
			handleErrorMessage(error, 'Failed to send message');
		}
		setFormData(initialValue);
	};

	useEffect(() => {
		setFormData((prev) => ({
			...prev,
			kolName: kol.name,
			kolId: kol._id,
		}));
	}, [kol, setFormData]);

	useEffect(() => {
		setFormErrors((prev) => ({ ...prev, message: '' }));
	}, [formData?.message, setFormErrors]);

	return {
		formData,
		setFormData,
		formErrors,
		setFormErrors,
		sendMessage,
		loading,
	};
}
