import { ColumnDef } from '@tanstack/react-table';
import { IUserAffiliations } from '../../types/admin-user-affiliation.types';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { convertToTitleCase, toTitleCase } from '@/utils/parsers';
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
} from '@/components/ui/dropdown-menu';
import { Link } from 'react-router-dom';
import { Separator } from '@/components/ui/separator';

export const AffiliateProgramColumns = (
	options: any,
	rewardBreakdown?: any,
): ColumnDef<IUserAffiliations>[] => {
	const cols: ColumnDef<IUserAffiliations>[] = [
		{
			accessorKey: 'superUserId',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Referrer Superuser Id"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{row.getValue('superUserId')}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'emailId',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Referrer Email Id" />
			),
			cell: ({ row }) => (
				<div className="flex items-center">{row.getValue('emailId')}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'enterpriseName',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Referrer Enterprise Name"
				/>
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{row.getValue('enterpriseName')}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'totalRewards',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Rewards" />
			),
			cell: ({ row }) => {
				return (
					<div className="flex items-center justify-end gap-2 text-sm w-fit">
						{row.getValue('totalRewards') &&
							Object.entries(row.getValue('totalRewards')).map(
								([type, amount]) => (
									<div
										className="bg-muted px-2 py-1 rounded-md text-muted-foreground cursor-pointer"
										key={type}
									>
										{toTitleCase(type)}:{' '}
										<span className="text-black/80">
											{amount}
										</span>
									</div>
								),
							)}

						{row.getValue('totalRewards') &&
						Object.keys(row.getValue('totalRewards')).length > 0 ? (
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<i className="bi-info-circle text-muted-foreground cursor-pointer"></i>
								</DropdownMenuTrigger>
								<DropdownMenuContent className="py-4 px-3 max-h-[20rem] overflow-auto ">
									<RewardBreakdown
										rewardBreakdown={
											rewardBreakdown.find(
												(item) =>
													item._id === row.original._id,
											)['rewardBreakdown']
										}
									/>
								</DropdownMenuContent>
							</DropdownMenu>
						) : null}
					</div>
				);
			},
			enableSorting: false,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => <DataTableRowActions row={row} options={options} />,
		},
	];
	return cols;
};

const RewardBreakdown = ({ rewardBreakdown }) => {
	return (
		<div className="w-[15rem]">
			{rewardBreakdown &&
				Object.entries(rewardBreakdown).map(
					([eventType, rewards], eventTypeIndex, eventArray) => (
						<div key={`${eventType}-${eventTypeIndex}`}>
							<div className="text-sm font-medium mb-3">
								{convertToTitleCase(eventType)}
							</div>
							{Array.isArray(rewards) &&
								rewards.map((reward, rewardIndex) => (
									<RewardItem
										key={`${eventType}-${rewardIndex}`}
										reward={reward}
									/>
								))}
							{eventTypeIndex < eventArray.length - 1 && (
								<Separator className="my-4" />
							)}
						</div>
					),
				)}
		</div>
	);
};

const RewardItem = ({ reward }) => {
	const [rewardType, rewardAmount] = Object.entries(reward).find(
		([key]) => key !== 'transactionHash',
	) as any;
	const { transactionHash } = reward;

	return (
		<div className="mb-2 p-2 border rounded-md bg-gray-50">
			<div className="flex justify-between text-sm text-gray-600">
				<span className="">{toTitleCase(rewardType)}:</span>
				<div className="flex justify-between items-center gap-1">
					{transactionHash && (
						<div className="flex justify-between items-center gap-2">
							<Link
								to={`https://etherscan.io/tx/${transactionHash}`}
								target="_blank"
								rel="noreferrer"
								className="underline"
							>
								<i className="bi-link-45deg text-base me-1"></i>
								{/* Etherscan */}
							</Link>
						</div>
					)}
					<span className="text-gray-900">{rewardAmount}</span>
				</div>
			</div>
		</div>
	);
};
