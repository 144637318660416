import { defineChain } from 'viem';

export const cronosZkEVM = defineChain({
	id: 388,
	name: 'Cronos zkEVM Mainnet',
	nativeCurrency: { name: 'zkCRO', symbol: 'zkCRO', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://mainnet.zkevm.cronos.org'],
		},
	},
	blockExplorers: {
		default: {
			name: 'cronosZkEVM Explorer',
			url: 'https://explorer.zkevm.cronos.org/',
		},
	},
	testnet: false,
});
