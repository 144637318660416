import InputSelect2 from '@/components/element/inputs/InputSelect2';
import InputSwitch from '@/components/element/inputs/InputSwitch';
import InputText from '@/components/element/inputs/InputText';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import useKOLMarketplace from '../hooks/useKOLMarketplace';

const MessageKolDialog = ({
	open,
	setOpen,
	kol,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	kol: any;
}) => {
	const { formData, setFormData, formErrors, sendMessage, loading } =
		useKOLMarketplace({ setOpen, kol });

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[565px] p-0">
				<DialogHeader className="flex flex-row items-end justify-between pt-4 px-8 pb-5">
					<div>
						<p>
							<Badge
								className="rounded-full px-3 mb-4"
								variant="outline"
							>
								<i className="bi-stars me-1 text-yellow-600"></i>
								Build your Hype Tribe
							</Badge>
						</p>
						<div className="ms-2">
							Partner with
							<DialogTitle className="text-3xl font-medium">
								{kol.name}
							</DialogTitle>
						</div>
					</div>
					<div className="h-32 min-w-32 border rounded-full flex items-center justify-between p-3 relative">
						<div className="h-[7.3rem] w-[7.3rem] border rounded-full flex items-center justify-between p-3 absolute z-0 bg-slate-100  top-0 start-0 bottom-0 end-0 mx-auto my-auto"></div>
						<img
							src={kol.avatar}
							className="h-full w-full rounded-full relative z-3"
						/>
					</div>
				</DialogHeader>
				<div className="min-h-[200px] bg-slate-100 px-8 pb-8 pt-5 space-y-8">
					<div className="grid grid-cols-2 gap-4">
						<InputSelect2
							value={formData.category}
							setValue={(value) =>
								setFormData((prev) => ({ ...prev, category: value }))
							}
							placeholder="Select a category"
							error={false}
							label="Category"
							options={categoryOptions}
						/>
						<InputSelect2
							value={formData.modeOfPayment}
							setValue={(value) =>
								setFormData((prev) => ({
									...prev,
									modeOfPayment: value,
								}))
							}
							placeholder="Select Mode of Payment"
							error={false}
							label="Select Mode of Payment"
							options={[
								{
									label: 'Your Own Token',
									value: 'token',
								},
								{
									label: 'Stable Coins',
									value: 'stables',
								},
							]}
						/>
					</div>

					<InputText
						value={formData.telegram}
						setValue={(value) =>
							setFormData((prev) => ({ ...prev, telegram: value }))
						}
						placeholder="Enter your telegram"
						className=" "
						inputClassName="bg-white"
						error={false}
						label="Your Telegram"
					/>

					<InputTextArea
						value={formData.message}
						setValue={(value) =>
							setFormData((prev) => ({ ...prev, message: value }))
						}
						placeholder="Type your message here"
						error={formErrors['message']}
						label="Your Message"
						inputClassName="min-h-[100px]"
						errorText={formErrors['message']}
					/>
					<div className="grid grid-cols-2 gap-4">
						<InputText
							value={formData.budget}
							setValue={(value) =>
								setFormData((prev) => ({ ...prev, budget: value }))
							}
							placeholder="Enter your budget"
							className=" "
							inputClassName="bg-white"
							error={false}
							label="Budget"
							type="number"
							prepend={<i className="bi-currency-dollar mx-3"></i>}
						/>
						<InputText
							value={formData.tokenomics}
							setValue={(value) =>
								setFormData((prev) => ({
									...prev,
									tokenomics: value,
								}))
							}
							placeholder="Tokenomics Link"
							className=" "
							inputClassName="bg-white"
							error={false}
							label="Tokenomics Link (optional)"
							prepend={<i className="bi-link-45deg mx-3"></i>}
						/>
					</div>
					<InputSwitch
						value={formData.isPreTokenPhase}
						setValue={(value) =>
							setFormData((prev) => ({
								...prev,
								isPreTokenPhase: value,
							}))
						}
						label="Are you pre token?"
						subLabel="Indicate whether your project is in the pre-token phase."
						subLabelClassName="text-xs text-muted-foreground font-normal mt-1"
					/>

					<div className="mt-5 flex justify-end">
						<Button onClick={() => sendMessage()} disabled={loading}>
							{loading ? (
								<i className="bi bi-arrow-clockwise me-2 animate-spin"></i>
							) : (
								''
							)}
							Send Message
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default MessageKolDialog;

//Tweet/ RT / QT / Thread / youtube video / newsletter / medium blog / streaming / debank marketing

const categoryOptions = [
	{
		label: 'Tweet',
		value: 'tweet',
	},
	{
		label: 'Retweet',
		value: 'retweet',
	},
	{
		label: 'Quote Tweet',
		value: 'quote-tweet',
	},
	{
		label: 'Thread',
		value: 'thread',
	},
	{
		label: 'Youtube Video',
		value: 'youtube-video',
	},
	{
		label: 'Newsletter',
		value: 'newsletter',
	},
	{
		label: 'Medium Blog',
		value: 'medium-blog',
	},
	{
		label: 'Streaming',
		value: 'streaming',
	},
	{
		label: 'Debank Marketing',
		value: 'debank-marketing',
	},
];
