import { defineChain } from 'viem';

export const capx = defineChain({
	id: 10245,
	name: 'Capx Testnet v2',
	nativeCurrency: { decimals: 18, name: 'GAS', symbol: 'GAS' },
	rpcUrls: {
		default: {
			http: ['https://capx-testnet.alt.technology'],
			webSocket: ['https://capx-testnet.alt.technology'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Capx Testnet v2',
			url: 'https://capx-testnet-explorer.alt.technology',
		},
	},
	testnet: true,
});
