import { useEffect, useState } from 'react';
import { useGetCommunityReferral } from '../hooks/useGetCommunityReferral';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import CreateCommunityReferralDialog from '../components/CreateCommunityReferralDialog';
import { useCommunityReferral } from '../hooks/useCommunityReferral';
import { QUEST_URL } from '@/config';
import { EnterpriseReferralProgramStatus } from '../types/customize-hub.enum';
import CampaignMetricsCard from '@/features/events/components/events-report/components/analytics/EventMetricsCard';
import NoDataCard from '@/components/element/cards/NoDataCard';

const CommunityReferral = () => {
	const { isLoading, data } = useGetCommunityReferral();
	const { setFormFields, formFields } = useCommunityReferral();
	const [showCreateReferralProgram, setShowCreateReferralProgram] =
		useState(false);

	const referralMetricsArray = data?.referralMetrics
		? [data?.referralMetrics]
		: [];

	useEffect(() => {
		if (data?.referralProgram) {
			setFormFields((prev) => ({
				...prev,
				referrerEligibilityConditions:
					data?.referralProgram?.referrerEligibilityConditions,
				referredUserEligibilityConditions:
					data?.referralProgram?.referredUserEligibilityConditions,
				rewards: data?.referralProgram?.rewards,
				status: data?.referralProgram?.status,
				_id: data?.referralProgram?._id,
				enterpriseId: data?.referralProgram?.enterpriseId,
			}));
		}
		if (data?.referralProgram && data?.referralMetrics) {
			setShowCreateReferralProgram(true);
		}
	}, [data, setFormFields]);

	if (isLoading) {
		return <FullPageLoading />;
	}
	return (
		<div>
			<div className={cn('flex items-center justify-between')}>
				<div className="space-y-1">
					<h2 className="text-xl font-medium tracking-tight">
						Community Referral Program
					</h2>
					<p className="text-sm text-muted-foreground max-w-[700px]">
						Launch a referral program and let users invite friends to
						join your community.
					</p>
				</div>
				<div className="ml-auto mr-4">
					{referralMetricsArray.length > 0 &&
					data?.referralProgram?.status ===
						EnterpriseReferralProgramStatus?.Active ? (
						<Button
							onClick={() =>
								window.open(
									`${QUEST_URL}/project/${formFields?.enterpriseId}`,
									'_blank',
								)
							}
							variant="outline"
						>
							<i className="bi-share me-2"></i>Go to community page
						</Button>
					) : null}
				</div>
			</div>
			{!showCreateReferralProgram ? (
				<NoDataCard
					title="Launch your referral program"
					description="Create a referral program for your community."
					btnLabel="Set Up Referral Program"
					// isLoading={isCreating}
					btnOnClick={() => setShowCreateReferralProgram(true)}
					className="mt-10"
					image="https://static.highongrowth.xyz/enterprise/66596dd621949f76bb468348/210d5e8d24b941f28384a6d80952eea5.png"
				/>
			) : (
				<div>
					<div className="mt-5 grid grid-cols-3 gap-3">
						<CampaignMetricsCard
							title="Referral Count"
							icon="person-plus-fill"
							value={data?.referralMetrics?.referralSessionsCount}
							subtitle="Total number of referrals made by eligible users"
						/>
						<CampaignMetricsCard
							title="Successful Referrals"
							icon="check-circle-fill"
							value={
								data?.referralMetrics?.successReferralSessionsCount
							}
							subtitle="Total number of successful referrals"
						/>
						<CampaignMetricsCard
							title="Referrer Count"
							icon="people-fill"
							value={data?.referralMetrics?.totalUniqueReferrerCount}
							subtitle="Total number of users who have made referrals"
						/>
					</div>
					<CreateCommunityReferralDialog
						formFields={formFields}
						setFormFields={setFormFields}
					/>
				</div>
			)}
		</div>
	);
};

export default CommunityReferral;
