import { DataTable } from '@/components/element/data-table/DataTable';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { Button } from '@/components/ui/button';
import AddContractDialog from '@/features/dave/components/contracts/AddContractDialog';
import { useGetContracts } from '@/features/dave/hooks/contracts/useGetContracts';
import { IDaveContracts } from '@/features/dave/types/dave.types';
import { cn } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import ContractABI from '../components/ContractABI';
import ChainBadge from '@/components/element/badges/ChainBadge';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';
import InputText from '@/components/element/inputs/InputText';

const ImportedContracts = () => {
	const [open, setOpen] = useState(false);
	const [openABI, setOpenABI] = useState(false);
	const { contracts, isLoading, deleteContract, search, setSearch } =
		useGetContracts();
	const [selectedContract, setSelectedContract] = useState<any>(null);

	const options = [
		{
			type: 'button',
			label: 'Update',
			icon: 'pencil-square',
			onClick: (row: IDaveContracts) => {
				setSelectedContract(row);
				setOpen(true);
			},
		},
	];

	const showABI = (row: IDaveContracts) => {
		setSelectedContract(row);
		setOpenABI(true);
	};

	useQueryInitialise('new', 'true', () => {
		setOpen(true);
	});

	const cols = columns(options, showABI);
	if (isLoading) {
		return <FullPageLoading />;
	}

	return (
		<div>
			<div className={cn('flex items-center justify-between')}>
				<div className="space-y-1">
					<h2 className="text-xl font-medium tracking-tight">
						Imported Contracts
					</h2>
					<p className="text-sm text-muted-foreground max-w-[700px]">
						Edit imported contracts or create new ones
					</p>
				</div>
				<div className="ml-auto mr-4">
					<Button
						onClick={() => {
							setSelectedContract(null);
							setOpen(true);
						}}
					>
						<i className="bi-plus-circle-fill me-2"></i>
						Add Contract
					</Button>
				</div>
			</div>
			<div className="mt-5">
				<div className="flex items-center mt-10 mb-5 space-x-4">
					<InputText
						value={search}
						setValue={setSearch as any}
						prepend={
							<i className="bi-search px-3 text-muted-foreground text-sm"></i>
						}
						placeholder="Search Contracts"
						className="w-[250px] lg:w-[250px]"
					/>
				</div>
				<DataTable
					data={contracts}
					columns={cols}
					totalCount={contracts?.length || 0}
				/>
			</div>
			<AddContractDialog
				open={open}
				setOpen={setOpen}
				selectedContract={selectedContract}
			/>
			<ContractABI
				open={openABI}
				setOpen={setOpenABI}
				selectedContract={selectedContract}
			/>
		</div>
	);
};

export default ImportedContracts;

const columns = (options, setSelectedContract): ColumnDef<IDaveContracts>[] => [
	{
		accessorKey: 'name',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Name" />
		),
		cell: ({ row }) => <div>{row.getValue('name')}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'contractAddress',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Contract Address" />
		),
		cell: ({ row }) => (
			<div className="w-[270px] truncate">
				{row.getValue('contractAddress')}
			</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'chainId',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Chain" />
		),
		cell: ({ row }) => (
			<ChainBadge
				chainId={row.getValue('chainId')}
				className="py-1 flex items-center"
			/>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'isProxy',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Is Proxy" />
		),
		cell: ({ row }) => <div>{row.getValue('isProxy') ? 'Yes' : 'No'}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'abi',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="ABI" />
		),
		cell: ({ row }) => (
			<Button
				variant="outline"
				size="sm"
				onClick={() => setSelectedContract(row.original)}
			>
				View ABI
			</Button>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		id: 'actions',
		cell: ({ row }) => <DataTableRowActions row={row} options={options} />,
	},
];
