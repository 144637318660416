import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import SelectDate from '@/components/element/inputs/input-duration/SelectDate';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import useQuestOfTheDay, {
	IQuestOfTheDay,
} from '@/features/admin/hooks/admin-cms-qotd/useQuestOfTheDay';
import useQuestOfTheDayColumns from '@/features/admin/hooks/admin-cms-qotd/useQuestOfTheDayColumns';
import { useGetAdminCampaigns } from '@/features/admin/hooks/useGetAdminCampaigns';
import { IAdminCampaigns } from '@/features/admin/types/admin.types';
import { Status } from '@/features/campaigns/types';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

const SelectQuestWithDate = ({
	open,
	setOpen,
	formFields,
	setFormFields,
	resetForm,
	handleQuestAddition,
	isSubmitting,
	questOfTheDay,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	formFields: IQuestOfTheDay;
	setFormFields: Dispatch<SetStateAction<IQuestOfTheDay>>;
	resetForm: () => void;
	handleQuestAddition: () => void;
	isSubmitting: boolean;
	questOfTheDay: IAdminCampaigns[];
}) => {
	const {
		results: campaigns,
		search,
		setSearch,
		filters,
		setFilters,
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading,
		refetch,
		count,
	} = useGetAdminCampaigns();

	const handleSelectQuest = (newValue: string) => {
		const selectedQuest = campaigns.find(
			(campaign) => campaign._id === newValue,
		);
		setFormFields((prev) => ({
			...prev,
			_id: newValue,
			banner: selectedQuest.banner,
			name: selectedQuest.name,
		}));
	};

	useEffect(() => {
		if (open) {
			setFilters((prev) => ({
				...prev,
				campaignStatus: [Status.Active],
				publishStatus: true,
			}));
			setPagination((prev) => ({
				...prev,
				pageSize: 30,
			}));
		}
	}, [open]);

	const filteredCampaigns = useMemo(() => {
		if (campaigns) {
			return campaigns.filter((i) =>
				questOfTheDay?.every((j) => j._id !== i._id),
			);
		}
		return [];
	}, [campaigns, questOfTheDay]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[550px] px-0 pb-0 overflow-visible">
				<DialogHeader className="border-b pb-3 px-5">
					<DialogTitle>Choose Quest</DialogTitle>
					<DialogDescription>
						Configure the quest to be displayed as Quest of the Day.
					</DialogDescription>
				</DialogHeader>
				<div className="flex flex-col justify-between space-y-5 px-5 mb-5">
					<div className="grid grid-cols-2 gap-4 items-center">
						<Label>Choose date for Quest of the Day</Label>
						<SelectDate
							date={formFields?.questOfTheDay?.date}
							setDate={(newDate) =>
								setFormFields((prev) => ({
									...prev,
									questOfTheDay: {
										...prev.questOfTheDay,
										date: newDate,
									},
								}))
							}
							isStart
							isContinous={null}
							continuousLabel={''}
							hideContinuousLabel
							setIsContinous={null}
							fromDate={new Date()}
							toDate={undefined}
							disabled={false}
						/>
					</div>
					<InputSelect2
						label="Select Quest"
						options={filteredCampaigns?.map((campaign) => ({
							value: campaign._id,
							label: campaign.name,
							searchText: campaign.name,
						}))}
						placeholder="Choose a quest to highlight"
						value={formFields._id}
						setValue={(newVal) => handleSelectQuest(newVal)}
						popoverClassName="w-[31.8rem]"
						className="w-full"
						required
						setSearch={setSearch}
					/>
				</div>
				<DialogFooter className="flex justify-between border-t px-5 pt-3 pb-3 w-full sm:justify-between">
					<div></div>
					<div className="space-x-2 flex items-center ">
						<Button
							className=""
							onClick={() => {
								setOpen(false);
								resetForm();
							}}
							variant="outline"
						>
							<span>Cancel</span>
						</Button>
						<Button
							className=""
							onClick={handleQuestAddition}
							disabled={isSubmitting}
						>
							{isSubmitting ? (
								<i className="bi bi-arrow-clockwise me-2 animate-spin"></i>
							) : null}
							<span>Update</span>
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default SelectQuestWithDate;
