import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useCreateQueryProvider } from '@/features/dave/hooks/queries/useCreateQueryProvider';
import { QueryListType } from '@/features/dave/types/dave.types';
import { MoreVertical } from 'lucide-react';

const ConditionOptions = (props: QueryListType) => {
	const { setDetails, setErrors, details } = useCreateQueryProvider();

	const isTransferConfigured = props.isChecked.tokenIn || props.isChecked.tokenOut;
	const canDelete = details.queryList.length > 1;

	const updateIsChecked = (key: string, value: any) => {
		setDetails((p) => {
			const queryList = p.queryList.map((q) => {
				if (q.id === props.id) {
					return { ...q, isChecked: { ...q.isChecked, [key]: value } };
				}
				return q;
			});
			return { ...p, queryList };
		});
	};

	const deleteCondition = () => {
		setDetails((p) => {
			const queryList = p.queryList.filter((q) => q.id !== props.id);
			return { ...p, queryList };
		});
		setErrors((p) => {
			return {
				...p,
				conditions: p.conditions.filter((q) => q.id !== props.id),
			};
		});
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="icon">
					<MoreVertical className="h-4 w-4" />
					<span className="sr-only">More</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start" className="max-w-[240px]">
				<DropdownMenuLabel>Select Condition Type</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuItem
					onClick={() => {
						updateIsChecked('multipleTxn', true);
						updateIsChecked('aggregatedVolume', false);
					}}
					className=""
				>
					{props.isChecked.multipleTxn ? (
						<i className="bi bi-record-circle-fill text-violet-500"></i>
					) : (
						<i className="bi bi-circle text-muted-foreground"></i>
					)}
					<div className="flex flex-col items-start ms-2">
						<div>Multiple Transactions</div>
						<div className="text-xs text-muted-foreground">
							Specify a minimum number of separate transactions that
							meet the set criteria
						</div>
					</div>
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<TooltipWrapper
					tooltip={
						!isTransferConfigured
							? 'This option requires a minimum token transfer configuration.'
							: ''
					}
				>
					<DropdownMenuItem
						onClick={() => {
							if (isTransferConfigured) {
								updateIsChecked('aggregatedVolume', true);
								updateIsChecked('multipleTxn', false);
							}
						}}
						disabled={!isTransferConfigured}
					>
						{props.isChecked.aggregatedVolume ? (
							<i className="bi bi-record-circle-fill text-violet-500"></i>
						) : (
							<i className="bi bi-circle text-muted-foreground"></i>
						)}
						<div className="flex flex-col items-start ms-2">
							<div>Aggregate Volume</div>
							<div className="text-xs text-muted-foreground text-start">
								Sum the value of all qualifying transactions. Useful
								for cumulative goals over multiple actions.
							</div>
						</div>
					</DropdownMenuItem>
				</TooltipWrapper>
				<DropdownMenuSeparator />
				{/* <DropdownMenuSeparator /> */}
				<DropdownMenuItem
					onClick={() => deleteCondition()}
					disabled={!canDelete}
				>
					<i className="bi bi-trash me-2 text-red-500"></i>
					Delete
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default ConditionOptions;
