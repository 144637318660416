import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import React, { useEffect, useRef } from 'react';

const KOLFilters = ({
	search,
	filters,
	setSearch,
	setFilters,
}: {
	search: string;
	filters: {
		language: string[];
		region: string[];
		channels: string[];
		price: string[];
		followers: string[];
	};
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
}) => {
	const { user } = useAuth();
	const previousSearch = useRef(search);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (search.trim() && search !== previousSearch.current) {
				analytics.track(TrackingEvents.SearchTextEntered, {
					userId: user?._id,
					enterpriseId: user?.enterpriseId,
					searchTerm: search,
				});
				previousSearch.current = search;
			}
		}, 1000);

		return () => clearTimeout(timer);
	}, [search, user]);

	const trackingEventMap: Record<string, TrackingEvents> = {
		language: TrackingEvents.LanguageFilterClicked,
		region: TrackingEvents.RegionFilterClicked,
		channels: TrackingEvents.ChannelsFilterClicked,
		followers: TrackingEvents.FollowersFilterClicked,
		price: TrackingEvents.PriceFilterClicked,
	};

	const createTrackingObj = (filterName: string) => ({
		event:
			trackingEventMap[filterName.toLowerCase()] ||
			TrackingEvents.ChannelsFilterClicked,
		params: {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
			filterName,
		},
	});
	return (
		<div className="flex items-center space-x-4">
			<InputText
				value={search}
				setValue={(value: string) => setSearch(value)}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search KOLs"
				className="w-[250px] lg:w-[250px]"
			/>
			<FacetedFilter
				title="Followers"
				options={followersRange}
				selectedValues={filters.followers}
				setSelectedValues={(values: any) =>
					setFilters((prev) => ({ ...prev, followers: values }))
				}
				trackingObj={createTrackingObj('Followers')}
			/>
			<FacetedFilter
				title="Channels"
				options={channelOptions}
				selectedValues={filters.channels}
				setSelectedValues={(values: any) =>
					setFilters((prev) => ({ ...prev, channels: values }))
				}
				trackingObj={createTrackingObj('Channels')}
			/>
		</div>
	);
};

export default KOLFilters;

const languageOptions = [
	{
		value: 'English',
		label: 'English',
		emoji: '🇬🇧',
	},
	{
		value: 'Spanish',
		label: 'Spanish',
		emoji: '🇪🇸',
	},
	{
		value: 'French',
		label: 'French',
		emoji: '🇫🇷',
	},
	{
		value: 'German',
		label: 'German',
		emoji: '🇩🇪',
	},
	{
		value: 'Chinese',
		label: 'Chinese',
		emoji: '🇨🇳',
	},
	{
		value: 'Japanese',
		label: 'Japanese',
		emoji: '🇯🇵',
	},
	{
		value: 'Russian',
		label: 'Russian',
		emoji: '🇷🇺',
	},
	{
		value: 'Arabic',
		label: 'Arabic',
		emoji: '🇸🇦',
	},
	{
		value: 'Portuguese',
		label: 'Portuguese',
		emoji: '🇵🇹',
	},
	{
		value: 'Hindi',
		label: 'Hindi',
		emoji: '🇮🇳',
	},
];

const regionsOptions = [
	{
		value: 'All',
		label: 'All',
		emoji: '🌍',
	},
	{
		value: 'Europe',
		label: 'Europe',
		emoji: '🇪🇺',
	},
	{
		value: 'Asia',
		label: 'Asia',
		emoji: '🌏',
	},
	{
		value: 'Southeast Asia',
		label: 'Southeast Asia',
		emoji: '🌏',
	},
	{
		value: 'South Asia',
		label: 'South Asia',
		emoji: '🌏',
	},
	{
		value: 'Africa',
		label: 'Africa',
		emoji: '🌍',
	},
	{
		value: 'North and Latin America',
		label: 'North and Latin America',
		emoji: '🌎',
	},
	{
		value: 'CIS',
		label: 'CIS',
		emoji: '🇷🇺',
	},
];

const channelOptions = [
	{
		value: 'youtube',
		label: 'YouTube',
		icon: 'youtube',
	},
	{
		value: 'discord',
		label: 'Discord',
		icon: 'discord',
	},
	{
		value: 'twitter',
		label: 'Twitter',
		icon: 'twitter',
	},
	{
		value: 'telegram',
		label: 'Telegram',
		icon: 'telegram',
	},
	{
		value: 'tiktok',
		label: 'TikTok',
		icon: 'tiktok',
	},
	{
		value: 'instagram',
		label: 'Instagram',
		icon: 'instagram',
	},
	{
		value: 'facebook',
		label: 'Facebook',
		icon: 'facebook',
	},
	{
		value: 'snapchat',
		label: 'Snapchat',
		icon: 'snapchat',
	},
	{
		value: 'reddit',
		label: 'Reddit',
		icon: 'reddit',
	},
	{
		value: 'linkedin',
		label: 'LinkedIn',
		icon: 'linkedin',
	},
	{
		value: 'substack',
		label: 'Substack',
		icon: 'substack',
	},
	{
		value: 'twitch',
		label: 'Twitch',
		icon: 'twitch',
	},
];

const followersRange = [
	{ value: 'gt100K', label: 'Above 100K' },
	{ value: '50Kto100K', label: '50K-100K' },
	{ value: '10Kto50K', label: '10K-50K' },
	{ value: 'lt10K', label: 'Below 10K' },
];

const priceOptions = [
	{
		value: '50to100',
		label: '$50 to $100',
	},
	{
		value: '100to500',
		label: '$100 to $500',
	},
	{
		value: '500to1000',
		label: '$500 to $1000',
	},
	{
		value: '1000to2000',
		label: '$1000 to $2000',
	},
	{
		value: 'above2000',
		label: 'Above $2000',
	},
];
