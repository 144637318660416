import InputFile from '@/components/element/inputs/InputFile';
import InputText from '@/components/element/inputs/InputText';
import Spinner from '@/components/element/loading/Spinner';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { IEventDetails } from '@/features/events/types/events.type';
import { useAuth } from '@/hooks/useAuth';
import { uploadFile } from '@/services/utility.service';
import { useEffect, useState } from 'react';

const EventHosts = ({
	projectsIncluded,
	setProjectsIncluded,
}: {
	projectsIncluded: IEventDetails['projectsIncluded'];
	setProjectsIncluded: any;
}) => {
	const [open, setOpen] = useState(false);
	return (
		<div className="mt-10 mb-16">
			<div className="mb-3 flex justify-between items-start">
				<div>
					<div className="text-sm font-medium">
						<i className="bi-people-fill me-2"></i>
						Event Host & Participants
					</div>
					<div className="text-xs text-muted-foreground mt-1">
						Set the participants of the event. If you are organizing the
						event with multiple partners, you can add there details here.
					</div>
				</div>
				<div>
					<Button
						variant="outline"
						onClick={() => {
							setOpen(true);
						}}
						size="sm"
					>
						Add Participant
					</Button>
				</div>
			</div>
			{projectsIncluded?.length > 0 ? (
				<div className="space-y-2">
					{Array.isArray(projectsIncluded) &&
						projectsIncluded.map((i) => <ParticipantItem {...i} />)}
				</div>
			) : null}
			<AddProjectDialog
				open={open}
				setOpen={setOpen}
				addHost={(data) => {
					setProjectsIncluded([...projectsIncluded, data]);
				}}
			/>
		</div>
	);
};

export default EventHosts;

const ParticipantItem = ({ name, logo, keyMetric }) => {
	return (
		<Card className="bg- shadow-none">
			<CardContent className="p-3 px-4">
				<div className="justify-between flex items-center">
					<div className="flex ">
						<Avatar className="size-5 me-2">
							<AvatarImage src={logo} />
						</Avatar>
						<div className="font-medium text-sm ">{name}</div>
					</div>
					<Badge variant="outline" className="bg-slate-200">
						{keyMetric.value}
					</Badge>
				</div>
			</CardContent>
		</Card>
	);
};

const AddProjectDialog = ({ open, setOpen, addHost }) => {
	const [project, setProject] = useState({
		logoFile: null,
		name: '',
		value: '',
	});
	const [errors, setErrors] = useState({
		logo: false,
		name: false,
		value: false,
	});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setErrors({
			logo: false,
			name: false,
			value: false,
		});
	}, [project]);
	useEffect(() => {
		setProject({
			logoFile: null,
			name: '',
			value: '',
		});
	}, [open]);

	const validate = () => {
		let isInvalid = false;
		if (!project.logoFile) {
			setErrors((p) => ({ ...p, logo: true }));
			isInvalid = true;
		}
		if (!project.name) {
			setErrors((p) => ({ ...p, name: true }));
			isInvalid = true;
		}
		if (!project.value) {
			setErrors((p) => ({ ...p, value: true }));
			isInvalid = true;
		}
		return isInvalid;
	};

	const addParticipant = async () => {
		try {
			if (validate()) return;
			setIsLoading(true);
			const fileLink = await uploadFile(project.logoFile);
			addHost({
				name: project.name,
				logo: fileLink,
				keyMetric: {
					label: '',
					value: project.value,
				},
			});

			setIsLoading(false);
			setOpen(false);
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[535px] overflow-visible">
				<DialogHeader className="mb-4">
					<DialogTitle>Add Event Participant</DialogTitle>
				</DialogHeader>
				<div className="space-y-8">
					<InputFile
						label="Project Logo"
						required={true}
						placeholder="Elon Musk"
						value={project.logoFile}
						setValue={(e) =>
							setProject((p) => ({
								...p,
								logoFile: e,
							}))
						}
						error={errors.logo}
						description="Upload a logo. Recommended size is 200x200px"
					/>
					<InputText
						label="Project Name"
						required={true}
						placeholder="Uniswap"
						value={project.name}
						setValue={(e) =>
							setProject((p) => ({
								...p,
								name: e,
							}))
						}
						error={errors.name}
					/>
					<InputText
						label="Tag"
						required={true}
						placeholder="Co-Host"
						value={project.value}
						setValue={(e) =>
							setProject((p) => ({
								...p,
								value: e,
							}))
						}
						error={errors.value}
					/>
				</div>
				<DialogFooter className="justify-between space-x-2 border-t pt-3 mt-4">
					<Button variant="ghost" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button onClick={() => addParticipant()} disabled={isLoading}>
						{isLoading && <Spinner className="me-2" />}
						<span>Add Participant</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
