import { adjustedIntercomLauncherPosition } from '@/lib/utils';
import { useEffect } from 'react';

const useIntercomLauncherPosition = ({
	scrollThresholdPercentage = 10,
}: {
	scrollThresholdPercentage?: number;
} = {}) => {
	useEffect(() => {
		const adjustLauncherPosition = () => {
			const launcher = document.querySelector('.intercom-launcher');
			if (!launcher) return;

			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			const scrollHeight = document.documentElement.scrollHeight;
			const clientHeight = document.documentElement.clientHeight;

			// Calculate the threshold in pixels based on percentage of scrollable height
			const scrollThreshold =
				(scrollHeight - clientHeight) * (scrollThresholdPercentage / 100);

			// console.log(
			// 	`Current Scroll: ${scrollTop}, Threshold: ${scrollThreshold}`,
			// );

			// If scrolled more than the threshold, set to 70px, otherwise set to 20px
			if (scrollTop > scrollThreshold) {
				adjustedIntercomLauncherPosition('70px');
			} else {
				adjustedIntercomLauncherPosition('20px');
			}
		};

		// Add scroll event listener
		window.addEventListener('scroll', adjustLauncherPosition);

		// Initial adjustment
		adjustLauncherPosition();

		return () => {
			// Cleanup scroll event listener
			window.removeEventListener('scroll', adjustLauncherPosition);
		};
	}, [scrollThresholdPercentage]); // Re-run if scrollThresholdPercentage changes
};

export default useIntercomLauncherPosition;
