import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Link, useLocation } from 'react-router-dom';

const NavItem = ({
	icon,
	label,
	href,
	badge,
	options,
	isNew,
}: {
	icon: string;
	label: string;
	href: string;
	badge?: {
		variant: 'default' | 'secondary' | 'destructive' | 'outline';
		text: string;
	};
	options?: {
		icon: string;
		href: string;
		label: string;
		isActive?: boolean;
	}[];
	isNew?: boolean;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();
	const isActive = useMemo(() => {
		let current = location.pathname;
		current =
			current[current.length - 1] === '/' ? current.slice(0, -1) : current;
		if (current !== '/app' && href === '/app') {
			return false;
		} else {
			return current.includes(href);
		}
	}, [location, href]);

	const activeClass = isActive
		? ' bg-secondary text-forground shadow-sm hover:bg-secondary/80 h-8 px-4 py-2 w-full justify-start'
		: ' hover:bg-accent hover:text-accent-foreground h-8 px-4 py-2 w-full justify-start';
	const baseClass =
		' inline-flex  items-center whitespace-nowrap rounded-md text-[13px]  transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50';

	if (!options)
		return (
			<Link to={href} className={cn(activeClass, baseClass)}>
				<i
					className={`bi bi-${icon} me-2 ${
						isActive ? '' : 'text-muted-foreground'
					}`}
				></i>
				{label}
				{isNew ? (
					<div className="ms-auto rounded-xl py-0.5 px-2 flex bg-violet-500 text-white text-[10px] leading-normal font-normal items-end">
						New
					</div>
				) : (
					badge && (
						<div className="ms-auto">
							<Badge variant={badge?.variant}>{badge?.text}</Badge>
						</div>
					)
				)}
				{options?.length && (
					<i className="bi bi-caret-down-fill ms-auto text-muted-foreground" />
				)}
			</Link>
		);
	return (
		<div className="relative">
			<div className="absolute h-full top-0 left-0  mt-5 pb-20 ms-6 ">
				{/* <div className="w-0.5 h-full bg-slate-400 rounded-lg"></div> */}
			</div>
			<div
				className={cn(
					activeClass,
					baseClass,
					'cursor-pointer z-10 relative ',
				)}
				onClick={() => setIsOpen((p) => !p)}
			>
				<i
					className={`bi bi-${icon} me-2 ${
						isActive ? '' : 'text-muted-foreground'
					}`}
				></i>
				{label}
				{badge && (
					<div className="ms-auto">
						<Badge variant={badge?.variant}>{badge?.text}</Badge>
					</div>
				)}
				{options?.length && (
					<i className="bi bi-caret-down-fill ms-auto text-muted-foreground" />
				)}
			</div>
			<AnimateHeight height={isOpen ? 'auto' : 0} duration={300}>
				<div className="ms-5 mt-1">
					{options.map((i) => (
						<Link
							to={i.href}
							className={cn(
								'hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 w-full justify-start',
								baseClass,
							)}
							key={i.label}
						>
							{i.icon && <i className={`${i.icon} me-2`} />}
							{i.label}
						</Link>
					))}
				</div>
			</AnimateHeight>
		</div>
	);
};

export default NavItem;
