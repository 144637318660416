import { Dispatch, SetStateAction, useState } from 'react';
import {
	createLoyaltyCampaign,
	hideLoyaltyProgram,
	updateLoyaltySettings,
} from '../services/loyalty.service';
import { queryClient } from '@/lib/react-query';
import { useAuth } from '@/hooks/useAuth';
import { toast } from 'sonner';
import { handleErrorMessage } from '@/utils/notifications';
import { useConfirm } from '@/hooks/useConfirm';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { EnterpriseApprovalStage } from '@/features/dashboard/types/enterprise.types';

export const useLoyaltyCampaignMutations = ({
	setOpen,
	setShowEnterpriseApproval,
}: {
	setOpen?: any;
	setShowEnterpriseApproval?: Dispatch<SetStateAction<boolean>>;
}) => {
	const [isLaunching, setIsLaunching] = useState(false);
	const [isHiding, setIsHiding] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const { user, updateUser } = useAuth();
	const { show } = useConfirm();

	const { enterprise } = useGetEnterprise();

	const isEnterpriseApproved = enterprise?.isApproved;
	const isApprovalCompleted =
		enterprise?.approvalStage === EnterpriseApprovalStage.Completed;

	const isApprovalRequired = !(isEnterpriseApproved || isApprovalCompleted);

	const launchLoyalty = async () => {
		try {
			if (isApprovalRequired) {
				setShowEnterpriseApproval?.(true);
				return;
			}
			setIsLaunching(true);
			await createLoyaltyCampaign();
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			setIsLaunching(false);
		} catch (err) {
			toast.error(
				err?.response?.data?.message ||
					err?.message ||
					'Failed to setup loyalty campaign',
			);
			setIsLaunching(false);
			console.log(err);
		}
	};

	const toggleLoyaltyHide = async (hide: boolean) => {
		try {
			if (isApprovalRequired) {
				setShowEnterpriseApproval?.(true);
				return;
			}
			if (!hide) {
				const res = await show({
					title: 'Are you sure?',
					subtitle: `This will archive your loyalty campaign and your users won't be able to see tasks and earn points.`,
					confirmText: 'Archive',
					cancelText: 'Cancel',
				});
				if (!res) {
					return;
				}
			}
			setIsHiding(true);
			await hideLoyaltyProgram(hide);
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			setIsHiding(false);
			if (hide) {
				toast.success(
					'Loyalty Un-archived. Continue setting up your Loyalty Program',
				);
			} else {
				toast.success(
					'Loyalty Archived, you can relaunch it anytime you want',
				);
			}
			setOpen?.(false);
		} catch (err) {
			handleErrorMessage(err);
			setIsHiding(false);
			console.log(err);
		}
	};

	const saveLoyaltyCampaignSettings = async (settings: any) => {
		try {
			setIsUpdating(true);
			await updateLoyaltySettings(settings);
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			queryClient.invalidateQueries({
				queryKey: ['user'],
			});
			updateUser({
				...user,
				enterpriseSlug: settings.slug,
			});
			setIsUpdating(false);
			toast.success('Settings updated successfully');
			setOpen?.(false);
		} catch (err) {
			handleErrorMessage(err);
			setIsUpdating(false);
			console.log(err);
		}
	};

	return {
		launchLoyalty,
		isLaunching,
		toggleLoyaltyHide,
		isHiding,
		saveLoyaltyCampaignSettings,
		isUpdating,
	};
};
