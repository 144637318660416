import { convertToTitleCase } from '@/utils/parsers';
import { EligibilityType, RewardStatus } from '../types/reward-store.enums';
import { useCampaigns } from '@/features/campaigns/hooks/report/useCampaigns';
import { useRewardStore } from './useRewardStore';
import { useConfirm } from '@/hooks/useConfirm';

const useRewardCardMutations = ({
	data,
	updateReward,
	setShowCreateNewReward,
	sectionId,
	rewardIndex: index,
	rewardLength: rewardLength,
}) => {
	const { show } = useConfirm();
	const { formFields, setFormFields, moveReward } = useRewardStore();

	const { campaigns } = useCampaigns();

	const openDeleteConfirmation = async (id: string, name: string) => {
		const confirm = await show({
			title: `Are you sure you want to delete this reward?`,
			subtitle:
				'You will not be able to recover this reward once it is deleted.',
			confirmText: 'Delete',
			cancelText: 'Cancel',
		});
		if (!confirm) {
			return;
		}
		updateReward({
			...data,
			_id: id,
			status: RewardStatus.Inactive,
		});
	};
	const rewardMutations = [
		{
			type: 'item',
			label: 'Edit Reward',
			onClick: () => {
				setFormFields({
					...formFields,
					sectionId: sectionId,
					...data,
					editMode: true,
				});
				setShowCreateNewReward(true);
			},
			icon: 'bi-pen me-2',
		},
		{
			type: 'item',
			label: 'Move right',
			onClick: () => moveReward(data?.sectionId, data?._id, 'right'),
			icon: 'bi-arrow-right-circle me-2',
			disabled: index === rewardLength - 1,
		},
		{
			type: 'item',
			label: 'Move left',
			onClick: () => moveReward(data?.sectionId, data?._id, 'left'),
			icon: 'bi-arrow-left-circle me-2',
			disabled: index === 0,
		},
		{
			type: 'separator',
		},
		{
			type: 'item',
			label: 'Delete Reward',
			onClick: () => openDeleteConfirmation(data?._id, data?.name),
			icon: 'bi-trash me-2',
		},
	];

	const campaignsNameJSON = campaigns.reduce((acc, campaign) => {
		acc[campaign._id] = campaign.name;
		return acc;
	}, {});

	const formatTooltipContent = (param) => {
		if (param.eligibilityType === EligibilityType.QuestsCompletion) {
			const campaignNames = param.campaignIds.map(
				(id: string) => campaignsNameJSON[id],
			);
			return campaignNames.map((name) => `• ${name}`).join('\n');
		} else {
			return `${convertToTitleCase(param.eligibilityType)}: ${param.minCount}`;
		}
	};
	return {
		formatTooltipContent,
		campaignsNameJSON,
		rewardMutations,
		openDeleteConfirmation,
	};
};

export default useRewardCardMutations;
