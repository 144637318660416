import FullScreenCreateLayout from '@/components/layout/full-screen-create/FullScreenCreateLayout';
import { links } from '@/config/links';
import { Status } from '@/features/campaigns/types';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useCreateEvent } from '@/features/events/hooks/create/useCreateEvent';
import { useNavigate } from 'react-router-dom';
import Navigation from './EventCreateNavigation';
import Sections from './EventCreateSection';

const EventCreateLayout = ({ children }: any) => {
	const navigate = useNavigate();
	const { details } = useCreateEvent();
	const { enterprise } = useGetEnterprise();
	return (
		<FullScreenCreateLayout showGrid={false}>
			<FullScreenCreateLayout.Header>
				<FullScreenCreateLayout.Title
					name={details?.title}
					placeholder={'Untitled Events'}
					status={Status.Draft}
					lastSaved={new Date()}
					onFeedback={() => {
						window.open(links.feedbackForm, '_blank');
					}}
					onBack={() => navigate('/app/campaign/events')}
					enterpriseCreditsBalance={enterprise?.credits?.balanceCredits}
				/>
				<FullScreenCreateLayout.Sections>
					<Sections />
				</FullScreenCreateLayout.Sections>
			</FullScreenCreateLayout.Header>
			<FullScreenCreateLayout.Content>
				{children}
			</FullScreenCreateLayout.Content>
			<FullScreenCreateLayout.Navigation>
				<Navigation />
			</FullScreenCreateLayout.Navigation>
		</FullScreenCreateLayout>
	);
};

export default EventCreateLayout;
