import { Navigate, Route, Routes } from 'react-router-dom';
import { NFTsProvider } from '../providers/NFTsContractProvider';
import NFTContractPage from './nft-contracts.page';
import YourContractsLayout from '../layout/YourContractsLayout';
import ImportedTokens from '@/features/customise-hub/routes/imported-tokens.page';
import ImportedContracts from '@/features/customise-hub/routes/imported-contracts.page';

const NFTContractRoutes = () => {
	return (
		<NFTContractPage />
		// <YourContractsLayout>
		// 	<Routes>
		// 		<Route path="nfts" element={<NFTContractPage />} />
		// 		<Route path="tracked-tokens" element={<ImportedTokens />} />
		// 		<Route path="tracked-contracts" element={<ImportedContracts />} />
		// 		<Route path="*" element={<Navigate replace to="/" />} />
		// 	</Routes>
		// </YourContractsLayout>
	);
};

export default NFTContractRoutes;
