import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useCampaignLaunch } from '@/features/campaigns/hooks/launch/useCampaignLaunch';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { ICampaignReward } from '@/features/campaigns/types';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useEffect } from 'react';
import DepositTokenDialog from './DepositTokenDialog';
import { LaunchConfirmation } from './LaunchConfirmation';

const CampaignLaunchDialog = ({
	open,
	setOpen,
	campaignId,
	startImmediately,
	tokenReward,
	showRewardInfo,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	campaignId: string;
	startImmediately: boolean;
	tokenReward: ICampaignReward;
	showRewardInfo?: boolean;
}) => {
	const { step, handleNext, isLoading, btnLabel, isCampaignLoading } =
		useCampaignLaunch({
			startImmediately,
			campaignId,
			setOpen,
		});
	const {
		botFilteringMethods,
		setBotFilteringMethods,
		hasPoHTask,
		hasCaptchaTask,
	} = useCampaignReport();

	useEffect(() => {
		if (open) {
			analytics.track(TrackingEvents.LaunchQuestViewed, {
				campaignId,
				isTokenReward: !!tokenReward,
			});
		}
	}, [open]);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[570px] p-7">
				{step === 'confirmation' && (
					<LaunchConfirmation
						showRewardInfo={showRewardInfo}
						botFilteringMethods={botFilteringMethods}
						setBotFilteringMethods={setBotFilteringMethods}
						hasCaptchaTask={hasCaptchaTask}
						hasPoHTask={hasPoHTask}
					/>
				)}

				{step === 'token' && tokenReward && (
					<DepositTokenDialog tokenReward={tokenReward} />
				)}
				<div className="mt-2">
					<Button
						onClick={handleNext}
						disabled={isLoading || isCampaignLoading}
						size="lg"
						className="w-full"
					>
						{isLoading ? <Spinner className="me-2" /> : <></>}
						<span>{btnLabel}</span>
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CampaignLaunchDialog;
