import useQuestOfTheDay from '../hooks/admin-cms-qotd/useQuestOfTheDay';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { DataTable } from '@/components/element/data-table/DataTable';
import { Button } from '@/components/ui/button';
import SelectQuestWithDate from '../components/content-management-system/quest-of-the-day/SelectQuestWithDate';
import useQuestOfTheDayColumns from '../hooks/admin-cms-qotd/useQuestOfTheDayColumns';

const AdminQOTD = () => {
	const {
		questOfTheDay,
		isLoading,
		showQuestModal,
		setShowQuestModal,
		setFormFields,
		formFields,
		resetForm,
		handleQuestAddition,
		isSubmitting,
	} = useQuestOfTheDay({
		setOpen: () => setShowQuestModal(false),
	});

	const { columns } = useQuestOfTheDayColumns({
		setFormFields,
		setOpen: setShowQuestModal,
	});

	if (isLoading) return <FullPageLoading />;
	return (
		<div className="flex justify-center relative items-center">
			<div className="mb-5 w-full relative">
				<h4 className="text-xl font-medium">Manage Quest of the Day</h4>
				<p className="text-muted-foreground text-xs mt-1 mb-8">
					Set the quest of the day to be displayed on the homepage.
				</p>
				<DataTable
					data={questOfTheDay}
					columns={columns}
					totalCount={questOfTheDay?.length || 0}
					isLoading={isLoading}
				/>

				<div className="flex items-center gap-4 my-5">
					<Button onClick={() => setShowQuestModal(true)}>
						<span>+ Add a quest</span>
					</Button>
				</div>

				<SelectQuestWithDate
					open={showQuestModal}
					setOpen={setShowQuestModal}
					formFields={formFields}
					setFormFields={setFormFields}
					resetForm={resetForm}
					handleQuestAddition={handleQuestAddition}
					isSubmitting={isSubmitting}
					questOfTheDay={questOfTheDay}
				/>
			</div>
		</div>
	);
};

export default AdminQOTD;
