import { useState } from 'react';
import {
	getSearchQuestBanners,
	updateWidgetCampaigns,
} from '../../services/cms.service';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { IWidgets } from '../../types/cms.types';

const useSearchQuestBanners = () => {
	const [showCampaignList, setShowCampaignList] = useState(false);
	const [details, setDetails] = useState<IWidgets>({
		_id: '',
		name: '',
		resourceIds: [],
		campaign: [
			{
				id: '',
				name: '',
				widgetId: '',
				banner: '',
			},
		],
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const saveSearchQuests = async () => {
		setIsSubmitting(true);
		const updatedCampaignIds = details?.campaign?.map((item) => item.id);

		setDetails((prev) => ({
			...prev,
			resourceIds: updatedCampaignIds,
		}));

		try {
			await updateWidgetCampaigns(details._id, updatedCampaignIds);

			await queryClient.invalidateQueries({
				queryKey: ['cms-search-quest'],
				refetchType: 'active',
			});
		} catch (error) {
			handleErrorMessage(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return {
		showCampaignList,
		setShowCampaignList,
		details,
		setDetails,
		isSubmitting,
		saveSearchQuests,
	};
};

export default useSearchQuestBanners;
