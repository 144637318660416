import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';
import { EnterpriseReferralRewardTypes } from '../types/customize-hub.enum';
import { Button } from '@/components/ui/button';
import { useMemo, useState } from 'react';
import { Separator } from '@/components/ui/separator';
import { useCommunityReferral } from '../hooks/useCommunityReferral';
import CriteriaItem from './CriteriaItem';
import { cn } from '@/lib/utils';
import useCreateReferralProgram from '../hooks/useCreateReferralProgram';

const CreateCommunityReferralDialog = ({
	formFields,
	setFormFields,
}: {
	formFields: any;
	setFormFields: (formFields: any) => void;
}) => {
	const [reward, setReward] = useState({
		minXp: 10,
		maxXp: 100,
	});
	const options = useMemo(() => {
		if (reward.minXp && reward.maxXp) {
			const length = Math.ceil((reward.maxXp - reward.minXp + 1) / 5);
			return Array.from({ length }, (_, i) => {
				const value = i * 5 + reward.minXp;
				return { value, label: value };
			});
		}
		return [];
	}, [reward.maxXp, reward.minXp]);

	const {
		items,
		setItems,
		formErrors,
		setFormErrors,
		successfulReferralItems,
		setSuccessfulReferralItems,
		loading,
		setLoading,
		resetForm,
	} = useCommunityReferral();

	const {
		addCriteria,
		addSuccessfulReferralCriteria,
		handleRewardChange,
		launchReferralProgram,
		isPending,
	} = useCreateReferralProgram({
		items,
		setItems,
		formFields,
		setFormFields,
		successfulReferralItems,
		setSuccessfulReferralItems,
		setFormErrors,
	});

	return (
		<div className="mt-5">
			<div className="my-10 flex gap-16">
				<p className="text-sm font-medium w-[22rem]">
					Referrer Eligibility Criteria
				</p>
				<div className="flex flex-col w-full ">
					<div className="overflow-y-auto max-h-[11rem] mb-1">
						{formFields?.referrerEligibilityConditions?.length > 0 ? (
							formFields?.referrerEligibilityConditions?.map(
								(_item: any, index: number) => (
									<>
										<CriteriaItem
											key={index}
											index={index}
											attribute="referrerEligibilityConditions"
											formFields={formFields}
											setFormFields={setFormFields}
											formErrors={formErrors}
											setFormErrors={setFormErrors}
											setItems={setItems}
										/>
										{formErrors[
											formFields
												?.referrerEligibilityConditions?.[
												index
											]?.condition
										] ? (
											<p className="mb-0 pt-1 text-right text-xs text-destructive">
												{
													formErrors[
														formFields
															?.referrerEligibilityConditions?.[
															index
														]?.condition
													]
												}
											</p>
										) : null}
									</>
								),
							)
						) : (
							<div className=" text-sm text-muted-foreground py-2 border-l pl-3 border-slate-300">
								No eligibility criteria specified. Referrals are open
								to everyone.
							</div>
						)}
					</div>
					{items < 4 &&
					formFields?.referrerEligibilityConditions?.length < 4 ? (
						<div className="flex justify-end gap-1">
							<Button
								className="mt-3 "
								variant="secondary"
								size="sm"
								onClick={() => addCriteria()}
							>
								<i className="bi-plus-circle me-2"></i>Add Referrer
								Criteria
							</Button>
						</div>
					) : null}
				</div>
			</div>
			<div className="my-10 flex gap-16">
				<p className="text-sm font-medium w-[22rem]">
					Conditions For Successful Referral
				</p>
				<div className="flex flex-col w-full ">
					<div className="overflow-y-auto max-h-[11rem] mb-1">
						{formFields?.referredUserEligibilityConditions?.length >
						0 ? (
							formFields?.referredUserEligibilityConditions?.map(
								(_item: any, index: number) => (
									<>
										<CriteriaItem
											key={index}
											index={index}
											attribute="referredUserEligibilityConditions"
											formFields={formFields}
											setFormFields={setFormFields}
											formErrors={formErrors}
											setFormErrors={setFormErrors}
											setItems={setSuccessfulReferralItems}
										/>
										{formErrors[
											formFields
												?.referredUserEligibilityConditions?.[
												index
											]?.condition
										] ? (
											<p className="mb-0 pt-1 text-right text-xs text-destructive">
												{
													formErrors[
														formFields
															?.referredUserEligibilityConditions?.[
															index
														]?.condition
													]
												}
											</p>
										) : null}
									</>
								),
							)
						) : (
							<div className=" text-sm text-muted-foreground py-2 border-l pl-3 border-slate-300">
								No eligibility criteria specified. Successful
								referrals require specific conditions to be met.
							</div>
						)}
					</div>
					{successfulReferralItems < 3 &&
					formFields?.referredUserEligibilityConditions?.length < 3 ? (
						<div className="flex justify-end gap-1">
							<Button
								className="mt-3 "
								variant="secondary"
								size="sm"
								onClick={() => addSuccessfulReferralCriteria()}
							>
								<i className="bi-plus-circle me-2"></i>Add Condition
							</Button>
						</div>
					) : null}
				</div>
			</div>
			<div className="my-10 flex gap-16">
				<p className="text-sm font-medium w-[22rem]">Reward Type</p>
				<div className="flex gap-2 py-1 px-0.5 w-full">
					<div className="w-[50%]">
						<Select value={EnterpriseReferralRewardTypes?.Xp}>
							<SelectTrigger>
								<SelectValue>
									{EnterpriseReferralRewardTypes?.Xp}
								</SelectValue>
							</SelectTrigger>
						</Select>
					</div>
					<div className="w-[50%]">
						<Select
							value={formFields?.rewards?.[0]?.value?.toString()}
							onValueChange={(newVal) => handleRewardChange(newVal)}
						>
							<SelectTrigger>
								<SelectValue />
							</SelectTrigger>
							<SelectContent>
								{options.map((i) => (
									<SelectItem
										key={i.value}
										value={i.value?.toString()}
									>
										{i.label} XP
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
				</div>
			</div>
			<Separator />
			<div className="mt-4">
				<Button
					variant="secondary"
					onClick={() => {
						resetForm();
					}}
				>
					Cancel
				</Button>
				<Button
					className={cn(
						'ms-2',
						isPending ? 'cursor-not-allowed opacity-70' : '',
					)}
					onClick={() => {
						launchReferralProgram();
						resetForm();
					}}
					disabled={isPending}
				>
					{isPending ? (
						<i className="bi-arrow-repeat animate-spin me-2"></i>
					) : (
						<i className="bi-rocket-takeoff-fill me-2"></i>
					)}
					Launch
				</Button>
			</div>
		</div>
	);
};

export default CreateCommunityReferralDialog;
