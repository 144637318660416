import ApiDetailsV2 from '@/features/task-apis/components/ApiDetailsV2';
import CurlParser from '@/features/task-apis/components/CurlParser';
import PayloadDetails from '@/features/task-apis/components/PayloadDetails';
import { memo } from 'react';
import { CreateTaskAPISteps } from '../../types/task-apis.enum';

// Memoize the components
const MemoizedCurlParser = memo(CurlParser);
const MemoizedApiDetails = memo(ApiDetailsV2);
const MemoizedPayloadDetails = memo(PayloadDetails);

interface StepProps {
	formFields: any;
	setFormFields: (fields: any) => void;
	formErrors: any;
	setFormErrors: (errors: any) => void;
	parsedData: any;
	setParsedData: (data: any) => void;
}

export const GetStepsConfig = (props: StepProps) => [
	{
		key: CreateTaskAPISteps.Basic,
		component: <MemoizedApiDetails />,
	},
	{
		key: CreateTaskAPISteps.Curl,
		component: <MemoizedCurlParser {...props} />,
	},
	{
		key: CreateTaskAPISteps.Payload,
		component: <MemoizedPayloadDetails />,
	},
];
