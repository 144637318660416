import { useMemo, useState } from 'react';
import { getAdminTokens } from '../services/admin.service';
import { useQuery } from '@tanstack/react-query';
import { ITokens } from '@/features/campaigns/hooks/tokens/useGetAllTokens';
import { useDebounce } from '@/hooks/useDebounce';

export const useGetAdminCustomTokens = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		approvalStatus: [],
		onboardingStatus: [],
	});
	const debounceSearch = useDebounce(search, 500);

	const { data, isLoading, refetch } = useQuery<ITokens[]>({
		queryKey: [
			'admin',
			'tokens',
			// {
			// 	filters,
			// 	debounceSearch,
			// },
		],
		queryFn: () =>
			getAdminTokens({
				// search: debounceSearch,
			}),
	});

	const tokens = useMemo(() => {
		if (!data) return [];
		if (search) {
			return data.filter((i) => {
				const isNameMatched = i.name
					?.toLowerCase()
					?.includes(search.toLowerCase());
				const isAddressMatched = i?.address
					?.toLowerCase()
					?.includes(search.toLowerCase());
				const isSymbolMatched = i?.symbol
					?.toLowerCase()
					?.includes(search.toLowerCase());
				const isEnterpiseMatched = i?.enterpriseId?.name
					?.toLowerCase()
					?.includes(search.toLowerCase());
				const isEnterpriseIdMatched = i?.enterpriseId?._id
					?.toLowerCase()
					?.includes(search.toLowerCase());

				return (
					isNameMatched ||
					isAddressMatched ||
					isSymbolMatched ||
					isEnterpiseMatched ||
					isEnterpriseIdMatched
				);
			});
		}
		return data;
	}, [data, search]);

	return {
		search,
		setSearch,
		filters,
		setFilters,
		results: tokens,
		isLoading: isLoading,
		refetch,
	};
};
