import { defineChain } from 'viem';

export const soneiumTestnet = defineChain({
	id: 1946,
	name: 'Minato',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc.minato.soneium.org/'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Soneium Testnet Explorer',
			url: 'https://explorer-testnet.soneium.org/',
		},
	},
	testnet: true,
});
