import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { ColumnDef } from '@tanstack/react-table';
import { Grip } from 'lucide-react';
import { IWidgets } from '../../types/cms.types';
import { updateWidgetCampaigns } from '../../services/cms.service';
import { useCreateWidget } from './useCreateWidget';
import { useConfirm } from '@/hooks/useConfirm';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';

const useWidgetColumns = () => {
	const { details, setDetails } = useCreateWidget();

	const { show } = useConfirm();

	const openDeleteConfirmation = async (id: string) => {
		const confirm = await show({
			title: 'Delete this quest',
			subtitle: `Are you sure you want to delete quest from this widget?`,
			confirmText: 'Delete',
			cancelText: 'Cancel',
		});
		if (!confirm) {
			return;
		}

		deleteQuestFromWidget(id);
	};

	const deleteQuestFromWidget = async (id: string) => {
		const updatedData = details?.campaign?.filter(
			(campaign) => campaign.id !== id,
		);

		const updatedCampaignIds = updatedData.map((item) => item.id);

		setDetails((prev) => ({
			...prev,
			resourceIds: updatedCampaignIds,
			campaign: updatedData,
		}));
		try {
			await updateWidgetCampaigns(details?._id, updatedCampaignIds);

			await queryClient.invalidateQueries({
				queryKey: ['cms-widget-campaigns', details?._id],
			});
		} catch (error) {
			handleErrorMessage(error);
		}
	};
	const columns: ColumnDef<IWidgets['campaign']>[] = [
		{
			id: 'drag',
			cell: () => (
				<div className="cursor-move">
					<Grip className="size-4" />
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'id',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Quest Id" />
			),
			cell: ({ row }) => (
				<div className="max-w-[200px] py-1 truncate">
					{row.getValue('id')}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Quest Name" />
			),
			cell: ({ row }) => (
				<TooltipWrapper tooltip={row.getValue('name')}>
					<div className="flex items-center py-1 max-w-[450px] truncate z-[2]">
						{row.getValue('name')}
					</div>
				</TooltipWrapper>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => (
				<div className="flex items-center">
					<TooltipWrapper
						tooltip="Remove this quest of widget"
						align="end"
					>
						<Button
							variant="ghost"
							size="icon"
							onClick={(e) => {
								e.stopPropagation();
								openDeleteConfirmation(row.getValue('id'));
							}}
						>
							<i className="bi bi-trash3"></i>
						</Button>
					</TooltipWrapper>
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
	];

	return {
		columns,
	};
};

export default useWidgetColumns;
