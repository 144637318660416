import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Separator } from '@/components/ui/separator';
import ChainBadge from '../badges/ChainBadge';

interface Network {
	name: string;
	value: number;
	chainId: number;
	img: string;
	type: string;
}

export function SelectNetwork2({
	value,
	setValue,
	error,
	errorMsg,
	allowedChainIds,
	placeholder,
	defaultAll = true,
	withoutPortal,
	disabled,
	showNonEVM,
	showAddCustomChain,
	setShowAddCustomChain,
	fetchEnterpriseChains,
	addCustomChain,
}: {
	value: number | number[];
	setValue: (network: Network) => void;
	allowedChainIds?: number[];
	error?: boolean | string;
	errorMsg?: string;
	placeholder?: string;
	defaultAll?: boolean;
	withoutPortal?: boolean;
	disabled?: boolean;
	showNonEVM?: boolean;
	showAddCustomChain?: boolean;
	setShowAddCustomChain?: Dispatch<SetStateAction<boolean>>;
	fetchEnterpriseChains?: boolean;
	addCustomChain?: boolean;
}) {
	const [searchTerm, setSearchTerm] = useState('');
	const [open, setOpen] = useState(false);

	const { chains, getNativeChainLogo, getChainName } = useGetSupportedChains({
		showAdditionalChains: false,
		fetchEnterpriseChains: fetchEnterpriseChains,
		showNonEVM: showNonEVM,
	});

	const networks = useMemo(() => {
		if (!chains) return [];
		return [
			{
				title: 'EVM Chains',
				networks: chains
					.filter((chain) => !chain.isTestnet && !chain.isCustom)
					?.map((i) => ({
						name: i.chainName,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.chainLogo,
						type: 'EVM',
					})),
			},
			...(showNonEVM
				? [
						{
							title: 'Non EVM Networks',
							networks: nonEVMChains?.map((i) => ({
								name: i.name,
								value: i.chainId as number,
								chainId: i.chainId as number,
								img: i.logo,
								type: 'NON_EVM',
							})),
						},
					]
				: []),
			{
				title: 'Custom Enterprise Chains',
				networks: chains
					.filter((chain) => chain.isCustom && !chain.isTestnet)
					?.map((i) => ({
						name: i.chainName,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.chainLogo,
						type: 'CUSTOM',
					})),
			},
			{
				title: 'EVM Testnets',
				networks: chains
					.filter((chain) => chain.isTestnet)
					?.map((i) => ({
						name: i.chainName,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.chainLogo,
						type: 'EVM_TESTNET',
					})),
			},
		]
			.map((network) => {
				if (!allowedChainIds || (allowedChainIds.length === 0 && defaultAll))
					return network;
				return {
					...network,
					networks: network.networks.filter((net: any) =>
						allowedChainIds.includes(net.chainId),
					),
				};
			})
			.filter((network) => network.networks.length > 0);
	}, [chains, allowedChainIds, defaultAll, showNonEVM]);

	const [filteredNetworks, setFilteredNetworks] = useState(networks);

	useEffect(() => {
		const filtered = networks.map((network) => ({
			...network,
			networks: network.networks.filter((net) => {
				return net.name
					.toLowerCase()
					.includes(searchTerm.trim().toLowerCase());
			}),
		}));
		setFilteredNetworks(filtered);
	}, [searchTerm, networks]);

	useEffect(() => {}, [filteredNetworks]);

	const selectedValues = Array.isArray(value) ? value : [value];

	const renderChains = () => {
		return (
			<>
				{networks?.map((network, index) => (
					<CommandGroup key={network.title}>
						<CommandItem
							className={`!bg-transparent hover:!bg-transparent text-xs font-medium pl-2 my-2 `}
						>
							{network.title}
						</CommandItem>
						{network?.networks?.map((net) => (
							<CommandItem
								key={net.value}
								// value={net.chainId.toString()}
								onSelect={() => {
									setValue(net);
									setOpen(false);
								}}
								className="cursor-pointer"
							>
								<div className="flex py-1 ">
									<img
										src={net.img}
										alt=""
										className="size-5 me-2 rounded-lg object-cover"
									/>
									{net.name}
								</div>
							</CommandItem>
						))}
					</CommandGroup>
				))}
			</>
		);
	};

	return (
		<>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						className="w-full justify-between px-3 hover:!bg-white"
						disabled={disabled}
						onClick={() => setOpen(!open)}
					>
						<ChainBadge
							chainId={value as number}
							showNonEVM={showNonEVM}
							fetchEnterpriseChains={fetchEnterpriseChains}
							className="max-w-[10rem] truncate"
						/>
					</Button>
				</PopoverTrigger>
				<PopoverContent
					className="p-0"
					align="start"
					withoutPortal={withoutPortal}
				>
					<Command>
						<CommandInput
							placeholder={placeholder || 'Select Chains'}
							className="border-none focus:ring-0 focus:outline-none"
							// onValueChange={(val) => setSearchTerm(val)}
						/>
						<CommandList>
							<CommandEmpty className="py-2 px-4 text-sm text-center">
								No such chain found
							</CommandEmpty>
							{renderChains()}
							{addCustomChain ? (
								<>
									<Separator />
									<Button
										variant="ghost"
										className="w-full text-sm font-normal"
										onClick={() => setShowAddCustomChain(true)}
									>
										<i className="bi bi-plus-circle me-2"></i>{' '}
										Add Custom Chain
									</Button>
								</>
							) : null}
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
			{error ? (
				<p className="text-destructive text-xs mt-1">{errorMsg || error}</p>
			) : null}
		</>
	);
}

const nonEVMChains = [
	{
		name: 'Solana',
		logo: 'https://upload.wikimedia.org/wikipedia/en/b/b9/Solana_logo.png',
		chainId: -10001,
	},
	{
		name: 'Bitcoin',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_bitcoin?w=48&h=48',
		chainId: -10002,
	},
	{
		name: 'Sui',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_sui?w=48&h=48',
		chainId: -10003,
	},
	{
		// sui, aptos, sei
		name: 'Hyperliquid',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_hyperliquid?w=48&h=48',
		chainId: -10004,
	},
	{
		name: 'Aptos',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_aptos?w=48&h=48',
		chainId: -10006,
	},
	{
		name: 'Starknet',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_starknet?w=48&h=48',
		chainId: -10008,
	},
	{
		name: 'Injective',
		logo: 'https://icons.llamao.fi/icons/chains/rsz_injective?w=48&h=48',
		chainId: -10009,
	},
	{
		name: 'Cosmos',
		logo: 'https://oss.web3ite.io/logo/b1d031475299801e979dba921db0206c.jpg?x-oss-process=image/resize,w_800/quality,q_80/format,webp',
		chainId: -10020,
	},
];
