import { defineChain } from 'viem';

export const neonMainnet = defineChain({
	id: 245022934,
	name: 'Neon EVM MainNet',
	nativeCurrency: { name: 'NEON', symbol: 'NEON', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://neon-mainnet.everstake.one'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Neonscan',
			url: 'https://neonscan.org',
		},
	},
	testnet: false,
});
