export enum EnterpriseReferralProgramStatus {
	Draft = 'DRAFT',
	Active = 'ACTIVE',
	Deleted = 'DELETED',
}
export enum EnterpriseReferralEligibilityConditions {
	MinXpNeeded = 'MIN_XP_NEEDED',
	MinLoyaltyTaskCompleted = 'MIN_LOYALTY_TASK_COMPLETED',
	MinNumCampaignsCompleted = 'MIN_CAMPAIGNS_COMPLETED',
	SpecificCampaignsCompleted = 'SPECIFIC_CAMPAIGNS_COMPLETED',
}
export enum EnterpriseReferralRewardTypes {
	Xp = 'XP',
}

export const EligibilityCriteria = [
	{
		value: EnterpriseReferralEligibilityConditions.MinXpNeeded,
		label: 'Minimum XP needed',
	},
	{
		value: EnterpriseReferralEligibilityConditions.MinLoyaltyTaskCompleted,
		label: 'Minimum loyalty tasks completed',
	},
	{
		value: EnterpriseReferralEligibilityConditions.MinNumCampaignsCompleted,
		label: 'Minimum number of campaigns completed',
	},
	{
		value: EnterpriseReferralEligibilityConditions.SpecificCampaignsCompleted,
		label: 'Specific campaigns completed',
	},
];

export const successfulReferralConditions = [
	{
		value: EnterpriseReferralEligibilityConditions.MinLoyaltyTaskCompleted,
		label: 'Minimum loyalty tasks completed',
	},
	{
		value: EnterpriseReferralEligibilityConditions.MinNumCampaignsCompleted,
		label: 'Minimum number of campaigns completed',
	},
	{
		value: EnterpriseReferralEligibilityConditions.SpecificCampaignsCompleted,
		label: 'Specific campaigns completed',
	},
];
