import { Checkbox } from '@/components/ui/checkbox';
import { useConnectAPI } from '../hooks/useConnectAPI';

const ApiMeta = () => {
	const { formFields, setFormFields, setIsDirty } = useConnectAPI();
	return (
		<div className="">
			<div className="flex flex-row items-start space-x-3 mt-8">
				<Checkbox
					className="mt-1"
					checked={
						formFields?.isMetricBasedTaskEnabled &&
						!formFields?.isRecurringTasksEnabled
					}
					onCheckedChange={(checked) => {
						setFormFields((prev: any) => ({
							...prev,
							isMetricBasedTaskEnabled: !!checked,
							isRecurringTasksEnabled: false,
							apiOutputExpressions: {
								...prev.apiOutputExpressions,
								metric: {
									expression: checked
										? 'return response?.data?.metric'
										: null,
								},
								metricDataType: {
									expression: checked
										? 'return response?.data?.metricDataType'
										: null,
								},
							},
						}));
						setIsDirty(true);
					}}
				/>
				<div className="space-y-1 leading-none">
					<div className="text-sm font-medium ">
						Does your API return value of the action?
					</div>
					<div className="text-xs leading-[1.8]">
						You can configure{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono font-semibold">
							Dynamic XPs
						</code>{' '}
						for tasks based on impact of the user action. For this API
						needs to send{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono font-semibold">
							value
						</code>{' '}
						.
					</div>
				</div>
			</div>
			<div className="flex flex-row items-start space-x-3 mt-4">
				<Checkbox
					className="mt-1"
					checked={
						formFields?.isRecurringTasksEnabled &&
						!formFields?.isMetricBasedTaskEnabled
					}
					onCheckedChange={(checked) => {
						setFormFields((prev: any) => ({
							...prev,
							isRecurringTasksEnabled: !!checked,
							isMetricBasedTaskEnabled: false,
						}));
						setIsDirty(true);
					}}
				/>
				<div className="space-y-1 leading-none">
					<div className="text-sm font-medium">
						Does your API support verification for recurring tasks?
					</div>

					<div className="text-xs leading-[1.8]">
						For{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
							recurring tasks
						</code>
						, we will send{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
							startTimestamp
						</code>{' '}
						and{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
							endTimestamp
						</code>{' '}
						to verify user action during the period.
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApiMeta;
