import { Button } from '@/components/plate-ui/button';
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';
import {
	RewardTokenOptions,
	RewardType,
	RewardTypeOptions,
} from '../../types/admin-user-affiliation.enums';
import { Input } from '@/components/ui/input';

const AffiliateRewardTypes = ({
	formFields,
	attribute,
	index,
	formErrors,
	removeReward,
	handleChange,
	handleRewardChange,
	setRewardMetadata,
}) => {
	return (
		<div className="flex gap-2 py-1 px-0.5">
			<div
				className={`flex items-center flex-grow space-x-2 sm:space-x-0 min-w-[14rem] max-w-[14rem]`}
			>
				<Select
					defaultValue={formFields?.[attribute]?.[index]?.rewardType}
					onValueChange={(newVal) => handleRewardChange(newVal, index)}
					value={formFields?.[attribute]?.[index]?.rewardType}
				>
					<SelectTrigger className="text-sm">
						<SelectValue placeholder="Select" className="h-10 " />
					</SelectTrigger>
					<SelectContent>
						{RewardTypeOptions?.map((item) => (
							<SelectItem key={item.value} value={item.value}>
								{item.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>
			<div className="flex flex-grow items-center gap-2 ">
				<Input
					className={`h-9 ${
						formErrors[formFields?.[attribute]?.[index]?.rewardType]
							? 'border-destructive'
							: ''
					}`}
					onFocus={(e) => {
						e.target.select();
					}}
					onChange={(e) => handleChange(e.target.value, index)}
					value={formFields?.[attribute]?.[index]?.rewardAmount}
				/>
			</div>

			<div className="flex items-center justify-end">
				<Button
					variant="outline"
					size="sm"
					className="w-9 h-9"
					onClick={() =>
						removeReward(formFields?.[attribute]?.[index]?.rewardType)
					}
				>
					<i className="bi bi-x-lg"></i>
				</Button>
			</div>
		</div>
	);
};

export default AffiliateRewardTypes;
