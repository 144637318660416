import { useAuth } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import analytics from '@/lib/analytics';
import { getUtmUtil, removeUtmParams } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { loginWithDiscord, loginWithGoogle } from '../services/auth.service';

interface IQuery {
	[x: string]: string | (string | null)[] | null | undefined;
}

export const useOAuthLogin = (data: IQuery) => {
	const navigate = useNavigate();
	const { refetchUser } = useAuth();

	const connectDiscord = async () => {
		try {
			if (!data) return;

			if (data.error && data.error === 'access_denied') {
				navigate('/');
			}
			if (!data.code) return;
			const params = new URLSearchParams();
			params.append('code', data.code.toString());
			params.append(
				'referralCode',
				(data.referralCode as string) ||
					localStorage.getItem('referral-code') ||
					'',
			);

			// Add UTM params
			const utmParams = await getUtmUtil();
			Object.entries(utmParams).forEach(([key, value]) => {
				params.append(key, value as string);
			});

			const { isNew, account } = await loginWithDiscord(params);
			if (isNew) {
				analytics.track(TrackingEvents.Signup, {
					loginMethod: 'Discord',
					email: data.email,
					userId: account._id,
				});
				analytics.identify(account._id, {
					email: account.email,
				});
			} else {
				analytics.track(TrackingEvents.Login, {
					loginMethod: 'Discord',
					email: data.email,
					userId: account._id,
				});
				analytics.identify(account._id, {
					email: account.email,
					name: account.name,
					enterpriseId: account?.enterpriseId,
					enterpriseName: account?.enterpriseName,
				});
			}
			await refetchUser();

			removeUtmParams();

			navigate('/app/home');
		} catch (err) {
			navigate('/');
		}
	};

	const connectGoogle = async () => {
		try {
			if (!data) return;

			if (data.error && data.error === 'access_denied') {
				navigate('/');
			}
			if (!data.code) return;
			const params = new URLSearchParams();
			params.append('code', data.code.toString());
			params.append(
				'referralCode',
				(data.referralCode as string) ||
					localStorage.getItem('referral-code') ||
					'',
			);

			// Add UTM params
			const utmParams = await getUtmUtil();
			Object.entries(utmParams).forEach(([key, value]) => {
				params.append(key, value as string);
			});

			const { isNew, account } = await loginWithGoogle(params);
			if (isNew) {
				analytics.track(TrackingEvents.Signup, {
					loginMethod: 'Google',
					email: data.email || account.email,
					userId: account._id,
				});
				analytics.identify(account._id, {
					email: account.email,
				});
			} else {
				analytics.track(TrackingEvents.Login, {
					loginMethod: 'Google',
					email: data.email || account.email,
					userId: account._id,
				});
				analytics.identify(account._id, {
					email: account?.email,
					name: account?.name,
					enterpriseId: account?.enterpriseId,
					enterpriseName: account?.enterpriseName,
				});
			}
			await refetchUser();

			removeUtmParams();
			navigate('/app/home');
		} catch (err) {
			console.log(err);
			navigate('/');
		}
	};

	return {
		connectDiscord,
		connectGoogle,
	};
};
