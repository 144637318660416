import { memo } from 'react';
import en from '../../../../locales/en.json';
import { useConnectAPI } from '../../hooks/useConnectAPI';
import { CreateTaskAPISteps } from '../../types/task-apis.enum';
import VerifyCard from './VerifyCard';
import { GetVerifyStepsConfig } from './VerifyStepsConfig';

const VerifySection = () => {
	const { step, setStep, formFields } = useConnectAPI();
	const { taskApiIntegration } = en;

	const stepsConfig = GetVerifyStepsConfig({
		setStep: (step: CreateTaskAPISteps) => setStep(step),
		showEvaluateResponse:
			!formFields.isApiSchemaValid && formFields.isApiTestDone,
	});

	return (
		<div className="space-y-5">
			{stepsConfig.map(({ key, component }) => (
				<VerifyCard
					key={key}
					stepKey={key}
					isActive={step === key}
					title={taskApiIntegration[key.toLowerCase()]?.title}
					onStepClick={(key: string) => setStep(key as any)}
				>
					{component}
				</VerifyCard>
			))}
		</div>
	);
};

const MemoizedVerifySection = memo(VerifySection);
export default MemoizedVerifySection;
