import { Skeleton } from '@/components/ui/skeleton';
import { getFileType } from '@/utils/parsers';
import * as React from 'react';

export interface MediaProps
	extends React.HTMLAttributes<HTMLImageElement | HTMLVideoElement> {
	src: string;
	lazyLoading?: boolean;
	controls?: boolean;
	imagePosition?: {
		x: number;
		y: number;
	};
	altText?: string;
}

const placeholderImg =
	'https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png';

const Media = React.forwardRef<HTMLImageElement | HTMLVideoElement, MediaProps>(
	(
		{
			className,
			lazyLoading,
			imagePosition,
			controls = false,
			src,
			altText,
			...props
		},
		ref,
	) => {
		const id = React.useId();
		const [isLoading, setIsLoading] = React.useState(true);
		const [hasError, setHasError] = React.useState(false);

		const mediaType = React.useMemo(() => getFileType(src), [src]);

		// Handlers for image/video loading and errors
		const handleMediaLoad = () => {
			setIsLoading(false);
			setHasError(false); // Ensure to reset error state on successful load
		};

		const handleMediaError = () => {
			console.log('error');
			setIsLoading(false);
			setHasError(true);
		};

		// Convert video source to thumbnail URL
		const videoThumbnail =
			mediaType === 'video' ? src.replace(/\.\w+$/, '.png') : '';

		let content: React.ReactNode = null;

		if (hasError) {
			content = (
				<img
					src={placeholderImg}
					className={className}
					alt={altText || 'placeholder'}
					{...props}
				/>
			);
		} else if (isLoading) {
			content = <Skeleton className={`${className} skeleton`} />;
		} else if (mediaType === 'image' || mediaType === 'gif') {
			content = (
				<img
					src={src}
					className={className}
					onLoad={handleMediaLoad}
					onError={handleMediaError}
					loading={lazyLoading ? 'lazy' : 'eager'}
					{...props}
					style={
						imagePosition
							? {
									objectFit: 'cover',
									objectPosition: `${imagePosition?.x}% ${imagePosition?.y}%`,
								}
							: {}
					}
				/>
			);
		} else if (mediaType === 'video') {
			content = (
				<>
					{isLoading && (
						<div className={`${className} skeleton`}>Loading...</div>
					)}
					<img
						src={videoThumbnail}
						className={className}
						style={{
							display: isLoading || lazyLoading ? 'block' : 'none',
						}}
						loading={lazyLoading ? 'lazy' : 'eager'}
						{...props}
					/>
					{!lazyLoading && (
						<video
							className={className}
							controls={controls}
							style={{ display: !isLoading ? 'block' : 'none' }}
							onLoadedData={handleMediaLoad}
							// onError={handleMediaError}
							id={id}
							{...props}
						>
							<source src={src} type="video/mp4" />
						</video>
					)}
				</>
			);
		}

		React.useEffect(() => {
			if (mediaType === 'video' && isLoading) {
				const img = new Image();
				img.src = videoThumbnail;
				img.onload = handleMediaLoad;
				img.onerror = handleMediaError;
			} else if ((mediaType === 'image' || mediaType === 'gif') && isLoading) {
				const img = new Image();
				img.src = src;
				img.onload = handleMediaLoad;
				img.onerror = handleMediaError;
			} else if (mediaType === 'unknown' && isLoading) {
				handleMediaError();
			}
		}, [mediaType, videoThumbnail, isLoading, src]);

		return <>{content}</>;
	},
);

export { Media };
