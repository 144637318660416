import LandingContainer from '../ui/LandingContainer';
import { aboutPage } from '../../content/about.content';

const IntractMission = () => {
	return (
		<LandingContainer className="">
			<div className=" bg-zinc-900 rounded-xl px-14 pt-14 md:pt-16 lg:pt-32 pb-14 md:pb-50 lg:pb-32">
				<div className="flex w-full flex-col">
					<div className="text-card items-center text-center justify-center md:items-center md:text-center md:justify-center">
						<div className="flex flex-col items-center text-center justify-center md:items-center md:text-center md:justify-center">
							<div className="flex flex-col space-y-4 md:space-y-7 items-center text-center justify-center md:items-center md:text-center md:justify-center">
								<div className="relative flex items-center gap-3">
									<div className="rounded-full border px-3 pb-1.5 pt-[0.5rem] text-center mx-auto w-fit mb-2 font-alliance text-[10px] font-normal uppercase leading-none tracking-[1px] self-center text-white border-white/10 bg-white/5">
										<h2 className="text-sm w-full transition-colors text-zinc-100">
											{aboutPage.mission.title}
										</h2>
									</div>
								</div>
								<div className="relative">
									<h2 className="w-full transition-colors text-4xl md:max-w-3xl text-white">
										{aboutPage.mission.description}
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default IntractMission;
