import useForgotPassword from '../hooks/useForgotPassword';
import { Button } from '@/components/ui/button';
import InputText from '@/components/element/inputs/InputText';
import { useEffect } from 'react';
import AuthLayout2 from '@/components/layout/auth/AuthLayout2';
import logo from '@/assets/logo.svg';

const ForgotPasswordPage = () => {
	const { handleSubmit, isLoading, formErrors, email, setEmail, setFormErrors } =
		useForgotPassword();

	useEffect(() => {
		setFormErrors({});
	}, [email, setFormErrors]);
	return (
		<AuthLayout2>
			<div className="mx-auto flex w-full h-full flex-col justify-center space-y-6 sm:w-[350px]">
				<img src={logo} alt="logo" className="mx-auto h-10 w-10" />
				<div className="flex flex-col space-y-2 text-center">
					<h1 className="text-2xl font-semibold tracking-tight">
						Forgot password?
					</h1>
					<p className="text-sm text-muted-foreground">
						We'll reset it this time, but try not to forget it again.
					</p>
				</div>
				<div className="grid gap-10">
					<form onSubmit={handleSubmit}>
						<div className="grid gap-8">
							<InputText
								label="Email"
								placeholder="name@example.com"
								required
								error={formErrors['email']}
								errorText={formErrors['email']}
								value={email}
								setValue={(e) => setEmail(e)}
								name="email"
								autoCapitalize="none"
								autoComplete="email"
								autoCorrect="off"
							/>
							<Button disabled={isLoading} className="mt-2">
								{isLoading ? (
									<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
								) : null}
								Send verification email
							</Button>
						</div>
					</form>
				</div>
			</div>
		</AuthLayout2>
	);
};

export default ForgotPasswordPage;
