import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	IParsedTxn,
	useTransactionGuide,
} from '../../hooks/actions/useTransactionGuide';
import { useCreateActionProvider } from '../../hooks/actions/useCreateActionProvider';
import Spinner from '@/components/element/loading/Spinner';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { IDaveCreateAction } from '../../types/dave.types';
import InputRadioCards from '@/components/element/inputs/InputRadioCards';
import { useState } from 'react';
import { useActionCriterias } from '../../hooks/actions/useActionCriterias';

export const TransactionHashGuide = () => {
	const { details, setDetails } = useCreateActionProvider();
	const { handleUserAddressSelected } = useActionCriterias();
	const { txnHash, setTxnHash, parsedTxn, fetchTxn, isLoading, errorHash } =
		useTransactionGuide({
			chainId: details.chainId,
			abi: details.abi,
		});

	const importFunction = () => {
		setDetails((prev) => ({
			...prev,
			functionSignature: parsedTxn.functionSignature,
		}));
	};

	const importEvent = (event: IParsedTxn['events'][0]) => {
		setDetails((prev) => ({
			...prev,
			event0Topic0: event.topic0,
			event0ContractAddress: event.contractAddress?.toLowerCase(),
			selectedEvent: `${event.topic0}:${event.contractAddress?.toLowerCase()}`,
		}));
	};

	const importAddress = (address) => {
		handleUserAddressSelected(address);
	};

	return (
		<>
			<div className="mt-6 rounded-lg bg-slate-100 p-3 text-sm">
				Quickly populate your action's criteria by importing function and
				event details directly from a valid transaction hash.
			</div>
			<div className="mt-10 grid grid-cols-12 items-end gap-4">
				<InputText
					value={txnHash}
					setValue={setTxnHash as any}
					placeholder="Enter Transaction Hash"
					className="w-full col-span-8"
					error={errorHash}
					label="Enter a sample transaction hash"
				/>
				<Button
					className="col-span-4 border"
					variant="secondary"
					disabled={isLoading}
					onClick={fetchTxn}
				>
					{isLoading && <Spinner className="me-2" />}
					Fetch Data
				</Button>
			</div>
			{errorHash && (
				<div className="text-xs mt-2 text-red-600">{errorHash}</div>
			)}
			<Separator className="my-10" />
			{parsedTxn && (
				<div>
					<div className="text-sm font-medium">Parsed Transaction</div>
					<ParsedFunction
						parsedTxn={parsedTxn}
						importFunction={importFunction}
						details={details}
					/>
					<ParsedEvent
						parsedTxn={parsedTxn}
						importEvent={importEvent}
						details={details}
					/>
					<ParseUserAddress
						parsedTxn={parsedTxn}
						importAddress={importAddress}
						details={details}
					/>
				</div>
			)}
		</>
	);
};

const ParsedFunction = ({
	parsedTxn,
	importFunction,
	details,
}: {
	parsedTxn: IParsedTxn;
	importFunction: () => void;
	details: any;
}) => {
	return (
		<div className="grid grid-cols-12 items-top justify-center py-3">
			<div className="col-span-2 text-sm text-muted-foreground font-medium pt-4">
				Function
			</div>
			<div className="col-span-10 bg-slate-50 p-4 rounded border relative">
				<div className="text-sm font-medium mb-3  flex justify-between items-top">
					<div className="w-2/3">
						{parsedTxn.functionName}
						<div className="text-muted-foreground truncate text-xs font-normal">
							{parsedTxn.functionParams
								?.map((param) => `${param.name}: ${param.type}`)
								.join(', ')}
						</div>
					</div>
					<Button
						className=""
						size="sm"
						variant="outline"
						onClick={importFunction}
						disabled={
							parsedTxn.functionSignature === details.functionSignature
						}
					>
						{parsedTxn.functionSignature ===
						details.functionSignature ? (
							<i className="bi-check-circle-fill me-1"></i>
						) : (
							<i className="bi-plus-circle-fill me-1"></i>
						)}
						Import Function
					</Button>
				</div>
				{parsedTxn.functionParams && (
					<div className="text-muted-foreground text-xs">
						{parsedTxn.functionParams.map((param) => (
							<div key={param.name} className="truncate">
								{param.name}:{` `}
								<strong
									className={cn(
										param.type === 'address'
											? 'bg-violet-100 text-slate-600 rounded px-1'
											: '',
									)}
								>
									{param.value}
								</strong>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

const ParsedEvent = ({
	parsedTxn,
	importEvent,
	details,
}: {
	parsedTxn: IParsedTxn;
	importEvent: (event: IParsedTxn['events'][0]) => void;
	details: any;
}) => {
	return (
		<div className="py-4">
			<div className="grid grid-cols-12 items-top justify-center py-3">
				<div className="col-span-2 text-sm text-muted-foreground font-medium pt-4">
					Events
				</div>
				<div className="col-span-10 ">
					{parsedTxn.events.map((event) => (
						<div className="bg-slate-50 p-4 rounded mb-2 border relative">
							<div className="text-sm font-medium mb-3  flex justify-between items-top">
								<div className="w-2/3">
									{event.name}
									<div className="text-muted-foreground truncate text-xs font-normal ">
										(
										{event.params
											?.map(
												(param) =>
													`${param.name}: ${param.type}`,
											)
											.join(', ')}
										)
									</div>
								</div>
								<Button
									className=""
									size="sm"
									variant="outline"
									onClick={() => importEvent(event)}
									disabled={event.topic0 === details.event0Topic0}
								>
									{event.topic0 === details.event0Topic0 ? (
										<i className="bi-check-circle-fill me-1"></i>
									) : (
										<i className="bi-plus-circle-fill me-1"></i>
									)}
									Import Event
								</Button>
							</div>
							{event.params && (
								<div className="text-muted-foreground text-xs">
									{event.params.map((param) => (
										<div key={param.name} className="truncate">
											{param.name}:{` `}
											<strong
												className={cn(
													param.type === 'address'
														? 'bg-violet-100 text-slate-600 rounded px-1'
														: '',
												)}
											>
												{param.value}
											</strong>
										</div>
									))}
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const ParseUserAddress = ({
	parsedTxn,
	importAddress,
	details,
}: {
	parsedTxn: IParsedTxn;
	importAddress: (address: string) => void;
	details: IDaveCreateAction;
}) => {
	const [userAddressSelected, setUserAddressSelected] = useState<string | null>();

	const userAddresses = [
		{
			address: parsedTxn.userAddress?.toLowerCase(),
			value: 'TXN_FROM',
			label: 'Transaction Sender',
		},
	];
	if (details.event0Topic0) {
		const event = parsedTxn.events.find(
			(e) => e.topic0 === details.event0Topic0,
		);
		const addressParams = event?.params.filter(
			(param) => param.type === 'address',
		);
		if (!addressParams) return null;
		addressParams?.forEach((param) => {
			userAddresses.push({
				address: param.value.toLowerCase(),
				value: `event:${event.topic0}:${param.name}:${param.type}`,
				label: `${param.name} from ${event.name} Event`,
			});
		});
	}
	if (details.functionSignature) {
		const functionParams = parsedTxn.functionParams.filter(
			(param) => param.type === 'address',
		);
		if (!functionParams) return null;
		functionParams.forEach((param) => {
			userAddresses.push({
				address: param.value.toLowerCase(),
				value: `function:${parsedTxn.functionSignature}:${param.name}:${param.type}`,
				label: `${param.name} from ${parsedTxn.functionName} Function`,
			});
		});
	}

	const uniqueAddresses = Array.from(
		new Set(
			userAddresses
				?.map((a) => a.address?.toLowerCase())
				?.filter((i) => {
					if (!i) return false;
					if (i === '0x0000000000000000000000000000000000000000')
						return false;
					return true;
				}),
		),
	);

	return (
		<div className="grid grid-cols-12 items-top justify-center py-3">
			<div className="col-span-2 text-sm text-muted-foreground font-medium pt-4">
				User Addresses
			</div>
			<div className="col-span-10 ">
				<div className="text-sm font-medium">
					Select which address is of the user
				</div>
				<div className="text-xs mb-4">
					There are {uniqueAddresses.length} unique addresses found in the
					transaction and your action criterias.
				</div>
				<InputRadioCards
					className="mb-4 gap-y-2"
					colSize={1}
					options={uniqueAddresses.map((i) => ({
						label: i,
						isChecked: userAddressSelected === i,
						setIsChecked: (_value: boolean) => {
							setUserAddressSelected(i);
						},
					}))}
				/>
				{userAddressSelected && (
					<div>
						<div className="">
							{userAddresses
								.filter((i) => {
									return (
										i.address.toLowerCase() ===
										userAddressSelected.toLowerCase()
									);
								})
								.map((i) => (
									<Button
										className="mb-2"
										size="sm"
										variant="outline"
										onClick={() => importAddress(i.value)}
										disabled={
											i.value === details.userAddressSelected
										}
									>
										{i.value === details.userAddressSelected ? (
											<i className="bi-check-circle-fill me-1"></i>
										) : (
											<i className="bi-plus-circle-fill me-1"></i>
										)}
										Import Address from {i.label}
									</Button>
								))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
