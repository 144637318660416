import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { usePrompt } from '@/hooks/usePrompt';
import { IDaveActions, IDaveCreateAction } from '../types/dave.types';
import { DaveActionCreateSectionEnum } from '../types/dave.enum';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchAction } from '../services/dave.service';
import { useGetContracts } from '../hooks/contracts/useGetContracts';
import { ethers } from 'ethers';
import { extractFunctionParams } from '../helpers/helpers';
import { useActionContextualSuggestions } from '../hooks/actions/useActionContextualSuggestions';

interface DaveActionCreateProviderState {
	setIsDirty: Dispatch<SetStateAction<boolean>>;
	setSection: Dispatch<SetStateAction<DaveActionCreateSectionEnum>>;
	section: DaveActionCreateSectionEnum;
	details: IDaveCreateAction;
	setDetails: Dispatch<SetStateAction<IDaveCreateAction>>;
	errors: any;
	setErrors: any;
	isUpdate: boolean;
	contextualSuggestions: {
		element: string | JSX.Element | Element;
		id: string;
	};
	setContextualSuggestions: Dispatch<
		SetStateAction<{
			element: string | JSX.Element | Element;
			id: string;
		}>
	>;
	id: string;
}

const initialFormState: IDaveCreateAction = {
	name: '',
	description: '',
	category: '',
	contractId: '',
	type: 'contract',
	transactionFrom: '',
	transactionTo: '',
	functionSignature: 'NA',
	event0ContractAddress: '',
	event0Topic0: 'NA',
	selectedEvent: 'NA',
	userAddressSource: 'TXN_FROM',
	userAddressArrayParamIndex: [],
	userAddressParamIndex: [],
	abi: [],
	abis: [],
	userAddressSelected: 'TXN_FROM',
};

const initialFormErrorState = {
	name: false,
	description: false,
	category: false,
	contractId: false,
	type: false,
	transactionFrom: false,
	transactionTo: false,
	functionSignature: false,
	event0ContractAddress: false,
	event0Topic0: false,
	userAddressSource: false,
};

const initialState: DaveActionCreateProviderState = {
	setSection: () => {},
	setIsDirty: () => {},
	section: DaveActionCreateSectionEnum.Details,
	details: initialFormState,
	setDetails: () => {},
	errors: {},
	setErrors: () => {},
	contextualSuggestions: {
		element: '',
		id: '',
	},
	setContextualSuggestions: () => {},
	isUpdate: false,
	id: '',
};

export const DaveActionCreateContext =
	createContext<DaveActionCreateProviderState>(initialState);

export function DaveActionCreateProvider({
	children,
	section,
	setSection,
}: {
	children: JSX.Element;
	section: DaveActionCreateSectionEnum;
	setSection: Dispatch<SetStateAction<DaveActionCreateSectionEnum>>;
}) {
	const { id } = useParams();
	const [mounted, setMounted] = useState(false);
	const { data } = useQuery<IDaveActions>({
		queryKey: ['dave-actions', id],
		queryFn: () => fetchAction(id),
		enabled: !!id,
	});
	const { contracts } = useGetContracts();

	const [isDirty, setIsDirty] = useState(false);
	const [details, setDetails] = useState(initialFormState);
	const [errors, setErrors] = useState(initialFormErrorState);
	const [contextualSuggestions, setContextualSuggestions] = useState({
		element: '',
		id: '',
	});

	usePrompt({ isDirty });

	useEffect(() => {
		if (mounted) return;
		if (data && contracts?.length > 0) {
			const contract = contracts?.find(
				(contract) => contract.contractAddress === data.transactionTo,
			);
			const abi = JSON.parse(contract?.abi);

			const iface2 = new ethers.utils.Interface(abi);
			let userAddressSelected: string;
			if (data.userAddressSource === 'TXN_FROM') {
				userAddressSelected = 'TXN_FROM';
			}
			if (data.userAddressSource === 'EVENT') {
				userAddressSelected = 'event';
				userAddressSelected += data.event0Topic0
					? `:${data.event0Topic0}`
					: '';
				const events_ = iface2.fragments
					.filter((fragment) => fragment.type === 'event')
					.map((fragment) => {
						const name = fragment.format();
						const topic = ethers.utils.id(name);
						const item = fragment;
						return {
							name,
							topic,
							item,
							params: fragment.inputs,
						};
					});
				const selectedEvent = events_.find(
					(event) => event.topic === data.event0Topic0,
				);
				const params = extractFunctionParams(selectedEvent);
				const param = params.params.find((i: any) => {
					return (
						JSON.stringify(i.position) ===
						JSON.stringify(data.userAddressParamIndex)
					);
				});
				userAddressSelected += param.name ? `:${param.name}` : '';
				userAddressSelected += param.type ? `:${param.type}` : '';
			}
			if (data.userAddressSource === 'FUNC_CALLDATA') {
				userAddressSelected = 'function';
				userAddressSelected += data.functionSignature
					? `:${data.functionSignature}`
					: '';
			}

			setDetails((prev) => ({
				...prev,
				name: data.name,
				description: data.description,
				category: data.category,
				chainId: data.chainId,
				contractId: contract?._id,
				type: data.transactionTo ? 'contract' : 'address',
				transactionFrom: data.transactionFrom,
				transactionTo: data.transactionTo,
				functionSignature: data.functionSignature || 'NA',
				event0ContractAddress: data.event0ContractAddress,
				event0Topic0: data.event0Topic0 || 'NA',
				selectedEvent: data.event0Topic0
					? `${data.event0Topic0}:${data.event0ContractAddress}`
					: 'NA',
				userAddressSource: data.userAddressSource || 'TXN_FROM',
				userAddressArrayParamIndex: data.userAddressArrayParamIndex || [],
				userAddressParamIndex: data.userAddressParamIndex || [],
				abi: abi || [],
				abis:
					data.abis?.map((i) => ({
						contract: i.contractAddress,
						abi: i.abi,
					})) ?? [],
				userAddressSelected,
			}));
			setMounted(true);
		}
	}, [data, contracts, mounted]);

	useActionContextualSuggestions({
		details,
		setContextualSuggestions,
		contextualSuggestions,
	});

	return (
		<DaveActionCreateContext.Provider
			value={{
				setIsDirty,
				section,
				setSection,
				details,
				setDetails,
				errors,
				setErrors,
				contextualSuggestions,
				setContextualSuggestions,
				isUpdate: !!id,
				id,
			}}
		>
			{children}
		</DaveActionCreateContext.Provider>
	);
}
