import { PUBLIC_URL } from '@/config';

export const UploadAvatar = ({
	avatarUrl,
	setAvatar,
	avatarRef,
	className,
}: {
	setAvatar: (file: File | File[], fileLink: string) => void;
	avatarUrl: string | File;
	className?: string;
	avatarRef: any;
}) => {
	return (
		<label
			className={`relative inline-block w-[7.875rem] h-[7.875rem] rounded-[50%] cursor-pointer border-[0.25rem] border-white mt-[-3.3rem] mb-[0.5rem] ml-auto mr-auto ${className} `}
		>
			<img
				className="w-full h-full object-cover rounded-[50%]"
				src={
					(avatarUrl as any) ||
					`${PUBLIC_URL}/assets/img/defaults/banner.jpg`
				}
				alt="avatar"
			/>
			<input
				type="file"
				className="absolute top-0 left-0 right-0 opacity-0 -z-[1] w-full h-full rounded-[50%]"
				ref={avatarRef}
				onChange={(event) =>
					setAvatar(
						Array.from(event?.target?.files || []),
						avatarUrl as any,
					)
				}
				accept="image/*"
			/>
			<span className="absolute bottom-0 right-0 cursor-pointer rounded-[50%] bg-white border ">
				<i className="bi-pencil-fill flex shrink-0 justify-center items-center rounded-[50%] shadow-sm w-[2.0rem] h-[2.0rem] text-sm"></i>
			</span>
		</label>
	);
};
