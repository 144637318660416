import { Media } from '@/components/element/media/Media';
import { Separator } from '@/components/ui/separator';
import { asset } from '@/config/asset-cdn';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { Link } from 'react-router-dom';
import Notifications from '../notifications/Notifications';
import { DashboardTabs } from './DashboardTabs';
import { UserNav } from './UserNav';
import { useAuth } from '@/hooks/useAuth';
import { useGetEnterprise } from '../../hooks/useGetEnterprise';
import NumberFlow from '@number-flow/react';

export default function DashboardLinks() {
	const { user } = useAuth();
	const { enterprise } = useGetEnterprise();

	return (
		<div className="flex items-center">
			<div className="md:flex hidden items-center">
				<div className=" mb-1">
					<DashboardTabs />
				</div>
			</div>
			<div className="mx-3 me-4">
				<Separator orientation="vertical" className="h-[26px]" />
			</div>
			<div className="gap-2 flex items-center">
				<Link
					to="/app/credits"
					className="pr-2 py-0.5 rounded-full hover:bg-slate-100 hover:text-slate-950 "
					onClick={() =>
						analytics.track(TrackingEvents.CreditsBalanceClicked, {
							userId: user?._id,
							enterpriseId: user?.enterpriseId,
						})
					}
				>
					<div className="flex gap-1">
						<Media
							src={asset['credit-icon-primary']}
							altText="credit-icon"
							className="size-5"
						/>
						<NumberFlow
							value={enterprise?.credits?.balanceCredits ?? 0}
							locales="en-US" // Intl.NumberFormat locales
							className="text-sm text-slate-600 font-normal"
							spinTiming={{
								duration: 700,
								easing: 'ease-in-out',
							}}
						/>
					</div>
				</Link>
				<Notifications />
				<UserNav />
				{/* <DashboardMobileMenu /> */}
			</div>
		</div>
	);
}
