import { Separator } from '@/components/ui/separator';
import WidgetSections from '../components/content-management-system/widgets/WidgetsSections';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import { useCreateWidget } from '../hooks/admin-cms-widgets/useCreateWidget';
import { useState } from 'react';
import AddWidgetButton from '../components/content-management-system/widgets/AddWidgetButton';
import CreateNewWidget from '../components/content-management-system/widgets/CreateNewWidget';

const AdminWidgets = () => {
	const {
		details,
		setDetails,
		widgets,
		handleSelectWidget,
		isPending,
		createNewWidget,
		resetForm,
		formFields,
		setFormFields,
		formErrors,
		showAddWidget,
		setShowAddWidget,
	} = useCreateWidget();

	return (
		<div className="flex justify-center relative items-center">
			<div className="mb-5 w-full relative">
				<h4 className="text-xl font-medium">Add Widgets to homepage</h4>
				<p className="text-muted-foreground text-xs mt-1 mb-4">
					Configure the layout, order of the widgets.
				</p>
				<Separator className="my-4" />
				<div className="space-y-8">
					<div className="flex justify-between items-center">
						<InputSelect2
							label="Select widgets"
							options={widgets?.map((i) => ({
								label: i.title,
								value: i._id,
								searchText: i.title,
							}))}
							placeholder="Choose a widget from the below list"
							value={details?._id}
							setValue={(newVal) => handleSelectWidget(newVal)}
							popoverClassName="w-[20rem]"
							className="w-[20rem]"
							addNew={
								<AddWidgetButton
									setShowAddWidget={setShowAddWidget}
								/>
							}
						/>

						{details?._id && (
							<div className="text-sm">
								<p className="text-right">
									Max Limit:&nbsp;
									<strong>
										{widgets?.find((i) => i._id === details?._id)
											?.displayLimit || '- NA -'}
									</strong>
								</p>
								<p className="text-xs text-muted-foreground mt-1">
									Please adhere to the max display limit of widgets
								</p>
							</div>
						)}
					</div>

					<WidgetSections />
				</div>
			</div>

			<CreateNewWidget
				show={showAddWidget}
				setShow={setShowAddWidget}
				isPending={isPending}
				createNewWidget={createNewWidget}
				resetForm={resetForm}
				formFields={formFields}
				setFormFields={setFormFields}
				formErrors={formErrors}
				setFormErrors={setFormFields}
			/>
		</div>
	);
};

export default AdminWidgets;
