import { ColumnDef } from '@tanstack/react-table';
import { ICampaign, Status } from '../types';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { formatNumberWithCommas } from '@/utils/parsers';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import dayjs from 'dayjs';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export const campaignsColumns = (options: any): ColumnDef<ICampaign>[] => {
	return [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ row }) => (
				<div className="flex-grow py-1 max-w-[450px] truncate">
					{row.getValue('name')}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ row }) => (
				<TooltipWrapper
					tooltip={
						row.getValue('status') === Status.InReview
							? 'In "Test" state, you can test the campaign and once you are satisfied then you can make the quest public to your users'
							: ''
					}
				>
					<StatusBadge status={row.getValue('status')} />
				</TooltipWrapper>
			),
			enableSorting: true,
			enableHiding: false,
		},

		{
			accessorKey: 'pageViewUsersCount',
			accessorFn: (row) => row?.pageViewUsersCount ?? 0,
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Page Views" />
			),
			cell: ({ row }) => (
				<div>
					{row.original.status === Status.Draft
						? ''
						: formatNumberWithCommas(
								row?.getValue('pageViewUsersCount'),
							)}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},

		{
			accessorKey: 'initiatedUsersCount',
			accessorFn: (row) => row?.initiatedUsersCount ?? 0,
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Initiations" />
			),
			cell: ({ row }) => (
				<div>
					{row.original.status === Status.Draft
						? ''
						: formatNumberWithCommas(
								row?.getValue('initiatedUsersCount'),
							)}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'completedUsersCount',
			accessorFn: (row) => row?.completedUsersCount ?? 0,
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Completed" />
			),
			cell: ({ row }) => (
				<div>
					{row.original.status === Status.Draft
						? ''
						: formatNumberWithCommas(
								row?.getValue('completedUsersCount'),
							)}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'createdAt',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Created On" />
			),
			cell: ({ row }) => (
				<div>{dayjs(row?.original?.createdAt).fromNow()}</div>
			),
			enableSorting: true,
			enableHiding: false,
			sortingFn: (a, b) => {
				return (
					new Date(a.original.createdAt).getTime() -
					new Date(b.original.createdAt).getTime()
				);
			},
		},
		{
			id: 'actions',
			cell: ({ row }) => <DataTableRowActions row={row} options={options} />,
		},
	];
};
