import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getLeaderboardUserDetails } from '../services/leaderboard.service';
import {
	ILeaderboardUserDetails,
	ILeaderboardUsers,
} from '../types/leaderboard.types';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useLeaderboardUser = () => {
	const [showUser, setShowUser] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState('');
	const [showTaskInfo, setShowTaskInfo] = useState(false);

	const { data: userProfile, isLoading: isUserProfileLoading } =
		useQuery<ILeaderboardUserDetails>({
			queryKey: ['user-profile', selectedUserId],
			queryFn: () => getLeaderboardUserDetails(selectedUserId),
			enabled: !!selectedUserId,
		});

	const handleRowClick = (row: ILeaderboardUsers) => {
		analytics.track(TrackingEvents.UserProfileViewed, {
			questUserId: row._id,
			userRank: row.rank,
			username: row.username,
			totalXp: row.totalXp,
		});
		setSelectedUserId(row._id);
		setShowUser(true);
	};

	return {
		selectedUserId,
		handleRowClick,
		showUser,
		setShowUser,
		userProfile,
		isUserProfileLoading,
		showTaskInfo,
		setShowTaskInfo,
		setSelectedUserId,
	};
};
