import InputText from '@/components/element/inputs/InputText';
import { Separator } from '@/components/plate-ui/separator';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { links } from '@/config/links';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import useExternalAPIs from '../../hooks/useExternalAPIs';
import { Dispatch, SetStateAction, useEffect } from 'react';

const AddExternalAPI = ({
	open,
	setOpen,
	step,
	setStep,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
}) => {
	const {
		apiName,
		setApiName,
		handleNext,
		formErrors,
		setFormErrors,
		resetForm,
		apiKey,
		isCreate,
		setIsCopied,
		isCopied,
	} = useExternalAPIs({
		setOpen,
		setStep,
	});

	const copyText = (endpoint: string) => {
		navigator.clipboard.writeText(endpoint);
		toast.success(`API Token has been copied on your clipboard`);
		setIsCopied(true);
	};

	useEffect(() => {
		setFormErrors((prev) => {
			return {
				...prev,
				apiName: '',
			};
		});
	}, [apiName, setFormErrors]);
	return (
		<Dialog open={open} onOpenChange={isCopied ? setOpen : null}>
			<DialogContent className="sm:max-w-[525px]">
				<DialogHeader className="">
					<DialogTitle>Create a new Auth Token</DialogTitle>
					<DialogDescription>
						For more information on how to use the API, see our&nbsp;
						<Link
							to={links?.docs?.apiDocs}
							target="_blank"
							className="text-sm font-normal text-normal text-muted-foreground hover:text-primary/80 underline"
						>
							documentation.
						</Link>
					</DialogDescription>
					<div className="pt-6 space-y-6">
						{step === 2 ? (
							<>
								<div className="flex w-full items-center mb-5 text-sm text-amber-600 bg-orange-100 px-2 py-1 rounded-md">
									<i className="bi-exclamation-triangle me-2"></i>
									Please copy this token to a safe place - it won't
									be shown again!
								</div>

								<div className="flex items-center gap-4 w-full mb-5">
									<Label>Token</Label>
									<div className="w-full flex items-center justify-between  rounded-md px-2 py-0.5 bg-muted">
										<p className="font-normal max-w-[22rem] truncate text-ellipsis text-xs">
											{apiKey}
										</p>

										<Button
											variant="ghost"
											size="sm"
											className="p-0 mr-2"
											onClick={() => copyText(apiKey)}
										>
											<i className="bi-clipboard-fill text-muted-foreground"></i>
										</Button>
									</div>
								</div>
							</>
						) : (
							<div className="flex gap-4 w-full mb-5">
								<InputText
									label="Token Name"
									placeholder="Enter Token name"
									required
									className="w-full"
									disabled={step === 2}
									value={apiName}
									setValue={(e) => setApiName(e)}
									error={formErrors?.apiName}
									errorText={formErrors?.apiName}
								/>
							</div>
						)}
					</div>
				</DialogHeader>
				<Separator />
				<DialogFooter>
					{step === 1 ? (
						<Button
							variant="outline"
							onClick={() => {
								setOpen(false);
								resetForm();
							}}
						>
							Cancel
						</Button>
					) : null}
					<Button
						onClick={() => {
							handleNext(step);
						}}
						disabled={isCreate}
					>
						{step === 1 ? (
							<span>
								{isCreate ? (
									<i className="bi-arrow-repeat animate-spin me-2"></i>
								) : null}
								Create Auth Token
							</span>
						) : (
							<span>Done</span>
						)}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddExternalAPI;
