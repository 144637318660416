import { Navigate, Route, Routes } from 'react-router-dom';
import ProfilePage from './profile.page';
import ProfilePasswordPage from './password.page';
import EnterpriseTeamPage from './team.page';
import ReferralPage from './referral.page';
import CreditsPage from '../../intract-credits/routes/credits-old.page';
import ExportsPage from './exports.page';
import ProfileLayout from '../components/layout/ProfileLayout';
import ExternalAPIs from './external-apis.page';

export default function ProfileRoutes() {
	return (
		<Routes>
			<Route path="" element={<ProfilePage />} />
			<Route path="settings" element={<ProfilePasswordPage />} />
			<Route path="team" element={<EnterpriseTeamPage />} />
			<Route path="referral" element={<ReferralPage />} />
			<Route path="credits" element={<CreditsPage />} />
			<Route path="exports" element={<ExportsPage />} />
			<Route path="api-tokens" element={<ExternalAPIs />} />
			<Route path="*" element={<Navigate replace to="/" />} />
		</Routes>
	);
}
