import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
	deleteBanner,
	getBanners,
	reorderBanners,
} from '../../services/cms.service';
import { queryClient } from '@/lib/react-query';

export interface IBanner {
	_id: string;
	title: string;
	index: number;
	banner: string;
	name: string;
}

const useCreateBannerSections = () => {
	const [sections, setSections] = useState<IBanner[]>([]);
	const [sectionId, setSectionId] = useState('');
	const [showBannerCreateMethod, setShowBannerCreateMethod] = useState(false);
	const [openCreateBanner, setOpenCreateBanner] = useState(false);

	const { data, isLoading } = useQuery({
		queryKey: ['cms-banners'],
		queryFn: getBanners,
	});

	const deleteBannerMutation = useMutation({
		mutationFn: deleteBanner,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['cms-banners'],
			});
		},
	});

	const saveOrderMutation = useMutation({
		mutationFn: reorderBanners,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['cms-banners'],
			});
		},
	});

	const handleBannerDelete = async (id: string) => {
		deleteBannerMutation.mutateAsync(id);
	};

	const handleReorder = (newData: IBanner[]) => {
		const updatedData = newData.map((item, idx) => ({
			...item,
			index: idx,
		}));

		setSections(updatedData);
	};

	const saveBannerOrder = async () => {
		saveOrderMutation.mutateAsync({
			bannerIdWithIndex: sections.map((item) => ({
				index: item.index,
				bannerId: item._id,
			})),
		});
	};
	useEffect(() => {
		if (!data) setSections([]);
		else setSections(data);
	}, [data]);

	return {
		sections,
		setSections,
		sectionId,
		setSectionId,
		showBannerCreateMethod,
		setShowBannerCreateMethod,
		openCreateBanner,
		setOpenCreateBanner,
		isLoading,
		isSubmitting: deleteBannerMutation.isPending || saveOrderMutation.isPending,
		handleBannerDelete,
		handleReorder,
		saveBannerOrder,
	};
};

export default useCreateBannerSections;
