import { Label } from '@/components/ui/label';
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { ICreateTasks } from '@/features/campaigns/types';

const SelectMetricsGraph = ({
	metricBasedXpConfig,
	taskId,
}: {
	taskId: string;
	metricBasedXpConfig: ICreateTasks['metricBasedXpConfig'];
}) => {
	const { editTask } = useCreateCampaignTasks();
	return (
		<div className="border-t py-5 px-5 flex items-center justify-between">
			<div>
				<Label className="block mb-1">
					<i className="bi-graph-up-arrow mr-2"></i>
					Set XP based on Volume
				</Label>
				<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
					Choose the graph type to distribute XP
				</p>
			</div>
			<div>
				<Select
					value={
						metricBasedXpConfig?.functionType
							? metricBasedXpConfig?.functionType
							: ''
					}
					onValueChange={(newValue) => {
						editTask(taskId, 'EDIT_METRIC_BASED_XP', newValue);
					}}
				>
					<SelectTrigger className=" bg-white min-w-[200px] ">
						<SelectValue placeholder={'Select Graph'} />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value={'NONE'}>None</SelectItem>
						<SelectItem value={'LINEAR'}>Linear</SelectItem>
						<SelectItem value={'TIERED'}>Tiered</SelectItem>
						<SelectItem value={'SIGMOID'}>Sigmoid</SelectItem>
					</SelectContent>
				</Select>
			</div>
		</div>
	);
};

export default SelectMetricsGraph;
