import { DataTable } from '@/components/element/data-table/DataTable';
import PageHeader from '@/components/layout/PageHeader';
import { useState } from 'react';
import { useGetAdminAffiliateProgram } from '../hooks/useGetAdminAffiliateProgram';
import AffiliateProgramFilters from '../components/affiliate-program/AffiliateProgramFilters';
import { AffiliateProgramColumns } from '../components/affiliate-program/AffiliateProgramColumns';
import AffiliateRewardsDialog from '../components/affiliate-program/AffiliateRewardsDialog';
import useAdminAffiliateProgram from '../hooks/useAdminAffiliateProgram';

const AdminAffiliateProgram = () => {
	const [open, setOpen] = useState(false);
	const [id, setId] = useState('');
	const { search, setSearch, isLoading, results, breakdown } =
		useGetAdminAffiliateProgram();

	const { setFormFields } = useAdminAffiliateProgram({ setOpen });

	const columns = AffiliateProgramColumns(
		[
			{
				type: 'button',
				label: 'Give Rewards',
				onClick: (row) => {
					setOpen(true);
					setId(row._id);
					setFormFields((prev) => {
						return {
							...prev,
							_id: row._id,
						};
					});
				},
			},
		],
		breakdown,
	);
	return (
		<div>
			<PageHeader title="Affiliate Program" />
			<AffiliateProgramFilters search={search} setSearch={setSearch} />
			<div className="mt-6">
				<DataTable
					data={results}
					columns={columns}
					totalCount={results.length}
					isLoading={isLoading}
					isServerSide={false}
				/>
			</div>

			<AffiliateRewardsDialog open={open} setOpen={setOpen} rowId={id} />
		</div>
	);
};

export default AdminAffiliateProgram;
