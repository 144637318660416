import { axios } from '@/lib/axios';
import { handleErrorMessage } from '@/utils/notifications';
import { toast } from 'sonner';

export const getEnterprise = async () => {
	try {
		const response = await axios.get('/enterprise');
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const uploadFile = async (file: any) => {
	const formData = new FormData();
	formData.append('file', file);
	const response = await axios.post('/enterprise/upload-file', formData);
	return response.data[0].location;
};

export const updateEnterprise = async (data) => {
	const response = await axios.put('/enterprise/profile', data);
	return response.data;
};

export const updateEnterpriseSocials = async (data: any) => {
	const response = await axios.put('/enterprise/socials', data);
	return response.data;
};

export const getReferral = async () => {
	const response = await axios.get('/enterprise/referral-program');
	return response.data;
};

export const launchCommunityReferralProgram = async (data: any) => {
	try {
		const response = await axios.post('/enterprise/referral-program', data);
		toast.success('Referral program launched successfully');
		return response.data;
	} catch (err) {
		handleErrorMessage(err);
	}
};

export const updateReferralProgram = async (data: any) => {
	try {
		const response = await axios.put('/enterprise/referral-program', data);
		toast.success('Referral program updated successfully');
		return response.data;
	} catch (err) {
		handleErrorMessage(err);
	}
};
