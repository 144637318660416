/* eslint-disable no-mixed-spaces-and-tabs */
import { Media } from '@/components/element/media/Media';
import { Button } from '@/components/ui/button';
import { asset } from '@/config/asset-cdn';
import { cn } from '@/lib/utils';
import { UserType } from '@/providers/AuthProvider';
import en from '../../../../locales/en.json';
import useEarnSection from '../../hooks/useEarnSection';
import { ICreditBenefit } from '../../types/credits.types';
import { CreditBaseCtaEnum } from '../../types/credits.enums';
import { convertToTitleCase } from '@/utils/parsers';

const EarnSection = ({
	getTaskCompletedStatus,
	user,
	creditBenefits,
}: {
	getTaskCompletedStatus: (index: number) => boolean;
	user: UserType;
	creditBenefits: ICreditBenefit[];
}) => {
	const { intractCredits } = en;
	const section = intractCredits?.sections?.earn;

	const { handleAction, formattedBenefits } = useEarnSection({
		user,
		creditBenefits,
	});

	return (
		<div className="flex flex-col rounded-xl p-6 border border-slate-200">
			<div>
				<h2 className="text-slate-700 text-lg">{section?.title}</h2>
				<p className="text-slate-500 text-sm leading-5">
					{section?.subtitle}
				</p>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 mt-6">
				{Array.isArray(formattedBenefits) &&
					formattedBenefits.map((benefit, index) => {
						const isTaskCompleted = getTaskCompletedStatus(index);
						const isClaimedStatus =
							benefit.enterpriseCurrentRequestStatus ===
							CreditBaseCtaEnum.Claimed;

						const buttonText =
							isTaskCompleted || isClaimedStatus ? (
								<>
									{section?.buttons?.claimed}
									<i className="bi bi-check-circle-fill text-violet-500 ms-2"></i>
								</>
							) : benefit.enterpriseCurrentRequestStatus ===
							  // eslint-disable-next-line no-nested-ternary
							  CreditBaseCtaEnum.Apply ? (
								section?.buttons?.claim
							) : (
								convertToTitleCase(
									benefit.enterpriseCurrentRequestStatus,
								)
							);

						return (
							<div
								key={benefit._id}
								className={cn(
									'py-4 px-3.5 border border-slate-200 rounded-lg',
									benefit.comingSoon &&
										'bg-gradient-to-b from-transparentWhite via-transparentWhite to-transparentBlue opacity-80',
								)}
							>
								{benefit.comingSoon ? (
									<div className="text-center mx-auto">
										<Media
											src={asset['credit-coming-soon']}
											altText="coming-soon"
											className="text-center mx-auto"
										/>
										<p className="text-sm text-slate-600 font-medium mt-1">
											More Coming Soon!
										</p>
									</div>
								) : (
									<div className="flex flex-col h-full">
										<div className="mb-2">
											{benefit?.icon?.startsWith('http') ? (
												<Media
													src={benefit.icon}
													altText={benefit.title}
													className="size-5"
												/>
											) : (
												<i
													className={`bi bi-${benefit.icon} text-slate-700 text-xl size-5`}
												></i>
											)}
										</div>
										<h3 className="text-slate-700 text-base font-normal mb-1">
											{benefit.title}
										</h3>
										<p className="text-slate-500 text-sm font-light mb-4">
											{benefit.description}
										</p>
										<div className="flex items-center justify-between mt-auto">
											{benefit.credits && (
												<div className="flex items-center gap-1 bg-slate-100 rounded-full">
													<Media
														src={
															asset[
																'credit-icon-primary'
															]
														}
														altText="credit-icon"
														className="size-[22px]"
													/>
													<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
														{benefit.credits}
													</span>
												</div>
											)}
											<Button
												className="px-4 py-2 rounded-lg text-sm"
												variant="outline"
												disabled={
													isTaskCompleted ||
													isClaimedStatus
												}
												onClick={() =>
													handleAction(
														benefit.enterpriseCurrentRequestStatus,
													)
												}
											>
												{buttonText}
											</Button>
										</div>
									</div>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default EarnSection;
