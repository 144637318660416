import { useContext } from 'react';
import { RewardStoreContext } from '../providers/reward-store.provider';

export const useRewardStore = () => {
	const context = useContext(RewardStoreContext);

	if (context === undefined) {
		throw new Error('useRewardStore must be used within a RewardStoreContext');
	}

	return context;
};
