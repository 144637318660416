import { useDebounce } from '@/hooks/useDebounce';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { getKols } from '../services/kol-marketplace.service';
import useScrollToTop from '@/hooks/useScrollToTop';

export const useKOLPagination = (initialPageSize = 10) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const scrollToTop = useScrollToTop();

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
		scrollToTop();
	};

	const handlePageSizeChange = (newPageSize: number) => {
		setPageSize(newPageSize);
		setCurrentPage(1);
	};

	return {
		currentPage,
		pageSize,
		setCurrentPage,
		handlePageChange,
		handlePageSizeChange,
	};
};

export const useKOLFilters = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		language: [],
		region: [],
		channels: [],
		price: [],
		followers: [],
	});
	const debouncedSearch = useDebounce(search, 500);

	return {
		search,
		setSearch,
		filters,
		setFilters,
		debouncedSearch,
	};
};

export const useKOLData = (
	debouncedSearch: string,
	filters: any,
	pageSize: number,
	currentPage: number,
) => {
	const { data, status, refetch, isFetching } = useQuery({
		queryKey: [
			'kols-marketplace',
			debouncedSearch,
			filters,
			pageSize,
			currentPage,
		],
		queryFn: () =>
			getKols({
				limit: pageSize,
				page: currentPage,
				search: debouncedSearch,
				...filters,
			}),
	});

	const { kolsData, totalPages, totalCount } = useMemo(
		() => ({
			kolsData: data?.kols || [],
			totalPages: Math.max(1, Math.ceil((data?.pages || 0) / pageSize)),
			totalCount: data?.pages || 0,
		}),
		[data, pageSize],
	);

	return { kolsData, totalPages, totalCount, status, refetch, isFetching };
};
