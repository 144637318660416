import { toast } from 'sonner';
import {
	AlphaHubNarrativeStatus,
	EventsCreateEnum,
	IAlphaHubNarrativeIcon,
} from '../../types/events.type';
import { useCreateEvent } from './useCreateEvent';
import { useState } from 'react';
import { uploadFile } from '@/services/utility.service';
import { upsertEvent } from '../../services/event.service';
import { handleErrorMessage } from '@/utils/notifications';
import { Button } from '@/components/ui/button';
import { QUEST_URL } from '@/config';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '@/lib/react-query';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

interface ICreateEvent {
	_id?: string;
	title: string;
	description: string;
	banner: string;
	startDate: Date;
	endDate: Date;
	timezone: string;
	campaigns: string[];
	network: number;
	sections: {
		title: string;
		campaigns: {
			campaignId: string;
			tag?: string;
		}[];
	}[];
	status: AlphaHubNarrativeStatus;
	bannerPosition: {
		x?: number;
		y?: number;
	};
	projectsIncluded: {
		name: string;
		logo: string;
		keyMetric: {
			value: string;
			label: string;
		};
	}[];
	rewards: {
		title: string;
		description: string;
	};
}

export const useCreateEventNavigation = () => {
	const navigate = useNavigate();
	const { section, setSection, details, sections, setErrors, id, setIsDirty } =
		useCreateEvent();
	const [isLoading, setIsLoading] = useState({
		publish: false,
		draft: false,
	});

	const moveForward = () => {
		if (section === EventsCreateEnum.Details) {
			setSection(EventsCreateEnum.Campaigns);
			return;
		}
	};

	const moveBack = () => {
		if (section === EventsCreateEnum.Campaigns) {
			setSection(EventsCreateEnum.Details);
			return;
		}
	};

	const previewEvent = (id: string) => {
		window.open(`${QUEST_URL}/events/${id}`, '_blank');
	};

	const saveDraft = async () => {
		if (!validate()) return;
		try {
			setIsLoading((p) => ({ ...p, draft: true }));
			setIsDirty(false);
			const res = await saveEvent(AlphaHubNarrativeStatus.Draft);
			analytics.track(TrackingEvents.EventCreateAction, {
				action: 'draft',
				eventId: res._id,
				eventTitle: res.title,
				eventStatus: res.status,
				isUpdate: !!id,
			});
			await queryClient.invalidateQueries({
				queryKey: ['event', res._id],
			});
			await queryClient.invalidateQueries({
				queryKey: ['events'],
			});
			if (!id) {
				navigate(`/app/campaign/events/${res._id}/update`);
			}
			toast.success('Draft saved successfully', {
				description: 'Check out how does your event look on the platform.',
				action: (
					<Button onClick={() => previewEvent(res._id)}>Preview</Button>
				),
				closeButton: true,
				duration: 2000,
			});
			setIsLoading((p) => ({ ...p, draft: false }));
		} catch (err) {
			setIsDirty(true);
			setIsLoading((p) => ({ ...p, draft: false }));
			handleErrorMessage(true);
			console.log(err);
		}
	};

	const publishDraft = async () => {
		if (!validate()) {
			toast.error('Please fill all the required fields');
			return;
		}
		try {
			setIsLoading((p) => ({ ...p, publish: true }));
			setIsDirty(false);
			const res = await saveEvent(AlphaHubNarrativeStatus.Active);
			analytics.track(TrackingEvents.EventCreateAction, {
				action: 'publish',
				eventId: res._id,
				eventTitle: res.title,
				eventStatus: res.status,
				isUpdate: !!id,
			});
			await queryClient.invalidateQueries({
				queryKey: ['event', res._id],
			});
			await queryClient.invalidateQueries({
				queryKey: ['events'],
			});
			toast.success('Event published successfully', {
				description: 'Check out how does your event look on the platform.',
				action: (
					<Button onClick={() => previewEvent(res._id)}>Preview</Button>
				),
				closeButton: true,
				duration: 2000,
			});
			navigate(`/app/campaign/events/${res._id}`);
			setIsLoading((p) => ({ ...p, publish: false }));
		} catch (err) {
			setIsDirty(true);
			setIsLoading((p) => ({ ...p, publish: false }));
			handleErrorMessage(true);
			console.log(err);
		}
	};

	const saveEvent = async (status: AlphaHubNarrativeStatus) => {
		if (!validate()) return;
		let bannerFile = details.bannerFileLink;
		if (details.bannerFile) {
			const banner = (await uploadResource(details.bannerFile)) as string;
			bannerFile = banner;
		}
		const body: ICreateEvent = {
			title: details.title,
			description: details.description,
			banner: bannerFile,
			startDate: details.startDate,
			endDate: details.endDate,
			timezone:
				details.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
			network: details.network,
			campaigns: sections
				.map((i) => i.campaigns)
				.flat()
				.map((i) => i._id),
			sections: sections.map((i) => ({
				title: i.title,
				campaigns: i.campaigns.map((j) => ({ campaignId: j._id })),
			})),
			bannerPosition: details.bannerPosition,
			status: status,
			projectsIncluded: details.projectsIncluded,
			rewards: {
				title: details.reward.title,
				description: details.reward.description,
			},
		};
		if (id) {
			body._id = id;
		}
		const res = await upsertEvent(body);
		return res;
	};

	const uploadResource = async (file: any) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					uploadFile(file).then((res) => {
						console.log(res);
						resolve(res);
					}),
				]),
				{
					loading: 'Uploading banner...',
					success: 'Banner uploaded successfully',
					error: 'Failed to upload',
				},
			);
		});
	};

	const validate = () => {
		const newErrors = {
			details: {
				title: false,
				description: false,
				startDate: false,
				endDate: false,
				duration: false,
				banner: false,
			},
			campaigns: false,
		};
		const {
			title,
			startDate,
			description,
			endDate,
			bannerFile,
			bannerFileLink,
		} = details;

		if (!title) newErrors.details.title = true;
		if (!description) newErrors.details.description = true;

		if (!startDate) newErrors.details.duration = true;
		if (!endDate) newErrors.details.duration = true;
		if (endDate < startDate) newErrors.details.duration = true;

		if (!bannerFileLink && !bannerFile) newErrors.details.banner = true;
		if (sections.length === 0) newErrors.campaigns = true;

		if (sections.some((item) => !item.title)) newErrors.campaigns = true;
		if (
			sections.length > 0 &&
			sections.some((item) => item.campaigns.length === 0)
		) {
			newErrors.campaigns = true;
		}
		setErrors(newErrors);
		setErrors((prev: any) => ({
			...prev,
			sections: {
				campaigns: newErrors.campaigns,
				details:
					Object.values(newErrors.details).filter((error) => error)
						.length !== 0,
			},
		}));
		return (
			Object.values(newErrors.details).filter((error) => error).length === 0 &&
			!newErrors.campaigns
		);
	};

	return {
		moveForward,
		moveBack,
		forwardLabel: 'Next',
		isLoading,
		publishDraft,
		saveDraft,
	};
};
