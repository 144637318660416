import InputText from '@/components/element/inputs/InputText';
import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { useRewardStoreSections } from '../hooks/useRewardStoreSections';
import { useRewardStore } from '../hooks/useRewardStore';
import { Textarea } from '@/components/ui/textarea';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { useEffect } from 'react';

const AddRewardSection = ({ open, setOpen, resetSectionDialog }) => {
	const { isAddingSection, addSection, section, setSection, errors } =
		useRewardStoreSections({ setOpen });

	const { formFields, setFormFields, formErrors, resetRewardForm } =
		useRewardStore();

	useEffect(() => {
		if (resetSectionDialog) {
			setSection({
				name: '',
				description: '',
			});
			resetRewardForm({ sectionId: '' });
		}
	}, [resetSectionDialog, setSection]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle>Add Reward Section</DialogTitle>
					<DialogDescription>
						Create a new section for your rewards
					</DialogDescription>
				</DialogHeader>
				<div className="my-2 flex flex-col gap-6">
					<InputText
						label="Section Title"
						value={section?.name}
						setValue={(e) =>
							setSection({
								...section,
								name: e,
							})
						}
						error={errors?.name}
						placeholder="Untitled Section"
						errorText={errors.name}
					/>
					<InputTextArea
						label="Section Description"
						value={section?.description}
						setValue={(e) =>
							setSection({
								...section,
								description: e,
							})
						}
						error={errors?.description}
						placeholder="Untitled Description"
						errorText={errors.description}
					/>
				</div>
				<DialogFooter className="justify-between space-x-2 border-t pt-3 mt-">
					<Button
						disabled={isAddingSection}
						onClick={() => {
							addSection();
						}}
					>
						{isAddingSection && <Spinner className="me-2" />}
						{formFields?.sectionId ? 'Update Section' : 'Create Section'}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddRewardSection;
