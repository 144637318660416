import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import { Dispatch, SetStateAction } from 'react';
import { CreditRequestApprovalStatus } from '../../types/admin-credit.enums';
import { CreditRequestTypeEnum } from '@/features/intract-credits/types/credits.enums';
import StatusBadge from '@/components/element/badges/StatusBadge';

const AdminCreditsFilters = ({
	search,
	setSearch,
	filters,
	setFilters,
}: {
	search: {
		value: string;
	};
	setSearch: Dispatch<SetStateAction<{ value: string }>>;
	filters: {
		status: string[];
		requestType: string[];
	};
	setFilters: Dispatch<
		SetStateAction<{
			status: string[];
			requestType: string[];
		}>
	>;
}) => {
	return (
		<div className="flex items-center mt-10 space-x-4">
			<InputText
				value={search.value}
				setValue={(e) => setSearch({ value: e })}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search by name, email or id"
				className="w-[250px] lg:w-[350px]"
			/>
			<FacetedFilter
				title="Approval Status"
				options={ApprovalStatus}
				selectedValues={filters.status}
				setSelectedValues={(values: any) =>
					setFilters((p) => ({ ...p, status: values }))
				}
			/>
			<FacetedFilter
				title="Requested Benefit"
				options={RequestedBenefit}
				selectedValues={filters.requestType}
				setSelectedValues={(values: any) =>
					setFilters((p) => ({ ...p, requestType: values }))
				}
			/>
		</div>
	);
};

export default AdminCreditsFilters;

const ApprovalStatus = [
	{
		value: CreditRequestApprovalStatus.Approved,
		label: <StatusBadge status={CreditRequestApprovalStatus.Approved} />,
	},
	{
		value: CreditRequestApprovalStatus.Rejected,
		label: <StatusBadge status={CreditRequestApprovalStatus.Rejected} />,
	},
	{
		value: CreditRequestApprovalStatus.Pending,
		label: <StatusBadge status={CreditRequestApprovalStatus.Pending} />,
	},
];

const RequestedBenefit = [
	{ value: CreditRequestTypeEnum.AirdropWidget, label: 'Airdrop Widget' },
	{ value: CreditRequestTypeEnum.BuzzingWidget, label: 'Buzzing Widget' },
	{ value: CreditRequestTypeEnum.ChainIntegration, label: 'Chain Integration' },
	{
		value: CreditRequestTypeEnum.GeneralNotification,
		label: 'General Notification',
	},
	{ value: CreditRequestTypeEnum.LeaderBoardExport, label: 'Leaderboard Export' },
	{ value: CreditRequestTypeEnum.ReTweetTwitter, label: 'Twitter RT/QRT' },
	{
		value: CreditRequestTypeEnum.VerificationTwitter,
		label: 'Twitter Verification ',
	},
];
