import * as React from 'react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useLogin } from '../hooks/useLogin';
import SeparatorWithText from '@/components/ui/seprator-with-text';
import { Link } from 'react-router-dom';

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
	const [showPassword, setShowPassword] = React.useState(false);
	const {
		handleSubmit,
		onSubmit,
		register,
		isLoading,
		loginWithDiscord,
		loginWithGoogle,
		isDiscordLoading,
		isGoogleLoading,
		errors,
	} = useLogin();

	return (
		<div className={cn('grid gap-6', className)} {...props}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid gap-2">
					<div className="grid gap-1">
						<Label className="font-normal" htmlFor="email">
							Email
						</Label>
						<Input
							id="email"
							placeholder="name@example.com"
							type="email"
							autoCapitalize="none"
							autoComplete="email"
							autoCorrect="off"
							{...register('email', { required: true })}
							className={errors.email ? 'border-destructive' : ''}
						/>
						{errors.email && (
							<p className={'text-xs text-destructive'}>
								{errors.email.message}
							</p>
						)}
						<Label className="mt-4 font-normal" htmlFor="password">
							Password
						</Label>
						<div className="relative">
							<Input
								id="password"
								placeholder="********"
								type={showPassword ? 'text' : 'password'}
								autoCapitalize="none"
								autoComplete="email"
								autoCorrect="off"
								{...register('password', { required: true })}
								className={
									errors.password ? 'border-destructive' : ''
								}
							/>
							<span
								className="absolute right-2 top-2 cursor-pointer text-muted-foreground"
								onClick={() => setShowPassword((prev) => !prev)}
							>
								<i
									className={`bi-${showPassword ? 'eye-slash' : 'eye'}`}
								></i>
							</span>
						</div>
						{errors.password && (
							<p className={'text-xs text-destructive'}>
								{errors.password.message}
							</p>
						)}
					</div>
					<div className="text-end mb-2">
						<Link
							to="/auth/forgot-password"
							className="hover:text-brand text-muted-foreground text-right py-1 text-sm underline underline-offset-2"
						>
							Forgot password
						</Link>
					</div>
					<Button disabled={isLoading}>
						{isLoading && (
							<i className="bi-arrow-clockwise mr-2  animate-spin" />
						)}
						<span>Sign In</span>
					</Button>
				</div>
			</form>
			<SeparatorWithText text="Or continue with" textClassName="px-1" />
			<div className="flex flex-col space-y-3">
				<Button
					onClick={() => loginWithDiscord()}
					variant="outline"
					disabled={isLoading || isDiscordLoading}
				>
					{isDiscordLoading && (
						<i className="bi-arrow-clockwise mr-2  animate-spin" />
					)}
					<i className="bi-discord text-blue-700 me-2" />
					<span>Discord</span>
				</Button>
				<Button
					onClick={() => loginWithGoogle()}
					variant="outline"
					disabled={isLoading || isGoogleLoading}
				>
					{isGoogleLoading && (
						<i className="bi-arrow-clockwise mr-2  animate-spin" />
					)}
					<i className="bi-google text-red-500 me-2" />
					<span>Google</span>
				</Button>
			</div>
		</div>
	);
}
