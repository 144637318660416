import { useCampaigns } from '@/features/campaigns/hooks/report/useCampaigns';
import { ICampaign, Status } from '@/features/campaigns/types';
import { useAuth } from '@/hooks/useAuth';
import { useRouter } from '@/hooks/useRouter';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { useEffect, useState } from 'react';

const useCreditsNux = () => {
	const [open, setOpen] = useState(false);
	const [step, setStep] = useState<'benefits' | 'earn'>('benefits');
	const { user } = useAuth();
	const { campaigns } = useCampaigns();
	const { query, setQueryParam } = useRouter();

	const loyaltyCampaign: ICampaign = queryClient.getQueryData([
		'campaign',
		'loyalty-quests',
	]);

	const isFirstCampaignLaunched = campaigns?.some(
		(campaign) =>
			campaign.status === Status.Active ||
			campaign.status === Status.Scheduled ||
			campaign.status === Status.Completed,
	);
	const isFirstLoayltyCampaignLaunched =
		loyaltyCampaign?.status === Status.Active ||
		loyaltyCampaign?.status === Status.Completed;

	const handleNext = () => setStep('earn');
	const handlePrevious = () => setStep('benefits');

	const getTaskCompletedStatus = (index: number) => {
		if (index === 0) {
			return !!user?._id;
		}
		if (index === 1) {
			return isFirstCampaignLaunched || isFirstLoayltyCampaignLaunched;
		}
		return false;
	};

	const handleOpenNux = () => {
		setOpen(true);

		analytics.track(TrackingEvents.CreditsNUXClicked, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	};

	const handleCloseNux = () => {
		setStep('benefits');
		setOpen(false);
		if (query?.openNux === 'true') {
			const url = new URL(window.location.href);
			url.searchParams.delete('openNux');
			window.history.replaceState(null, '', url.toString());
			setQueryParam('openNux', null);
		}
	};

	useEffect(() => {
		if (query?.openNux === 'true') {
			setStep('benefits');
			setOpen(true);
		}
	}, [query]);

	return {
		open,
		setOpen,
		step,
		setStep,
		handleNext,
		handlePrevious,
		getTaskCompletedStatus,
		handleOpenNux,
		handleCloseNux,
	};
};

export default useCreditsNux;
