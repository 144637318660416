import InputSelect2 from '@/components/element/inputs/InputSelect2';
import InputText from '@/components/element/inputs/InputText';
import InputWrapper from '@/components/element/inputs/InputWrapper';
import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import SeparatorWithText from '@/components/ui/seprator-with-text';
import { useGetActions } from '@/features/dave/hooks/actions/useGetActions';
import ConditionOptions from './ConditionOptions';
import InputSwitch from '@/components/element/inputs/InputSwitch';
import { QueryListType } from '@/features/dave/types/dave.types';
import { useCreateQueryProvider } from '@/features/dave/hooks/queries/useCreateQueryProvider';
import TokenTransferConfig from './TokenTransferConfig';
import InputRadioCards from '@/components/element/inputs/InputRadioCards';
import { QueryCategory } from '@/features/dave/types/dave.enum';

interface ConditionCardProps extends QueryListType {
	error: {
		id?: string;
		actionTag: boolean;
		inflowAddressesList?: boolean;
		outflowAddressesList?: boolean;
		inflowAmount?: boolean;
		outflowAmount?: boolean;
		countCondition: boolean;
	};
}

export const ConditionCard = (props: ConditionCardProps) => {
	const { setDetails } = useCreateQueryProvider();
	const { actions } = useGetActions('');

	const updateQuery = (key: string, value: any) => {
		setDetails((p) => {
			const queryList = p.queryList.map((q) => {
				if (q.id === props.id) {
					return { ...q, [key]: value };
				}
				return q;
			});
			return { ...p, queryList };
		});
	};

	const updateIsChecked = (key: string, value: any) => {
		setDetails((p) => {
			const queryList = p.queryList.map((q) => {
				if (q.id === props.id) {
					return { ...q, isChecked: { ...q.isChecked, [key]: value } };
				}
				return q;
			});
			return { ...p, queryList };
		});
	};

	return (
		<Card className="mb-5 w-[600px] relative">
			<CardContent className="p-5 px-0">
				<div className="w-full  px-5 flex justify-between ">
					<InputWrapper
						label="Choose an Action"
						description="Select an action from the list that matches the user activity you want to track"
						tooltip="Select an action from the list that matches the user activity you want to track"
						labelClassName="font-medium mb-1"
						className="w-full"
					>
						<InputSelect2
							appendIcon="bi bi-search"
							placeholder="Select Action"
							value={props.actionTag}
							setValue={(e) => {
								const action = actions?.find(
									(i) => i.actionTag === e,
								);
								updateQuery('actionTag', e);
								updateQuery('chainId', action.chainId);
							}}
							error={props.error?.actionTag}
							errorText="Please select an action"
							options={
								actions
									?.map((i) => ({
										label: i.name,
										value: i.actionTag,
										chainId: i.chainId,
									}))
									.filter((i, index, arr) => {
										return (
											arr.findIndex(
												(x) => x.value === i.value,
											) === index
										);
									}) ?? []
							}
							popoverClassName="w-[32.7rem]"
						/>
					</InputWrapper>
					<ConditionOptions {...props} />
				</div>
				{props.actionTag && (
					<>
						<SeparatorWithText
							text="Define Condition Details"
							className="mt-8 px-5"
							align="start"
							textClassName="font-semibold text-black px-1"
						/>
						<div className="px-5 pt-3">
							<p className="text-xs text-muted-foreground">
								Configure the minimum token transfer requirements.
								You can set criteria for tokens coming in (to the
								user), going out (from the user), or both.
							</p>
							<div className="mt-4">
								<InputRadioCards
									options={[
										{
											label: 'Minimum Token Transfer In',
											icon: 'bi-arrow-down-circle',
											isChecked: props.isChecked.tokenOut,
											setIsChecked: () => {
												updateIsChecked(
													'tokenOut',
													!props.isChecked.tokenOut,
												);
											},
										},
										{
											label: 'Minimum Token Transfer Out',
											icon: 'bi-arrow-up-circle',
											isChecked: props.isChecked.tokenIn,
											setIsChecked: () => {
												updateIsChecked(
													'tokenIn',
													!props.isChecked.tokenIn,
												);
											},
										},
									]}
								/>
							</div>
							{(props.isChecked.tokenIn ||
								props.isChecked.tokenOut) && (
								<Separator className="mt-4" />
							)}
							{/**
							 *  FE tokenIn means txn is IN to user's address
							 *  but
							 *  BE tokenIn means contract token IN so, use tokenOut from API
							 */}
							{props.isChecked.tokenOut && (
								<TokenTransferConfig
									title="Minimum Token Transfer In"
									description="Specify the criteria for incoming token transfers. Define the minimum amount and the types of tokens that must be received by the user."
									tooltip={`Define the minimum amount a user must recieve for the transaction to be valid. For example, '25' for transactions of $25 or more`}
									icon="bi-arrow-down-circle"
									amount={props.outflowAmount}
									setAmount={(e) =>
										updateQuery('outflowAmount', e)
									}
									tokenSet={props.outflowAddressesList}
									setTokenSet={(e) =>
										updateQuery('outflowAddressesList', e)
									}
									chainId={props.chainId}
									isAnyToken={props.eligibleOutflowAddressesTag}
									setIsAnyToken={(e) =>
										updateQuery('eligibleOutflowAddressesTag', e)
									}
									amountError={props.error?.outflowAmount}
								/>
							)}
							{props.isChecked.tokenIn && props.isChecked.tokenOut && (
								<Separator className="mt-4" />
							)}
							{/**
							 *  FE tokenOut means txn in OUT from user's address
							 *  but
							 *  BE tokenOut means contract token IN so, use tokenIn from API
							 */}
							{props.isChecked.tokenIn && (
								<TokenTransferConfig
									title="Minimum Token Transfer Out"
									description="Set the conditions for outgoing token transfers. Choose the minimum amount and the allowed tokens that the user must send."
									tooltip={`Define the minimum amount a user must send for the transaction to be valid. For example, '25' for transactions of $25 or more`}
									icon="bi-arrow-up-circle"
									amount={props.inflowAmount}
									setAmount={(e) => updateQuery('inflowAmount', e)}
									tokenSet={props.inflowAddressesList}
									setTokenSet={(e) =>
										updateQuery('inflowAddressesList', e)
									}
									chainId={props.chainId}
									isAnyToken={props.eligibleInflowAddressesTag}
									setIsAnyToken={(e) =>
										updateQuery('eligibleInflowAddressesTag', e)
									}
									amountError={props.error?.inflowAmount}
								/>
							)}
						</div>
						{props.isChecked.multipleTxn && (
							<>
								<Separator className="mt-4" />
								<div className="mt-4 px-5">
									<InputText
										label="Minimum number of trasactions"
										placeholder="Enter Condition Name"
										subLabel={`Enter the minimum number of transactions that must occur. E.g., '3' means the user needs to complete at least three qualifying transactions`}
										tooltip="Enter the minimum number of transactions that must occur. E.g., '3' means the user needs to complete at least three qualifying transactions"
										value={props.countCondition}
										setValue={(value) => {
											updateQuery('countCondition', value);
										}}
										error={props.error?.countCondition}
										errorText="Please enter a valid number"
										onFocus={(e) => {
											e.target.select();
										}}
									/>
								</div>
							</>
						)}
						{props.isChecked.aggregatedVolume && (
							<>
								<Separator className="mt-4" />
								<div className="mt-6 px-5">
									<InputSwitch
										label="Aggregate Transaction Volume"
										subLabel={`Select this option if the total value of all qualifying transactions combined should reach a minimum threshold`}
										tooltip="Enter the minimum number of transactions that must occur. E.g., '3' means the user needs to complete at least three qualifying transactions"
										value={
											props.category ===
											QueryCategory.MultipleTransaction
										}
										setValue={(checked) => {
											updateQuery(
												'category',
												checked
													? QueryCategory.MultipleTransaction
													: QueryCategory.SingleTransaction,
											);
										}}
									/>
								</div>
							</>
						)}
					</>
				)}
			</CardContent>
		</Card>
	);
};
