import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/element/data-table/DataTable';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { RewardTypesMap } from '../types/reward-store.enums';
import { IReward, IRewardStore } from '../types/reward-store.type';
import { Badge } from '@/components/ui/badge';

interface IRewardStoreTable {
	name: string;
	totalClaims: number;
	status: string;
	rewardType: string;
	sectionName: string;
}

const RewardStoreTable = ({ filteredRewardStore }) => {
	const formattedData = filteredRewardStore.flatMap((section: IRewardStore) =>
		section.rewards.map((reward: IReward) => ({
			...reward,
			sectionName: section.name,
		})),
	);

	return (
		<div>
			{filteredRewardStore.length > 0 ? (
				<div className="mt-6">
					<DataTable
						data={formattedData}
						columns={rewardStoreColumns}
						totalCount={filteredRewardStore.reduce(
							(acc: any, section: IRewardStore) =>
								acc + section.rewards.length,
							0,
						)}
					/>
				</div>
			) : (
				<div className="flex items-center justify-center p-10 border rounded-lg">
					<p className="text-sm">
						No rewards found. Try changing your search criteria.
					</p>
				</div>
			)}
		</div>
	);
};

export default RewardStoreTable;

const rewardStoreColumns: ColumnDef<IRewardStoreTable>[] = [
	{
		accessorKey: 'name',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Name" />
		),
		cell: ({ row }) => (
			<div className="p-1 w-[18rem] truncate">{row?.original?.name}</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'totalClaims',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Total Claims" />
		),
		cell: ({ row }) => <div className="p-1">{row?.original?.totalClaims}</div>,
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'status',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Status" />
		),
		cell: ({ row }) => (
			<StatusBadge className="text-xs" status={row.original.status} />
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'rewardType',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Reward Type" />
		),
		cell: ({ row }) => (
			<div className="p-1">{RewardTypesMap[row?.original?.rewardType]}</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'sectionName',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Section Name" />
		),
		cell: ({ row }) => (
			<Badge variant="secondary" className="font-medium">
				{row?.original?.sectionName}
			</Badge>
		),
		enableSorting: true,
		enableHiding: false,
	},
];
