import SeparatorWithText from '@/components/ui/seprator-with-text';
import { cn } from '@/lib/utils';
import TaskInputs from '@/features/campaigns/components/create-campaign/tasks/components/Tasks/params/inputs/TaskInputs';
import { useLoyaltyTasks } from '@/features/loyalty-campaigns/hooks/tasks/useLoyaltyTasks';
import { useLoyaltyTasksEdit } from '@/features/loyalty-campaigns/hooks/tasks/useLoyaltyTasksEdit';
import TaskTitleAndLogo from '../inputs/TaskTitleAndLogo';
import InputText from '@/components/element/inputs/InputText';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import { RequiredLogins } from '@/features/campaigns/types';
import LoyaltyTaskToggleFeatures from './LoyaltyTaskToggleFeatures';

const LoyaltyTaskParams = ({ isUpdate }) => {
	const { setErrors, errors, task, template, setTask } = useLoyaltyTasks();
	const { editTaskAdminInput } = useLoyaltyTasksEdit();
	const isDisabled = task?.isDisabled;
	return (
		<div className="min-h-[420px] flex flex-col mb-4">
			<TaskTitleAndLogo isUpdate={isUpdate} />
			{task?.adminInputs?.length === 0 &&
				!template?.isCustomInitiationURLAllowed &&
				!template?.featureApplicability?.recurrence &&
				!template?.featureApplicability?.maxFailedCount &&
				!template?.isCustomInitiationURLAllowed &&
				!template?.isAdminRequiredLoginsSelectionNeeded && (
					<div className="mt-10">
						<div className="text-sm text-gray-500">
							No parameters are required for this task.
						</div>
					</div>
				)}
			<div className="mt-10"></div>
			{(task?.adminInputs?.length > 0 ||
				template?.isCustomInitiationURLAllowed) && (
				<div className="">
					<SeparatorWithText
						align="start"
						text="Task Parameters"
						className="mb-5"
					/>
					<div className={`grid py- grid-cols-12 gap-6 gap-y-8`}>
						{task.adminInputs
							?.filter((i) => !i.hide)
							.map((i) => (
								<div className={cn(`${i.size}`)}>
									<TaskInputs
										adminInput={i}
										adminInputs={task.adminInputs}
										templateFamily={task.templateFamily}
										templateType={task.templateType}
										description={task.description}
										taskId={task._id}
										error={errors?.tasks?.[task._id]?.[i.key]}
										editTaskAdminInput={editTaskAdminInput}
										setErrors={(taskId, key, value) => {
											setErrors((prev: any) => ({
												...prev,
												tasks: {
													...prev?.tasks,
													[taskId]: {
														...prev.tasks?.[taskId],
														[key]: value,
													},
												},
											}));
										}}
										isUpdate={isUpdate}
										src="loyalty"
									/>
								</div>
							))}
					</div>
				</div>
			)}
			{template?.isCustomInitiationURLAllowed && !isDisabled && (
				<div className="col-span-12 mt-5">
					<InputText
						value={task.customInitiationURL}
						setValue={(e) => {
							setTask((prev) => ({
								...prev,
								customInitiationURL: e,
							}));
						}}
						label="Initiation URL (optional)"
						placeholder="https://www.example.com"
						subLabel="Add link where users will be redirected to start the task."
						subLabelClassName="font-normal text-xs mt-1"
						error={errors?.customInitiationURL}
						errorText={errors?.customInitiationURL}
					/>
				</div>
			)}
			{template.isAdminRequiredLoginsSelectionNeeded && !isDisabled && (
				<div className="col-span-12 mt-5">
					<InputSelect2
						value={task.requiredLogins?.[0]}
						setValue={(e) => {
							setTask((prev) => ({
								...prev,
								requiredLogins: [e as RequiredLogins],
							}));
						}}
						options={
							template.requiredLogins?.map((i: RequiredLogins) => ({
								label: i,
								value: i,
							})) ?? []
						}
						label="Required Login"
						subLabel="Select the login method required to complete the task."
						subLabelClassName="font-normal text-xs my-2"
					/>
				</div>
			)}
			{/* {!isDisabled && (
				<div className="mt-auto -mx-5 -mb-4">
					<LoyaltyTaskToggleFeatures />
				</div>
			)} */}
		</div>
	);
};

export default LoyaltyTaskParams;
