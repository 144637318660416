import FullScreenCreateLayout from '@/components/layout/full-screen-create/FullScreenCreateLayout';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useNavigate } from 'react-router-dom';
import { useCreateActionProvider } from '../../../hooks/actions/useCreateActionProvider';
import DaveActionCreateNavigation from './DaveActionCreateNavigation';
import DaveActionCreateSection from './DaveActionCreateSection';
import { links } from '@/config/links';

const DaveCreateActionLayout = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate();
	const { details } = useCreateActionProvider();

	const { enterprise } = useGetEnterprise();

	return (
		<FullScreenCreateLayout showGrid={false}>
			<FullScreenCreateLayout.Header>
				<FullScreenCreateLayout.Title
					name={details?.name}
					placeholder={'Untitled Action'}
					onFeedback={() => {
						window.open(links?.feedbackForm, '_blank');
					}}
					onBack={() => navigate('/app/tools/dave/actions')}
					enterpriseCreditsBalance={enterprise?.credits?.balanceCredits}
				/>
				<FullScreenCreateLayout.Sections>
					<DaveActionCreateSection />
				</FullScreenCreateLayout.Sections>
			</FullScreenCreateLayout.Header>
			<FullScreenCreateLayout.Content>
				{children}
			</FullScreenCreateLayout.Content>
			<FullScreenCreateLayout.Navigation>
				<DaveActionCreateNavigation />
			</FullScreenCreateLayout.Navigation>
		</FullScreenCreateLayout>
	);
};

export default DaveCreateActionLayout;
