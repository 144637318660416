import { AdminInputType } from '@/features/campaigns/types';
import { LoyaltyTaskCreateSteps } from '../../types/loyalty.enums';
import { useLoyaltyTasks } from './useLoyaltyTasks';

export const useLoyaltyTasksValidate = () => {
	const { errors, setErrors, task, template } = useLoyaltyTasks();

	const validate = (step: LoyaltyTaskCreateSteps) => {
		if (step === LoyaltyTaskCreateSteps.TaskParams) {
			const newErrors = {
				description: '',
				customInitiationURL: '',
				tasks: {
					...errors?.tasks,
					[task._id]: {
						...errors?.tasks?.[task._id],
					},
				},
			};
			if (!task.description)
				newErrors['description'] =
					'Please add task description. This is what your users will see. ';
			for (const adminInput of task.adminInputs) {
				let error = newErrors['tasks'][task._id][adminInput.key];

				//  DOUBT
				if (error) {
				} else if (adminInput.optional) {
					error = false;
				} else if (
					[
						AdminInputType.Select,
						AdminInputType.SelectAsync,
						AdminInputType.SelectFromAdminInput,
					].includes(adminInput.inputType)
				) {
					if (!adminInput.value) {
						error = true;
					} else {
						error = false;
					}
				} else if (
					[AdminInputType.InputString].includes(adminInput.inputType)
				) {
					if (!adminInput.value) {
						error = true;
					}
				} else if (Array.isArray(adminInput.value)) {
					if (!adminInput.value?.length) {
						error = true;
					}
				} else {
					error = !!error;
				}
				newErrors['tasks'][task._id][adminInput.key] = error;
			}

			if (template?.isCustomInitiationURLAllowed && task.customInitiationURL) {
				const isValidURL = new RegExp(/^(https):\/\/[^ "]+$/).test(
					task.customInitiationURL,
				);
				if (!isValidURL) {
					newErrors.customInitiationURL =
						'Invalid URL, please enter a valid URL.';
				} else {
					newErrors.customInitiationURL = '';
				}
			}
			const isValid =
				Object.values(newErrors['tasks'][task._id]).every(
					(error) => !error,
				) &&
				!newErrors['description'] &&
				!newErrors.customInitiationURL;
			setErrors(newErrors);
			return isValid;
		}
		if (step === LoyaltyTaskCreateSteps.TaskFeatures) {
			if (task?.maxFailedCountConfig?.isMaxFailedCountEnabled) {
				const xp = task.xp;
				const max = task.maxFailedCountConfig.maxFailedCount;
				if (xp % max !== 0) {
					setErrors((p) => ({
						...p,
						maxFailedCountConfig:
							'XP should be divisible by max attempts',
					}));
					return false;
				}
			}
			return true;
		}
		if (step === LoyaltyTaskCreateSteps.TaskDuration) {
			let isValid = true;
			let message = '';
			if (!task.startImmediately && !task.noEndTime) {
				if (!task.taskStartDate) {
					isValid = false;
					message = 'Start date is required';
				}
				if (!task.taskEndDate) {
					isValid = false;
					message = 'End date is required';
				}
				if (new Date(task.taskStartDate) > new Date(task.taskEndDate)) {
					isValid = false;
					message = 'Selected start date should be before end date';
				}
			}
			if (!isValid) {
				setErrors((p) => ({ ...p, duration: message }));
			}
			return isValid;
		}
	};

	return {
		validate,
	};
};
