import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import {
	exportLeaderboardUsers,
	getLeaderboardUserCount,
	getLeaderboards,
} from '../services/leaderboard.service';
import { toast } from 'sonner';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';

export interface ISelectedLeaderboard {
	type: 'overall' | 'campaign' | 'event';
	id?: string;
}

export const useLeaderboard = ({
	campaignId,
	eventId,
}: {
	campaignId?: string;
	eventId?: string;
}) => {
	const [selectedLeaderboard, setSelectedLeaderboard] =
		useState<ISelectedLeaderboard>({
			type: 'overall',
			id: 'overall',
		});

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState([]);
	const [isExporting, setIsExporting] = useState(false);

	const { data, isLoading } = useQuery({
		queryKey: [
			'leaderboard',
			{
				pagination,
				campaignId: campaignId ? campaignId : selectedLeaderboard,
				eventId: eventId ? eventId : selectedLeaderboard,
			},
		],
		queryFn: () =>
			getLeaderboards(
				Number(pagination.pageIndex + 1) ?? 1,
				Number(pagination.pageSize) ?? 10,
				campaignId
					? campaignId
					: selectedLeaderboard?.type === 'campaign'
						? selectedLeaderboard.id
						: undefined,
				eventId
					? eventId
					: selectedLeaderboard?.type === 'event'
						? selectedLeaderboard.id
						: undefined,
			),
	});

	const { data: count, isLoading: isCountLoading } = useQuery({
		queryKey: [
			'leaderboard-count',
			campaignId ? campaignId : selectedLeaderboard,
		],
		queryFn: () =>
			getLeaderboardUserCount(
				campaignId
					? campaignId
					: selectedLeaderboard.type === 'campaign'
						? selectedLeaderboard.id
						: undefined,
				eventId
					? eventId
					: selectedLeaderboard.type === 'event'
						? selectedLeaderboard.id
						: undefined,
			),
	});

	const exportLeaderboard = async () => {
		try {
			setIsExporting(true);
			await exportLeaderboardUsers(
				campaignId
					? campaignId
					: selectedLeaderboard.type === 'campaign'
						? selectedLeaderboard.id
						: undefined,
				eventId
					? eventId
					: selectedLeaderboard.type === 'event'
						? selectedLeaderboard.id
						: undefined,
			);
			analytics.track(TrackingEvents.LeaderboardExportInitiated, {
				resourceType:
					campaignId || selectedLeaderboard.type === 'campaign'
						? 'campaign'
						: eventId || selectedLeaderboard.type === 'event'
							? 'event'
							: 'overall',
			});
			setIsExporting(false);
			toast.success(
				'Leaderboard is being exported, you will receive an email shortly.',
			);
		} catch (err) {
			handleErrorMessage(err);
			setIsExporting(false);
			console.log(err);
		}
	};

	return {
		users: data?.users ?? [],
		isLoading,
		totalCount: count?.count ?? 10,
		selectedLeaderboard,
		setSelectedLeaderboard,
		isCountLoading,
		pagination,
		setPagination,
		sorting,
		setSorting,
		exportLeaderboard,
		isExporting,
	};
};
