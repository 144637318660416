import { defineChain } from 'viem';

export const tabiTestnet = defineChain({
	id: 9789,
	name: 'TABI',
	nativeCurrency: { decimals: 18, name: 'TABI', symbol: 'TABI' },
	rpcUrls: {
		default: {
			http: ['https://rpc.testnet.tabichain.com'],
			webSocket: ['https://rpc.testnet.tabichain.com'],
		},
	},
	blockExplorers: {
		default: {
			name: 'BOB',
			url: 'https://testnet.tabiscan.com',
		},
	},
	testnet: false,
});
