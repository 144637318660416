import { useCommunityReferral } from './useCommunityReferral';

const useCriteriaItems = ({
	formFields,
	setFormFields,
	setFormErrors,
	setItems,
}) => {
	const { campaigns } = useCommunityReferral();

	const removeCriteria = (key: string, attribute: string) => {
		try {
			setItems((prevItems: number) => Math.max(prevItems - 1, 0));

			const updated = formFields?.[attribute]?.filter(
				(item) => item.condition !== key,
			);
			setFormFields((prev: any) => ({
				...prev,
				[attribute]: updated,
			}));
		} catch (error) {
			console.log(error);
		}
	};

	const handleChange = (newVal: any, attribute: string, idx: number) => {
		try {
			if (!/^\d*$/.test(newVal) && newVal !== '') {
				return;
			} else {
				setFormErrors((prev: any) => ({
					...prev,
					[formFields?.[attribute]?.[idx]?.condition]: '',
				}));

				const tempCriteria = [...(formFields?.[attribute] ?? [])];
				tempCriteria[idx] = {
					...tempCriteria[idx],
					value: newVal,
				};
				setFormFields((prev: any) => ({
					...prev,
					[attribute]: tempCriteria,
				}));
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleConditionChange = (newVal: any, attribute: string, idx: number) => {
		try {
			const tempCondition = [...(formFields?.[attribute] ?? [])];
			tempCondition[idx] = {
				...tempCondition[idx],
				condition: newVal,
			};

			setFormFields((prev: any) => ({
				...prev,
				[attribute]: tempCondition,
			}));
		} catch (err) {
			console.log(err);
		}
	};

	const handleCampaignChange = (
		newVal: string[],
		attribute: string,
		idx: number,
	) => {
		setFormErrors((prev: any) => ({
			...prev,
			[formFields?.[attribute]?.[idx]?.condition]: '',
		}));

		const newValue = newVal.map((campaignId) => ({
			campaignId,
			campaignName: campaigns.find((campaign) => campaign.value === campaignId)
				?.label,
		}));
		const tempCriteria = [...(formFields?.[attribute] ?? [])];
		tempCriteria[idx] = {
			...tempCriteria[idx],
			value: newValue,
		};
		setFormFields((prev: any) => ({
			...prev,
			[attribute]: tempCriteria,
		}));
	};

	return {
		removeCriteria,
		handleChange,
		handleConditionChange,
		handleCampaignChange,
	};
};

export default useCriteriaItems;
