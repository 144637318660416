import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { useRewardStore } from '../hooks/useRewardStore';
import NoDataCard from '@/components/element/cards/NoDataCard';
import RewardSections from './RewardSections';
import AddRewardSection from './AddRewardSection';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { RewardSectionTabs } from '../types/reward-store.enums';
import TabOptions from '@/components/element/tabs/TabOptions';
import RewardStoreAnalytics from './RewardStoreAnalytics';
import CreateNewReward from './CreateNewReward';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

const RewardStore = () => {
	const [showAddSection, setShowAddSection] = useState(false);
	const [showCreateNewReward, setShowCreateNewReward] = useState(false);
	const [steps, setSteps] = useState(1);
	const [resetSectionDialog, setResetSectionDialog] = useState(false);

	useLockBodyScroll(false);

	const {
		isLoading,
		id,
		open,
		setOpen,
		rewardStore,
		createSection,
		isCreating,
		selectedTab,
		setSelectedTab,
	} = useRewardStore();

	const options = [
		{
			value: RewardSectionTabs.Store,
			label: 'Rewards & Sections',
		},
		{
			value: RewardSectionTabs.Analytics,
			label: 'Analytics',
		},
	];

	if (isLoading) {
		return <FullPageLoading />;
	}

	return (
		<div>
			{rewardStore && rewardStore.length > 0 ? (
				<div className="mt-8 flex mb-8">
					<TabOptions
						selected={selectedTab}
						setSelected={setSelectedTab}
						options={options}
					/>
				</div>
			) : null}

			<>
				{rewardStore && rewardStore.length > 0 ? (
					<>
						{selectedTab === RewardSectionTabs.Analytics && (
							<RewardStoreAnalytics />
						)}
						{selectedTab === RewardSectionTabs.Store && (
							<>
								<div className=" space-y-10 mb-10">
									{rewardStore.map((section) => (
										<RewardSections
											key={section._id}
											rewardData={section}
											setShowAddSection={setShowAddSection}
											setCreateNewReward={
												setShowCreateNewReward
											}
											steps={steps}
											setSteps={setSteps}
										/>
									))}
									<AddRewardSection
										open={showAddSection}
										setOpen={setShowAddSection}
										resetSectionDialog={resetSectionDialog}
									/>
									<CreateNewReward
										open={showCreateNewReward}
										setOpen={setShowCreateNewReward}
										steps={steps}
										setSteps={setSteps}
									/>
								</div>
								<Button
									variant="outline"
									onClick={() => {
										setShowAddSection(true);
										setResetSectionDialog(true);
									}}
								>
									+ Add Section
								</Button>
							</>
						)}
					</>
				) : (
					<NoDataCard
						title="Launch your reward store"
						description="Create a new reward store to get started."
						btnLabel="Set Up Reward Store"
						isLoading={isCreating}
						btnOnClick={() =>
							createSection({
								name: 'New Section',
								description: 'New Section Description',
							})
						}
						className="mt-10"
						image="https://static.highongrowth.xyz/enterprise/66596dd621949f76bb468348/7484f1d2ca1647df96772416f8b05835.png"
					/>
				)}
			</>
		</div>
	);
};

export default RewardStore;
