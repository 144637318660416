import { Button } from '@/components/ui/button';
import LandingContainer from '../ui/LandingContainer';
import { homepageContent } from '../../content/homepage.content';
import { useRouter } from '@/hooks/useRouter';
import { cn } from '@/lib/utils';
import { BackgroundSVG } from './BackgroundSVG';

const Hero = ({ style }: { style?: string }) => {
	const { navigate, location } = useRouter();
	const content = homepageContent['hero'];

	return (
		<LandingContainer className={style ? style : 'py-20 px-0'}>
			<BackgroundSVG />
			<div className={cn('relative  space-y-12 md:space-y-20 text-left')}>
				<div className="flex w-full flex-col">
					<div className="text-card items-center text-center justify-center md:items-center md:text-center md:justify-center">
						<div className="flex flex-col items-center text-center justify-center md:items-center md:text-center md:justify-center">
							<div className="flex flex-col space-y-4 md:space-y-8 items-center justify-center md:items-center md:text-center md:justify-center">
								{/* <Badge link={content.badge.link}>
									{content.badge.label}
								</Badge> */}
								<div className={cn('relative px-6', style)}>
									<h1 className="w-full transition-colors text-4xl lg:text-7xl font-semibold  leading-tight   text-black">
										{content.title}
									</h1>
								</div>
								<div
									className={cn(
										'text-lg transition-colors text-zinc-500  w-full',
										style,
									)}
									style={{ maxWidth: 750 }}
								>
									<p>{content.description}</p>
								</div>
							</div>
							<div className=" flex w-full  gap-4 xxs:flex-row md:w-auto items-center pt-10  text-center justify-center md:items-center md:text-center md:justify-center ">
								{location.pathname?.includes('/auth/') ? null : (
									<Button
										className="rounded-xl"
										onClick={() => {
											if (
												content.cta.primary.link.includes(
													'http',
												)
											) {
												window.open(
													content.cta.primary.link,
													'_blank',
												);
											} else {
												navigate(content.cta.primary.link);
											}
										}}
									>
										<span className="me-2">
											{content.cta.primary.label}
										</span>
										<i className="bi-chevron-right ms-1"></i>
									</Button>
								)}
								<Button
									variant="link"
									onClick={() => {
										if (
											content.cta.secondary.link.includes(
												'http',
											)
										) {
											window.open(
												content.cta.secondary.link,
												'_blank',
											);
										} else {
											navigate(content.cta.secondary.link);
										}
									}}
								>
									<span className="me-2">
										{content.cta.secondary.label}
									</span>
									<i className="bi-chevron-right ms-1"></i>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default Hero;

const Badge = ({ link, children }) => {
	return (
		<a
			className="group flex items-center gap-2 rounded-[5px] px-3 py-1 lg:px-4 lg:py-2 font-inter text-[12px] leading-normal tracking-[0.1px]  xs:gap-5 xs:text-[12px] xs:tracking-[0.14px] sm:pl-4 sm:text-[12px] md:py-[5.5px] md:px-[17.5px] self-center bg-zinc-100 text-black"
			target="_blank"
			href={link}
		>
			{children}
		</a>
	);
};
