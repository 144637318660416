import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { useEffect, useState } from 'react';
import { RewardType, rewardIconMap } from '../types/reward-store.enums';
import { useAuth } from '@/hooks/useAuth';
import { cn } from '@/lib/utils';
import InputText from '@/components/element/inputs/InputText';
import RewardNFTSelect from '@/features/campaigns/components/create-campaign/rewards/components/reward-methods/reward/RewardNFTSelect';
import { INFTs } from '@/features/nft-contracts/providers/NFTsContractProvider';
import SelectDiscordRole from '@/features/campaigns/components/create-campaign/rewards/components/input/SelectDiscordRole';
import { ContractType, useGetContract } from '@/hooks/useGetContract';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useRewardStore } from '../hooks/useRewardStore';

const SelectRewardType = () => {
	const [chainId, setChainId] = useState(null);

	const { user } = useAuth();
	const { enterprise } = useGetEnterprise();

	const { formFields, setFormFields, formErrors, setFormErrors } =
		useRewardStore();

	const { contract } = useGetContract(
		chainId,
		ContractType.IntractCampaignRewardNft,
	);

	const toggleRewardType = (type: RewardType) => {
		setFormFields({
			...formFields,
			rewardType: type,
			image: '',
			imageFile: null,
		});
	};

	useEffect(() => {
		if (contract && chainId) {
			const tempFields = structuredClone(formFields);
			tempFields.rewardDetails.nftDetails.nftContractAddress =
				contract.address;
			setFormFields(tempFields);
		}
	}, [contract, chainId]);

	useEffect(() => {
		setFormErrors((prev: any) => ({
			...prev,
			rewardDetails: '',
		}));
	}, [formFields.rewardType, formFields.rewardDetails, setFormErrors]);
	return (
		<div>
			<p className="text-sm font-medium mb-3">
				Choose the type of reward you want to offer
			</p>
			<div className="grid grid-cols-3 gap-3">
				{rewardCategories.map((i) => (
					<RadioItem
						isChecked={formFields.rewardType === i.category}
						label={i.label}
						icon={rewardIconMap[i.category]}
						description={i.description}
						onClick={() => toggleRewardType(i.category)}
						disabledTooltip={
							formFields?.editMode
								? ''
								: 'Please connect your discord account to enable this reward'
						}
						disabled={
							formFields.editMode ||
							(i.category === RewardType.DiscordRole
								? !user.integrationStatus.discord
								: false)
						}
					/>
				))}
			</div>
			<div
				className={cn(
					'my-5 w-full',
					formFields.editMode ? 'pointer-events-none opacity-80' : '',
				)}
			>
				{formFields.rewardType === RewardType.Custom ? (
					<InputText
						label="Secret code"
						placeholder="Enter your custom code"
						value={formFields?.rewardDetails?.customDetails?.secretCode}
						setValue={(e) =>
							setFormFields({
								...formFields,
								rewardDetails: {
									customDetails: { secretCode: e },
								},
							})
						}
						disabled={formFields.editMode}
						error={formErrors?.rewardDetails}
						errorText={formErrors?.rewardDetails}
					/>
				) : null}
				{formFields.rewardType === RewardType.Nft ? (
					<>
						<div className="text-sm font-medium mb-1">
							Select the deployed contract
						</div>
						<RewardNFTSelect
							value={formFields?.rewardDetails?.nftDetails?.nftDocId}
							setValue={(contract: INFTs) => {
								setChainId(contract.chainId);
								setFormFields({
									...formFields,
									image: contract.imageUrl,
									rewardDetails: {
										nftDetails: {
											nftDocId: contract._id,
											nftTokenId: contract.tokenId,
											nftChainId: contract.chainId,
											nftContractAddress: '',
										},
									},
								});
							}}
							error={formErrors?.rewardDetails}
						/>
					</>
				) : null}
				{formFields.rewardType === RewardType.DiscordRole ? (
					<>
						<div className="text-sm font-medium mb-1">
							Select the discord role
						</div>
						<SelectDiscordRole
							value={
								formFields?.rewardDetails?.discordRoleDetails
									?.discordRoleId
							}
							setValue={(id: string, name: string) => {
								setFormFields({
									...formFields,
									rewardDetails: {
										discordRoleDetails: {
											discordRoleId: id,
											discordGuildId:
												enterprise.primaryGuildId,
										},
									},
								});
							}}
							error={formErrors?.rewardDetails}
							errorText="Please select a discord role"
						/>
					</>
				) : null}
			</div>
		</div>
	);
};

export default SelectRewardType;

const rewardCategories = [
	{
		label: 'NFTs',
		icon: 'code-slash text-green-600',
		category: RewardType.Nft,
		description: 'Engage your users with NFTs',
	},
	{
		label: 'Discord Role',
		icon: 'discord text-indigo-500',
		category: RewardType.DiscordRole,
		description: 'Reward your users with a discord role from your server',
	},
	{
		label: 'Custom',
		icon: 'gift-fill text-orange-600',
		category: RewardType.Custom,
		description: 'Provide your users with a secret code to redeem rewards',
	},
];

const RadioItem = ({
	isChecked,
	label,
	icon,
	description,
	onClick,
	disabled,
	disabledTooltip,
}) => {
	return (
		<TooltipWrapper tooltip={disabled ? disabledTooltip : ''}>
			<div
				className={cn('p-3 rounded-lg bg-white relative', {
					'cursor-pointer': !disabled,
					'cursor-not-allowed opacity-80': disabled,
					'border-violet-500 border-2 border-opacity-80':
						isChecked && !disabled,
					'border-slate-300 border-2': !isChecked || disabled,
				})}
				onClick={disabled ? () => {} : onClick}
			>
				<div className="absolute top-0 end-0 p-3">
					<i
						className={cn(
							isChecked ? 'bi-check-circle-fill' : `bi-circle`,
							isChecked ? 'text-violet-500' : `text-slate-300 `,
						)}
					/>
				</div>

				<div className="text-sm font-medium flex items-center gap-2">
					<i
						className={cn(
							isChecked ? 'text-violet-500' : 'text-slate-400',
							icon,
							'block',
						)}
					/>
					<p className="text-sm font-medium ">{label}</p>
				</div>
				<div className="text-xs text-muted-foreground mt-2 text-left">
					{description}
				</div>
			</div>
		</TooltipWrapper>
	);
};
