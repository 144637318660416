import { useEffect, useState } from 'react';
import {
	createApiToken,
	deleteAPIKey,
	getExternalAPIs,
} from '../services/external-apis';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { useConfirm } from '@/hooks/useConfirm';
import { toast } from 'sonner';
import { queryClient } from '@/lib/react-query';
import { Badge } from '@/components/ui/badge';
import { handleErrorMessage } from '@/utils/notifications';

interface IApiTokens {
	keyName: string;
	createdAt: string;
	apiKey: string;
	usage: string; //TODO: date or string ?
	_id: string;
	lastAccessed: Date;
}

const useExternalAPIs = ({ setOpen, setStep }) => {
	const [apiData, setApiData] = useState([]);
	const [formErrors, setFormErrors] = useState<any>({});
	const [apiName, setApiName] = useState('');
	const [apiKey, setApiKey] = useState('');
	const [isCopied, setIsCopied] = useState(false);

	const { show } = useConfirm();

	const apiTokenQuery = useQuery({
		queryKey: ['api-tokens'],
		queryFn: getExternalAPIs,
	});
	const deleteApiMutation = useMutation({
		mutationFn: deleteAPIKey,
		onSuccess: () => {
			toast.success('API deleted successfully');
			queryClient.invalidateQueries({ queryKey: ['api-tokens'] });
		},
		onError: (err) => {
			handleErrorMessage(err);
		},
	});
	const createApiTokenMutation = useMutation({
		mutationFn: createApiToken,
		onSuccess: (resp) => {
			toast.success('API Token created successfully');
			setApiKey(resp.apiKey);
			queryClient.invalidateQueries({ queryKey: ['api-tokens'] });
			setStep(2);
		},
		onError: (err) => {
			handleErrorMessage(err);
		},
	});

	const resetForm = () => {
		setApiName('');
		setFormErrors({});
		setStep(1);
		setIsCopied(false);
		setApiKey('');
	};

	const handleNext = (step: number) => {
		switch (step) {
			case 1:
				if (!apiName.trim()) {
					setFormErrors({ apiName: 'API Key Name is required' });
					return;
				}
				createApiTokenMutation.mutateAsync(apiName.trim());
				break;
			case 2:
				if (isCopied) {
					setOpen(false);
					resetForm();
				} else {
					toast.warning(
						'Please copy the API Key before closing the dialog',
					);
				}
				break;
		}
	};

	const deleteConfirmation = async (data: any) => {
		const res = await show({
			title: 'Delete API',
			subtitle: `Are you sure you want to delete this token?`,
			confirmText: 'Delete',
		});
		if (!res) return;
		await deleteApiMutation.mutateAsync(data);
	};

	const options = (row: any) => [
		{
			type: 'button',
			label: 'Revoke Auth Token',
			onClick: () => deleteConfirmation(row.original),
		},
	];

	const externalAPIColumns: ColumnDef<IApiTokens>[] = [
		{
			accessorKey: 'keyName',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className="px-1"
				/>
			),
			cell: ({ row }) => {
				return (
					<div className="min-w-[12rem] max-w-fit">
						{row?.original?.keyName}
					</div>
				);
			},
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'createdAt',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Created At" />
			),
			cell: ({ row }) => {
				return (
					<div className="">
						{new Date(row?.original?.createdAt).toLocaleString()}
					</div>
				);
			},
			enableSorting: true,
			enableHiding: false,
		},
		// {
		// 	accessorKey: 'usage',
		// 	header: ({ column }) => (
		// 		<DataTableColumnHeader column={column} title="Usage (last 7 days)" />
		// 	),
		// 	cell: ({ row }) => {
		// 		return <div>{row?.original?.usage}</div>;
		// 	},
		// 	enableSorting: true,
		// 	enableHiding: false,
		// },
		// {
		// 	accessorKey: 'lastAccessed',
		// 	header: ({ column }) => (
		// 		<DataTableColumnHeader column={column} title="Last Accessed" />
		// 	),
		// 	cell: ({ row }) => {
		// 		return (
		// 			<div>
		// 				{row?.original?.lastAccessed
		// 					? new Date(row?.original?.lastAccessed).toLocaleString()
		// 					: 'Never'}
		// 			</div>
		// 		);
		// 	},
		// 	enableSorting: true,
		// 	enableHiding: false,
		// },
		{
			id: 'actions',
			cell: ({ row }) => (
				// <DataTableRowActions row={row} options={options(row) as any} />
				<Badge
					variant="secondary"
					onClick={() => deleteConfirmation(row.original)}
					className="font-medium cursor-pointer py-1.5 float-right mr-4"
				>
					<i className="bi-dash-circle me-2"></i>
					Revoke
				</Badge>
			),
			enableSorting: false,
			enableHiding: false,
		},
	];

	useEffect(() => {
		if (apiTokenQuery?.data) {
			setApiData(apiTokenQuery.data);
			return;
		}
		setApiData([]);
	}, [apiTokenQuery.data]);

	return {
		apiData,
		formErrors,
		setFormErrors,
		apiKey,
		setApiData,
		apiTokenQuery,
		isLoading: apiTokenQuery.isLoading,
		isCreate: createApiTokenMutation.isPending,
		isDelete: deleteApiMutation.isPending,
		apiName,
		setApiName,
		handleNext,
		externalAPIColumns,
		resetForm,
		setIsCopied,
		isCopied,
	};
};

export default useExternalAPIs;
