import { defineChain } from 'viem';

export const uniChainSepoliaTestnet = defineChain({
	id: 1301,
	name: 'Unichain Sepolia Testnet',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: [
				'https://young-cosmopolitan-mountain.unichain-sepolia.quiknode.pro/4045986828b2bb5f010586765c9984afed9e7957',
			],
		},
	},
	blockExplorers: {
		default: {
			name: 'Unichain Testnet Explorer',
			url: 'https://sepolia.uniscan.xyz/',
		},
	},
	testnet: true,
});
