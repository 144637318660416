import { campaignsColumns } from '../../core/campaign-columns';
import { DataTable } from '@/components/element/data-table/DataTable';
import { useNavigate } from 'react-router-dom';
import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import { ICampaign, Status } from '../../types';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { QUEST_URL } from '@/config';
import { useCampaignActions } from '../../hooks/report/useCampaignActions';
import { toast } from 'sonner';

const CampaignsTable = ({
	data,
	filters,
	setFilters,
	setSearch,
	search,
	isLoading,
}) => {
	const navigate = useNavigate();
	const { deleteCampaign, convertToDraft } = useCampaignActions();

	const cols = campaignsColumns([
		{
			type: 'button',
			label: 'Preview',
			icon: 'eye',
			onClick: (row: any) => {
				window.open(`${QUEST_URL}/quest/${row._id}`, '_blank');
			},
			show: (row: any) => row.status !== Status.Draft,
		},
		{
			type: 'button',
			label: 'Edit',
			icon: 'pencil',
			onClick: (row: any) => {
				navigate(`/app/campaign/quests/${row._id}/update`);
			},
			show: (row: any) => row.status === Status.Draft,
		},
		{
			type: 'button',
			label: 'Duplicate',
			icon: 'copy',
			onClick: (row: any) => {
				navigate(`/app/campaign/quests/new/?duplicateFrom=${row._id}`);
			},
		},
		{
			type: 'button',
			label: 'Convert to Draft',
			icon: 'file-earmark',
			onClick: (row: any) => {
				toast.promise(Promise.all([convertToDraft(row._id, row.status)]), {
					loading: 'Converting to draft...',
					success: 'Converted to draft successfully',
					error: 'Failed to convert to draft',
				});
			},
			show: (row: any) => row.status === Status.InReview,
		},
		{
			type: 'separator',
			show: (row: any) =>
				row.status === Status.Active
					? !row.initiatedUsersCount || row.initiatedUsersCount === 0
					: true,
		},
		{
			type: 'button',
			label: 'Delete',
			icon: 'trash',
			onClick: (row: ICampaign) => {
				deleteCampaign(row._id);
			},
			show: (row: ICampaign) =>
				row.status === Status.Active
					? !row.initiatedUsersCount || row.initiatedUsersCount === 0
					: true,
		},
	]);
	return (
		<div className="mt-10">
			<div className="flex items-center mt-10 mb-5 space-x-4">
				<InputText
					value={search}
					setValue={setSearch as any}
					prepend={
						<i className="bi-search px-3 text-muted-foreground text-sm"></i>
					}
					placeholder="Search Campaigns"
					className="w-[250px] lg:w-[250px]"
				/>
				<FacetedFilter
					title="Status"
					options={statusOptions}
					selectedValues={filters.status}
					setSelectedValues={(values: any) =>
						setFilters((prev: any) => ({ ...prev, status: values }))
					}
				/>
			</div>
			<div>
				<DataTable
					columns={cols}
					data={data}
					onRowClick={(row) => {
						if (row.status === Status.Draft) {
							navigate(`/app/campaign/quests/${row._id}/update`);
						} else {
							navigate(`/app/campaign/quests/${row._id}`);
						}
					}}
					totalCount={data.length}
					isLoading={isLoading}
					defaultSort={[{ id: 'createdAt', desc: true }]}
				/>
			</div>
		</div>
	);
};

export default CampaignsTable;

const statusOptions = [
	{
		value: Status.Active,
		label: <StatusBadge status={Status.Active} className="text-sm" />,
	},
	{
		value: Status.InReview,
		label: <StatusBadge status={Status.InReview} className="text-sm" />,
	},
	{
		value: Status.Completed,
		label: <StatusBadge status={Status.Completed} className="text-sm" />,
	},
	{
		value: Status.Draft,
		label: <StatusBadge status={Status.Draft} className="text-sm" />,
	},
	{
		value: Status.Scheduled,
		label: <StatusBadge status={Status.Scheduled} className="text-sm" />,
	},
];
