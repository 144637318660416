import { Card, CardContent } from '@/components/ui/card';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import coin from '../assets/coin.png';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { formatNumberWithCommas } from '@/utils/parsers';

const IntractCreditsPageOld = () => {
	const { isLoading, enterprise } = useGetEnterprise();

	if (isLoading) return <FullPageLoading />;
	return (
		<div className="mt-">
			<Card
				style={{
					backgroundColor: '#8EC5FC',
					backgroundImage:
						'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)',
				}}
			>
				<CardContent className="px-10 py-1 flex items-center justify-between">
					<div>
						<div className="mb-3">Your available credits are</div>
						<div>
							<span className="text-4xl font-semibold me-2">
								<span>$</span>
								<span>
									{formatNumberWithCommas(
										enterprise?.credits?.remaining ?? 10000,
									)}
								</span>
							</span>
							<span>Credits</span>
						</div>
						<div className="mt-4 text-xs">
							<span className="">
								<span>You recieved total of $</span>
								<span>
									{formatNumberWithCommas(
										enterprise?.credits?.total ?? 10000,
									)}
								</span>
								<span className="ms-1">Credits</span>
							</span>
						</div>
					</div>
					<img src={coin} className="h-[200px]" />
				</CardContent>
			</Card>
			<div className="grid grid-cols-2 mt-10 gap-10">
				<div className="border rounded-lg">
					<div className="flex flex-col items-center justify-center -mt-5 mb-8">
						<div className="h-12 w-12 border rounded-full bg-white flex items-center justify-center">
							<i className="bi bi-coin text-2xl text-violet-600"></i>
						</div>
						<div className="font-semibold mt-2">
							How can you spend credits?
						</div>
					</div>
					<div className="space-y-3 px-10">
						{spending.map((i) => (
							<div className="flex items-start gap-2 border-b pb-3 mb-3">
								<i
									className={`bi ${i.icon} text-violet-600 me-3 text-lg`}
								></i>{' '}
								<div>
									<div className="font-semibold text-sm">
										{i.title}
									</div>
									<p className="text-xs text-muted-foreground">
										{i.description}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="border rounded-lg">
					<div className="flex flex-col items-center justify-center -mt-5">
						<div className="h-10 w-10 border rounded-full bg-white flex items-center justify-center">
							<i className="bi bi-question-circle text-violet-600"></i>
						</div>
						<div className="font-medium">Frequently Asked Questions</div>
					</div>
					<div className="px-10">
						<CreditsFAQs />
					</div>
				</div>
			</div>
		</div>
	);
};

export default IntractCreditsPageOld;

const CreditsFAQs = () => {
	return (
		<Accordion type="single" collapsible className="w-full mt-5">
			{faqs.map((faq, index) => (
				<AccordionItem value={`item-${index}`}>
					<AccordionTrigger className="text-sm text-start">
						{faq.question}
					</AccordionTrigger>
					<AccordionContent>{faq.answer}</AccordionContent>
				</AccordionItem>
			))}
		</Accordion>
	);
};

const faqs = [
	{
		question: 'What is the Intract Comm Affiliate Network program?',
		answer: 'The Intract Comm Affiliate Network program is an incentivised business referral system designed for Intract Community Ambassadors to refer small and mid-sized web3 communities to use Intract Loyalty Suite. The program offers guaranteed $ rewards and other future incentives for successful referrals.',
	},
	{
		question: 'When is Phase 1 of the program?',
		answer: 'Phase 1 of the Intract Comm Affiliate Network program runs from April 03, 2024, to April 10, 2024.',
	},
	{
		question: 'What are the rewards for successful referrals?',
		answer: 'Rewards are based on the number of successful referrals, starting from a minimum of 50$ for 1-5 referrals, 150$ for 6-10 referrals, and 400$ for 10-20 referrals. Additional bonuses include 10$ for each call the referred community makes with Intract, 20$ for communities already using platforms like Zealy or Galxe, and 30$ for communities that actively maintain their loyalty hub.',
	},
	{
		question: 'What constitutes a successful referral?',
		answer: 'A successful referral requires the web3 community to be legitimate, have more than 10,000 Twitter followers or Discord members, be referred by a manager in the community & marketing team, launch an active loyalty quest on Intract with specified tasks, and post about their community hub on their channels.',
	},
	{
		question: 'How can I submit my referrals?',
		answer: 'To submit referrals, raise a ticket in discord tagging Harsh_web3 with the project details, screenshots, and the TG/Discord handles of the referred community. Also, provide your wallet address for reward processing.',
	},
	{
		question: 'How and when will I receive my rewards?',
		answer: 'Rewards are distributed weekly to the registered wallet address of the referring ambassador by the community team.',
	},
	{
		question: 'What are the unique offerings of the Intract Loyalty Suite?',
		answer: 'The document briefly mentions the Intract Loyalty Suite but does not detail its unique offerings. Typically, such offerings may include customized loyalty programs, comprehensive community engagement tools, and analytics to track program success.',
	},
	{
		question: 'How do I get started with the Intract Loyalty Suite?',
		answer: "While the document does not provide specific steps on getting started, generally, one would begin by visiting the Intract platform, signing up for the Loyalty Suite, and following the onboarding instructions to set up their community's loyalty program.",
	},
];

const spending = [
	{
		icon: 'bi-gear-fill',
		title: 'Automatic Verification with DAVE',
		description:
			'Automate on-chain task verification without custom APIs. Charges are 1 credit per 1000 actions fetched.',
	},
	{
		icon: 'bi-twitter',
		title: 'Accurate Twitter Verification',
		description:
			'Leverage paid APIs for precise Twitter task verifications, payable with credits.',
	},
	{
		icon: 'bi-eye-fill',
		title: 'Consumer Page Placement',
		description:
			'Use credits for prominent quest placement on our product, subject to certain limitations.',
	},
	{
		icon: 'bi-file-earmark-bar-graph',
		title: 'Exporting User Data',
		description: 'Export user data at 10 credits per 10,000 users exported.',
	},
	{
		icon: 'bi-headset',
		title: 'Priority Support',
		description:
			'Gain access to quicker assistance and support for any issues or queries.',
	},
	{
		icon: 'bi-megaphone-fill',
		title: 'Social Media Push by KOLs',
		description:
			'Get our Key Opinion Leaders (KOLs) to promote your quest on social media platforms.',
	},
	{
		icon: 'bi-bell-fill',
		title: 'Sending Notifications',
		description:
			'Send targeted notifications to our users to increase engagement and participation.',
	},
];
