import {
	getEnterpriseApprovalData,
	getEnterpriseFeatureUsage,
	getSimilarEnterprises,
	updateEntepriseApproval,
	updateEnterpriseDetails,
} from '../services/admin.service';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
	IAdminEnterprise,
	IEnterpriseFeatureUsage,
	IEnterpriseSimilarProjects,
} from '../types/admin.types';
import {
	EnterpriseApprovalStatus,
	EnterpriseTokenStatus,
} from '@/features/dashboard/types/enterprise.types';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { toast } from 'sonner';

export const useAdminEnterpriseApproval = ({ refetch, enterpriseId }) => {
	const [selected, setSelected] = useState('approvalData');
	const [isLoading, setIsLoading] = useState(false);
	const [details, setDetails] = useState({
		status: '',
		message: '',
		reason: '',
		approveCampaigns: false,
		publishCampaigns: false,
		tokenStatus: '',
		tokenDetails: {
			tokenAddress: '',
			tokenChainId: null,
		},
		leadType: '',
		twitterCredits: 0,
	});
	const [errors, setErrors] = useState({
		status: false,
		message: false,
	});

	const { data: enterpriseData, isLoading: isEnterpriseLoading } =
		useQuery<IAdminEnterprise>({
			queryKey: ['admin', 'enterprise', 'approval', enterpriseId],
			queryFn: () => getEnterpriseApprovalData(enterpriseId),
			enabled: !!enterpriseId,
		});

	const { data: enterpriseFeatureUsage, isLoading: isEnterpriseFeatureLoading } =
		useQuery<IEnterpriseFeatureUsage>({
			queryKey: ['admin', 'enterprise', 'feature', enterpriseId],
			queryFn: () => getEnterpriseFeatureUsage(enterpriseId),
			enabled: !!enterpriseId,
		});

	const { data: similarEnterprises, isLoading: isSimilarEnterpriseLoading } =
		useQuery<IEnterpriseSimilarProjects[]>({
			queryKey: ['admin', 'enterprise', 'similar', enterpriseId],
			queryFn: () => getSimilarEnterprises(enterpriseId),
			enabled: !!enterpriseId,
		});

	const updateStatus = async () => {
		try {
			const errors_ = {
				message: false,
				status: false,
			};
			if (!details.status) errors_.status = true;
			if (
				!details.message &&
				details.status === EnterpriseApprovalStatus.Rejected
			)
				errors_.message = true;
			setErrors(errors_);
			if (errors_.message || errors_.status) {
				return;
			}
			setIsLoading(true);
			await updateEntepriseApproval(enterpriseId, {
				isApproved: details.status === EnterpriseApprovalStatus.Approved,
				notification: details.message,
				rejectionReason: details.reason,
				publishCampaigns: details.publishCampaigns,
				approveCampaigns: details.approveCampaigns,
			});
			toast.success('Enterprise status updated successfully');
			refetch?.();
			setIsLoading(false);
			queryClient.invalidateQueries({
				queryKey: ['admin', 'enterprise'],
			});
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading(false);
			console.log(err);
		}
	};

	const updateInlineItems = async () => {
		try {
			setIsLoading(true);
			await updateEnterpriseDetails(enterpriseId, {
				tokenStatus: details.tokenStatus,
				leadType: details.leadType,
				tokenDetails: details.tokenDetails,
				twitterCredits: details.twitterCredits,
			});
			toast.success('Enterprise details updated successfully');
			refetch?.();
			queryClient.invalidateQueries({
				queryKey: ['admin', 'enterprise'],
			});
		} catch (err) {
			handleErrorMessage(err);
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		updateStatus,
		details,
		setDetails,
		selected,
		setSelected,
		isLoading,
		enterpriseData,
		isEnterpriseLoading,
		enterpriseFeatureUsage,
		isEnterpriseFeatureLoading,
		similarEnterprises,
		isSimilarEnterpriseLoading,
		errors,
		updateInlineItems,
	};
};
