import { CampaignRewardCategory, CampaignRewardType } from './campaigns.enums';

export const campaignTemplates = [
	{
		name: 'Collaborate with Community',
		value: 1,
		numTasks: 6,
		color: 'primary',
		icon: 'person-hearts',
	},
	{
		name: 'Giveaway on Twitter',
		value: 2,
		numTasks: 2,
		color: 'info',
		icon: 'twitter',
	},
	{
		name: 'Collaborate with KOL',
		value: 3,
		numTasks: 2,
		color: 'danger',
		icon: 'mic-fill',
	},
	{
		name: 'Onboarding new users',
		value: 4,
		numTasks: 3,
		color: 'warning',
		icon: 'bookmark-star',
	},
	{
		name: 'Onchain Contest',
		value: 5,
		numTasks: 2,
		color: 'primary',
		icon: 'wallet',
	},
];

export const campaignViews = [
	{
		label: 'All',
		value: 'all',
		icon: 'bi-list-nested',
		badgeColor: 'primary',
	},
	{
		label: 'Drafts',
		value: 'draft',
		icon: 'bi-file-earmark',
		badgeColor: 'info',
	},
	{
		label: 'Upcoming',
		value: 'upcoming',
		icon: 'bi-calendar2-plus',
		badgeColor: 'danger',
	},
];

export const CampaignTags = [
	{ label: 'Giveaway', value: 'GIVEAWAY' },
	{ label: 'Collab', value: 'COLLAB' },
	{ label: 'KOL', value: 'KOL' },
	{ label: 'Community', value: 'COMMUNITY' },
	{ label: 'Quest', value: 'QUEST' },
	{ label: 'Ads', value: 'ADS' },
	{ label: 'Special Event', value: 'SPECIAL_EVENT' },
	{ label: 'Anniversary', value: 'ANNIVERSARY' },
	{ label: 'Custom', value: 'CUSTOM' },
];

export const distributionTypeMap = {
	[CampaignRewardType.Fcfs]: 'First Come, First Serve',
	[CampaignRewardType.Leaderboard]: 'Leaderboard',
	[CampaignRewardType.SmartRaffle]: 'Smart Raffle',
	[CampaignRewardType.Unlimited]: 'Unlimited',
	[CampaignRewardType.TieredLeaderboard]: 'Tiered Leaderboard',
	[CampaignRewardType.LuckyDraw]: 'Lucky Draw',
};

export const rewardTypeMap = {
	[CampaignRewardCategory.Token]: 'ERC20 Tokens',
	[CampaignRewardCategory.Nft]: 'NFTs',
	[CampaignRewardCategory.DiscordRole]: 'Discord Role',
	[CampaignRewardCategory.OfficialPoints]: 'Official Points',
	[CampaignRewardCategory.Whitelist]: 'Custom',
};
