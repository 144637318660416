import { Button } from '@/components/ui/button';
import { ITask } from '@/features/campaigns/types';
import { Dispatch, SetStateAction, useState } from 'react';
import AddContentDialog from '../../../create-campaign/tasks/components/LearningContent/AddTaskContent';

const TaskFeatures = ({ task, setTask }: { task: ITask; setTask: any }) => {
	const [open, setOpen] = useState(false);

	return (
		<div className="pb-4 h-full ">
			<div className="mt-8 max-h-[500px] overflow-y-auto">
				<div className="mb-10 flex items-center justify-between space-x-8">
					<div>
						<div className="text-sm font-medium">
							Add Educational Content Slides:
						</div>
						<p className="text-xs mt-2 mb-0 text-muted-foreground max-w-[400px]">
							Add content slides in your task to help users understand.
						</p>
						{/* <div className="text-xs text-violet-800 hover:underline cursor-pointer mt-1">
							See how it looks.
						</div> */}
					</div>
					<Button
						variant="outline"
						onClick={() => {
							if (!task?.slides || task.slides?.length === 0) {
								setTask((prev) => ({
									...prev,
									slides: [{ title: '', content: '' }],
								}));
							}
							setOpen(true);
						}}
						className="min-w-[200px] justify-between pe-2 font-normal"
					>
						{task.slides?.length
							? `${task.slides?.length} Slides Added`
							: 'Add Slides'}
						<i className="bi-plus-circle-dotted me-2"></i>
					</Button>
				</div>
			</div>
			<AddContentDialog
				setOpen={setOpen}
				open={open}
				slides={task.slides}
				setSlides={(slides) => {
					setTask((prev) => ({
						...prev,
						slides,
					}));
				}}
			/>
		</div>
	);
};

export default TaskFeatures;
