import { useMutation, useQuery } from '@tanstack/react-query';
import {
	getSearchQuestBanners,
	getWidgetCampaigns,
	updateWidgetCampaigns,
} from '../../services/cms.service';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { Media } from '@/components/element/media/Media';
import { useConfirm } from '@/hooks/useConfirm';
import { toast } from 'sonner';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { IWidgets } from '../../types/cms.types';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Grip } from 'lucide-react';
import { IAdminCampaigns } from '../../types/admin.types';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';

export interface ISearchQuestBanners {
	_id: string;
	name: string;
	banner: string;
}

const useGetSearchQuests = ({
	details,
	setDetails,
}: {
	details: IWidgets;
	setDetails: Dispatch<SetStateAction<IWidgets>>;
}) => {
	const { data, isLoading } = useQuery({
		queryKey: ['cms-search-banner'],
		queryFn: getSearchQuestBanners,
	});

	const { data: campaignData, isLoading: campaignLoading } = useQuery({
		queryKey: ['cms-search-quest'],
		queryFn: () => {
			return getWidgetCampaigns(details?._id, details?.resourceIds);
		},
		enabled: !!details?._id,
	});

	const { show } = useConfirm();

	const openDeleteConfirmation = async (id: string) => {
		const confirm = await show({
			title: 'Delete this quest',
			subtitle: `Are you sure you want to delete quest from search?`,
			confirmText: 'Delete',
			cancelText: 'Cancel',
		});
		if (!confirm) {
			return;
		}

		deleteQuest(id);
	};

	const deleteQuest = async (id: string) => {
		const updatedCampaigns = details?.campaign?.filter(
			(campaign) => campaign.id !== id,
		);

		const updatedCampaignIds = updatedCampaigns.map((item) => item.id);

		setDetails((prev) => ({
			...prev,
			resourceIds: updatedCampaignIds,
			campaign: updatedCampaigns,
		}));

		try {
			await updateWidgetCampaigns(details?._id, updatedCampaignIds);
			await queryClient.invalidateQueries({
				queryKey: ['cms-search-quest'],
			});
		} catch (error) {
			handleErrorMessage(error);
		}
	};

	const options = (row: any) => [
		{
			type: 'button',
			label: 'Copy Campaign Id',
			onClick: () => {
				navigator.clipboard.writeText(row?.original?.id);
				toast.success('Campaign ID copied to clipboard');
			},
		},
		{
			type: 'button',
			label: 'Remove',
			onClick: () => {
				openDeleteConfirmation(row?.original?.id);
			},
		},
	];

	const columns: ColumnDef<ISearchQuestBanners>[] = [
		{
			id: 'drag',
			cell: () => (
				<div className="cursor-move">
					<Grip className="size-4" />
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'banner',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Banner" />
			),
			cell: ({ row }) => (
				<div className="py-1 px-1">
					<Media
						src={row?.original?.banner}
						altText="banner"
						className="h-10 rounded-md shadow-sm"
					/>
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className="px-1"
				/>
			),
			cell: ({ row }) => (
				<div className="py-1 px-1">{row?.original?.name}</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => (
				<DataTableRowActions row={row} options={options(row) as any} />
			),
		},
	];

	const handleReorder = async (newData: IWidgets['campaign']) => {
		setDetails((prev) => ({
			...prev,
			campaign: newData.flat(),
		}));
	};
	const addQuest = (campaign: IAdminCampaigns) => {
		setDetails((prev) => ({
			...prev,
			campaign: [
				...prev.campaign,
				{
					id: campaign?._id,
					name: campaign?.name,
					widgetId: details._id,
					banner: campaign?.banner,
				},
			],
		}));
	};

	useEffect(() => {
		if (data) {
			setDetails({
				_id: data[0]?._id,
				name: data[0]?.title,
				resourceIds: data[0]?.ids,
				campaign: campaignData?.map((item: any) => {
					return {
						id: item._id,
						name: item.title,
						widgetId: data[0]?._id,
						banner: item.banner,
					};
				}),
			});
		}
	}, [data, campaignData, setDetails]);

	return {
		searchQuests: details?.campaign || [],
		isLoading: isLoading || campaignLoading,
		columns,
		handleReorder,
		addQuest,
	};
};

export default useGetSearchQuests;
