import PageHeader from '@/components/layout/PageHeader';
import { useState } from 'react';
import AddTeamMembers from '../components/manage-team/AddTeamMembers';
import TeamsTable from '../components/manage-team/TeamsTable';
import { useAuth } from '@/hooks/useAuth';
import { AccountRoles } from '../types/team.enums';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';

const EnterpriseTeamPage = () => {
	const [open, setOpen] = useState(false);

	const { user } = useAuth();

	useQueryInitialise('new', 'true', () => {
		setOpen(true);
	});

	return (
		<div>
			<PageHeader
				title="Manage your team"
				description="In this section, you can manage the members of your team who can access this dashboard."
				icon="people-fill"
				btnOnClick={() => setOpen(true)}
				btnLabel={
					user?.role === AccountRoles?.Admin ||
					user?.role === AccountRoles?.Owner
						? 'Add Team Member'
						: ''
				}
			/>
			<TeamsTable setOpen={setOpen} />

			<AddTeamMembers open={open} setOpen={setOpen} />
		</div>
	);
};

export default EnterpriseTeamPage;
