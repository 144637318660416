import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { fetchTxnFromHash } from '../../services/dave.service';
import { validateTransactionHash } from '@/utils/validation';
import { ethers } from 'ethers';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';

export interface IParsedTxn {
	userAddress: string;
	contractAddress: string;
	functionSignature: string;
	functionName: string;
	functionParams: {
		name: string;
		value: string;
		type: string;
	}[];
	events: {
		name: string;
		topic0: string;
		contractAddress: string;
		params: {
			name: string;
			value: string;
			type: string;
		}[];
	}[];
}

interface IRawTxn extends ethers.providers.TransactionReceipt {
	data: string;
	from: string;
}

export const useTransactionGuide = ({
	chainId,
	abi,
}: {
	chainId: number;
	abi: any[];
}) => {
	const [txnHash, setTxnHash] = useState('');
	const [txn, setTxn] = useState<IRawTxn>();
	const [isLoading, setIsLoading] = useState(false);
	const [errorHash, setErrorHash] = useState('');

	const fetchTxn = async () => {
		if (!validateTransactionHash(txnHash)) {
			setErrorHash('Invalid transaction hash');
			return;
		}
		try {
			setErrorHash('');
			setIsLoading(true);
			const startTime = Date.now();
			const res = await fetchTxnFromHash(txnHash, chainId);
			console.log('res', res);
			console.log(!res && !res?.to);
			if (!res || !res?.to) {
				setErrorHash(
					'Transaction not found, please try again with some other hash',
				);
				setIsLoading(false);
				return;
			}
			const responseTime = Date.now() - startTime;
			analytics.track(TrackingEvents.DaveTxnHelperUsed, {
				txnHash,
				chainId,
				responseTime,
			});
			if (!res) {
				throw new Error(
					'Transaction not found. Please verify the transaction hash.',
				);
			}
			setTxn(res);
		} catch (error) {
			console.error('Error fetching transaction:', error);
			handleErrorMessage(error);
		} finally {
			setIsLoading(false);
		}
	};

	const parsedTxn: IParsedTxn = useMemo(() => {
		if (!txn) return null;
		const { logs, to } = txn;
		const input = txn.data;

		let contract;
		try {
			contract = new ethers.Contract(to, abi, ethers.getDefaultProvider());
		} catch (error) {
			handleErrorMessage(error);
			return;
		}

		const parseLogData = (log) => {
			try {
				const parsedLog = contract.interface.parseLog(log);
				return {
					name: parsedLog.name,
					topic0: log.topics[0],
					contractAddress: log.address,
					params: parsedLog.args.map((arg, index) => ({
						name: parsedLog.eventFragment.inputs[index].name,
						value: arg.toString(),
						type: parsedLog.eventFragment.inputs[index].type,
					})),
				};
			} catch (error) {
				console.error('Error parsing log data:', error);
				return null;
			}
		};
		let functionName = '';
		let functionSignature = '';
		let functionParams = [];
		if (input && input !== '0x') {
			const methodId = input.slice(0, 10);
			try {
				const txnData = contract.interface.parseTransaction({ data: input });
				functionName = txnData.name;
				functionSignature = methodId;
				functionParams = txnData.args.map((arg, index) => ({
					name: txnData.functionFragment.inputs[index].name,
					value: arg.toString(),
					type: txnData.functionFragment.inputs[index].type,
				}));
			} catch (error) {
				console.error('Error decoding transaction input:', error);
			}
		}

		const events = logs.map(parseLogData).filter((log) => log);
		return {
			userAddress: txn.from, // Address initiating the transaction
			contractAddress: to, // Contract address the transaction was sent to
			functionSignature,
			functionName,
			functionParams,
			events, // All parsed events
		};
	}, [txn, abi]);

	return {
		txnHash,
		setTxnHash,
		txn,
		isLoading,
		fetchTxn,
		parsedTxn,
		errorHash,
	};
};
