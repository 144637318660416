import { Media } from '@/components/element/media/Media';
import en from '../../../../locales/en.json';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import NumberFlow from '@number-flow/react';

const StatsCard = () => {
	const { intractCredits } = en;
	const { enterprise } = useGetEnterprise();

	return (
		<div className="flex gap-4 ">
			<div className="bg-slate-800 rounded-lg p-6 flex items-center justify-between flex-1">
				<div>
					<p className="text-xs font-light tracking-[0.125em] uppercase text-white">
						{intractCredits?.balanceLabel}
					</p>
					<NumberFlow
						value={enterprise?.credits?.balanceCredits ?? 0}
						locales="en-US" // Intl.NumberFormat locales
						className="text-2xl font-bold tracking-[1px] uppercase text-white "
						spinTiming={{
							duration: 700,
							easing: 'ease-in-out',
						}}
					/>
				</div>
				<div>
					<Media
						src={
							'https://static.highongrowth.xyz/enterprise/65f402cd6f492692b3b7b127/02c171e38f5148ee99d0c27366b2cd85.svg'
						}
						altText="credit-icon"
						className="size-14"
					/>
				</div>
			</div>
			<div className="bg-slate-200 rounded-lg p-6 flex items-center justify-between flex-1">
				<div className="">
					<p className="text-xs font-light tracking-[0.125em] uppercase text-slate-700">
						{intractCredits?.lifetimeSpendingLabel}
					</p>
					<NumberFlow
						value={enterprise?.credits?.consumedCredits ?? 0}
						locales="en-US" // Intl.NumberFormat locales
						className="text-2xl font-bold tracking-[1px] uppercase text-slate-700"
						spinTiming={{
							duration: 700,
							easing: 'ease-in-out',
						}}
					/>
				</div>
				<div>
					<Media
						src={
							'https://static.highongrowth.xyz/enterprise/65f402cd6f492692b3b7b127/02c171e38f5148ee99d0c27366b2cd85.svg'
						}
						altText="credit-icon"
						className="size-14"
					/>
				</div>
			</div>
		</div>
	);
};

export default StatsCard;
