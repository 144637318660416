import { defineChain } from 'viem';

export const proofOfPlayBoss = defineChain({
	id: 70701,
	name: 'Proof of Play Boss',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc.boss.proofofplay.com'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Boss Explorer',
			url: 'https://explorer.boss.proofofplay.com',
		},
	},
	testnet: false,
});
