import { homepageContent } from '../../content/homepage.content';

const Customers = () => {
	const content = homepageContent.companies;
	return (
		<div className="pb-16 w-full">
			<div className="my-10 py-4 overflow-hidden w-full">
				<p className="mx-auto max-w-sm text-center font-medium sm:max-w-xl sm:text-lg">
					{content.title}
				</p>

				<div className="flex flex-row items-center gap-20 animate-marquee whitespace-nowrap mt-4">
					{[...content.logos, ...content.logos].map((i, index) => (
						<img
							key={index}
							alt={i.name}
							loading="lazy"
							decoding="async"
							className="blur-0 text-center transition-all hover:grayscale-0 lg:h-20 object-contain grayscale w-32 h-8 flex"
							src={i.img}
							style={{ color: 'transparent' }}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default Customers;
