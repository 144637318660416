import { axios } from '@/lib/axios';

export const setUpUserProfileAPI = async (data: {
	name: string;
	source: string;
	role: string;
	referralCode?: string;
}) => {
	const response = await axios.post('/onboarding/user-profile', data);
	return response.data;
};

export const setUpUserProfileAPIV2 = async (data: {
	name: string;
	source: string;
	role: string;
	goal: string[];
}) => {
	const response = await axios.post('/onboarding/user-profile', data);
	return response.data;
};

export const setUpCommunityProfileAPI = async (data: {
	logo: string;
	name: string;
	chainIds: number[];
	category: string[];
}) => {
	const response = await axios.post('/onboarding/community-profile', data);
	return response.data;
};

export const setUpCommunityProfileAPIV2 = async (data: {
	name: string;
	twitter: string;
	tokenStatus?: string;
	tokenDetails?: {
		tokenAddress: string;
		tokenChainId: number;
	};
	referralCode?: string;
}) => {
	const response = await axios.post('/onboarding/community-profile-v2', data);
	return response.data;
};

export const setUpSocialsAPI = async (data: {
	twitter?: string;
	website?: string;
	discord?: string;
}) => {
	const response = await axios.post('/onboarding/socials', data);
	return response.data;
};

export const getOnboardingData = async () => {
	const response = await axios.get('/onboarding/');
	return response.data;
};

export const updateAccountDetails = async (body: any): Promise<any> => {
	const response = await axios.post('/onboarding/update-account-details', body);
	return response.data;
};

export const updateCommunityDetails = async (body: {
	logo?: string;
	chainIds?: number[];
	category?: string[];
	socials?: {
		website?: string;
	};
	customChainIds?: number[];
}): Promise<any> => {
	const response = await axios.post('/onboarding/update-community-details', body);
	return response.data;
};
