import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { useState } from 'react';
import useCommunityApproval from '../../hooks/useCommunityApproval';
import { EnterpriseApprovalStage } from '../../types/enterprise.types';
import AccountDetails from './AccountDetails';
import CommunityDetails from './CommunityDetails';
import SocialsSetup from './SocialsSetup';
import WaitingForApproval from './WaitingForApproval';
import AddNewCustomChain from '@/features/campaigns/components/create-campaign/details/AddNewCustomChain';

const initialSteps = [
	// {
	// 	label: 'Tell us about yourself',
	// 	icon: 'bi-person-badge',
	// 	key: 'ACCOUNT_DETAILS',
	// 	complete: false,
	// 	formTitle: `Let's set up your account`,
	// 	description:
	// 		'We need to validate your information, for that we need some personal data.',
	// },
	{
		label: 'Tell us about your project',
		icon: 'bi-building-add',
		key: 'COMMUNITY_DETAILS',
		complete: false,
		formTitle: `Let's set up your community`,
		description:
			"Tell us a bit about your community and we'll help you get started",
	},
	{
		label: 'Connect project socials',
		icon: 'bi-globe2',
		complete: false,
		key: 'COMMUNITY_SOCIALS',
		formTitle: `Let's set up your socials`,
		description: "Connect your community's socials to approve your project",
	},
];

const EnterpriseApprovalProcess = ({
	open,
	setOpen,
	enterpriseDetails: enterprise,
}) => {
	const [steps, setSteps] = useState(initialSteps);

	const {
		step,
		account,
		setAccount,
		community,
		setCommunity,
		handleNext,
		isLoading,
		errors,
		handleBack,
		socials,
		setSocials,
		isAuthenticating,
		setIsAuthenticating,
		showNewChain,
		setShowNewChain,
		handleOpenCustomChainDialog,
		setCustomChainByDefault,
	} = useCommunityApproval({
		steps,
		setSteps,
		setOpen,
	});

	return (
		<>
			<Dialog open={open} onOpenChange={setOpen}>
				<DialogContent className="sm:max-w-[865px] p-4 flex !overflow-visible">
					<div className="w-[35%] bg-gray-100 p-8 relative rounded-lg">
						<div className="mb-6">
							<h2 className="text-lg font-bold text-gray-700">
								<i className="bi-buildings me-2"></i>
								{enterprise?.isApproved
									? 'Complete setting up your community'
									: 'Community Approval'}
							</h2>
						</div>
						<div className="relative">
							<ol className="list-none p-0 relative z-10">
								{steps.map((step_, index) => {
									const currentStep =
										stageToStep[enterprise?.approvalStage] || 0;

									const isStepComplete = index < currentStep;
									return (
										<li
											className="flex items-center mt-8 relative"
											key={index}
										>
											<i
												className={`bi ${isStepComplete ? 'bi-check-circle-fill' : step_.icon} w-6 h-6 ${isStepComplete ? 'text-violet-500' : 'text-muted-foreground'}`}
											></i>
											<span
												className={`text-sm ${isStepComplete ? 'font-bold' : ''}`}
											>
												{step_.label}
											</span>
											{index < steps.length - 1 && (
												<span
													className={cn(
														'absolute left-2 top-7 h-full w-[1.5px] rounded-lg ',
														isStepComplete
															? 'bg-violet-500'
															: 'bg-gray-400',
													)}
												></span>
											)}
										</li>
									);
								})}
							</ol>
							<div className="absolute top-0 left-4 h-full w-px bg-gradient-to-b from-transparent to-white opacity-50"></div>
						</div>
					</div>

					<div className="w-[65%] p-4">
						{step !== EnterpriseApprovalStage.Completed && (
							<DialogHeader>
								<h2 className="text-lg font-semibold">
									{steps.find((s) => s.key === step)?.formTitle}
								</h2>
								<p className="text-sm text-gray-500">
									{steps.find((s) => s.key === step)?.description}
								</p>
							</DialogHeader>
						)}
						<div className="space-y-6 my-4">
							{/* {step === EnterpriseApprovalStage.AccountDetails && (
								<AccountDetails
									account={account}
									setAccount={setAccount}
									errors={errors.account}
								/>
							)} */}
							{step === EnterpriseApprovalStage.CommunityDetails && (
								<CommunityDetails
									community={community}
									setCommunity={setCommunity}
									errors={errors.community}
									onOpenCustomChainDialog={
										handleOpenCustomChainDialog
									}
								/>
							)}
							{step === EnterpriseApprovalStage.CommunitySocials && (
								<SocialsSetup
									socials={socials}
									setSocials={setSocials}
									errors={errors.socials}
									enterprise={enterprise}
									isAuthenticating={isAuthenticating}
									setIsAuthenticating={setIsAuthenticating}
								/>
							)}
							{step === EnterpriseApprovalStage.Completed && (
								<WaitingForApproval setOpen={setOpen} />
							)}
						</div>
						<DialogFooter className="mt-8 flex justify-between w-full">
							{step !== EnterpriseApprovalStage.CommunityDetails ? (
								<Button
									variant="outline"
									onClick={() => handleBack()}
									disabled={isLoading}
								>
									Back
								</Button>
							) : null}
							<Button
								onClick={() => handleNext()}
								disabled={isLoading}
							>
								{isLoading ? (
									<i className="bi-arrow-repeat animate-spin me-2"></i>
								) : null}
								<span>
									{step ===
										EnterpriseApprovalStage.CommunitySocials ||
									step === EnterpriseApprovalStage.Completed
										? 'Continue'
										: 'Save and Proceed'}
								</span>
							</Button>
						</DialogFooter>
					</div>
				</DialogContent>
			</Dialog>
			<AddNewCustomChain
				show={showNewChain}
				setShow={setShowNewChain}
				onClose={() => setOpen(true)} // Reopen the EnterpriseApprovalProcess dialog when AddNewCustomChain closes
				setCustomChainByDefault={setCustomChainByDefault} //setter for new custom chain created
			/>
		</>
	);
};

export default EnterpriseApprovalProcess;

const stageToStep = {
	[EnterpriseApprovalStage.CommunityDetails]: 0,
	[EnterpriseApprovalStage.CommunitySocials]: 1,
	[EnterpriseApprovalStage.Completed]: 2,
};
