import FullScreenCreateLayout from '@/components/layout/full-screen-create/FullScreenCreateLayout';
import { links } from '@/config/links';
import { useRouter } from '@/hooks/useRouter';
import Navigation from './ApisCreateNavigation';
import Sections from './ApisCreateSection';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { TaskAPIStatus } from '../types/task-apis.enum';

const ApisCreateLayout = ({ children }: any) => {
	const { navigate } = useRouter();
	const { enterprise } = useGetEnterprise();

	const { formFields, setFormFields } = useConnectAPI();

	return (
		<FullScreenCreateLayout showGrid={false}>
			<FullScreenCreateLayout.Header>
				<FullScreenCreateLayout.Title
					name={formFields?.apiName}
					setName={(e) => {
						setFormFields((prev) => ({ ...prev, apiName: e }));
					}}
					placeholder={'Untitled Task API'}
					status={TaskAPIStatus.Draft}
					lastSaved={new Date()}
					onFeedback={() => {
						window.open(links.feedbackForm, '_blank');
					}}
					onBack={() => navigate('/app/tools/task-apis')}
					enterpriseCreditsBalance={enterprise?.credits?.balanceCredits}
				/>
				<FullScreenCreateLayout.Sections>
					<Sections />
				</FullScreenCreateLayout.Sections>
			</FullScreenCreateLayout.Header>
			<FullScreenCreateLayout.Content>
				{children}
			</FullScreenCreateLayout.Content>
			<FullScreenCreateLayout.Navigation>
				<Navigation />
			</FullScreenCreateLayout.Navigation>
		</FullScreenCreateLayout>
	);
};

export default ApisCreateLayout;
