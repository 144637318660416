export const ppcContent = {
	comparison: {
		title: 'Intract vs Galxe / Zealy',
		description:
			'Intract is the #1 preferred quest platform for top web3 enterprises',
		list: [
			{
				title: 'Only real users',
				description: 'No bots or mercenaries or sybil attacks',
				link: '',
				col1: true,
				col2: false,
				col3: false,
			},
			{
				title: 'Priority support',
				description: 'Dedicated 24x7 account manager',
				link: '',
				col1: true,
				col2: false,
				col3: false,
			},
			{
				title: '100x co-marketing boost',
				description: 'Get highlighted on our homepage & socials',
				link: '',
				col1: true,
				col2: false,
				col3: false,
			},

			{
				title: 'Quests for loyal users',
				description: 'Leverage recurring tasks to build a loyal user base',
				link: '',
				col1: true,
				col2: false,
				col3: true,
			},
			{
				title: 'Network of 1,000+ KOLs',
				description: 'Amplify your reach with our influencers',
				link: '',
				col1: true,
				col2: true,
				col3: false,
			},

			{
				title: 'Intuitive & bug-free interface',
				description: 'No more complaints from your community',
				link: '',
				col1: true,
				col2: false,
				col3: false,
			},

			// {
			// 	title: 'Free customizations',
			// 	description: 'Design your quests in your own brand’s look & feel',
			// 	link: '',
			// 	col1: true,
			// 	col2: false,
			// 	col3: false,
			// },

			{
				title: 'No-code on-chain quests',
				description: 'Launch any quest in seconds without any dev cost',
				link: '',
				col1: true,
				col2: false,
				col3: false,
			},

			{
				title: 'Advanced analytics',
				description:
					'Automatic campaign reports to give your team full visibility',
				link: '',
				col1: true,
				col2: false,
				col3: false,
			},
		],
	},
};
