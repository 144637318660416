import { Route, Routes } from 'react-router-dom';
import DashboardHomePage from '../pages/DashboardHomePage';

export default function GettingStartedRoutes() {
	return (
		<Routes>
			<Route path="" element={<DashboardHomePage />} />
		</Routes>
	);
}
