import { Media } from '@/components/element/media/Media';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Checkbox } from '@/components/ui/checkbox';
import { asset } from '@/config/asset-cdn';
import { TaskKeys } from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { ITaskInputsProps } from './TaskInputs';

const TaskInputCheckbox = ({
	adminInput,
	taskId,
	error,
	editTaskAdminInput,
	credits,
	creditsPerRequestCount,
}: ITaskInputsProps) => {
	return (
		<>
			<div
				className="relative flex items-center cursor-pointer"
				onClick={() => {
					editTaskAdminInput(taskId, adminInput.key, !adminInput.value);
				}}
			>
				<Checkbox
					checked={adminInput.value as boolean}
					className={cn(error && 'border-red-500')}
				/>
				<div className="text-sm font-medium ms-2">{adminInput.label}</div>
				{adminInput.key === TaskKeys.TwitterAcknowledgementOpt ? (
					<>
						{credits && (
							<div className="flex items-center gap-1 bg-slate-100 rounded-full ms-2">
								<Media
									src={asset['credit-icon-primary']}
									altText="credit-icon"
									className="size-[20px]"
								/>
								<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-0.5 pr-2">
									{`~${credits} Credits / ${creditsPerRequestCount} users`}
								</span>
							</div>
						)}
						<TooltipWrapper
							tooltip="Your credits will be deducted once the campaign has ended - based on the number of users who attempt such X/Twitter tasks."
							align="end"
							trackingEvent={
								TrackingEvents.TwitterCreditsTooltipViewed
							}
						>
							<i className="bi bi-question-circle text-gray-400 ms-2"></i>
						</TooltipWrapper>
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default TaskInputCheckbox;
