import { Button } from '@/components/ui/button';
import LandingContainer from '../ui/LandingContainer';
import { Link } from 'react-router-dom';
import { featuresContent } from '../../content/features.content';

const FeaturesHero = () => {
	const content = featuresContent.hero;
	return (
		<LandingContainer>
			<div className="absolute left-[24px] top-0 z-0 xl:left-[-354px] xl:top-[84px] w-screen h-screen ">
				<div className="pointer-events-none absolute z-[0] h-[676px] w-[700px] rounded-full scale-[1.75] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-violet-500 via-red-300 to-transparent to-70% opacity-[22%]"></div>
			</div>
			<div className="pt-20 z-10 relative pb-40 z-4">
				<div className="flex flex-col items-center justify-center ">
					<div className="text-sm rounded-full bg-slate-100 px-4 py-1">
						{content.label}
					</div>
					<div className="relative mb-3 mt-4">
						<h1 className=" text-5xl text-center font-medium  leading-snug  selection:text-black">
							{content.title}
						</h1>
					</div>
					<div
						className="text-lg transition-colors text-center text-zinc-500  w-full"
						style={{ maxWidth: 730 }}
					>
						<p>{content.description}</p>
					</div>
					<div className="link-group flex w-full  gap-4 xxs:flex-row md:w-auto items-center pt-10  text-center justify-center md:items-center md:text-center md:justify-center ">
						<Link to="/auth/register">
							<Button className="rounded-xl" size="lg">
								<span className="me-2">Sign up now</span>
								<svg
									className=""
									xmlns="http://www.w3.org/2000/svg"
									width={12}
									height={12}
									fill="none"
								>
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="1.44"
										d="M4.5 2.25 8.25 6 4.5 9.75"
									/>
								</svg>
							</Button>
						</Link>
					</div>
					<div className="grid xl:grid-cols-3 grid-cols-1 gap-6 mt-20">
						{content?.features?.map((i, index) => (
							<div
								className="supahub-card-md  bg-black/5 border backdrop-blur-lg w-full space-y-4 z-40"
								key={`${i.description}_${index}`}
							>
								<span className="h4 text-black-500">
									0{index + 1}.
								</span>
								<h3 className="text-lg font-medium">{i.title}</h3>
								<p className="text-sm text-muted-foreground">
									{i.description}
								</p>
								<Link to={i.link} className="text-xs mt-4 block">
									{i.cta}
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default FeaturesHero;
