import { useCreateWidget } from '@/features/admin/hooks/admin-cms-widgets/useCreateWidget';
import { DataTableWithDnd } from '@/components/element/data-table/DataTableWithDnD';
import useWidgetColumns from '@/features/admin/hooks/admin-cms-widgets/useWidgetColumns';

const WidgetSection = () => {
	const { sections: campaigns, handleReorder, isLoading } = useCreateWidget();

	const { columns } = useWidgetColumns();

	return (
		<DataTableWithDnd
			data={campaigns || []}
			columns={columns}
			totalCount={campaigns?.length || 0}
			onReorder={handleReorder}
			isLoading={isLoading}
		/>
	);
};

export default WidgetSection;
