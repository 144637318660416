import { DataTable } from '@/components/element/data-table/DataTable';
import PageHeader from '@/components/layout/PageHeader';
import { useState } from 'react';
import { AdminCreditColumns } from '../components/credits/AdminCreditColumns';
import AdminCreditsFilters from '../components/credits/AdminCreditFilters';
import RejectRequestDialog from '../components/credits/RejectRequestDialog';
import useGetAdminCredits from '../hooks/admin-credits/useGetAdminCredits';
import useAdminCreditsMutation from '../hooks/admin-credits/useAdminCreditsMutation';
import { CreditRequestStatusEnum } from '@/features/intract-credits/types/credits.enums';
import { convertToTitleCase } from '@/utils/parsers';
import CreditRequestDetails from '../components/credits/CreditRequestDetails';

const AdminCredits = () => {
	const [open, setOpen] = useState(false);
	const [openDetails, setOpenDetails] = useState(false);
	const [id, setId] = useState('');
	const {
		search,
		setSearch,
		isLoading,
		creditRequests,
		totalCount,
		filters,
		setFilters,
		pagination,
		setPagination,
	} = useGetAdminCredits();

	const {
		isSubmitting,
		openConfirmation,
		rejectionReason,
		setRejectionReason,
		updateRequestStatus,
		errors,
	} = useAdminCreditsMutation({
		setOpen,
	});

	const columns = AdminCreditColumns([
		{
			type: 'button',
			label: 'Approve',
			onClick: (row) => {
				const requestText = `${convertToTitleCase(row?.requestType)} of ${row?.status === CreditRequestStatusEnum.Pending ? row?.lockedCredits : row?.consumedCredits} credits`;
				setId(row._id);
				openConfirmation(
					row?._id,
					CreditRequestStatusEnum.Approved,
					requestText,
				);
			},
			show: (row: any) => row.status === CreditRequestStatusEnum.Pending,
		},
		{
			type: 'button',
			label: 'Reject',
			onClick: (row) => {
				setOpen(true);
				setId(row._id);
			},
			show: (row: any) => row.status === CreditRequestStatusEnum.Pending,
		},
		{
			type: 'separator',
			show: (row: any) => row.status === CreditRequestStatusEnum.Pending,
		},
		{
			type: 'button',
			label: 'Details',
			onClick: (row) => {
				setId(row._id);
				setOpenDetails(true);
			},
		},
	]);
	return (
		<div>
			<PageHeader title="Intract Credits" />
			<AdminCreditsFilters
				search={search}
				setSearch={setSearch}
				filters={filters}
				setFilters={setFilters}
			/>
			<div className="mt-6">
				<DataTable
					data={creditRequests}
					columns={columns}
					totalCount={totalCount}
					isLoading={isLoading}
					isServerSide={true}
					pagination={pagination}
					setPagination={setPagination}
				/>
			</div>

			<RejectRequestDialog
				open={open}
				setOpen={setOpen}
				rowId={id}
				isSubmitting={isSubmitting}
				rejectionReason={rejectionReason}
				setRejectionReason={setRejectionReason}
				updateRequestStatus={updateRequestStatus}
				errors={errors}
			/>
			<CreditRequestDetails
				open={openDetails}
				setOpen={setOpenDetails}
				requestDetails={creditRequests.find((row) => row._id === id)}
			/>
		</div>
	);
};

export default AdminCredits;
