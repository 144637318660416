import { Media } from '@/components/element/media/Media';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import { asset } from '@/config/asset-cdn';
import { cn } from '@/lib/utils';
import { Dispatch, SetStateAction, useEffect } from 'react';
import en from '../../../../locales/en.json';

const CreditNux = ({
	open,
	setOpen,
	step,
	setStep,
	handleNext,
	handlePrevious,
	getTaskCompletedStatus,
	handleCloseNux,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	step: string;
	setStep: Dispatch<SetStateAction<string>>;
	handleNext: () => void;
	handlePrevious: () => void;
	getTaskCompletedStatus: (index: number) => boolean;
	handleCloseNux: () => void;
}) => {
	const { intractCredits } = en;
	const steps = intractCredits?.modals?.nux?.steps;

	return (
		<Dialog open={open} onOpenChange={handleCloseNux}>
			<DialogContent className="w-[29rem] p-6">
				{step === 'benefits' && <BenefitStep stepData={steps[0]} />}
				{step === 'earn' && (
					<HowToEarnStep
						stepData={steps[1]}
						getTaskCompletedStatus={getTaskCompletedStatus}
					/>
				)}

				<Separator className="mt-1 w-[111%] -mx-6" />

				<DialogFooter className="justify-between w-full sm:justify-between mt-3">
					<Button
						variant="outline"
						onClick={
							step === 'benefits' ? handlePrevious : handlePrevious
						}
						disabled={step === 'benefits'}
					>
						{step === 'benefits'
							? steps[0].buttons.previous
							: steps[1].buttons.previous}
					</Button>
					<StepIndicator currentStep={step} />
					<Button
						onClick={
							step === 'benefits' ? handleNext : () => handleCloseNux()
						}
						variant="violet-primary"
					>
						{step === 'benefits'
							? steps[0].buttons.next
							: steps[1].buttons.done}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CreditNux;

const StepIndicator = ({ currentStep }: { currentStep: string }) => {
	const steps = ['benefits', 'earn'];
	return (
		<div className="flex justify-center mt-4 mb-2 space-x-2">
			{steps.map((step, index) => (
				<div
					key={index}
					className={`h-2 w-2 rounded-full ${
						currentStep === step ? 'bg-slate-500' : 'bg-slate-200'
					}`}
				/>
			))}
		</div>
	);
};

const BenefitStep = ({ stepData }) => {
	return (
		<div className="">
			<Media
				src={asset['credit-icon-primary']}
				altText="credit-icon"
				className="mb-4 size-20"
			/>
			<DialogHeader>
				<DialogTitle className="text-lg leading-6 font-medium text-slate-700">
					{stepData.title}
				</DialogTitle>
				<DialogDescription className="text-sm leading-5 text-slate-500">
					{stepData.subtitle}
				</DialogDescription>
			</DialogHeader>
			<ul className="list-none text-left mt-4 space-y-2">
				{Array.isArray(stepData.list) &&
					stepData.list.map((item, index: number) => (
						<li key={index} className="flex items-center space-x-2">
							<i className="bi-check-lg text-violet-500 size-4 text-base leading-4"></i>
							<span className="text-sm leading-5 text-slate-500">
								{item}
							</span>
						</li>
					))}
			</ul>
		</div>
	);
};

const HowToEarnStep = ({ stepData, getTaskCompletedStatus }) => {
	return (
		<div className="text-center">
			<Media
				src={asset['savings']}
				altText="earn-credits"
				className="mb-6 mx-auto w-[14.65rem] h-[11rem]"
			/>
			<DialogHeader>
				<DialogTitle className="text-lg leading-6 font-medium text-slate-700 mb-0.5">
					{stepData.title}
				</DialogTitle>
				<DialogDescription className=" text-sm leading-6 text-slate-500 font-normal">
					{stepData.subtitle.before}&nbsp;
					<span className="text-slate-600 font-medium">
						{stepData.subtitle.highlight}
					</span>
					&nbsp;
					{stepData.subtitle.after}
				</DialogDescription>
			</DialogHeader>
			<div className="rounded-xl p-3 mt-4 border border-slate-200">
				{Array.isArray(stepData.tasks) &&
					stepData?.tasks?.map((task, index) => (
						<div key={index}>
							<div
								className={cn(
									'flex items-center justify-between  px-3 -mx-3',
									getTaskCompletedStatus(index)
										? 'cursor-default'
										: 'cursor-pointer',
								)}
								onClick={() => {
									if (!getTaskCompletedStatus(index)) {
										window.open(
											'/app/campaign/quests/new',
											'_blank',
										);
									}
								}}
							>
								<span
									className={cn(
										'text-base text-slate-700',
										getTaskCompletedStatus(index)
											? 'line-through text-slate-500 '
											: '',
									)}
								>
									{task.label}
								</span>
								<div className="flex items-center space-x-1">
									<span className="text-slate-500 font-medium me-1">
										+
									</span>
									<div className="flex items-center gap-1 bg-slate-100 rounded-full">
										<Media
											src={asset['credit-icon-primary']}
											altText="credit-icon"
											className="size-[22px]"
										/>
										<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
											{task.points}
										</span>
									</div>
									<i
										className={cn(
											getTaskCompletedStatus(index)
												? 'bi-check-circle-fill text-violet-500'
												: 'bi-circle text-slate-400',
											'ms-2 text-lg leading-5',
										)}
									></i>
								</div>
							</div>
							{index !== stepData.tasks.length - 1 && (
								<Separator className="my-3 -mx-3 w-[106%]" />
							)}
						</div>
					))}
			</div>
		</div>
	);
};
