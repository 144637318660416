import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const intercomWidgetKey = 'intercom_widget';

export const useIntercom = () => {
	const navigate = useNavigate();

	// TODO: Currently we do not handle the case where intercom takes a lot of time to load.
	// In that case, the intercom widget might not be shown (Haven't seen this happening yet)
	useEffect(() => {
		const handleIntercomWidget = () => {
			const intercomWidgetQueryParam = new URLSearchParams(
				window.location.search,
			).get(intercomWidgetKey);

			if (
				!intercomWidgetQueryParam ||
				intercomWidgetQueryParam !== 'true' ||
				!window.Intercom
			)
				return;

			window.Intercom('showNewMessage');

			// This code handles the removal of intercom search param once intercom closes.
			// This is done to prevent intercom from auto opening on every page load. (bad UX)

			window.Intercom('onHide', removeIntercomSearchParam);

			window.Intercom('onShow', removeIntercomSearchParam);

			function removeIntercomSearchParam() {
				const urlParams = new URLSearchParams(window.location.search);

				if (!urlParams.has(intercomWidgetKey)) return;

				urlParams.delete(intercomWidgetKey);

				navigate(`${window.location.pathname}?${urlParams.toString()}`, {
					replace: true,
				});
			}
		};

		// Do not show intercom popup immediately (bad UX)
		const timeout = setTimeout(handleIntercomWidget, 0);

		// Cleanup
		return () => clearTimeout(timeout);
	}, []);
};
