import { DataTable } from '@/components/element/data-table/DataTable';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import useExternalAPIs from '../../hooks/useExternalAPIs';

const ApiTokenTable = ({ setOpen, setStep }: { setOpen: any; setStep: any }) => {
	const { apiData, isLoading, externalAPIColumns } = useExternalAPIs({
		setOpen,
		setStep,
	});

	if (isLoading) {
		return <FullPageLoading />;
	}
	return (
		<div className="mt-10">
			<div>
				{/* {apiData && apiData?.length > 0 && ( */}
				<DataTable
					columns={externalAPIColumns}
					data={apiData}
					isLoading={isLoading}
					totalCount={apiData.length}
				/>
				{/* )} */}
			</div>
		</div>
	);
};

export default ApiTokenTable;
