import { useDebounce } from '@/hooks/useDebounce';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { creditRequests } from './../services/credits.service';

const useGetCreditsRequests = () => {
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [search, setSearch] = useState({
		value: '',
	});
	const [filters, setFilters] = useState({});
	const debounceSearch = useDebounce(search.value, 500);

	const { data, isLoading, refetch } = useQuery({
		queryKey: [
			'credits',
			'requests',
			{
				pagination,
				filters,
				debounceSearch,
			},
		],
		queryFn: () =>
			creditRequests({
				search: {
					value: debounceSearch,
				},
				filters,
				page: Number(pagination.pageIndex + 1) ?? 1,
				limit: Number(pagination.pageSize) ?? 10,
			}),
	});
	return {
		creditHistory: data?.data?.creditRequests || [],
		totalCount: data?.data?.totalCount || 0,
		isLoading,
		pagination,
		setPagination,
		refetch,
	};
};

export default useGetCreditsRequests;
