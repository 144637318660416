import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { getCampaign, getCampaignTemplate } from '../../services/campaigns.service';
import {
	ICampaign,
	ICreateCampaignDetails,
	ReferralRewardType,
	Status,
} from '../../types';
import { useCreateCampaignRewards } from './useCreateCampaignRewards';
import { useCreateCampaignTasks } from './useCreateCampaignTasks';
import { useTaskTemplates } from './useTaskTemplates';

export const useCreateCampaignInitialise = ({
	setDetails,
	setRewards,
	setTasks,
	setIsInitalising,
	setIsUpdate,
	setIsDirty,
	setCampaign,
}: {
	setDetails: Dispatch<SetStateAction<ICreateCampaignDetails>>;
	setIsUpdate: Dispatch<SetStateAction<boolean>>;
	setCampaign: Dispatch<SetStateAction<ICampaign>>;
	setRewards: (rewards: any[]) => void;
	setTasks: (tasks: any[]) => void;
	setIsInitalising: (isInitalising: boolean) => void;
	setIsDirty: (isInitalising: boolean) => void;
}) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { id } = useParams();
	const [isMounted, setIsMounted] = useState(false);
	const { initialiseRewards } = useCreateCampaignRewards();
	const { initialiseTasks, createTaskFromTemplate } = useCreateCampaignTasks();
	const { rawTemplates } = useTaskTemplates();

	useEffect(() => {
		if (isMounted) return;

		if (!rawTemplates || rawTemplates.length === 0) return;
		const templateId = searchParams.get('templateId');
		const duplicateFrom = searchParams.get('duplicateFrom');
		if (id) {
			initaliseTemplateCampaign(id, {
				isUpdate: true,
			});
		} else if (duplicateFrom || templateId) {
			initaliseTemplateCampaign(duplicateFrom || templateId, {
				isDuplicate: !!duplicateFrom,
				isTemplate: !!templateId,
			});
			if (duplicateFrom) {
				setIsDirty(true);
			}
		} else {
			initaliseEmptyCampaign();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rawTemplates]);

	const initaliseEmptyCampaign = async () => {
		analytics.track(TrackingEvents.CreateQuestViewed, {
			state: 'empty',
		});
		const rewards_ = initialiseRewards();
		setRewards(rewards_);
		if (searchParams.get('taskTemplate')) {
			const taskTemplate = rawTemplates.find(
				(template) =>
					template.templateType === searchParams.get('taskTemplate'),
			);
			if (taskTemplate) {
				const twitterFollowTask = createTaskFromTemplate(taskTemplate);

				// setTasks([twitterFollowTask]);
			}
		}

		setIsInitalising(false);
	};

	const initaliseTemplateCampaign = async (
		id: string,
		{
			isDuplicate = false,
			isUpdate = false,
			isTemplate = false,
		}: {
			isDuplicate?: boolean;
			isUpdate?: boolean;
			isTemplate?: boolean;
		},
	) => {
		try {
			analytics.track(TrackingEvents.CreateQuestViewed, {
				state: isDuplicate ? 'duplicate' : isUpdate ? 'update' : 'template',
			});
			let campaign_;
			if (isTemplate) {
				analytics.track(TrackingEvents.CreateQuestViewed, {
					state: 'template',
				});
				campaign_ = await getCampaignTemplate(id);
			} else {
				campaign_ = await getCampaign(id);
			}
			if (!campaign_) {
				if (isUpdate) {
					toast.error('Campaign not found, please try again.');
					navigate('/app/campaign/quests');
					return;
				}
				initaliseEmptyCampaign();
			}
			if (isUpdate) {
				setCampaign(campaign_);
			}
			if (isUpdate && campaign_?.status !== Status.Draft) {
				window.location.href = '/app/campaign/quests/' + id;
				return;
			}
			const network = campaign_.tags.find(
				(tag: any) => tag.key === 'chainId' || tag.key === 'possibleChainId',
			)?.value;
			const rewards_ = initialiseRewards(campaign_, isDuplicate);
			const tasks_ = initialiseTasks(campaign_, rawTemplates, !isUpdate);
			setDetails((prev) => ({
				...prev,
				name: isDuplicate
					? `${campaign_?.name} (Duplicate)`
					: campaign_?.name,
				description: campaign_?.description,
				startDate: new Date(campaign_?.startDate),
				endDate: new Date(campaign_?.endDate),
				bannerLink: campaign_?.banner,
				bannerPosition: campaign_?.bannerPosition,
				startImmediately: campaign_?.startImmediately,
				noEndTime: campaign_?.noEndTime,
				timezone: campaign_?.startDateTz,
				network: network ? parseInt(network) : null,
				narrativeText:
					campaign_?.narrativeTexts?.length > 0
						? campaign_?.narrativeTexts[0].text
						: '',
				twitterShare: campaign_?.twitterShare?.text,
				seoMetadata: {
					title: campaign_?.seoMetadata?.title,
					description: campaign_?.seoMetadata?.description,
					keywords: campaign_?.seoMetadata?.keywords,
					image: campaign_?.seoMetadata?.image,
					imageFile: null,
					isCustomImage:
						campaign_?.seoMetadata?.image !== campaign_?.banner,
				},
				referral: {
					referralTaskEnabled:
						campaign_?.quest?.referralTask?.referralTaskEnabled || false,
					numberOfRequiredReferrals:
						campaign_?.quest?.referralTask?.numberOfRequiredReferrals ||
						1,
					rewardType:
						campaign_?.quest?.referralTask?.rewardType ||
						ReferralRewardType.Fixed,
					rewardValue: campaign_?.quest?.referralTask?.rewardValue || 0,
					referralTaskText:
						campaign_?.quest?.referralTask?.referralTaskText ||
						'Refer friends to participate in quests',
					enableCustomReferralPoints:
						campaign_?.quest?.referralTask?.enableCustomReferralPoints,
					customPoints: campaign_?.quest?.referralTask?.customPoints,
				},
			}));
			setRewards(rewards_);
			setTasks(tasks_);
			setIsUpdate(isUpdate);
			setIsInitalising(false);
			setIsMounted(true);
		} catch (err) {
			console.log(err);
			handleErrorMessage(err);
			navigate('/app/campaign/quests');
		}
	};

	return {};
};
