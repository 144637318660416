import { axios } from '@/lib/axios';

export const getExternalAPIs = async () => {
	try {
		const response = await axios.get('/enterprise/api-keys');

		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteAPIKey = async (data: any) => {
	const response = await axios.delete(`/enterprise/api-keys/${data._id}`);

	return response.data;
};

export const createApiToken = async (data: string) => {
	const response = await axios.post(`/enterprise/api-keys`, {
		keyName: data,
	});
	return response.data;
};
