import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Media } from '@/components/element/media/Media';
import { IReward } from '@/features/reward-store/types/reward-store.type';
import { Badge } from '@/components/ui/badge';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { convertToTitleCase } from '@/utils/parsers';
import { RewardStatus, rewardIconMap } from '../types/reward-store.enums';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { Separator } from '@/components/ui/separator';
import DotsDropdown from '@/components/element/dropdowns/DotsDropdown';
import useRewardCardMutations from '../hooks/useRewardCardMutations';

const RewardCard = ({
	data,
	updateReward,
	setShowCreateNewReward,
	sectionId,
	rewardIndex,
	rewardLength,
}: {
	data: IReward;
	updateReward: (data: IReward) => void;
	setShowCreateNewReward: (show: boolean) => void;
	sectionId: string;
	rewardIndex: number;
	rewardLength: number;
}) => {
	const { rewardMutations, formatTooltipContent } = useRewardCardMutations({
		data,
		updateReward,
		setShowCreateNewReward,
		sectionId,
		rewardIndex,
		rewardLength,
	});

	return (
		<Card className="relative" key={data?._id}>
			<CardHeader className="p-2 relative">
				<Media
					className="rounded-lg h-[250px] object-cover aspect-square border"
					src={data?.image}
					lazyLoading
					imagePosition={data?.imagePosition}
				/>
				<div className="absolute top-0.5 right-2">
					<DotsDropdown options={rewardMutations} />
				</div>
			</CardHeader>
			<CardContent className="px-3 pt-3 pb-3">
				<div className="">
					<div>
						<div className="flex justify-between items-center">
							<div className="font-medium">{data?.name}</div>
							<div>
								{data?.status === RewardStatus.Active ? (
									<StatusBadge
										className="text-xs"
										status={RewardStatus.Active}
									/>
								) : (
									<StatusBadge
										className="text-xs "
										status={RewardStatus.Inactive}
									/>
								)}
							</div>
						</div>
						<div className="text-xs font-normal text-muted-foreground mt-1">
							<TooltipWrapper tooltip={data?.description}>
								<p className="max-w-[12.2rem] truncate">
									{data?.description}
								</p>
							</TooltipWrapper>
						</div>
					</div>
					<Separator className="mt-2" />

					<div className="w-full">
						<div className="flex justify-between items-center mt-4">
							<div className="text-xs">Reward Type</div>
							<div className="text-xs">
								<div>
									<i
										className={`${rewardIconMap[data?.rewardType]} me-1 text-black/60`}
									></i>
									{data?.rewardType}
								</div>
							</div>
						</div>
						<div className="flex justify-between items-center mt-4">
							<div className="text-xs">Eligibility Criteria</div>
							<div className="text-xs font-normal">
								{data?.eligibilityParams &&
									Array.isArray(data?.eligibilityParams) &&
									data?.eligibilityParams?.map((param) => (
										<TooltipWrapper
											tooltip={formatTooltipContent(param)}
											tooltipContentClass="flex flex-col gap-2 h-auto overflow-y-auto whitespace-pre-line max-w-[14rem] truncate"
										>
											<Badge variant="secondary">
												<div className="max-w-[7rem] truncate font-medium">
													{convertToTitleCase(
														param.eligibilityType,
													)}
												</div>
											</Badge>
										</TooltipWrapper>
									))}
							</div>
						</div>
						<div className="flex justify-between items-center mt-4">
							<div className="text-xs">Total Claims</div>
							<div className="text-xs">{data?.totalClaims || 0}</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default RewardCard;
