import { validateEVMAddress } from '@/utils/validation';
import { validateAdminInput } from '../../services/campaigns.service';
import {
	AdminInputType,
	ITaskAdminInput,
	TaskKeys,
	TemplateFamily,
	TemplateType,
} from '../../types';
import { useEffect, useState } from 'react';
import { completeAndSanitizeUrl } from '@/utils/parsers';

export const useCreateCampaignValidateAdminInputs = (
	taskId?: string,
	value?: any,
	key?: TaskKeys,
	setErrors?: (taskId: string, key: TaskKeys, value: boolean) => void,
) => {
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState('');

	useEffect(() => {
		if (setErrors && taskId && key) {
			setErrors(taskId, key, false);
		}
	}, [value, key, taskId]);

	const validateTaskAdminInput = async (
		inputType: AdminInputType,
		templateFamily: TemplateFamily | string,
		templateType: TemplateType | string,
	) => {
		try {
			setIsLoading(true);
			let isValid = false;
			let errorMessage = '';
			let newVal = value;

			if (inputType === AdminInputType.InputAddress) {
				isValid = validateEVMAddress(newVal);
				if (!isValid) errorMessage = 'Invalid EVM Address';
			} else {
				if (inputType === AdminInputType.InputStringArray) {
					if (Array.isArray(newVal)) {
						newVal = (newVal as { value: string; id: string }[])
							.map((item) => item.value)
							.join(',');
					} else {
						// Handle non-array newVal appropriately, maybe log a warning
						console.warn('Expected newVal to be an array');
						newVal = '';
					}
				}
				if (
					key === TaskKeys.DiscordInviteLink ||
					key === TaskKeys.TeleGramGroupLink ||
					key === TaskKeys.WebsiteUrl
				) {
					newVal = completeAndSanitizeUrl(newVal as string);
				}

				const res = await validateAdminInput({
					key: key!,
					value: newVal,
					templateFamily: templateFamily,
					templateType: templateType,
				});

				isValid = res?.isValid ?? false;
				errorMessage = res?.errorMessage ?? '';
			}

			setMessage(errorMessage);
			if (setErrors) {
				setErrors(taskId!, key!, !isValid);
			}

			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	const validateAdminInputs = async (
		adminInputs: ITaskAdminInput[],
		templateFamily: TemplateFamily,
		templateType: TemplateType,
	) => {
		const errors = {};
		for (const adminInput of adminInputs) {  
			let value = adminInput.value;
			if (adminInput.inputType === AdminInputType.InputStringArray) {
				value = (value as { value: string; id: string }[])
					.map((i) => i.value)
					.join(',');
			}
			if (
				(adminInput.inputType === AdminInputType.Select ||
					adminInput.inputType === AdminInputType.SelectAsync ||
					adminInput.inputType === AdminInputType.SelectFromAdminInput) &&
				!value
			) {
				errors[adminInput.key] = 'This field is required';
			} else {
				const res = await validateAdminInput({
					key: adminInput.key,
					value: value,
					templateFamily: templateFamily,
					templateType: templateType,
				});
				errors[adminInput.key] = res?.isValid ? '' : res?.errorMessage;
			}
		}
		return errors;
	};

	return {
		validateTaskAdminInput,
		isLoading: isLoading,
		message,
		validateAdminInputs,
	};
};
