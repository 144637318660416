import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { links } from '@/config/links';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { formatCompactNumber } from '@/utils/parsers';
import { useState } from 'react';
import MessageKolDialog from './MessageKolDialog';
import DOMPurify from 'dompurify';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export const KOLCard = ({ kol }) => {
	const [show, setShow] = useState(false);
	const [showMoreSocials, setShowMoreSocials] = useState(false);

	const { user } = useAuth();

	const name = typeof kol.name === 'string' ? kol.name : 'Unknown KOL';

	const getHypeLevel = (followers: number) => {
		if (followers > 500000) return 5;
		if (followers > 100000) return 4;
		if (followers > 50000) return 3;
		if (followers > 10000) return 2;
		return 1;
	};

	const safeHTML = DOMPurify.sanitize(kol?.description);

	return (
		<>
			<Card className=" h-full flex flex-col" key={kol._id}>
				<CardHeader className=" p-3">
					<div className="flex justify-between items-center ">
						<div className="flex items-center">
							<Avatar className="h-10 w-10 border rounded-full">
								<AvatarImage src={kol?.avatar} />
								<AvatarFallback name="avatar">
									{name?.charAt(0)}
								</AvatarFallback>
							</Avatar>
							<div className="ms-3">
								<TooltipWrapper tooltip={name} align="start">
									<div className="font-medium max-w-[15rem] truncate">
										{name}
									</div>
								</TooltipWrapper>
							</div>
						</div>
						<div className="text-center">
							<div className="text-lg font-medium">
								{formatCompactNumber(kol?.total_followers)}
							</div>
							<div className="text-xs">Followers</div>
						</div>
					</div>
				</CardHeader>
				<CardContent className="p-3 pb-">
					<div
						className="text-xs text-muted-foreground line-clamp-2 "
						dangerouslySetInnerHTML={{ __html: safeHTML }}
					></div>

					<div className="mt-5 flex space-x-3">
						{Array.isArray(kol?.social_info) &&
							kol?.social_info?.slice(0, 3)?.map((key: any) => (
								<Button
									size="sm"
									variant="outline"
									className="text-muted-foreground"
									onClick={() => {
										analytics.track(
											TrackingEvents.SocialChannelClicked,
											{
												userId: user?._id,
												enterpriseId: user?.enterpriseId,
												kolName: kol?.name,
												channel: key?.name,
											},
										);
										window.open(key.url, '_blank');
									}}
								>
									<i
										className={`bi-${key?.name?.toLowerCase?.()} me-2`}
									></i>{' '}
									{formatCompactNumber(key?.followers)}
								</Button>
							))}
						{kol?.social_info?.length > 3 ? (
							<Popover
								open={showMoreSocials}
								onOpenChange={setShowMoreSocials}
							>
								<PopoverTrigger asChild>
									<Button
										size="sm"
										variant="outline"
										className="text-muted-foreground"
									>
										<i className="bi-grid-3x3-gap-fill me-2 "></i>{' '}
										More
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-[4.9rem] p-0">
									{kol?.social_info?.slice(3)?.map((key: any) => (
										<Button
											size="sm"
											variant="ghost"
											className="text-muted-foreground w-full "
											onClick={() => {
												analytics.track(
													TrackingEvents.SocialChannelClicked,
													{
														userId: user?._id,
														enterpriseId:
															user?.enterpriseId,
														kolName: kol?.name,
														channel: key.name,
													},
												);
												window.open(key.url, '_blank');
											}}
										>
											<i
												className={`bi-${key?.name} me-2`}
											></i>{' '}
											{formatCompactNumber(key?.followers)}
										</Button>
									))}
								</PopoverContent>
							</Popover>
						) : null}
					</div>
				</CardContent>
				<CardFooter className="border-t mt-auto px-3 py-2">
					<div className="flex justify-between w-full">
						<div className="flex items-center">
							<div className="text-xs text-muted-foreground">
								Hype Level
							</div>
							<div className="ms-2 flex space-x-0.5">
								{[...Array(getHypeLevel(kol?.total_followers))].map(
									(_, index) => (
										<div
											key={index}
											className="h-[16px] w-[3px] rounded bg-green-500"
										></div>
									),
								)}
								{[
									...Array(5 - getHypeLevel(kol?.total_followers)),
								].map((_, index) => (
									<div
										key={index}
										className="h-[16px] w-[3px] rounded bg-slate-300"
									></div>
								))}
							</div>
						</div>
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								analytics.track(TrackingEvents.BoostersCardClicked, {
									booster: 'kol',
									kolId: kol?._id,
									kolName: kol?.name,
									userId: user?._id,
									enterpriseId: user?.enterpriseId,
									kolLanguage: kol?.language,
									kolFollowers: kol?.total_followers,
									kolChannels: kol?.social_info?.map(
										(channel) => channel.name,
									),
								});
								window.open(links?.kolProfile, '_blank');
							}}
						>
							<i className="bi-chat me-2"></i>
							Send message
						</Button>
					</div>
				</CardFooter>
			</Card>
			<MessageKolDialog open={show} setOpen={setShow} kol={kol} />
		</>
	);
};
