import { lazy } from 'react';
import { CreateTaskAPISteps } from '../../types/task-apis.enum';

const TestAPI = lazy(() => import('@/features/task-apis/components/TestAPI'));
const EvaluateResponse = lazy(
	() => import('@/features/task-apis/components/EvaluateResponse'),
);

interface StepProps {
	setStep: (step: CreateTaskAPISteps) => void;
	showEvaluateResponse: boolean;
}

export const GetVerifyStepsConfig = (props: StepProps) => {
	const steps = [
		{
			key: CreateTaskAPISteps.Testing,
			component: <TestAPI setStep={props.setStep} />,
		},
	];

	if (props.showEvaluateResponse) {
		steps.push({
			key: CreateTaskAPISteps.EvaluateResponse,
			component: <EvaluateResponse />,
		});
	}

	return steps;
};
