import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Fragment } from 'react/jsx-runtime';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { ApiCreateSectionEnum } from '../types/task-apis.enum';
import { useState } from 'react';

const ApiCreateSection = () => {
	const {
		section,
		setSection,
		evaluationErrors: errors,
		validateRequiredSteps,
	} = useConnectAPI();
	const sections = [
		{
			title: 'API Details',
			value: ApiCreateSectionEnum.Details,
			icon: 'file-text',
		},
		{
			title: 'Test Your API',
			value: ApiCreateSectionEnum.Verify,
			icon: 'ui-checks',
		},
	];

	const handleSectionChange = (value: ApiCreateSectionEnum) => {
		if (validateRequiredSteps()) setSection(value);
	};

	return (
		<div className="flex justify-center items-center space-x-4">
			{sections.map((i, index) => (
				<Fragment key={i.value}>
					<TooltipWrapper
						tooltip={
							errors?.sections?.[i.value.toLowerCase()]
								? 'Some of the details are added wrongly'
								: ''
						}
					>
						<Button
							size="sm"
							className={cn(
								'rounded-full px-4',
								section !== i.value
									? ''
									: 'border-purple-700 bg-purple-100 text-purple-800  hover:bg-purple-100  hover:text-purple-800',
								errors?.sections?.[i.value]
									? 'border-red-200 border'
									: 'border-transparent',
							)}
							variant="secondary"
							onClick={() => handleSectionChange(i.value)}
						>
							{errors?.sections?.[i.value] ? (
								<i className="bi bi-exclamation-triangle-fill me-2 text-red-500"></i>
							) : (
								<i className={`bi bi-${i.icon} me-2`}></i>
							)}
							{i.title}
						</Button>
					</TooltipWrapper>
					{sections.length !== index + 1 && (
						<div>
							<i className="bi bi-chevron-right text-muted-foreground"></i>
						</div>
					)}
				</Fragment>
			))}
		</div>
	);
};

export default ApiCreateSection;
