import { Button } from '@/components/ui/button';
import { useCreateActionProvider } from '@/features/dave/hooks/actions/useCreateActionProvider';
import { useGetContracts } from '@/features/dave/hooks/contracts/useGetContracts';
import { useState } from 'react';
import AddContractDialog from '../../contracts/AddContractDialog';
import { Card, CardContent } from '@/components/ui/card';
import img from './shape.png';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import { links } from '@/config/links';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { getScanLink } from '@/utils/blockChain';
import { IDaveContracts } from '@/features/dave/types/dave.types';

const SelectContract = () => {
	const [open, setOpen] = useState(false);
	const { contracts, isLoading } = useGetContracts();
	const { details, setDetails, errors } = useCreateActionProvider();
	const { getNativeChainLogo } = useGetSupportedChains({
		showAdditionalChains: false,
	});
	const [selectedContract, setSelectedContract] = useState<any>(null);

	const handleSelectContract = (e: string) => {
		const ABI = [];
		const contract = contracts?.find((i) => i._id === e);
		const abi = JSON.parse(contract.abi);
		ABI.push(...abi);
		const abis = [
			{
				contract: contract.contractAddress,
				abi: contract.abi,
			},
		];
		const contractAddress = contract.contractAddress;
		setDetails((prev) => ({
			...prev,
			contractId: e,
			abi: ABI,
			abis,
			chainId: contract.chainId,
			transactionTo: contractAddress,
		}));
	};

	const handleSelectNewContract = (contract: IDaveContracts) => {
		try {
			const ABI = [];
			const abi = JSON.parse(contract.abi);
			ABI.push(...abi);
			const abis = [
				{
					contract: contract.contractAddress,
					abi: contract.abi,
				},
			];

			const contractAddress = contract.contractAddress;
			setDetails((prev) => ({
				...prev,
				contractId: contract?._id,
				abi: ABI,
				abis,
				chainId: contract.chainId,
				transactionTo: contractAddress,
			}));
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className="pb-20">
			<div className="mb-4">
				<h3 className="text-md mb-1 font-medium">
					<i className="bi-code-square me-2" />
					Select your contract
				</h3>
				<p className="text-xs text-muted-foreground">
					Select the contract you want to verify
				</p>
			</div>
			{contracts?.length === 0 && !isLoading ? (
				<NoContractCard setOpen={setOpen} />
			) : (
				<>
					<div className="grid grid-cols-12 items-top gap-4">
						<InputSelect2
							value={details.contractId}
							setValue={handleSelectContract}
							error={errors.contractId}
							placeholder="Search contracts"
							options={
								contracts?.map((i) => ({
									value: i._id,
									searchText: i.name + ' ' + i.contractAddress,
									label: (
										<div className="flex items-center">
											<div className="">
												<img
													src={getNativeChainLogo(
														i.chainId,
													)}
													className="h-6 w-6 me-2 rounded-full"
													alt="Chain Logo"
												/>
											</div>
											<div>
												<div className="font-medium">
													{i.name}
												</div>
												<div className="text-xs text-muted-foreground">
													{i.contractAddress}
												</div>
												{i.implementationAddress && (
													<div className="text-xs text-muted-foreground mt-1">
														Implementation at{' '}
														{i.implementationAddress?.slice(
															0,
															6,
														) +
															'...' +
															i.implementationAddress?.slice(
																-4,
															)}
													</div>
												)}
											</div>
										</div>
									),
									selectedLabel: (
										<div className="flex items-center">
											<div className="">
												<img
													src={getNativeChainLogo(
														i.chainId,
													)}
													className="h-5 w-5 me-2 rounded-full"
													alt="Chain Logo"
												/>
											</div>
											<div>
												<div className="font-medium">
													{i.name
														? i.name
														: i.contractAddress}
												</div>
											</div>
										</div>
									),
								})) ?? []
							}
							inputClassName="bg-white dark:bg-gray-800 h-10"
							className="col-span-9"
							errorText="Please select a contract."
							appendIcon={details.contractId ? '' : 'bi-search'}
							popoverClassName="w-[400px]"
						/>
						<Button
							variant="outline"
							className=" col-span-3 h-10"
							onClick={() => {
								setSelectedContract(null);
								setOpen(true);
							}}
						>
							+ Add Contract
						</Button>
					</div>
				</>
			)}
			{details.contractId && (
				<div className="text-xs text-muted-foreground mt-4">
					You have selected{' '}
					{contracts?.find((i) => i._id === details.contractId)?.name
						? contracts?.find((i) => i._id === details.contractId)?.name
						: contracts?.find((i) => i._id === details.contractId)
								?.contractAddress}
					. You can check the contract details{' '}
					<a
						href={getScanLink(
							details.transactionTo,
							details.chainId,
							'address',
						)}
						target="_blank"
						className="text-primary"
					>
						here
					</a>
					. If you want to edit the contract details, {` `}
					<span
						onClick={() => {
							setSelectedContract(
								contracts?.find((i) => i._id === details.contractId),
							);
							setOpen(true);
						}}
						className="text-primary hover:underline"
					>
						click here
					</span>
				</div>
			)}
			<AddContractDialog
				open={open}
				setOpen={setOpen}
				onSuccess={(contract) => {
					handleSelectNewContract(contract);
				}}
				selectedContract={selectedContract}
			/>
		</div>
	);
};

export default SelectContract;

const NoContractCard = ({ setOpen }) => {
	return (
		<Card className="me-">
			<CardContent className="py-4 px-20  flex flex-col items-center justify-center">
				<img src={img} className="h-20 mb-3" alt="Empty Contract" />
				<div className="text-base font-medium mb-2">
					Add your Smart Contract
				</div>
				<p className="text-xs text-muted-foreground mb-6 max-w-[290px] text-center">
					You can easily import verified smart contracts, or add your own
					contract with your ABI.
				</p>
				<Button
					className="w-1/2"
					onClick={() => setOpen(true)}
					variant="outline"
				>
					<i className="bi-plus-circle-fill me-2"></i> Add Contract
				</Button>
				<Button
					className="w-full text-xs"
					size="sm"
					onClick={() => {
						window.open(links.docs.addingContract, '_blank');
					}}
					variant="link"
				>
					Learn more
				</Button>
			</CardContent>
		</Card>
	);
};
