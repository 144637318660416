import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import SeparatorWithText from '@/components/ui/seprator-with-text';
import { useAuth } from '@/hooks/useAuth';
import { toast } from 'sonner';

const ReferralPage = () => {
	return (
		<div className="mt-5 space-y-9">
			<ReferralCode />
			{/* <UserEarnings /> */}
			<ReferralFAQs />
			<div className="grid grid-cols-12 gap-5">
				<div className="col-span-8"></div>

				<div className="col-span-4 pe-10 2xl:pe-40"></div>
			</div>
		</div>
	);
};

export default ReferralPage;

const UserEarnings = () => {
	return (
		<div className="grid grid-cols-2 gap-5">
			<Card className="bg-slate-50 shadow-none flex flex-col">
				<CardContent className="p-4 h-100 flex flex-col">
					<div className="flex justify-center items-center border-b pb-5">
						<div className="flex justify-center items-center flex-col ">
							<div className="text-muted-foreground text-sm">
								Total Earnings
							</div>
							<div className="font-semibold text-3xl mt-1">$1,000</div>
						</div>
					</div>
					<div className="flex justify-between mb-2 py-4 border-b ">
						<div className="text-sm text-muted-foreground">
							Available Balance
						</div>
						<div className="font-medium">$500</div>
					</div>
					<div className="flex justify-between py-2">
						<div className="text-sm text-muted-foreground">
							Redeemed Balance
						</div>
						<div className="font-medium">$500</div>
					</div>
					<Button className="w-full mt-12">
						<i className="bi-wallet me-2"></i>
						Redeem Now
					</Button>
				</CardContent>
			</Card>
			<div className="flex flex-col justify-between space-y-4">
				<Card className="bg-slate-50 shadow-none">
					<CardContent className="p-4">
						<div className="flex justify-center items-center ">
							<div className="flex justify-center items-center flex-col ">
								<div className="text-muted-foreground text-sm">
									Clients Referred
								</div>
								<div className="font-semibold text-3xl mt-1">12</div>
							</div>
						</div>
					</CardContent>
				</Card>
				<Card className="bg-slate-50 shadow-none">
					<CardContent className="p-4">
						<div className="flex justify-center items-center ">
							<div className="flex justify-center items-center flex-col ">
								<div className="text-muted-foreground text-sm">
									Activated Clients
								</div>
								<div className="font-semibold text-3xl mt-1">12</div>
							</div>
						</div>
					</CardContent>
				</Card>
				<Card className="bg-slate-50 shadow-none">
					<CardContent className="p-4">
						<div className="flex justify-center items-center ">
							<div className="flex justify-center items-center flex-col ">
								<div className="text-muted-foreground text-sm">
									Total Quests
								</div>
								<div className="font-semibold text-3xl mt-1">25</div>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

const ReferralFAQs = () => {
	return (
		<Card className=" shadow-none mt-10">
			<CardContent className="p-4">
				<div className="text-sm font-semibold">
					<i className="bi-question-circle  text-violet-600 me-2"></i>
					Frequently Asked Questions
				</div>
				<Accordion type="single" collapsible className="w-full mt-5">
					{faqs.map((faq, index) => (
						<AccordionItem value={`item-${index}`}>
							<AccordionTrigger className="text-sm text-start">
								{faq.question}
							</AccordionTrigger>
							<AccordionContent>{faq.answer}</AccordionContent>
						</AccordionItem>
					))}
				</Accordion>
			</CardContent>
		</Card>
	);
};

const faqs = [
	{
		question: 'What is the Intract Comm Affiliate Network program?',
		answer: 'The Intract Comm Affiliate Network program is an incentivised business referral system designed for Intract Community Ambassadors to refer small and mid-sized web3 communities to use Intract Loyalty Suite. The program offers guaranteed $ rewards and other future incentives for successful referrals.',
	},
	{
		question: 'When is Phase 1 of the program?',
		answer: 'Phase 1 of the Intract Comm Affiliate Network program runs from April 03, 2024, to April 10, 2024.',
	},
	{
		question: 'What are the rewards for successful referrals?',
		answer: 'Rewards are based on the number of successful referrals, starting from a minimum of 50$ for 1-5 referrals, 150$ for 6-10 referrals, and 400$ for 10-20 referrals. Additional bonuses include 10$ for each call the referred community makes with Intract, 20$ for communities already using platforms like Zealy or Galxe, and 30$ for communities that actively maintain their loyalty hub.',
	},
	{
		question: 'What constitutes a successful referral?',
		answer: 'A successful referral requires the web3 community to be legitimate, have more than 10,000 Twitter followers or Discord members, be referred by a manager in the community & marketing team, launch an active loyalty quest on Intract with specified tasks, and post about their community hub on their channels.',
	},
	{
		question: 'How can I submit my referrals?',
		answer: 'To submit referrals, raise a ticket in discord tagging Harsh_web3 with the project details, screenshots, and the TG/Discord handles of the referred community. Also, provide your wallet address for reward processing.',
	},
	{
		question: 'How and when will I receive my rewards?',
		answer: 'Rewards are distributed weekly to the registered wallet address of the referring ambassador by the community team.',
	},
	{
		question: 'What are the unique offerings of the Intract Loyalty Suite?',
		answer: 'The document briefly mentions the Intract Loyalty Suite but does not detail its unique offerings. Typically, such offerings may include customized loyalty programs, comprehensive community engagement tools, and analytics to track program success.',
	},
	{
		question: 'How do I get started with the Intract Loyalty Suite?',
		answer: "While the document does not provide specific steps on getting started, generally, one would begin by visiting the Intract platform, signing up for the Loyalty Suite, and following the onboarding instructions to set up their community's loyalty program.",
	},
];

const ReferralCode = () => {
	const { user } = useAuth();
	return (
		<Card className="">
			<CardHeader className="bg-primary rounded-t-xl text-primary-foreground text-center py-10">
				<div className="pb-24">
					<Badge className="rounded-full px-3 mb-4" variant="secondary">
						<i className="bi-credit-card me-2 text-yellow-600"></i>
						Earn with Intract
					</Badge>
					<div className="text-4xl">
						Refer & Earn{' '}
						<span className="text-4xl italic text-yellow-500 font-semibold ">
							$$ Unlimited
						</span>
					</div>

					<div className="mt-6">Earn 2% of future billings in BTRUST.</div>
				</div>
			</CardHeader>
			<div className="px-10 -mt-24 pb-10">
				<Card>
					<CardContent className="p-5 text-center">
						<div>
							<div className="text-">
								Your Referral Code
								<i
									className="bi-clipboard ms-2 text-sm text-muted-foreground cursor-pointer"
									onClick={() => {
										navigator.clipboard.writeText(
											user?.referralCode,
										);
										toast.success(
											`Referral code has been copied on your clipboard`,
										);
									}}
								></i>
							</div>
							<div className="text-4xl  text-black font-semibold mt-2">
								{user?.referralCode}
							</div>
						</div>
						<SeparatorWithText text="Refer Via" className="mt-5" />
						<div className="mt-6 flex items-center justify-center space-x-4">
							<Button variant="outline" size="icon">
								<i className="text-xl bi-twitter text-blue-500"></i>
							</Button>
							<Button variant="outline" size="icon">
								<i className="text-xl bi-discord text-blue-700"></i>
							</Button>
							<Button variant="outline" size="icon">
								<i className="text-xl bi-telegram text-green-600"></i>
							</Button>
							<Button variant="outline" size="icon">
								<i className="text-xl bi-globe text-slate-600"></i>
							</Button>
						</div>
					</CardContent>
				</Card>
			</div>
		</Card>
	);
};
