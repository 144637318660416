import FullScreenCreateLayout from '@/components/layout/full-screen-create/FullScreenCreateLayout';
import { links } from '@/config/links';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useCreateQueryProvider } from '@/features/dave/hooks/queries/useCreateQueryProvider';
import { useNavigate } from 'react-router-dom';
import DaveActionCreateNavigation from './DaveQueryCreateNavigation';

const DaveCreateQueryLayout = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate();
	const { details } = useCreateQueryProvider();

	const { enterprise } = useGetEnterprise();

	return (
		<FullScreenCreateLayout showGrid={false}>
			<FullScreenCreateLayout.Header>
				<FullScreenCreateLayout.Title
					name={details?.name}
					placeholder={'Untitled Query'}
					onFeedback={() => {
						window.open(links.feedbackForm, '_blank');
					}}
					onBack={() => navigate('/app/tools/dave/queries')}
					enterpriseCreditsBalance={enterprise?.credits?.balanceCredits}
				/>
				{/* <FullScreenCreateLayout.Sections>
					<DaveActionCreateSection />
				</FullScreenCreateLayout.Sections> */}
			</FullScreenCreateLayout.Header>
			<FullScreenCreateLayout.Content fullHeight>
				{children}
			</FullScreenCreateLayout.Content>
			<FullScreenCreateLayout.Navigation>
				<DaveActionCreateNavigation />
			</FullScreenCreateLayout.Navigation>
		</FullScreenCreateLayout>
	);
};

export default DaveCreateQueryLayout;
