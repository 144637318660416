import { useEffect, useMemo, useState } from 'react';
import { DaveActionCreateSectionEnum } from '../../types/dave.enum';
import { useCreateActionProvider } from './useCreateActionProvider';
import { handleErrorMessage } from '@/utils/notifications';
import { createAction, updateAction } from '../../services/dave.service';
import { v4 as uuidv4 } from 'uuid';
import { queryClient } from '@/lib/react-query';
import { toast } from 'sonner';
import { useConfirm } from '@/hooks/useConfirm';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useCreateActionNavigation = () => {
	const { section, setSection, setErrors, details, id } =
		useCreateActionProvider();
	const [isLoading, setIsLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [actionId, setActionId] = useState<string | null>(null);

	const forwardLabel = useMemo(() => {
		if (details?.type === 'contract') {
			if (section === DaveActionCreateSectionEnum.Intractions)
				return id ? 'Update Action' : 'Save Action';
		} else {
			if (section === DaveActionCreateSectionEnum.Details)
				return id ? 'Update Action' : 'Save Action';
		}
		return 'Next';
	}, [section, details, id]);

	useEffect(() => {
		setErrors({});
	}, [details, setErrors]);

	const validate = () => {
		if (section === DaveActionCreateSectionEnum.Details) {
			const newErrors: any = {};
			if (!details.name) {
				newErrors.name = true;
			}
			if (!details.category) {
				newErrors.category = true;
			}
			if (details.type === 'contract') {
				if (!details.contractId) {
					newErrors.contractId = true;
				}
			}
			if (details.type === 'address') {
				if (!details.transactionFrom) {
					newErrors.transactionFrom = true;
				}
			}
			if (Object.keys(newErrors).length) {
				setErrors(newErrors);
				return false;
			}
		}
		if (section === DaveActionCreateSectionEnum.Intractions) {
			let isValid = true;
			const newErrors = {
				userAddressSelected: false,
				event0Topic0: false,
			};
			if (!details.userAddressSelected) {
				newErrors.userAddressSelected = true;
				isValid = false;
			}
			if (
				details.event0ContractAddress &&
				!details.abis
					.map((i) => i.contract)
					.includes(details.event0ContractAddress)
			) {
				newErrors.event0Topic0 = true;
				isValid = false;
			}
			setErrors(newErrors);
			return isValid;
		}
		return true;
	};

	const moveForward = () => {
		if (!validate()) return;
		if (section === DaveActionCreateSectionEnum.Details) {
			if (details.type === 'contract') {
				setSection(DaveActionCreateSectionEnum.Intractions);
			} else {
				saveAction();
				// setSection(DaveActionCreateSectionEnum.Testing);
			}
		}
		if (section === DaveActionCreateSectionEnum.Intractions) {
			saveAction();
		}
	};
	const moveBack = () => {
		if (section === DaveActionCreateSectionEnum.Intractions) {
			setSection(DaveActionCreateSectionEnum.Details);
		}
	};
	const { show } = useConfirm();

	const saveAction = async () => {
		try {
			setIsLoading(true);
			let actionId = id;
			if (actionId) {
				const res = await show({
					title: 'Are you sure you want to update this action?',
					subtitle:
						'This action will be updated. Updating the action will not affect the already fetched transactions and only the new transactions will be fetched as per new action rules. Please reach out to the support team if you have any queries.',
					confirmText: 'Update',
					cancelText: 'Cancel',
					type: 'warning',
				});
				if (!res) {
					setIsLoading(false);
					return;
				}
			}
			const payload = {
				_id: id,
				name: details?.name?.trim(),
				description: details?.description?.trim(),
				category: details?.category,
				chainId: details?.chainId,
				actionTag: uuidv4(),

				transactionFrom: details?.transactionFrom,

				transactionTo: details?.transactionTo,
				functionSignature:
					details?.functionSignature === 'NA'
						? ''
						: details?.functionSignature,
				event0ContractAddress: details?.event0ContractAddress,
				event0Topic0:
					details?.event0Topic0 === 'NA' ? '' : details?.event0Topic0,

				userAddressSource: details?.userAddressSource,
				userAddressArrayParamIndex: details?.userAddressArrayParamIndex,
				userAddressParamIndex: details?.userAddressParamIndex,

				abis: details?.abis.map((i) => ({
					contractAddress: i.contract,
					abi:
						i.contract === details?.transactionTo
							? JSON.stringify(details?.abi)
							: i.abi,
				})),
			};
			if (id) {
				await updateAction(payload, id);
				queryClient.invalidateQueries({
					queryKey: ['dave-actions', id],
				});
				setActionId(id);
			} else {
				const res = await createAction(payload);
				actionId = res._id;
				setActionId(res._id);
			}
			analytics.track(TrackingEvents.DaveActionAdded, {
				chainId: details?.chainId,
				actionId: actionId,
				actionType: details?.type,
			});
			queryClient.invalidateQueries({
				queryKey: ['dave', 'actions'],
			});
			//TODO: add action with toast message!!
			toast.success(
				'Action created successfully, you can now go and create a query',
			);
			setShowSuccess(true);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			handleErrorMessage(err);
		}
	};

	return {
		moveForward,
		moveBack,
		forwardLabel,
		isLoading,
		setShowSuccess,
		showSuccess,
		actionId,
	};
};
