import { Separator } from '@/components/ui/separator';
import HomepageBanner from '../components/content-management-system/banners/HomepageBanner';

const AdminBanners = () => {
	return (
		<div className="flex justify-center relative items-center">
			<div className="mb-5 w-full relative">
				<h4 className="text-xl font-medium">Add Banners to homepage</h4>
				<p className="text-muted-foreground text-xs mt-1 mb-4">
					Configure the layout, order of the banners.
				</p>
				{/* <Separator className="my-4" /> */}
				<HomepageBanner />
			</div>
		</div>
	);
};

export default AdminBanners;
