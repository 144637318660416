import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogFooter,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
} from '@/components/ui/dialog';
import useAddCustomChain from '@/features/campaigns/hooks/create/useAddCustomChain';
import { Dispatch, SetStateAction } from 'react';

const AddNewCustomChain = ({
	show,
	setShow,
	onClose,
	setCustomChainByDefault,
}: {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	onClose?: () => void;
	setCustomChainByDefault?: (chainId: number) => void;
}) => {
	const {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		handleAddNewCustomChain,
		isPending,
		handleClose,
	} = useAddCustomChain({ setShow, onClose, setCustomChainByDefault });

	return (
		<Dialog open={show} onOpenChange={handleClose}>
			<DialogContent className="sm:max-w-[600px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between">
						Add Custom Chain
					</DialogTitle>
					<DialogDescription>
						Add a custom chain to this enterprise
					</DialogDescription>
				</DialogHeader>
				<div className="my-4">
					<div className="grid grid-cols-2 gap-8">
						<div className="space-y-10">
							<InputText
								label="Chain Name"
								placeholder="Enter chain name"
								required
								value={formFields.chainName}
								setValue={(e) =>
									setFormFields((prev) => ({
										...prev,
										chainName: e,
									}))
								}
								onFocus={(e) => {
									e.target.select();
								}}
								error={formErrors.chainName}
								errorText={formErrors.chainName}
							/>
							<InputText
								label="Chain Id"
								placeholder="Enter chain id"
								value={formFields.chainId}
								setValue={(e) => {
									const numericValue = Number(e);
									if (isNaN(numericValue)) {
										return;
									}
									setFormFields((prev) => ({
										...prev,
										chainId: numericValue,
									}));
								}}
								error={formErrors.chainId}
								errorText={formErrors.chainId}
							/>
						</div>
						<div>
							<DropzoneWithReposition
								allowedFiles={['images']}
								description="Recommended size: 400x400px"
								label="Chain Logo"
								cta="Upload the image "
								aspectRatio={1 / 1}
								className="w-full"
								dropzoneClass="bg-muted mt-2"
								required
								file={formFields.chainLogoFile}
								fileLink={formFields.chainLogo}
								clearSelection={() => {
									setFormFields((prev) => ({
										...prev,
										chainLogo: '',
										chainLogoFile: null,
									}));
								}}
								setFile={(file: File, fileLink: string) => {
									setFormFields((prev) => ({
										...prev,
										chainLogoFile: file,
										chainLogo: fileLink,
									}));
								}}
								customHeight="!max-h-[25rem]"
								error={formErrors.chainLogo}
								errorText={formErrors.chainLogo}
								onImageReposition={({ x, y }) => {
									setFormFields({
										...formFields,
										imagePosition: { x, y },
									});
								}}
								lockBodyScroll={false}
								showPlaceHolderImage
							/>
						</div>
					</div>
				</div>

				<DialogFooter
					className={`justify-between space-x-2 border-t pt-3 flex sm:justify-between `}
				>
					<div></div>
					<div className="flex gap-2">
						<Button variant="ghost" onClick={() => handleClose()}>
							Cancel
						</Button>
						<Button
							onClick={handleAddNewCustomChain}
							disabled={isPending}
						>
							{isPending ? (
								<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
							) : null}
							Add Chain
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddNewCustomChain;
