import React, { useMemo } from 'react';
import { SubmissionItem } from './SubmissionItem';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';
import { ITask, POWVerifyStatus } from '@/features/campaigns/types';
import { useCampaignSubmissionsApprovals } from '@/features/campaigns/hooks/report/useCampaignSubmissionsApprovals';
import { Badge } from '@/components/ui/badge';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { custom } from 'zod';
import InputText from '@/components/element/inputs/InputText';

export const SubmissionCard = ({
	selectedRow,
	handlePrevious,
	handleNext,
	selectedTaskId,
	powTasks,
	queryKey,
	setCursor,
	campaignId,
	customXP,
	setCustomXP,
	enterpriseId,
}: {
	selectedRow: any;
	handlePrevious: () => void;
	handleNext: () => void;
	selectedTaskId: string;
	powTasks: ITask[];
	queryKey: any[];
	setCursor: any;
	campaignId: string;
	customXP: number;
	setCustomXP: (val: number) => void;
	enterpriseId: string;
}) => {
	const { handleReview, isActionLoading } = useCampaignSubmissionsApprovals(
		queryKey,
		campaignId,
	);

	const allowedEnterprises = ['66431fa3c1cc7f2544721dd0'];

	return (
		<div className=" p-4 rounded-xl border flex-col h-full relative min-h-[65vh] border-dashed flex items-center justify-start bg-slate-50">
			<SubmissionItem
				task={powTasks.find((i) => i._id === selectedTaskId)}
				submission={selectedRow}
			/>
			{allowedEnterprises.includes(enterpriseId) ? (
				<div className="flex flex-col gap-2 w-full px-4">
					<InputText
						label="Give custom XP"
						value={customXP}
						setValue={(v) => {
							if (v > selectedRow?.task?.xp) return;
							setCustomXP(v);
						}}
						placeholder="Enter custom XP"
						className="w-full"
						disabled={selectedRow?.status !== POWVerifyStatus.Initiated}
					/>
					<p className="text-xs">
						{`This custom XP should not be greater than the task's XP i.e. ${powTasks.find((i) => i._id === selectedTaskId)?.xp}`}
					</p>
				</div>
			) : null}

			<div className="absolute flex gap-2 justify-between w-full bottom-0 p-4 items-center">
				<div className="flex gap-2">
					<Button
						size="icon"
						variant="outline"
						className="h-6 w-6"
						onClick={handlePrevious}
					>
						<i className="bi-chevron-left"></i>
					</Button>
					<Button
						size="icon"
						variant="outline"
						className="h-6 w-6"
						onClick={handleNext}
					>
						<i className="bi-chevron-right"></i>
					</Button>
				</div>
				<div className="flex items-center gap-2">
					{selectedRow?.status === POWVerifyStatus.Initiated && (
						<>
							<Button
								variant="outline"
								disabled={
									isActionLoading.reject ||
									isActionLoading.approval
								}
								onClick={async () => {
									await handleReview(
										selectedRow?._id,
										selectedTaskId,
										false,
										customXP,
									);
									handleNext();
								}}
							>
								{isActionLoading.rejectSelected && (
									<Spinner className="me-1" />
								)}
								<span>Reject</span>
							</Button>

							<Button
								disabled={
									isActionLoading.reject ||
									isActionLoading.approval
								}
								onClick={async () => {
									await handleReview(
										selectedRow?._id,
										selectedTaskId,
										true,
										customXP,
									);
									handleNext();
								}}
							>
								{isActionLoading.approval && (
									<Spinner className="me-1" />
								)}
								<span>Approve</span>
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
