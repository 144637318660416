import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Row } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import TooltipWrapper from '../../tooltips/TooltipWrapper';

export function DataTableRowActions({
	row,
	options,
}: {
	row: any;
	options: {
		type: 'button' | 'separator';
		label?: string;
		icon?: string;
		onClick?: (row: Row<any>) => void;
		disabled?: boolean;
		tooltip?: string;
		show?: (row: Row<any>) => boolean;
	}[];
}) {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
			key={row.id}
		>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant="ghost"
						className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
					>
						<DotsHorizontalIcon className="h-4 w-4" />
						<span className="sr-only">Open menu</span>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end" className="w-[160px]">
					{options.map((option, index) => {
						if (option.show && !option.show(row.original)) {
							return null;
						}
						if (option.type === 'separator') {
							return <DropdownMenuSeparator key={index} />;
						}
						if (option.tooltip) {
							return (
								<TooltipWrapper
									tooltip={option.tooltip}
									key={`${index}-${option.label}`}
								>
									<DropdownMenuItem
										key={`${index}-${option.label}`}
										onClick={(e) => {
											e.stopPropagation();
											option.onClick(row.original);
										}}
										disabled={option.disabled}
										className="flex items-center cursor-pointer w-[150px] text-left"
									>
										{option.icon && (
											<i
												className={`bi-${option.icon} me-2`}
											></i>
										)}
										<p className="w-full">{option.label}</p>
									</DropdownMenuItem>
								</TooltipWrapper>
							);
						}
						return (
							<DropdownMenuItem
								key={`${index}-${option.label}`}
								onClick={(e) => {
									e.stopPropagation();
									option.onClick(row.original);
								}}
								disabled={option.disabled}
								className="flex items-center cursor-pointer w-full"
							>
								{option.icon && (
									<i className={`bi-${option.icon} me-2`}></i>
								)}
								<p className="w-full">{option.label}</p>
							</DropdownMenuItem>
						);
					})}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
}
