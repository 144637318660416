import clsx from 'clsx';
import { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '@/styles/quill.css';
import './LinkSanitizer'; // Import the custom link sanitizer

interface WYSIWYGProps {
	className?: string;
	labelClassName?: string;
	value: any;
	setValue: (value: any) => void;
	placeholder?: string;
	label?: string;
	error?: boolean | string;
	errorText?: string;
	onBlur?: () => void;
	size?: 'sm' | 'lg';
	customToolbar?: boolean;
}

export const QuillEditor = ({
	className,
	labelClassName,
	value,
	setValue,
	label,
	error,
	errorText,
	onBlur,
	placeholder,
	size,
	customToolbar = false,
}: WYSIWYGProps) => {
	const quillRef = useRef<ReactQuill>(null);

	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			['bold', 'italic', 'underline'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ align: [] }],
			['link', 'image', 'video'],
			['clean'], // remove formatting button
		],
	};

	const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'list',
		'bullet',
		'link',
		'image',
		'video',
		'align',
	];

	return (
		<div
			className={`quill-custom ${size === 'lg' ? 'quill-custom-lg' : ''}  rounded-lg  ${className}`}
		>
			{label && (
				<label className={clsx('form-label', labelClassName)}>{label}</label>
			)}
			<ReactQuill
				ref={quillRef}
				theme="snow"
				value={value}
				onChange={setValue}
				placeholder={placeholder}
				onBlur={onBlur}
				className={clsx(
					'bg-white',
					error && 'border-destructive border rounded-lg',
				)}
				style={{ maxHeight: '400px', overflow: 'scroll' }}
				formats={customToolbar ? formats : undefined}
				modules={customToolbar ? modules : undefined}
			/>
			{error && (
				<div className="d-block text-destructive text-sm mt-2">
					{errorText}
				</div>
			)}
		</div>
	);
};
