import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { formatNumberWithCommas } from '@/utils/parsers';

const CampaignMetricsCard = ({ title, icon, value, subtitle }) => {
	return (
		<Card>
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				<CardTitle className="text-sm font-medium">{title}</CardTitle>
				<i className={`bi-${icon}`} />
			</CardHeader>
			<CardContent>
				<div className="text-2xl font-bold">
					{formatNumberWithCommas(value)}
				</div>
				<p className="text-xs text-muted-foreground mt-1">{subtitle}</p>
			</CardContent>
		</Card>
	);
};

export default CampaignMetricsCard;
