import { toast } from 'sonner';
import { IQuestOfTheDay } from './useQuestOfTheDay';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { Media } from '@/components/element/media/Media';
import { QuestOfTheDayStatus } from '../../types/cms.types';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import dayjs from 'dayjs';

const useQuestOfTheDayColumns = ({
	setFormFields,
	setOpen,
}: {
	setFormFields: React.Dispatch<React.SetStateAction<IQuestOfTheDay>>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const options = (row: any) => [
		{
			type: 'button',
			label: 'Edit',
			onClick: () => {
				setFormFields((prev) => ({
					...prev,
					_id: row?.original?._id,
					name: row?.original?.name,
					banner: row?.original?.banner,
					questOfTheDay: {
						date: row?.original?.questOfTheDay?.date,
					},
				}));
				setOpen(true);
			},
			tooltip: 'Edit date for this quest',
		},
		{
			type: 'button',
			label: 'Copy Campaign Id',
			onClick: () => {
				navigator.clipboard.writeText(row?.original?._id);
				toast.success('Campaign ID copied to clipboard');
			},
		},
	];

	const columns: ColumnDef<IQuestOfTheDay>[] = [
		{
			accessorKey: 'banner',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Banner" />
			),
			cell: ({ row }) => (
				<div className="py-1 px-1">
					<Media
						src={row?.original?.banner}
						altText="banner"
						className="h-10 rounded-md shadow-sm"
					/>
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className="px-1"
				/>
			),
			cell: ({ row }) => (
				<div className="py-1 px-1">{row?.original?.name}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'questOfTheDay.date',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Quest of the Day" />
			),
			cell: ({ row }) => (
				<div className="py-1 px-1">
					{new Date(
						new Date(row?.original?.questOfTheDay?.date),
					).toLocaleDateString()}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ row }) => {
				const questDate = dayjs(row?.original?.questOfTheDay?.date).format(
					'YYYY-MM-DD',
				);
				const todayDate = dayjs(new Date()).format('YYYY-MM-DD');
				const status =
					questDate === todayDate
						? QuestOfTheDayStatus.Active
						: QuestOfTheDayStatus.Upcoming;

				return <StatusBadge status={status} />;
			},
			enableSorting: false,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => (
				<DataTableRowActions row={row} options={options(row) as any} />
			),
		},
	];
	return {
		columns,
	};
};

export default useQuestOfTheDayColumns;
