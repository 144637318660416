import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { switchEnterprise } from '@/services/profile.service';
import { TrackingEvents } from '@/types/tracking.type';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useSwitchEnterprise = () => {
	const { user, refetchUser } = useAuth();
	const params = useParams();
	const navigate = useNavigate();
	const [isSwitching, setIsSwitching] = useState(false);

	const handleSwitchEnterprise = async (enterpriseId: string) => {
		try {
			setIsSwitching(true);
			await switchEnterprise(enterpriseId);
			analytics.track(TrackingEvents.EnterpriseSwitched, {
				newEnterpriseId: enterpriseId,
				enterpriseId: user?.enterpriseId,
			});
			await refetchUser();
			if (
				!(
					Object.keys(params).length === 1 &&
					(params['*'] === '' || params['*']?.length < 10)
				)
			) {
				if (Object.keys(params).length > 0) {
					navigate(`/app/home`);
				}
			}
			queryClient.removeQueries();
			setIsSwitching(false);
		} catch (error) {
			setIsSwitching(false);
			console.log(error);
		}
	};

	const enterprises = user?.enterprises?.map((i) => ({
		name: i?.name,
		logo: i.logo,
		_id: i._id,
		isApproved: i?.isApproved,
	}));
	const primaryEnterpriseIndex = user?.enterprises?.findIndex(
		(i) => i._id === user.enterpriseId,
	);

	return {
		handleSwitchEnterprise,
		enterprises: enterprises || [],
		primaryEnterpriseIndex,
		isSwitching,
	};
};
