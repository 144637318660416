import { useQuery } from '@tanstack/react-query';
import { deleteContract, fetchAllContracts } from '../../services/dave.service';
import { IDaveContracts } from '../../types/dave.types';
import { toast } from 'sonner';
import { queryClient } from '@/lib/react-query';
import { useMemo, useState } from 'react';

export const useGetContracts = () => {
	const [search, setSearch] = useState('');

	const { data, isLoading } = useQuery<IDaveContracts[]>({
		queryFn: fetchAllContracts,
		queryKey: ['dave', 'contracts'],
	});

	const updateContract = (contractId: string) => {};

	const handleDeleteContract = (contractId: string) => {
		toast.promise(Promise.all([deleteContract(contractId)]), {
			success: 'Contract deleted successfully',
			error: 'Error deleting contract',
			loading: 'Deleting contract...',
		});
		queryClient.invalidateQueries({
			queryKey: ['dave', 'contracts'],
		});
	};

	const filteredData = useMemo(() => {
		if (!data) return [];
		return data.filter((i) => {
			if (search && !i.name.toLowerCase().includes(search.toLowerCase()))
				return false;
			return true;
		});
	}, [data, search]);

	return {
		contracts: filteredData,
		isLoading,
		deleteContract: handleDeleteContract,
		updateContract,
		search,
		setSearch,
	};
};
