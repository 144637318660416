import { axios } from '@/lib/axios';
import { IReward, IRewardStore } from '../types/reward-store.type';

export const fetchRewards = async (): Promise<IRewardStore[]> => {
	const response = await axios.get('/enterprise/rewards/fetch');
	return response.data as IRewardStore[];
};

export const createRewardSection = async (data: {
	name: string;
	description: string;
}) => {
	const response = await axios.post('/enterprise/rewards/create-section', data);
	return response.data;
};

export const updateSection = async (data: {
	_id: string;
	name?: string;
	description?: string;
	isDeleted?: boolean;
}) => {
	const response = await axios.post('/enterprise/rewards/update-section', data);
	return response.data;
};

export const createReward = async (data: IReward) => {
	const response = await axios.post('/enterprise/rewards/create', data);
	return response.data;
};

export const updateRewardData = async (data: IReward) => {
	const response = await axios.put('/enterprise/rewards/update', data);
	return response.data;
};

export const updateRewardSectionsOrdering = async (
	data: { sectionId: string; rewardIds: string[]; index: number }[],
) => {
	const response = await axios.put('/enterprise/rewards/reorder', data);
	return response.data;
};
