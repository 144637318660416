import { trackUserSession } from '@/features/getting-started/services/home.service';
import { useAuth } from '@/hooks/useAuth';
import { getUtmParams } from '@/lib/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface UtmParams {
	[key: string]: string | null;
}

const getStoredUtmParams = (): UtmParams => {
	let storedUtmParams: UtmParams = {};
	try {
		storedUtmParams = JSON.parse(localStorage.getItem('utmParams') || '{}');
	} catch (error) {
		console.error('Failed to parse stored UTM params:', error);
	}
	return storedUtmParams;
};

export const useSessionTracking = (): void => {
	const { user } = useAuth();
	const location = useLocation();
	const hasTrackedSession = useRef<boolean>(false);
	const [isUrlLoaded, setIsUrlLoaded] = useState<boolean>(false);
	const isMounted = useRef(true);

	// New state to track when UTM params have been saved
	const [utmParamsSaved, setUtmParamsSaved] = useState<boolean>(false);

	const saveUtmParams = useCallback(async (): Promise<void> => {
		try {
			const utmParams: UtmParams = await getUtmParams();
			const storedUtmParams: UtmParams = getStoredUtmParams();

			const hasNewUtmParams: boolean = Object.keys(utmParams).some(
				(key) =>
					utmParams[key] !== null &&
					utmParams[key] !== storedUtmParams[key],
			);
			if (hasNewUtmParams) {
				const mergedUtmParams: UtmParams = {
					...storedUtmParams,
					...utmParams,
				};
				localStorage.setItem('utmParams', JSON.stringify(mergedUtmParams));
			}
			// Indicate that UTM params have been saved
			setUtmParamsSaved(true);
		} catch (error) {
			console.error('Error saving UTM params:', error);
		}
	}, []);

	const handleUserSessionTracking = useCallback(async (): Promise<void> => {
		if (!user || hasTrackedSession.current) return;
		const storedUtmParams: UtmParams = getStoredUtmParams();
		try {
			await trackUserSession(storedUtmParams);
			hasTrackedSession.current = true;
		} catch (error) {
			console.error('Failed to track user session:', error);
		}
	}, [user]);

	useEffect(() => {
		const checkUrlLoaded = (): void => {
			if (window.performance) {
				const perfEntries = performance.getEntriesByType('navigation');
				if (
					perfEntries.length > 0 &&
					(perfEntries[0] as PerformanceNavigationTiming).loadEventEnd > 0
				) {
					if (isMounted.current) setIsUrlLoaded(true);
					return;
				}
			}
			// Fallback if performance API is not available
			if (document.readyState === 'complete') {
				if (isMounted.current) setIsUrlLoaded(true);
				return;
			}
			requestAnimationFrame(checkUrlLoaded);
		};

		checkUrlLoaded();

		return () => {
			isMounted.current = false;
		};
	}, [location.search]);

	useEffect(() => {
		if (isUrlLoaded) {
			(async () => {
				try {
					await saveUtmParams();
					// Ensure utmParamsSaved is set even if there are no new UTM params
					setUtmParamsSaved(true);
				} catch (error) {
					console.error('Error saving UTM params:', error);
				}
			})();
		}
	}, [saveUtmParams, isUrlLoaded]);

	useEffect(() => {
		if (
			isUrlLoaded &&
			utmParamsSaved &&
			user &&
			location.pathname.startsWith('/app/')
		) {
			handleUserSessionTracking();
		}
	}, [
		handleUserSessionTracking,
		user,
		location.pathname,
		isUrlLoaded,
		utmParamsSaved,
	]);
};
