import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import InputSelect from '@/components/element/inputs/InputSelect';
import InputText from '@/components/element/inputs/InputText';
import { Media } from '@/components/element/media/Media';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogFooter,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import useAddCustomToken from '@/features/dave/hooks/queries/useAddCustomToken';
import { PeggedToken } from '@/features/dave/types/dave.enum';
import { useEffect } from 'react';

const AddCustomTokenDialog = ({ show, setShow, chainName, chainLogo, chainId }) => {
	const {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		addNewCustomToken,
		isInProgress,
	} = useAddCustomToken({ setShow });

	useEffect(() => {
		if (chainId) {
			setFormFields((prev) => ({
				...prev,
				chainId,
			}));
		}
	}, [chainId, setFormFields]);

	useEffect(() => {
		setFormErrors({});
	}, [formFields, setFormErrors]);

	return (
		<Dialog open={show} onOpenChange={setShow}>
			<DialogContent className="sm:max-w-[700px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between">
						Add Custom Token
					</DialogTitle>
					<DialogDescription>
						Add a custom token to the list
					</DialogDescription>
				</DialogHeader>
				<div className="my-4">
					<div className="grid grid-cols-2 gap-5">
						<div className="space-y-7">
							<div className="">
								<Label className="flex items-center mb-2">
									Selected Chain
								</Label>
								<div className="flex items-center gap-2 border rounded-md px-3 py-1">
									<Media
										src={chainLogo}
										altText={chainName}
										className="w-6 h-6"
									/>
									<p className="text-sm">{chainName}</p>
								</div>
							</div>
							<InputText
								label="Token Symbol"
								placeholder="Enter token symbol"
								required
								value={formFields.tokenSymbol}
								setValue={(e) =>
									setFormFields((prev) => ({
										...prev,
										tokenSymbol: e,
									}))
								}
								error={formErrors.tokenSymbol}
								errorText={formErrors.tokenSymbol}
							/>

							<InputText
								label="Token Address"
								placeholder="Enter token address"
								required
								value={formFields.tokenAddress}
								setValue={(e) =>
									setFormFields((prev) => ({
										...prev,
										tokenAddress: e,
									}))
								}
								error={formErrors.tokenAddress}
								errorText={formErrors.tokenAddress}
							/>
							<InputSelect
								label="Is pegged to?"
								options={options}
								required
								placeholder="Select"
								value={formFields.peggedTo}
								setValue={(newVal) =>
									setFormFields((prev) => ({
										...prev,
										peggedTo: newVal as PeggedToken,
									}))
								}
								error={formErrors.peggedTo}
								errorText={formErrors.peggedTo}
							/>
						</div>
						<div>
							<DropzoneWithReposition
								allowedFiles={['images']}
								description="Recommended size: 400x400px"
								label="Token Icon"
								cta="Upload the image "
								aspectRatio={1 / 1}
								className="w-full"
								dropzoneClass="bg-muted mt-2"
								required
								file={formFields.tokenImageFile}
								fileLink={formFields.tokenIcon}
								clearSelection={() => {
									setFormFields((prev) => ({
										...prev,
										tokenIcon: '',
										tokenImageFile: null,
									}));
								}}
								setFile={(file: File, fileLink: string) => {
									setFormFields((prev) => ({
										...prev,
										tokenImageFile: file,
										tokenIcon: fileLink,
									}));
								}}
								customHeight="!max-h-[25rem]"
								error={formErrors.tokenIcon}
								errorText={formErrors.tokenIcon}
								onImageReposition={({ x, y }) => {
									setFormFields({
										...formFields,
										imagePosition: { x, y },
									});
								}}
								lockBodyScroll={false}
								showPlaceHolderImage
							/>
						</div>
					</div>
					<p className="mt-5 text-xs font-medium w-full ">
						Note: If your token price is not pegged to one of the above
						coins, you need to contact our team through Intercom to
						create queries that depend on token dollar values.
					</p>
				</div>
				<DialogFooter
					className={`justify-between space-x-2 border-t pt-3 flex sm:justify-between `}
				>
					<div></div>
					<div className="flex gap-2">
						<Button variant="ghost" onClick={() => setShow(false)}>
							Cancel
						</Button>
						<Button
							onClick={() => addNewCustomToken()}
							disabled={isInProgress}
						>
							{isInProgress ? (
								<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
							) : null}
							Add Token
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddCustomTokenDialog;

const options = [
	{
		label: 'ETH',
		value: PeggedToken.ETH,
	},
	{
		label: 'USD',
		value: PeggedToken.USD,
	},
	{
		label: 'NONE',
		value: PeggedToken.NONE,
	},
];
