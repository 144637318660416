import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';
import dayjs from 'dayjs';
import { Building, CalendarIcon } from 'lucide-react';
import { memo, type Dispatch, type SetStateAction } from 'react';

import StatusBadge from '@/components/element/badges/StatusBadge';
import { Media } from '@/components/element/media/Media';
import { asset } from '@/config/asset-cdn';
import { convertToTitleCase } from '@/utils/parsers';
import useAdminCreditsRequestDetails from '../../hooks/admin-credits/useAdminCreditsRequestDetails';
import { type IAdminCredits } from '../../types/admin-credit.types';
import { CreditRequestStatusEnum } from '@/features/intract-credits/types/credits.enums';
import { Badge } from '@/components/ui/badge';

interface CreditRequestDetailsProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	requestDetails: IAdminCredits;
}

const CreditRequestDetails = memo(
	({ open, setOpen, requestDetails }: CreditRequestDetailsProps) => {
		const { renderTypeSpecificDetails } = useAdminCreditsRequestDetails({
			requestDetails,
		});

		if (!requestDetails) return null;

		return (
			<Dialog open={open} onOpenChange={setOpen}>
				<DialogContent className="sm:max-w-[530px] max-h-[80vh] overflow-y-auto">
					<DialogHeader className="border-b pb-3">
						<DialogTitle className="flex justify-between text-slate-700 font-medium">
							Credit Request Details
						</DialogTitle>
						<DialogDescription className="text-sm text-slate-500 flex justify-between items-center">
							<div>
								View details of the{' '}
								<span className="text-slate-600 font-medium">
									{convertToTitleCase(requestDetails.requestType)}
								</span>{' '}
								request
							</div>

							<StatusBadge status={requestDetails.status} />
						</DialogDescription>
						<DialogClose />
					</DialogHeader>

					<div className="space-y-6">
						<div className="grid grid-cols-2 gap-3">
							<CreditCard
								title="Request Price"
								value={
									requestDetails?.status ===
									CreditRequestStatusEnum.Pending
										? requestDetails.lockedCredits
										: requestDetails.consumedCredits
								}
							/>
							<CreditCard
								title="Credits Balance"
								value={
									requestDetails.enterpriseDetails?.creditsBalance
								}
							/>
						</div>

						<div className="bg-slate-50 rounded-lg p-4 text-slate-700">
							<div className="flex items-center gap-2 mb-3">
								<Building className="w-4 h-4 text-slate-500" />
								<span className="text-sm font-medium">
									Enterprise Details
								</span>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<span className="text-xs text-slate-500">
										Name
									</span>
									<p className="text-sm font-medium">
										{requestDetails.enterpriseName}
									</p>
								</div>
								<div>
									<span className="text-xs text-slate-500">
										ID
									</span>
									<p
										className="text-sm font-medium truncate"
										title={requestDetails.enterpriseId}
									>
										{requestDetails.enterpriseId}
									</p>
								</div>
							</div>
						</div>

						{requestDetails.details?.length > 0 && (
							<div className="bg-slate-50 rounded-lg p-4 text-slate-700">
								{renderTypeSpecificDetails()}
							</div>
						)}

						<div className="bg-slate-50 rounded-lg p-4">
							<div className="grid grid-cols-2 gap-4">
								<TimeStampField
									label="Created At"
									date={requestDetails.createdAt}
								/>
								<TimeStampField
									label="Updated At"
									date={requestDetails.updatedAt}
								/>
							</div>
						</div>

						<div className="flex items-center gap-1 text-sm float-right">
							<i className="bi-envelope-at text-base text-slate-500" />
							<span className="text-slate-500">Requested by:</span>
							<span className="font-medium text-slate-700">
								{requestDetails.userEmail}
							</span>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	},
);

CreditRequestDetails.displayName = 'CreditRequestDetails';

export default CreditRequestDetails;

const CreditCard = memo(({ title, value }: { title: string; value: number }) => (
	<div className="border rounded-lg p-2 bg-slate-50">
		<div className="flex flex-col items-center text-center">
			<span className="text-sm font-medium text-slate-700 mb-2">{title}</span>
			<div className="flex items-center gap-1">
				<Media
					src={asset['credit-icon-primary']}
					altText="credit-icon"
					className="size-4"
				/>
				<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal">
					{value}
				</span>
			</div>
		</div>
	</div>
));

const TimeStampField = memo(({ label, date }: { label: string; date: Date }) => (
	<div>
		<div className="flex items-center gap-1 text-xs text-slate-500 mb-1">
			<CalendarIcon className="size-3" />
			{label}
		</div>
		<p className="text-sm">{dayjs(date).format('MMM D, YYYY h:mm A')}</p>
	</div>
));
