export enum UserInputTypes {
	Image = 'IMAGE',
	Textarea = 'TEXTAREA',
	Input = 'INPUT',
}
export enum RateLimitMode {
	ArrayBased = 'ARRAY_BASED',
}
/**
 * @description
 * Enum for the different types of tasks completion criteria categories.
 * REMEMBER: Required tasks in a task group are always mandatory.
 *
 * @enum
 * @property {string} OptionalTasks - The completion category for the task group is based on optional tasks.
 * @property {string} Xp - The completion category for the task group is based on XP.
 * @property {string} None - Theres no completion category for the task group. Only required tasks are mandatory.
 */
export enum CompletionCategory {
	OptionalTasks = 'OPTIONAL_TASKS',
	Xp = 'XP',
	None = 'NONE',
}
export enum TaskSectionUnitTypes {
	Task = 'TASK',
	TaskGroup = 'TASK_GROUP',
}
export enum TaskErrorStatus {
	NoError = 'NO_ERROR',
	HandledError = 'HANDLED_ERROR',
	UnhandledError = 'UNHANDLED_ERROR',
}
export enum TaskStatus {
	Active = 'ACTIVE',
	Draft = 'DRAFT',
	Inactive = 'INACTIVE',
}

export enum MetricBasedXpFunction {
	None = 'NONE',
	Linear = 'LINEAR',
	Tiered = 'TIERED',
	Sigmoid = 'SIGMOID',
}

export enum EditLiveTaskSteps {
	TaskParams = 'TASK_PARAMS',
	TaskFeatures = 'TASK_FEATURES',
}
