import { useState } from 'react';
import {
	IAffiliateRewardEvents,
	IReward,
} from '../types/admin-user-affiliation.types';
import {
	EventTypes,
	RewardType,
	RewardTypeOptions,
	TokenSymbol,
} from '../types/admin-user-affiliation.enums';
import { updateUserAffiliateRewards } from '../services/admin.service';
import { toast } from 'sonner';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';

const initialFormFields: IAffiliateRewardEvents = {
	eventType: EventTypes.FirstQuestCreated,
	event: '',
	reward: [
		{
			rewardType: RewardType.Xp,
			rewardAmount: 1,
		},
	],
	_id: '',
};

const useAdminAffiliateProgram = ({ setOpen }) => {
	const [formFields, setFormFields] =
		useState<IAffiliateRewardEvents>(initialFormFields);
	const [formErrors, setFormErrors] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [items, setItems] = useState(1);

	const resetForm = () => {
		setFormFields(initialFormFields);
		setItems(1);
		setIsLoading(false);
		setOpen(false);
	};

	const addReward = () => {
		if (items >= 4) return;
		setFormFields((prev) => {
			if (!prev.reward) {
				prev.reward = [];
			}

			const existingRewardTypes = new Set(
				prev.reward.map((item) => item.rewardType),
			);

			const newReward = RewardTypeOptions.find(
				(reward) => !existingRewardTypes.has(reward.value),
			);

			if (newReward) {
				const tempReward = [
					...prev.reward,
					{
						rewardType: newReward.value,
						rewardAmount: 1,
						metadata: {
							tokenSymbol: TokenSymbol.USDC,
							tokenName: RewardType.TokenUSDC,
							transactionHash: '',
						},
					},
				];
				setItems((prevItems) => prevItems + 1);

				return {
					...prev,
					reward: tempReward,
				};
			}

			return prev;
		});
	};
	const removeReward = (rewardType: string) => {
		try {
			setItems((prevItems) => Math.max(prevItems - 1, 0));

			const updatedRewards = formFields?.reward?.filter(
				(reward) => reward.rewardType !== rewardType,
			);
			setFormFields((prev: any) => ({
				...prev,
				reward: updatedRewards,
			}));
		} catch (error) {
			console.log(error);
		}
	};

	const handleRewardChange = (newVal: any, idx: number) => {
		try {
			const tempReward = [...(formFields?.reward ?? [])];
			tempReward[idx] = {
				...tempReward[idx],
				rewardType: newVal,
				metadata: {
					tokenName: newVal,
					tokenSymbol: newVal.includes('USDC')
						? TokenSymbol.USDC
						: TokenSymbol.USDT,
					transactionHash: '',
				},
			};

			setFormFields((prev: any) => ({
				...prev,
				reward: tempReward,
			}));
		} catch (err) {
			console.log(err);
		}
	};

	const handleChange = (newVal: any, idx: number) => {
		try {
			if (!/^\d*$/.test(newVal) && newVal !== '') {
				return;
			} else {
				setFormErrors((prev: any) => ({
					...prev,
					[formFields?.reward?.[idx]?.rewardAmount]: '',
				}));

				const tempReward = [...(formFields?.reward ?? [])];
				tempReward[idx] = {
					...tempReward[idx],
					rewardAmount: newVal,
				};
				setFormFields((prev: any) => ({
					...prev,
					reward: tempReward,
				}));
			}
		} catch (err) {
			console.log(err);
		}
	};

	const setRewardMetadata = (
		index: number,
		field: keyof IReward['metadata'],
		value: any,
	) => {
		setFormFields((prev) => {
			const updatedRewards = [...prev.reward];

			if (!updatedRewards[index].metadata) {
				updatedRewards[index].metadata = {
					tokenSymbol: TokenSymbol.USDC,
					tokenName: TokenSymbol.USDC,
					transactionHash: '',
				};
			}

			updatedRewards[index].metadata[field] = value;

			return {
				...prev,
				reward: updatedRewards,
			};
		});
	};

	const validateForm = () => {
		const errors: any = {};
		if (!formFields?.eventType || !formFields?.eventType.trim()) {
			errors.eventType = 'Event Type is required';
		}
		if (!formFields?.reward || !formFields?.reward.length) {
			errors.reward = 'Reward is required';
		}
		if (
			formFields?.reward?.some(
				(reward) => reward.rewardAmount <= 0 || !reward.rewardAmount,
			)
		) {
			errors.rewardType = 'Reward amount should be greater than 0';
		}
		// if (
		// 	formFields?.reward?.some(
		// 		(reward) =>
		// 			(reward.rewardType === RewardType.TokenUSDC ||
		// 				reward.rewardType === RewardType.TokenUSDT) &&
		// 			(!reward.metadata?.tokenName ||
		// 				!reward?.metadata?.tokenName?.trim()),
		// 	)
		// ) {
		// 	errors.tokenName = 'Token Name is required';
		// }
		if (
			formFields?.reward?.some(
				(reward) =>
					(reward.rewardType === RewardType.TokenUSDC ||
						reward.rewardType === RewardType.TokenUSDT) &&
					(!reward.metadata?.transactionHash ||
						!reward.metadata?.transactionHash?.trim()),
			)
		) {
			errors.transactionHash = 'Token txnHash is required';
		}
		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};

	const prepareRequestBody = () => {
		const transformedRewards = formFields.reward.map((reward) => {
			if (reward.metadata && reward.metadata?.tokenName?.includes('TOKEN')) {
				return {
					...reward,
					rewardType: 'TOKEN',
				};
			}
			return reward;
		});

		const requestBody = {
			rewardEvent: {
				eventType: formFields.eventType,
				reward: transformedRewards,
			},
		};

		return requestBody;
	};
	const updateAffiliateRewards = async () => {
		if (!validateForm()) return;

		setIsLoading(true);
		const requestBody = prepareRequestBody();
		try {
			const resp = await updateUserAffiliateRewards(
				formFields?._id,
				requestBody,
			);
			await queryClient.invalidateQueries({
				queryKey: ['admin', 'affiliate-program'],
			});
			resetForm();
			toast.success('Rewards updated successfully');
		} catch (error) {
			console.error(error);
			handleErrorMessage('Failed to update rewards');
		} finally {
			setIsLoading(false);
		}
	};

	return {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		isLoading,
		addReward,
		items,
		setItems,
		removeReward,
		handleRewardChange,
		handleChange,
		setRewardMetadata,
		updateAffiliateRewards,
		resetForm,
	};
};

export default useAdminAffiliateProgram;
