import { ICampaignAddReward, ITier } from '@/features/campaigns/types';
import { isAddress } from 'web3-validator';

export const validateEVMAddress = (address: string) => {
	return isAddress(address);
};

export const validateContractABI = (abi: string) => {
	if (!abi) return false;
	if (abi === '[]') return false;
	try {
		const parsedABI = JSON.parse(abi);
		if (Array.isArray(parsedABI)) return true;
	} catch (err) {
		return false;
	}
};

export const validateTransactionHash = (hash: string) => {
	const re = /^0x[a-fA-F0-9]{64}$/;
	return re.test(hash);
};

export const validateString = (value: string) => {
	return value && value.length > 0;
};

export const validateDuration = (startDate: Date, endDate: Date) => {
	if (!startDate || !endDate) return false;
	return startDate < endDate;
};

export const validatePositiveNumber = (value: number) => {
	return value && !Number.isNaN(value) && value > 0;
};

export const validateTiers = ({
	updatedTiers,
	reward,
}: {
	updatedTiers: ITier[];
	reward: ICampaignAddReward;
}): ITier[] => {
	const totalWinners = reward?.numRewards;

	const validatedTiers = updatedTiers.map((tier) => ({
		...tier,
		errors: { from: '', to: '', rewardPerUser: '' },
	}));

	if (!totalWinners) {
		validatedTiers[validatedTiers.length - 1].errors.to =
			'Total winners not set';
		return validatedTiers;
	}

	for (let i = 0; i < validatedTiers.length; i++) {
		const tier = validatedTiers[i];
		const nextTier =
			i < validatedTiers.length - 1 ? validatedTiers[i + 1] : null;

		// Validate "from" value
		if (tier.from === null || tier.from < 1) {
			tier.errors.from = 'Invalid "From" value';
		}

		// Validate "to" value
		if (tier.to !== null) {
			if (tier.to > totalWinners) {
				tier.errors.to = `Cannot exceed total winners (${totalWinners})`;
			} else if (tier.from !== null && tier.to < tier.from) {
				tier.errors.to = '"To" must be greater than or equal to "From"';
			}
		}

		// Validate rewardPerUser
		if (tier.rewardPerUser === null || tier.rewardPerUser < 1) {
			tier.errors.rewardPerUser = 'Reward per user must be at least 1';
		}

		// Validate consecutive tiers
		if (nextTier && tier.to !== null && nextTier.from !== null) {
			if (nextTier.from !== tier.to + 1) {
				tier.errors.to = 'Tiers must be consecutive';
				nextTier.errors.from = 'Tiers must be consecutive';
			}
		}

		// Check for overlapping tiers
		if (
			i < validatedTiers.length - 1 &&
			tier.to !== null &&
			nextTier.from !== null
		) {
			if (tier.to >= nextTier.from) {
				tier.errors.to = 'Tiers cannot overlap';
				nextTier.errors.from = 'Tiers cannot overlap';
			}
		}
	}

	// Check if last tier's "to" value matches total winners
	const lastTier = validatedTiers[validatedTiers.length - 1];
	if (lastTier.to !== totalWinners) {
		lastTier.errors.to = `Last tier must end at total winners (${totalWinners})`;
	}

	return validatedTiers;
};
