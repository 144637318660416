import { EnterpriseTokenStatus } from '@/features/dashboard/types/enterprise.types';

export const profileOptions = [
	{
		value: 'founder',
		label: 'Founder/Executive',
	},
	{
		value: 'moderator',
		label: 'Community Moderator',
	},
	{
		value: 'marketer',
		label: 'Marketer',
	},
	{
		value: 'growth',
		label: 'Growth Product Manager',
	},
	{
		value: 'agency',
		label: 'Agency',
	},
	{
		value: 'Others',
		label: 'Others',
	},
];

export const sourceOptions = [
	{
		value: 'Google',
		label: 'Google',
	},
	{
		value: 'Twitter',
		label: 'Twitter',
	},
	{
		value: 'Friend',
		label: 'Friend',
	},
	{
		value: 'Others',
		label: 'Others',
	},
];

export const goalOptions = [
	{
		value: 'Acquiring new users',
		label: 'Acquiring new users',
	},
	{
		value: 'Engaging your community',
		label: 'Engaging your community',
	},
	{
		value: 'Creating community hub',
		label: 'Creating community hub',
	},
	{
		value: 'Increasing token adoption',
		label: 'Increasing token adoption',
	},
	{
		value: 'Boosting brand awareness',
		label: 'Boosting brand awareness',
	},
	{
		value: 'Educating users about products',
		label: 'Educating users about products',
	},
	{
		value: 'Facilitating partnerships',
		label: 'Facilitating partnerships',
	},
	{
		value: 'Collecting user feedback',
		label: 'Collecting user feedback',
	},
	{
		value: 'Launching new products',
		label: 'Launching new products',
	},
	{
		value: 'Rewarding community loyalty',
		label: 'Rewarding community loyalty',
	},
];

export const tokenStatus = [
	{
		value: EnterpriseTokenStatus.Listed,
		label: 'Listed',
	},
	{
		value: EnterpriseTokenStatus.OfficialAirdropAnnounced,
		label: 'Official Airdrop Announced',
	},
	{
		value: EnterpriseTokenStatus.ICOAnnounced,
		label: 'ICO Announced',
	},
	{
		value: EnterpriseTokenStatus.TGEComing,
		label: 'TGE Coming',
	},
	{
		value: EnterpriseTokenStatus.Unknown,
		label: 'Unknown',
	},
];
