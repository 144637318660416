import { axios } from '@/lib/axios';
import { HomepageWidgetSources, HomepageWidgetTypes } from '../types/cms.types';

/**
 * Content Management System
 */

/**
 * banners
 */
export const getBanners = async () => {
	const res = await axios.get(`/content-management/banners`);
	return res.data;
};
export const upsertBanner = async (data: any) => {
	const res = await axios.post(`/content-management/banner/upsert`, data);
	return res.data;
};
export const reorderBanners = async (data: any) => {
	const res = await axios.post(`/content-management/banner/reorder`, data);
	return res.data;
};
export const deleteBanner = async (id: string) => {
	const res = await axios.delete(`/content-management/banner/${id}`);
	return res.data;
};

/**
 * widgets
 */
export const getWidgets = async () => {
	const res = await axios.get(`/content-management/widgets`);
	return res.data;
};

export const getWidgetCampaigns = async (
	widgetId: string,
	resourceIds: string[],
	fetchLoyaltyCampaigns?: boolean,
) => {
	const res = await axios.post(
		`/content-management/widget/${widgetId}/campaigns`,
		{
			resourceIds,
			fetchLoyaltyCampaigns: fetchLoyaltyCampaigns || false,
		},
	);
	return res.data;
};

export const updateWidgetCampaigns = async (widgetId: string, data: any) => {
	const res = await axios.put(`/content-management/widget/${widgetId}/update`, {
		ids: [...data],
	});
	return res.data;
};

export const createWidget = async (data: {
	title: string;
	description?: string;
	index: number;
	displayLimit?: number;
}) => {
	const res = await axios.post(`/content-management/widget/create`, data);
	return res.data;
};

/**
 * QOTD
 */
export const getQuestOfTheDay = async () => {
	const res = await axios.get(`/content-management/quest-of-the-day`);
	return res.data;
};

export const updateQuestOfTheDay = async (data: any) => {
	const res = await axios.post(
		`/content-management/quest-of-the-day/update`,
		data,
	);
	return res.data;
};

/**
 * Search quests (banners)
 */
export const getSearchQuestBanners = async () => {
	const res = await axios.get(`/content-management/search-banner`);
	return res.data;
};
