import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';
import {
	EligibilityCriteria,
	EnterpriseReferralEligibilityConditions,
	successfulReferralConditions,
} from '../types/customize-hub.enum';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useCommunityReferral } from '../hooks/useCommunityReferral';
import MultiSelect from '@/components/element/inputs/MultiSelect';
import useCriteriaItems from '../hooks/useCriteriaItems';

const CriteriaItem = ({
	index,
	attribute,
	formFields,
	setFormFields,
	formErrors,
	setFormErrors,
	setItems,
}) => {
	const { campaigns } = useCommunityReferral();

	const {
		handleConditionChange,
		handleCampaignChange,
		handleChange,
		removeCriteria,
	} = useCriteriaItems({
		formFields,
		setFormFields,
		setFormErrors,
		setItems,
	});

	const valueArray = Array.isArray(formFields?.[attribute]?.[index]?.value)
		? formFields?.[attribute]?.[index]?.value
		: [];

	return (
		<div className="flex gap-2 py-1 px-0.5">
			<div
				className={`flex items-center flex-grow space-x-2 sm:space-x-0 w-[20rem]`}
			>
				<Select
					defaultValue={formFields?.[attribute]?.[index]?.condition}
					onValueChange={(newVal) =>
						handleConditionChange(newVal, attribute, index)
					}
					value={formFields?.[attribute]?.[index]?.condition}
				>
					<SelectTrigger className="text-sm">
						<SelectValue placeholder="Select" className="h-10 " />
					</SelectTrigger>
					{attribute === 'referredUserEligibilityConditions' ? (
						<SelectContent>
							{successfulReferralConditions?.map((item) => (
								<SelectItem key={item.value} value={item.value}>
									{item.label}
								</SelectItem>
							))}
						</SelectContent>
					) : (
						<SelectContent>
							{EligibilityCriteria?.map((item) => (
								<SelectItem key={item.value} value={item.value}>
									{item.label}
								</SelectItem>
							))}
						</SelectContent>
					)}
				</Select>
			</div>
			<div className="flex flex-grow items-center gap-2 w-[12rem]">
				{formFields?.[attribute]?.[index]?.condition ===
				EnterpriseReferralEligibilityConditions.SpecificCampaignsCompleted ? (
					<MultiSelect
						value={valueArray.map((obj) => obj.campaignId)}
						setValue={(newVal: string[]) =>
							handleCampaignChange(newVal, attribute, index)
						}
						options={campaigns}
						placeholder="Select Campaigns"
						popoverClassName="w-[16rem] h-[15rem]"
						maxLimit={1}
						withoutPortal
					/>
				) : (
					<Input
						className={`h-9 ${
							formErrors[formFields?.[attribute]?.[index]?.condition]
								? 'border-destructive'
								: ''
						}`}
						onFocus={(e) => {
							e.target.select();
						}}
						onChange={(e) =>
							handleChange(e.target.value, attribute, index)
						}
						value={formFields?.[attribute]?.[index]?.value}
					/>
				)}
			</div>

			<div className="flex items-center justify-end">
				<Button
					variant="outline"
					size="sm"
					className="w-9 h-9"
					onClick={() =>
						removeCriteria(
							formFields?.[attribute]?.[index]?.condition,
							attribute,
						)
					}
				>
					<i className="bi bi-x-lg"></i>
				</Button>
			</div>
		</div>
	);
};

export default CriteriaItem;
