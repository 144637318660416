import { IEditingTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import TaskCard from '../Tasks/TaskCard';

const TasksAndTaskGroups = ({
	task,
	isDragging,
	taskIndex,
}: {
	task: IEditingTasks;
	isDragging: boolean;
	taskIndex: number;
}) => {
	// if (task.type === 'group') {
	// 	return (
	// 		<TaskGroupLayout taskGroup={task}>
	// 			{task.tasks.map((i) => (
	// 				<TaskCard task={i} />
	// 			))}
	// 		</TaskGroupLayout>
	// 	);
	// }
	return <TaskCard task={task?.task} taskIndex={taskIndex} />;
};

export default TasksAndTaskGroups;
