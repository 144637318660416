import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import analytics from '@/lib/analytics';
import { cn } from '@/lib/utils';
import { useState } from 'react';

const TooltipWrapper = ({
	tooltip,
	children,
	align = 'center',
	tooltipContentClass,
	side = 'top',
	contentClassName,
	trackingEvent,
	triggerClassName = '',
}: {
	tooltip: string | string[] | JSX.Element;
	children: React.ReactNode;
	align?: 'center' | 'start' | 'end';
	tooltipContentClass?: string;
	side?: 'top' | 'right' | 'bottom' | 'left';
	contentClassName?: string;
	trackingEvent?: string;
	triggerClassName?: string;
}) => {
	const [isVisible, setIsVisible] = useState(false);

	const handlePointerEnter = () => {
		if (!isVisible) {
			setIsVisible(true);
			if (trackingEvent) {
				analytics.track(trackingEvent, {});
			}
		}
	};
	if (!tooltip) return children;

	return (
		<>
			<TooltipProvider delayDuration={0}>
				<Tooltip>
					<TooltipTrigger
						className={triggerClassName}
						onPointerEnter={handlePointerEnter}
						onPointerLeave={() => setIsVisible(false)}
					>
						{children}
					</TooltipTrigger>
					<TooltipContent
						align={align}
						side={side}
						className={contentClassName}
					>
						<div
							className={cn(
								'text-sm font-normal max-w-[400px] text-slate-600 ',
								tooltipContentClass ? tooltipContentClass : '',
							)}
						>
							{tooltip}
						</div>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</>
	);
};

export default TooltipWrapper;
