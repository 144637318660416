import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { useCreateEventNavigation } from '@/features/events/hooks/create/useCreateEventNavigation';
import { useConnectAPI } from '../hooks/useConnectAPI';
import {
	ApiCreateSectionEnum,
	CreateTaskAPISteps,
	EvaluationFields,
} from '../types/task-apis.enum';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { useCreateTaskApiNavigation } from '../hooks/useCreateTaskApiNavigation';
import { useMemo } from 'react';
import useCreateTaskAPI from '../hooks/useCreateTaskAPI';

const TaskApiCreateNavigation = () => {
	const {
		section,
		step,
		evaluationErrors,
		formFields,
		evaluatedFields,
		isLoading,
		contextualSuggestions,
		animateWiggle,
	} = useConnectAPI();
	const { moveBack, moveForward, saveDraft } = useCreateTaskApiNavigation();
	const { handleClick, getButtonLabel, getDescription } = useCreateTaskAPI();

	const isDisabled = useMemo(() => {
		if (step === CreateTaskAPISteps.EvaluateResponse) {
			// Check if any evaluationErrors key has error
			const hasErrors = Object.values(evaluationErrors).some(
				(error) => !!error,
			);

			const requiredFields = [EvaluationFields.DataResult];

			if (formFields?.isMetricBasedTaskEnabled) {
				requiredFields.push(
					EvaluationFields.DataMetric,
					EvaluationFields.DataMetricDataType,
				);
			}

			const allRequiredFieldsEvaluated = requiredFields.every(
				(field) => evaluatedFields[field],
			);

			return hasErrors || !allRequiredFieldsEvaluated || isLoading;
		}
		return isLoading;
	}, [
		evaluationErrors,
		evaluatedFields,
		isLoading,
		step,
		formFields?.isMetricBasedTaskEnabled,
	]);

	const showToolTip = useMemo(() => {
		return step === CreateTaskAPISteps.EvaluateResponse && isDisabled;
	}, [isDisabled, step]);
	return (
		<div className="flex justify-between items-center space-x-4 border-t p-3 pb-3 px-4">
			<div className="min-w-[200px]">
				{section !== ApiCreateSectionEnum.Details && (
					<Button
						className=" px-10"
						variant="secondary"
						onClick={() => moveBack()}
					>
						<i className="bi bi-arrow-left me-2"></i>
						Back
					</Button>
				)}
			</div>
			<div>
				{contextualSuggestions && (
					<div
						className={`text-xs ${
							animateWiggle ? 'animate-wiggle repeat-[3]' : ''
						} text-destructive`}
					>
						{contextualSuggestions}
					</div>
				)}
			</div>
			<div className="flex space-x-4">
				<Button
					className=" px-10"
					variant="secondary"
					onClick={saveDraft}
					disabled={isLoading}
				>
					{/* {isLoading && <Spinner className="me-2" />} */}
					<span>Save as Draft</span>
				</Button>
				<TooltipWrapper
					tooltip={showToolTip ? 'Evaluate the expressions first' : ''}
					align="end"
				>
					<Button
						onClick={() => handleClick()}
						disabled={isDisabled}
						className=" px-10"
					>
						{isLoading ? (
							<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
						) : null}
						{getButtonLabel}
						<i className="bi bi-arrow-right ms-2"></i>
					</Button>
				</TooltipWrapper>
			</div>
		</div>
	);
};

export default TaskApiCreateNavigation;
