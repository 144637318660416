import InputSelect2 from '@/components/element/inputs/InputSelect2';
import InputText from '@/components/element/inputs/InputText';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter } from '@/components/ui/dialog';
import { useCampaigns } from '@/features/campaigns/hooks/report/useCampaigns';
import { Status } from '@/features/campaigns/types';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { cn } from '@/lib/utils';
import { Dispatch, SetStateAction } from 'react';
import { ISelectedBenefit } from '../../hooks/useCreditBenefitsModal';
import { CreditRequestValueEnum } from '../../types/credits.enums';
import { ICreditBenefit } from '../../types/credits.types';

interface ApplyConfirmationModalProps {
	isOpen: boolean;
	selectedBenefit: ISelectedBenefit | null;
	setSelectedBenefit: Dispatch<SetStateAction<ICreditBenefit | null>>;
	onClose: () => void;
	onSubmit: () => void;
	getModalConfig: (benefit: ICreditBenefit) => {
		title: JSX.Element;
		subtitle: string;
		type: string;
		inputPlaceholder?: string;
		disclaimer?: string;
	};
	isApplyingForBenefit: boolean;
	formErrors: any;
	isSubmittingRequest: boolean;
}

const ApplyConfirmation: React.FC<ApplyConfirmationModalProps> = ({
	isOpen,
	selectedBenefit,
	setSelectedBenefit,
	onClose,
	onSubmit,
	getModalConfig,
	isApplyingForBenefit,
	formErrors,
	isSubmittingRequest,
}) => {
	const { campaigns } = useCampaigns();
	const { enterprise } = useGetEnterprise();
	const activeCampaigns = campaigns?.filter(
		(i) => i.status === Status.Active || i.status === Status.Scheduled,
	);

	if (!selectedBenefit) return null;

	const config = getModalConfig(selectedBenefit);

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="w-[32rem] p-6 overflow-y-visible">
				<div>
					<h3 className="text-lg font-medium mb-2 text-slate-700">
						{config.title}
					</h3>
					<p
						className={cn(
							'text-sm font-light text-slate-600',
							config.type === 'chain-integration' ? 'mb-0' : 'mb-6',
						)}
					>
						{config.subtitle}
					</p>

					{config.type === 'homepage' || config.type === 'quest' ? (
						<InputSelect2
							placeholder={config.inputPlaceholder}
							value={selectedBenefit?.campaignId}
							setValue={(e) =>
								setSelectedBenefit((prev) => ({
									...prev,
									campaignId: e,
									campaignName: activeCampaigns?.find(
										(i) => i._id === e,
									)?.name,
								}))
							}
							className=""
							label="Select a quest"
							options={activeCampaigns?.map((i) => ({
								label: i.name,
								value: i._id,
								searchText: i.name,
							}))}
							popoverClassName="w-[29rem] bg-red-200"
							inputClassName="max-w-[29rem] truncate shadow-md"
							// setSearch={setSearch}
							error={!!formErrors?.[CreditRequestValueEnum.questId]}
							errorText={
								activeCampaigns?.length > 0
									? 'Please select a quest'
									: 'You have no active quests to avail this benefit on'
							}
						/>
					) : null}

					{config.type === 'notification' && (
						<div className="space-y-6">
							<InputSelect2
								placeholder={config.inputPlaceholder}
								value={selectedBenefit?.campaignId}
								setValue={(e) =>
									setSelectedBenefit((prev) => ({
										...prev,
										campaignId: e,
										campaignName: activeCampaigns?.find(
											(i) => i._id === e,
										)?.name,
									}))
								}
								className=""
								label="Select a quest"
								options={activeCampaigns?.map((i) => ({
									label: i.name,
									value: i._id,
									searchText: i.name,
								}))}
								popoverClassName="w-[29rem] bg-red-200"
								inputClassName="max-w-[29rem] truncate shadow-md"
								// setSearch={setSearch}
								error={
									!!formErrors?.[CreditRequestValueEnum.questId]
								}
								errorText={
									activeCampaigns?.length > 0
										? 'Please select a quest'
										: 'You have no active quests to avail this benefit on'
								}
							/>
							<InputTextArea
								placeholder={config.inputPlaceholder}
								value={selectedBenefit?.notificationContent}
								setValue={(e) =>
									setSelectedBenefit((prev) => ({
										...prev,
										notificationContent: e,
									}))
								}
								inputClassName="shadow-md"
								label="Enter Notification Content:"
								error={!!formErrors?.[CreditRequestValueEnum.text]}
								errorText={'Please enter notification content'}
							/>
						</div>
					)}

					{config.type === 'repost' && (
						<>
							<InputText
								label="Enter Tweet/Post Link:"
								placeholder={config.inputPlaceholder}
								value={selectedBenefit?.tweet}
								setValue={(e) =>
									setSelectedBenefit((prev) => ({
										...prev,
										tweet: e,
									}))
								}
								inputClassName="shadow-md"
								labelClassName="bg-transparent"
								error={!!formErrors?.[CreditRequestValueEnum.text]}
								errorText={
									enterprise?.isApproved
										? 'Please enter tweet/post link'
										: 'Get your project approved to access Credits and its benefits.'
								}
							/>
							{/* <p className="text-xs text-destructive mt-2">
								{config.disclaimer}
							</p> */}
						</>
					)}
				</div>
				<DialogFooter className="w-full mt-3">
					<Button
						variant="outline"
						onClick={onClose}
						disabled={isApplyingForBenefit || isSubmittingRequest}
					>
						Cancel
					</Button>
					<TooltipWrapper
						tooltip={
							enterprise?.isApproved
								? ''
								: 'Get your project approved to access Credits and its benefits.'
						}
						align="end"
						tooltipContentClass="w-[22rem]"
					>
						<Button
							onClick={onSubmit}
							disabled={
								isApplyingForBenefit ||
								isSubmittingRequest ||
								!enterprise?.isApproved
							}
						>
							{isApplyingForBenefit || isSubmittingRequest ? (
								<i className="bi-arrow-clockwise animate-spin me-1"></i>
							) : null}
							<span>Apply Now</span>
						</Button>
					</TooltipWrapper>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ApplyConfirmation;
