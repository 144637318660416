import { Label } from '@/components/ui/label';
import { Dropzone } from '@/components/element/dropzone/Dropzone';
import ImageControl from '@/components/element/images/ImageControl';
import { cn } from '@/lib/utils';
import { UploadAvatar } from '@/components/element/avatar/UploadAvatar';
import { Button } from '@/components/ui/button';

const BannerWithLogo = ({
	file,
	setFile,
	fileLink,
	description,
	label,
	cta,
	aspectRatio,
	className,
	customHeight,
	required,
	error,
	errorText,
	updateLogo,
	logoURL,
	logoRef,
	bannerRef,
	setBannerPosition,
	bannerPos,
}: {
	file: File | null;
	setFile: (file: File | File[], fileLink: string) => void;
	fileLink: string;
	description?: string;
	label?: string;
	cta?: string;
	aspectRatio: number;
	className?: string;
	customHeight?: string;
	required?: boolean;
	error?: string;
	errorText?: string;
	updateLogo: (e: any) => void;
	logoURL: File | string;
	logoRef: any;
	bannerRef: any;
	setBannerPosition?: ({ x, y }: { x: number; y: number }) => void;
	bannerPos: { x: number; y: number };
}) => {
	return (
		<div className={className}>
			<div className="flex mb-2 justify-between items-center">
				<div>
					<Label className="block mb-1">
						{label}
						{required ? (
							<span className="text-red-400 ms-1">*</span>
						) : null}
					</Label>
				</div>
			</div>
			<div className="relative">
				{!file && !fileLink && (
					<Dropzone
						className={cn(
							'flex flex-col !py-[2rem] w-full cursor-pointer items-center justify-center rounded-md bg-gray-50 dark:bg-slate-950 border-dashed border-2 text-sm',
							error ? 'border-destructive' : '',
							customHeight || 'h-[150px]',
						)}
						onFileChange={(file: File | File[], fileLink: string) => {
							setFile(file, fileLink);
						}}
						height={customHeight}
						allowedFiles={['image/*']}
						aspectRatio={aspectRatio * 2}
					>
						<div>{cta}</div>
						<div className="text-xs text-muted-foreground">
							{description}
						</div>
					</Dropzone>
				)}
				{(file || fileLink) && (
					<div className={customHeight}>
						<ImageControl
							backgroundImage={fileLink}
							aspectRatio={aspectRatio}
							error={error}
							onImageReposition={setBannerPosition}
							imagePosition={bannerPos}
						/>
					</div>
				)}

				<div className="absolute bottom-5 right-5 z-[40]">
					<Button
						variant="outline"
						onClick={() => bannerRef.current.click()}
					>
						<i className="bi bi-camera-fill mr-2"></i>Upload Banner
					</Button>
					<input
						type="file"
						className="absolute top-0 w-0 -z-1 opacity-0"
						ref={bannerRef}
						onChange={(e) => {
							const file = e.target.files[0];
							setFile(file, URL.createObjectURL(file));
						}}
						accept="image/*"
					/>
				</div>
			</div>

			<div className="flex items-center relative z-40">
				<UploadAvatar
					avatarUrl={logoURL}
					setAvatar={updateLogo}
					avatarRef={logoRef}
					className="mt-4"
				/>
			</div>
			{error ? (
				<p className="text-xs text-destructive mt-1">{errorText || error}</p>
			) : null}
		</div>
	);
};

export default BannerWithLogo;
