import Quill from 'quill';

const Link = Quill.import('formats/link');

class CustomLink extends Link {
	static create(value: string) {
		const node = super.create(value) as HTMLElement;
		value = CustomLink.sanitize(value);
		node.setAttribute('href', value);
		node.setAttribute('target', '_blank'); // Optional: Open link in a new tab
		return node;
	}

	static sanitize(url: string): string {
		if (!url.startsWith('http://') && !url.startsWith('https://')) {
			url = 'https://' + url;
		}
		return url;
	}
}

Quill.register(CustomLink, true);

export default CustomLink;
