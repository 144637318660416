import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from '@/components/ui/select';

import { Input } from '@/components/ui/input';
import MultiSelect from '@/components/element/inputs/MultiSelect';
import { EligibilityCriteria, EligibilityType } from '../types/reward-store.enums';
import { useRewardStore } from '../hooks/useRewardStore';
import useRewardCriteria from '../hooks/useRewardCriteria';

const CriteriaItem = ({ index }) => {
	const { formFields, setFormFields, formErrors, quests } = useRewardStore();
	const { handleConditionChange, handleCampaignChange, handleChange } =
		useRewardCriteria();

	const valueArray = Array.isArray(
		formFields?.eligibilityParams?.[index]?.campaignIds,
	)
		? formFields?.eligibilityParams?.[index]?.campaignIds
		: [];

	return (
		<div className="flex gap-3 py-1 px-0.5">
			<div
				className={`flex items-center flex-grow space-x-2 sm:space-x-0 w-[11.72rem] `}
			>
				<Select
					defaultValue={
						formFields?.eligibilityParams?.[index]?.eligibilityType
					}
					onValueChange={(newVal) =>
						handleConditionChange(newVal, 'eligibilityParams', index)
					}
					value={formFields?.eligibilityParams?.[index]?.eligibilityType}
				>
					<SelectTrigger className="text-sm">
						<SelectValue placeholder="Select" className="h-10 " />
					</SelectTrigger>

					<SelectContent>
						{EligibilityCriteria?.map((item) => (
							<SelectItem key={item.value} value={item.value}>
								{item.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>
			<div className="flex flex-grow items-center gap-2 w-[12rem] ">
				{formFields?.eligibilityParams?.[index]?.eligibilityType ===
				EligibilityType.QuestsCompletion ? (
					<MultiSelect
						value={valueArray}
						setValue={(newVal: string[]) =>
							handleCampaignChange(newVal, 'eligibilityParams', index)
						}
						options={quests}
						placeholder="Select Campaigns"
						popoverClassName="w-[17rem] h-[18rem]"
						maxLimit={0}
						withoutPortal
						error={formErrors['eligibilityParams']}
					/>
				) : (
					<Input
						className={`h-9 ${
							formErrors['eligibilityParams']
								? 'border-destructive'
								: ''
						}`}
						onFocus={(e) => {
							e.target.select();
						}}
						onChange={(e) =>
							handleChange(e.target.value, 'eligibilityParams', index)
						}
						value={formFields?.eligibilityParams?.[index]?.minCount}
					/>
				)}
			</div>
		</div>
	);
};

export default CriteriaItem;
