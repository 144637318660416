import CampaignHeader from './components/header';
import { useCampaignReport } from '../../hooks/report/useCampaignReport';
import Overview from './sections/Overview';
import Leaderboard from './sections/Leaderboard';
import Analytics from './sections/Analytics';
import Channels from './sections/Channels';
import Submissions from './sections/Submissions';
import Winners from './sections/Winners';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';

const CampaignReport = () => {
	const { selectedTab, isLoading, campaign } = useCampaignReport();

	if (isLoading) return <FullPageLoading />;
	return (
		<div>
			<CampaignHeader />
			{selectedTab === 'analytics' && <Analytics />}
			{selectedTab === 'overview' && <Overview />}
			{selectedTab === 'leaderboard' && <Leaderboard />}
			{selectedTab === 'channels' && <Channels />}
			{selectedTab === 'winners' && <Winners />}
			{selectedTab === 'submissions' && <Submissions campaign={campaign} />}
		</div>
	);
};

export default CampaignReport;
