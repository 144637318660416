import { useEffect, useState } from 'react';
import { useRewardStore } from './useRewardStore';

export const useRewardStoreSections = ({ setOpen }) => {
	const [showAddSection, setShowAddSection] = useState(false);
	const [errors, setErrors] = useState({
		name: '',
		description: '',
	});
	const [isDeletingSection, setIsDeletingSection] = useState(false);
	const [isAddingSection, setIsAddingSection] = useState(false);

	const {
		createSection,
		formFields,
		rewardStore,
		editSection,
		setFormErrors,
		setFormFields,
		formErrors,
		section,
		setSection,
	} = useRewardStore();

	const addSection = async () => {
		const errs: any = {};
		if (!section?.name || !section?.name?.trim()) {
			errs.name = 'Section title is required';
		}
		if (!section?.description || !section?.description?.trim()) {
			errs.description = 'Section description is required';
		}

		setErrors(errs);
		if (Object.keys(errs).length > 0) {
			return;
		}

		setIsAddingSection(true);
		if (formFields?.sectionId) {
			editSection({
				_id: formFields?.sectionId,
				name: section?.name,
				description: section?.description,
			});
		} else {
			createSection({
				name: section?.name,
				description: section?.description,
			});
		}

		setOpen(false);

		setIsAddingSection(false);
		setFormFields({
			...formFields,
			sectionId: '',
		});
		setSection({
			name: '',
			description: '',
		});
		// setErrors({
		// 	...formErrors,
		// 	name: '',
		// 	description: '',
		// });
	};

	useEffect(() => {
		setErrors({
			...errors,
			name: '',
			description: '',
		});
	}, [section.name, section.description]);

	return {
		showAddSection,
		setShowAddSection,
		setSection,
		section,
		errors,
		addSection,
		setErrors,
		isDeletingSection,
		setIsDeletingSection,
		isAddingSection,
		setIsAddingSection,
	};
};
