import { Button } from '@/components/ui/button';
import { useCreateEventNavigation } from '@/features/events/hooks/create/useCreateEventNavigation';
import { useCreateEvent } from '@/features/events/hooks/create/useCreateEvent';
import { EventsCreateEnum } from '@/features/events/types/events.type';
import { ReactNode } from 'react';
import Spinner from '@/components/element/loading/Spinner';

const EventCreateNavigation = () => {
	const { section, contextualSuggestions } = useCreateEvent();
	const { moveBack, moveForward, isLoading, saveDraft, publishDraft } =
		useCreateEventNavigation();
	return (
		<div className="flex justify-between items-center space-x-4 border-t p-3 pb-3 px-4">
			<div className="min-w-[200px]">
				{section !== EventsCreateEnum.Details && (
					<Button
						className=" px-10"
						variant="secondary"
						onClick={() => moveBack()}
					>
						<i className="bi bi-arrow-left me-2"></i>
						Back
					</Button>
				)}
			</div>
			<div>
				<div className="text-xs">
					{contextualSuggestions.id && (
						<div
							className={`text-xs  repeat-[3] animate-wiggle`}
							key={contextualSuggestions.id}
						>
							{contextualSuggestions.element as ReactNode}
						</div>
					)}
				</div>
			</div>
			<div className="flex space-x-4">
				<Button
					className=" px-10"
					variant="secondary"
					onClick={saveDraft}
					disabled={isLoading.draft}
				>
					{isLoading.draft && <Spinner className="me-2" />}
					<span>Save as Draft</span>
				</Button>
				{section === EventsCreateEnum.Campaigns ? (
					<>
						<Button
							className=" px-10"
							onClick={publishDraft}
							disabled={isLoading.publish}
						>
							{isLoading.publish && <Spinner className="me-2" />}
							<span>Publish Event</span>
						</Button>
					</>
				) : (
					<Button className=" px-10" onClick={moveForward}>
						<span>Next</span>
						<i className="bi bi-arrow-right ms-2"></i>
					</Button>
				)}
			</div>
		</div>
	);
};

export default EventCreateNavigation;
