import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	IEditingTasks,
	useCreateCampaignTasks,
} from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { ICreateTasks } from '@/features/campaigns/types';
import { MetricBasedXpFunction } from '@/features/campaigns/types/tasks.enums';
import { LinearGraph, SigmoidGraph, TieredGraph } from './GraphTypes';

const GraphComponent = ({
	metricBasedXpConfig,
	task,
}: {
	metricBasedXpConfig: ICreateTasks['metricBasedXpConfig'];
	task: IEditingTasks['task'];
}) => {
	const { handleTaskUpdate } = useCreateCampaignTasks();

	return (
		<div className="border-t py-5 px-5 flex items-center justify-between">
			{metricBasedXpConfig?.functionType === MetricBasedXpFunction.Linear && (
				<div className="flex flex-col">
					<div className="flex justify-between items-center">
						<InputText
							label="XP Multiplier"
							value={metricBasedXpConfig?.linearMetadata?.xpMultiplier}
							setValue={(newValue) =>
								handleTaskUpdate(
									task.taskId,
									newValue,
									'xpMultiplier',
								)
							}
							placeholder="Enter XP Multiplier"
						/>
						<InputText
							label="Max XP"
							value={task.xp}
							setValue={(newValue) =>
								handleTaskUpdate(task.taskId, newValue, 'xp')
							}
							placeholder="Enter XP Per Volume"
						/>
					</div>
					<div className="w-full my-4">
						<LinearGraph
							xpMultiplier={
								metricBasedXpConfig?.linearMetadata?.xpMultiplier
							}
							maxXP={task.xp}
						/>
					</div>
				</div>
			)}
			{metricBasedXpConfig?.functionType === MetricBasedXpFunction.Tiered && (
				<div className="flex flex-col">
					<div className="flex flex-col items-end">
						<Button
							variant="secondary"
							className="text-sm py-1 px-2 h-7"
							onClick={() =>
								handleTaskUpdate(task.taskId, 0, 'addMetricTiers')
							}
						>
							+ Add Tier
						</Button>
					</div>

					<div className="flex flex-col mt-2">
						{metricBasedXpConfig?.tieredMetadata?.tiers?.map(
							(tier, index) => (
								<div
									key={index}
									className="flex items-center gap-4 mb-4"
								>
									<InputText
										label={`Tier ${index + 1} Min Metric`}
										labelClassName="text-sm"
										value={tier.minMetric}
										setValue={(newValue) =>
											handleTaskUpdate(
												task.taskId,
												newValue,
												'tierMinMetric',
												index,
											)
										}
										placeholder="Enter Min Metric"
										onFocus={(e) => e.target.select()}
									/>
									<InputText
										label={`Tier ${index + 1} Max Metric`}
										labelClassName="text-sm"
										value={tier.maxMetric}
										setValue={(newValue) =>
											handleTaskUpdate(
												task.taskId,
												newValue,
												'tierMaxMetric',
												index,
											)
										}
										placeholder="Enter Max Metric"
										onFocus={(e) => e.target.select()}
									/>
									<InputText
										label={`Tier ${index + 1} XP`}
										labelClassName="text-sm"
										value={tier.tierXp}
										setValue={(newValue) =>
											handleTaskUpdate(
												task.taskId,
												newValue,
												'tierXp',
												index,
											)
										}
										placeholder="Enter Tier XP"
										onFocus={(e) => e.target.select()}
									/>
									<Button
										variant="outline"
										size="icon"
										className="mt-[1.75rem]"
										onClick={() =>
											handleTaskUpdate(
												task.taskId,
												index,
												'removeMetricTiers',
											)
										}
									>
										<i className="bi bi-trash text-muted-foreground"></i>
									</Button>
								</div>
							),
						)}
					</div>

					<div className="w-full my-4">
						<TieredGraph metricBasedXpConfig={metricBasedXpConfig} />
					</div>
				</div>
			)}
			{metricBasedXpConfig?.functionType === MetricBasedXpFunction.Sigmoid && (
				<div className="flex flex-col">
					<div className="flex justify-between items-center gap-4">
						<InputText
							label="Max XP"
							value={task.xp}
							setValue={(newValue) =>
								handleTaskUpdate(task.taskId, newValue, 'xp')
							}
							placeholder="Enter L"
							onFocus={(e) => e.target.select()}
						/>
						<InputText
							label="Optimal Metric Value"
							value={
								metricBasedXpConfig?.sigmoidMetadata
									?.optimalMetricValue
							}
							setValue={(newValue) =>
								handleTaskUpdate(
									task.taskId,
									newValue,
									'optimalMetricValue',
								)
							}
							placeholder="Enter x0"
							onFocus={(e) => e.target.select()}
						/>
						<InputText
							label="Rate of Increase"
							value={
								metricBasedXpConfig?.sigmoidMetadata?.rateOfIncrease
							}
							setValue={(newValue) =>
								handleTaskUpdate(
									task.taskId,
									newValue,
									'rateOfIncrease',
								)
							}
							placeholder="Enter k"
							onFocus={(e) => e.target.select()}
						/>
					</div>
					<div className="w-full my-4">
						<SigmoidGraph
							optimalMetricValue={
								metricBasedXpConfig?.sigmoidMetadata
									?.optimalMetricValue
							}
							rateOfIncrease={
								metricBasedXpConfig?.sigmoidMetadata?.rateOfIncrease
							}
							maxXP={task.xp}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default GraphComponent;
