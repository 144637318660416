export enum RewardStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}
export enum RewardType {
	DiscordRole = 'DISCORD_ROLE',
	Nft = 'NFT',
	Custom = 'CUSTOM',
}
export enum EligibilityType {
	UserXps = 'USER_XPS',
	NumberOfQuestsCompleted = 'NUMBER_OF_QUESTS_COMPLETED',
	NumberOfLoyaltyTasksCompleted = 'NUMBER_OF_LOYALTY_TASKS_COMPLETED',
	ReferralsDone = 'REFERRALS_DONE',
	QuestsCompletion = 'QUESTS_COMPLETION',
}
export enum ClaimStatus {
	NotEligible = 'NOT_ELIGIBLE',
	Eligible = 'ELIGIBLE',
	Initiated = 'INITIATED',
	Claimed = 'CLAIMED',
}

export enum RewardSectionTabs {
	Store = 'store',
	Analytics = 'analytics',
}

export const EligibilityCriteria = [
	{
		value: EligibilityType.UserXps,
		label: 'User XPs',
	},
	{
		value: EligibilityType.NumberOfQuestsCompleted,
		label: 'Number of quests completed',
	},
	{
		value: EligibilityType.NumberOfLoyaltyTasksCompleted,
		label: 'Number of loyalty tasks completed',
	},
	{
		value: EligibilityType.ReferralsDone,
		label: 'Referrals done',
	},
	{
		value: EligibilityType.QuestsCompletion,
		label: 'Quests completion',
	},
];

export const RewardTypesMap = {
	[RewardType.DiscordRole]: 'Discord Role',
	[RewardType.Nft]: 'NFT',
	[RewardType.Custom]: 'Secret Code',
};

export const rewardIconMap = {
	[RewardType.DiscordRole]: 'bi-discord',
	[RewardType.Nft]: 'bi-gem',
	[RewardType.Custom]: 'bi-gift-fill',
};
