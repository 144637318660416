import {
	IEditingTasks,
	useCreateCampaignTasks,
} from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { TaskKeys, TemplateType } from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import { useMemo } from 'react';

const TaskToggleFeatures = ({ task }: { task: IEditingTasks['task'] }) => {
	const { deleteTask, tasks } = useCreateCampaignTasks();

	const isTemplateRecurring = useMemo(() => {
		const task_ = tasks?.find((t) => t.task?.taskId === task.taskId)?.task;
		if (task_.templateType === TemplateType.ApiBasedVerification) {
			const adminApiInput = task_.adminInputs.find(
				(input) => input.key === TaskKeys.ApiRequestId,
			)?.value;
			if (adminApiInput) return task_?.featureApplicability?.recurrence;
			return false;
		}
		return task_?.featureApplicability?.recurrence;
	}, [tasks, task]);

	const isTemplateMetricBased = useMemo(() => {
		const task_ = tasks?.find((t) => t.task?.taskId === task.taskId)?.task;
		if (task_.templateType === TemplateType.ApiBasedVerification) {
			const adminApiInput = task_.adminInputs.find(
				(input) => input.key === TaskKeys.ApiRequestId,
			)?.value;
			if (adminApiInput) return task_?.featureApplicability?.metricBasedXp;
			return false;
		}
		return task_?.featureApplicability?.metricBasedXp;
	}, [tasks, task]);

	return (
		<div className="border-t py-3 px-6 ">
			<div className="flex space-x-2 justify-between">
				<div className="flex space-x-2 items-center">
					<TaskToggleFeature
						value={!task.isRequiredTask}
						taskId={task.taskId}
						label="Optional"
						action="OPTIONAL"
						newValue={!task.isRequiredTask}
					/>
					<TaskToggleFeature
						value={task.learningContent.enabled}
						taskId={task.taskId}
						label="Slides"
						action="LEARNING_CONTENT_TOGGLE"
						newValue={!task.learningContent.enabled}
					/>
					{task?.featureApplicability?.recurrence &&
						!task.featureApplicability?.metricBasedXp && (
							<TaskToggleFeature
								value={task.appliedFeatures?.recurrance?.enabled}
								taskId={task.taskId}
								label="Recurrence"
								action="RECURRING_TOGGLE"
								newValue={!task.appliedFeatures?.recurrance?.enabled}
							/>
						)}
					{task?.featureApplicability?.metricBasedXp &&
						!task?.featureApplicability?.recurrence && (
							<TaskToggleFeature
								value={task.appliedFeatures?.metricBasedXp?.enabled}
								taskId={task.taskId}
								label="Metric Based XP"
								action="METRIC_BASED_XP_TOGGLE"
								newValue={
									!task.appliedFeatures?.metricBasedXp?.enabled
								}
							/>
						)}
					{task?.featureApplicability?.maxFailedCount && (
						<TaskToggleFeature
							value={task.appliedFeatures?.maxFailedCount?.enabled}
							taskId={task.taskId}
							label="Max Limit"
							action="MAX_RETRIES_TOGGLE"
							newValue={!task.appliedFeatures?.maxFailedCount?.enabled}
						/>
					)}
				</div>
				<div className="flex space-x-4">
					<div
						className="text-xs bg-slate-100 rounded-full px-2 py-2 font-medium hover:bg-slate-300 transition-all duration-200 cursor-pointer "
						onClick={() => {
							deleteTask(task.taskId);
						}}
					>
						<i className="bi-trash"></i>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TaskToggleFeatures;

const TaskToggleFeature = ({ value, taskId, label, action, newValue }) => {
	const { editTask } = useCreateCampaignTasks();
	return (
		<div
			className={cn(
				`text-xs  rounded-full px-4 py-2 font-medium transition-all duration-200 cursor-pointer`,
				value ? 'bg-slate-800 text-white' : 'bg-slate-100',
				value
					? 'hover:bg-slate-900'
					: 'hover:bg-slate-200 hover:text-slate-800',
			)}
			onClick={() => editTask(taskId, action, newValue)}
		>
			{value ? (
				<i className="bi-check-circle-fill me-2"></i>
			) : (
				<i className="bi-plus-circle-fill me-2"></i>
			)}
			{label}
		</div>
	);
};
