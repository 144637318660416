import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import useAdminCampaignPause from '../../hooks/useAdminCampaignPause';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Skeleton } from '@/components/ui/skeleton';
import InputSelect from '@/components/element/inputs/InputSelect';
import { toTitleCase } from '@/utils/parsers';
import { useMemo } from 'react';

const CampaignPauseDialog = ({ open, setOpen, campaign, setCampaign, refetch }) => {
	const {
		editDetails,
		isLoading,
		handleCampaignAndTasksPause,
		openAccordion,
		setOpenAccordion,
		campaignTasks,
		isFetchingData,
		formFields,
		formErrors,
		handleAccordionChange,
	} = useAdminCampaignPause({
		campaign,
		setCampaign,
		refetch,
		setOpen,
	});
	const getActionLabel = useMemo(() => {
		if (formFields.updateType === 'CAMPAIGN' && campaign?.status === 'PAUSED') {
			return 'Activate Campaign';
		} else if (
			formFields.updateType === 'CAMPAIGN' &&
			campaign?.status === 'ACTIVE'
		) {
			return 'Pause Campaign';
		} else {
			return 'Update Tasks';
		}
	}, [formFields.updateType, campaign]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[570px] min-h-[10rem] overflow-visible">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between">
						Pause Campaign or Its Tasks
					</DialogTitle>
					<DialogDescription>
						Update the status of campaign:{' '}
						<strong>{campaign?.name}</strong>
					</DialogDescription>
				</DialogHeader>
				<div className="w-full space-y-6">
					<div className="flex items-center justify-center w-full gap-3">
						<SelectUpdateType
							value={formFields.updateType}
							setValue={(e) => editDetails('updateType', e, '')}
						/>
						<span className="text-sm font-medium h-9 px-2 py-1 flex rounded-md border items-center bg-muted text-muted-foreground">
							{toTitleCase(formFields.updateType)}
						</span>
					</div>
					{formFields.updateType === 'TASK' && (
						<div className="grid grid-cols-2 w-full gap-3">
							{isFetchingData ? (
								<>
									{[...Array(6).keys()].map((_, index) => (
										<div key={index} className="space-y-2">
											<Skeleton className="h-7 w-full" />
										</div>
									))}
								</>
							) : (
								Array.isArray(campaignTasks) &&
								campaignTasks?.map((task, index) => (
									<>
										<div
											key={task?._id}
											className="flex items-center space-x-2 h-9 px-2 py-1 rounded-md border text-sm "
										>
											<p className="max-w-[20rem] truncate">
												Task {task.name}
											</p>
										</div>
										<InputSelect
											options={[
												{
													label: 'Paused',
													value: 'PAUSED',
												},
												{
													label: 'Active',
													value: 'ACTIVE',
												},
											]}
											value={
												formFields.updateData?.find(
													(i) => i.id === task._id,
												)?.status
											}
											setValue={(e) =>
												editDetails('status', e, task._id)
											}
											disabled={
												formFields.updateType === 'CAMPAIGN'
											}
											className=""
										/>
									</>
								))
							)}
						</div>
					)}

					<Accordion
						type="single"
						collapsible
						className="w-full"
						value={openAccordion}
					>
						<AccordionItem value={'pauseReason'} className="border-none">
							<AccordionTrigger
								className="text-left font-normal pt-0 text-sm focus:ring-0 focus:outline-none focus:border-none focus-visible:ring-0 focus-visible:outline-none"
								onClick={() => handleAccordionChange('pauseReason')}
							>
								{`Custom message for paused ${toTitleCase(formFields.updateType)}?`}
							</AccordionTrigger>
							<AccordionContent className="text-gray-500 transition-all">
								<InputTextArea
									placeholder={`Enter the reason to pause ${toTitleCase(formFields.updateType)}`}
									className="text-black focus:ring-0 focus:outline-none !focus-visible:ring-0 focus-visible:outline-none focus-visible:border-none focus-visible:ring-offset-0"
									value={
										formFields?.updateData?.find(
											(item) => item.status === 'PAUSED',
										)?.pauseReason || ''
									}
									setValue={(e) =>
										editDetails('pauseReason', e, '')
									}
									error={formErrors.pauseReason}
									errorText={formErrors.pauseReason}
								/>
							</AccordionContent>
						</AccordionItem>
					</Accordion>
				</div>
				<DialogFooter className="space-x-2 flex border-t pt-3">
					<Button variant="ghost" onClick={() => setOpen(close)}>
						Cancel
					</Button>
					<Button
						onClick={() => handleCampaignAndTasksPause()}
						disabled={isLoading}
					>
						{isLoading ? (
							<i className="bi-arrow-clockwise animate-spin me-2"></i>
						) : null}
						<span>{getActionLabel}</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CampaignPauseDialog;

const SelectUpdateType = ({ value, setValue }) => {
	const options = [
		{
			type: 'item',
			label: 'Campaign',
			onClick: () => setValue('CAMPAIGN'),
			value: 'CAMPAIGN',
		},
		{
			type: 'item',
			label: 'Tasks',
			onClick: () => setValue('TASK'),
			value: 'TASK',
		},
	];
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="outline" className="data-[state=open]:bg-muted h-9">
					<span>Update Type</span>
					<i className="bi-chevron-down ms-2"></i>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[160px]">
				{options.map((i) => (
					<>
						{i.type === 'item' && (
							<DropdownMenuItem onClick={i.onClick}>
								{i.label}
							</DropdownMenuItem>
						)}
						{i.type === 'separator' && <DropdownMenuSeparator />}
					</>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
