import { useConnectAPI } from '../../hooks/useConnectAPI';
import { ApiCreateSectionEnum } from '../../types/task-apis.enum';
import DetailsSection from './DetailsSection';
import VerifySection from './VerifySection';

const SectionWrapper = () => {
	const { section } = useConnectAPI();

	return (
		<div className="my-5">
			{section === ApiCreateSectionEnum.Details && <DetailsSection />}
			{section === ApiCreateSectionEnum.Verify && <VerifySection />}
		</div>
	);
};

export default SectionWrapper;
