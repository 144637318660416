import PageHeader from '@/components/layout/PageHeader';
import EnterpriseFilters from '../components/enterprises/EnterpriseFilters';
import { DataTable } from '@/components/element/data-table/DataTable';
import { enterprisesColumns } from '../components/enterprises/EnterprisesColumns';
import { toast } from 'sonner';
import { useState } from 'react';
import { IAdminEnterprises } from '../types/admin.types';
import EnterpriseApprovalDialog from '../components/enterprises/EnterpriseApprovalDialog';
import { useAdminEnterpriseActions } from '../hooks/useAdminEnterpriseActions';
import { useAuth } from '@/hooks/useAuth';
import { useGetAdminEnterprises } from '../hooks/useGetAdminEnterperises';

const AdminEnterprises = () => {
	const { user } = useAuth();
	const [selected, setSelected] = useState<IAdminEnterprises>(null);
	const [showApproval, setShowApproval] = useState(false);
	const {
		search,
		setSearch,
		filters,
		setFilters,
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading,
		results,
		refetch,
		count,
	} = useGetAdminEnterprises();

	const { ghostLogin, removeGhostLogin } = useAdminEnterpriseActions({ refetch });

	const columns = enterprisesColumns([
		{
			type: 'button',
			label: 'Update Approval',
			onClick: (row: any) => {
				setSelected(row);
				setShowApproval(true);
			},
		},
		{
			type: 'button',
			label: 'Add Ghost Login',
			onClick: (row: any) => {
				ghostLogin(row._id);
			},
			show: (row: any) => {
				return !user.enterprises?.map((i) => i._id).includes(row._id);
			},
		},
		{
			type: 'button',
			label: 'Remove Ghost Login',
			onClick: (row: any) => {
				removeGhostLogin(row._id);
			},
			show: (row: any) =>
				user?.enterprises?.map((i) => i._id)?.includes(row._id),
		},
		{
			type: 'separator',
		},
		{
			type: 'button',
			label: 'Copy Enterprise ID',
			onClick: (row: any) => {
				navigator.clipboard.writeText(row._id);
				toast.success('Enterprise ID copied to clipboard');
			},
		},
	]);

	return (
		<div>
			<PageHeader
				title="Enterprises Approval"
				description="Manage enterprises, their approvals, etc"
			/>
			<EnterpriseFilters
				search={search}
				filters={filters}
				setSearch={setSearch}
				setFilters={setFilters}
			/>
			<div className="mt-6 ">
				<DataTable
					onRowClick={(row) => {
						setSelected(row);
						setShowApproval(true);
					}}
					data={results}
					columns={columns}
					totalCount={count || 100}
					isLoading={isLoading}
					isServerSide={true}
					pagination={pagination}
					setPagination={setPagination}
					sorting={sorting}
					setSorting={setSorting}
				/>
			</div>
			<EnterpriseApprovalDialog
				open={showApproval}
				setOpen={setShowApproval}
				enterprise={selected}
				refetch={refetch}
			/>
		</div>
	);
};

export default AdminEnterprises;
