import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useIntegrations } from '@/features/integrations/hooks/useIntegrations';
import { useTheme } from '@/hooks/useTheme';
import { useEffect } from 'react';
import { toast } from 'sonner';

const ShareCampaign = ({
	open,
	setOpen,
	link,
	isCelebrate,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	link: string;
	isCelebrate: boolean;
}) => {
	const { celebrate } = useTheme();
	const { guildId } = useIntegrations();

	useEffect(() => {
		if (isCelebrate) {
			celebrate(3000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCelebrate, open]);

	const copyText = (address: string) => {
		navigator.clipboard.writeText(address);
		toast.success(`Link has been copied on your clipboard`);
	};

	const shareText = `Check out this awesome campaign we launched on Intract!`;

	const shareLinks = [
		{
			icon: 'bi-twitter',
			color: 'bg-twitter',
			label: 'Twitter',
			onclick: () => {
				const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(link)}`;
				window.open(url, '_blank');
			},
		},
		{
			icon: 'bi-discord',
			color: 'bg-discord',
			label: 'Discord',
			onclick: () => {
				if (guildId) {
					const url = `https://discord.com/channels/${guildId}`;
					window.open(url, '_blank');
				} else {
					window.open('https://discord.com/', '_blank');
				}
			},
		},
		{
			icon: 'bi-telegram',
			color: 'bg-telegram',
			label: 'Telegram',
			onclick: () => {
				const url = `https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(shareText)}`;
				window.open(url, '_blank');
			},
		},
		{
			icon: 'bi-linkedin',
			color: 'bg-linkedin',
			label: 'Linkedin',
			onclick: () => {
				const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}&mini=true&summary=${encodeURIComponent(shareText)}`;
				window.open(url, '_blank');
			},
		},
	];

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[505px] p-0 border-none ">
				<div className="bg-gradient-to-t from-white to-violet-400 -mb-10 ">
					<div className="flex justify-start items-center py-10 px-10">
						<div className="h-12 w-12 rounded-full border-white border-2 bg-white bg-opacity-40 flex items-center justify-center text-2xl">
							✌️
						</div>
						<div className="h-20 w-20 rounded-full border-white border-2 bg-violet-50  flex items-center justify-center text-4xl -mx-2 relative z-5">
							🥳
						</div>
						<div className="h-12 w-12 rounded-full border-white border-2 bg-white bg-opacity-40 flex items-center justify-center text-2xl ">
							🚀
						</div>
					</div>
				</div>
				<div className="px-10 pb-10">
					<div className="text- mb-14">
						<div className="text-xl font-medium">
							Share quest with community
						</div>
						<p className="mt-2 text-sm px">
							Share the campaign link with your audience and start
							celebrating the success!
						</p>
					</div>
					<div className="mb-10 grid grid-cols-4 gap-3">
						{shareLinks.map((item, index) => (
							<div
								className="text-center"
								key={`${item.label}_${index}`}
							>
								<Button
									key={index}
									variant="outline"
									size="lg"
									className={`space-x-2 w-full flex-col items-center justify-center px-3 h-[80px] rounded-xl mb-2 ${item.color} hover:${item.color}`}
									onClick={item.onclick}
								>
									<i
										className={`${item.icon} text-white text-3xl `}
									></i>
								</Button>
								<div className="font-medium text-xs">
									{item.label}
								</div>
							</div>
						))}
					</div>
					<div className="text-center">
						<Button
							variant="outline"
							size="lg"
							className="space-x-2 w-full items-center justify-between px-3 truncate"
							onClick={() => copyText(link)}
						>
							<div className="flex items-center truncate">
								<i className="bi-link-45deg text-lg me-2"></i>
								{link?.slice(0, 40)}...
							</div>
							<i className="bi-clipboard-fill text-muted-foreground"></i>
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ShareCampaign;
