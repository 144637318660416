import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
} from '@/components/ui/dialog';

const TaskInformation = ({ open, setOpen, userTasks }) => {
	const getTaskInformation = (task) => {
		return Object.keys(task)
			.filter((key) => key.startsWith('task_'))
			.reduce((result, key) => {
				result[key.replace('task_', '')] = task[key];
				return result;
			}, {});
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[500px] ">
				<DialogHeader>
					<DialogTitle>Task Information</DialogTitle>
					<DialogDescription>
						Here is the task information for the quest
					</DialogDescription>
				</DialogHeader>
				<div className="my-4 grid gap-4 border border-gray-300 rounded-md">
					{userTasks &&
						Array.isArray(userTasks) &&
						userTasks.map((task, index) => {
							const taskInfo = getTaskInformation(task);
							return (
								<div
									key={index}
									className="border-b border-gray-200 mb-4 last:border-b-0 last:pb-0 last:mb-0"
								>
									{Object.keys(taskInfo).map((key) => (
										<div
											key={`${index}-${key}`}
											className="grid grid-cols-2 gap-2 text-left border-b border-gray-200 py-2 px-4 last:border-b-0"
										>
											<TooltipWrapper
												tooltip={key}
												align="start"
											>
												<h4 className="text-sm font-medium max-w-[11rem] truncate text-left">
													{key}
												</h4>
											</TooltipWrapper>

											<TooltipWrapper
												tooltip={taskInfo[key]}
												align="start"
											>
												<p className="text-sm font-normal max-w-[20rem] truncate text-muted-foreground text-left">
													{taskInfo[key]}
												</p>
											</TooltipWrapper>
										</div>
									))}
								</div>
							);
						})}
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default TaskInformation;
