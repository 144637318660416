import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
	getPoWTaskSubmissions,
	getPoWTaskSubmissionsCount,
} from '../../services/campaigns.service';
import { IQuestPOWSubmissions, POWVerifyStatus } from '../../types';
import { useDebounce } from '@/hooks/useDebounce';

export const useCampaignSubmissions = ({ campaign }) => {
	const [rowSelection, setRowSelection] = useState({});
	const [selectedTaskId, setSelectedTaskId] = useState<string>('');
	const [cursor, setCursor] = useState('');
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [statusFilters, setStatusFilters] = useState([
		POWVerifyStatus.Initiated,
		POWVerifyStatus.Verified,
		POWVerifyStatus.Failed,
	]);
	const [sorting, setSorting] = useState([]);
	const [selectedRow, setSelectedRow] = useState<IQuestPOWSubmissions>();
	const [customXP, setCustomXP] = useState(0);
	const [search, setSearch] = useState('');

	const debounceSearch = useDebounce(search, 500);

	const powTasks = useMemo(() => {
		if (!campaign || !campaign?.quest || !campaign?.quest?.tasks) return [];
		return campaign.quest.tasks.filter(
			(task) => task?.powVerifyConfig?.isPOWVerify,
		);
	}, [campaign]);

	const queryKey = useMemo(() => {
		return [
			'campaign',
			'approvals',
			selectedTaskId,
			pagination.pageIndex,
			pagination.pageSize,
			sorting,
			statusFilters,
			debounceSearch,
		];
	}, [
		selectedTaskId,
		pagination.pageIndex,
		pagination.pageSize,
		sorting,
		statusFilters,
		debounceSearch,
	]);

	const fetchSubmissions = () =>
		getPoWTaskSubmissions({
			campaignId: campaign?._id,
			taskId: selectedTaskId,
			page: pagination.pageIndex,
			limit: pagination.pageSize,
			sorting,
			statusFilters,
			cursor: pagination.pageIndex > 0 ? cursor : '', // Include cursor only for subsequent page
			search: debounceSearch,
		});

	const { data, isLoading } = useQuery<{
		submissions: IQuestPOWSubmissions[];
		count: number;
		nextCursor: string;
		limit: number;
	}>({
		queryKey: queryKey,
		queryFn: fetchSubmissions,
		enabled: !!campaign && powTasks?.length > 0 && !!selectedTaskId.length,
	});

	useEffect(() => {
		if (data) {
			setCursor(data.nextCursor);
		}
	}, [data]);

	useEffect(() => {
		setCursor('');
	}, [selectedTaskId, statusFilters, sorting, debounceSearch]);

	const { data: count, isLoading: isCountLoading } = useQuery({
		queryKey: ['campaign', 'approvals', selectedTaskId, statusFilters],
		queryFn: () =>
			getPoWTaskSubmissionsCount({
				campaignId: campaign?._id,
				taskId: selectedTaskId,
				statusFilters,
				search: debounceSearch,
			}),
		enabled: !!campaign && powTasks?.length > 0 && !!selectedTaskId.length,
	});

	useEffect(() => {
		//Taking user to the first unverified submission when a new page is loaded
		if (!data?.submissions || data?.submissions.length === 0) return;
		const selected = data.submissions?.find(
			(sub) => sub._id === selectedRow?._id,
		);
		if (!selected) {
			const firstUnverifiedSubmission = data.submissions.find(
				(i) =>
					i.status === POWVerifyStatus.Initiated ||
					i.status === POWVerifyStatus.Failed,
			);
			if (!firstUnverifiedSubmission) {
				setSelectedRow(data.submissions[0]);
				return;
			}
			setCustomXP(firstUnverifiedSubmission?.customXp || 0);
			setSelectedRow(firstUnverifiedSubmission);
		} else {
			setSelectedRow(selected);
			setCustomXP(selected?.customXp || 0);
		}
	}, [data, selectedRow?._id]);

	useEffect(() => {
		//Setting the first task as selected task
		if (!selectedTaskId && powTasks?.length > 0) {
			setSelectedTaskId(powTasks[0]._id);
		}
	}, [powTasks]);

	const handleNext = () => {
		const selectedRowIndex = data.submissions.findIndex(
			(submission) => submission._id === selectedRow._id,
		);
		if (selectedRowIndex === data.submissions.length - 1) {
			if (data.submissions.length < pagination.pageSize) return;
			setPagination((prev) => ({
				...prev,
				pageIndex: prev.pageIndex + 1,
			}));
			return;
		}
		const nextRow = data.submissions[selectedRowIndex + 1];
		if (nextRow) {
			setSelectedRow(nextRow);
			// setCustomXP(nextRow?.customXp || 0);
		}
	};

	const handlePrevious = () => {
		const selectedRowIndex = data.submissions.findIndex(
			(submission) => submission._id === selectedRow._id,
		);
		if (selectedRowIndex === 0) {
			if (pagination.pageIndex === 0) return;
			setPagination((prev) => ({
				...prev,
				pageIndex: prev.pageIndex - 1,
			}));
			return;
		}
		const prevRow = data.submissions[selectedRowIndex - 1];
		if (prevRow) {
			setSelectedRow(prevRow);
			// setCustomXP(prevRow?.customXp || 0);
		}
	};

	return {
		isLoading,
		powTasks,
		setSelectedTaskId,
		selectedTaskId,
		submissions: data?.submissions ?? [],
		pagination,
		setPagination,
		sorting,
		setSorting,
		isCountLoading,
		totalCount: count ?? 10,
		rowSelection,
		setRowSelection,
		selectedRow,
		setSelectedRow,
		handleNext,
		handlePrevious,
		statusFilters,
		setStatusFilters,
		queryKey,
		setCursor,
		customXP,
		setCustomXP,
		search,
		setSearch,
	};
};
