import { cn } from '@/lib/utils';
import { aboutPage } from '../../content/about.content';
import LandingContainer from '../ui/LandingContainer';
import SectionHeader from '../ui/SectionHeader';

const IntractCulture = () => {
	return (
		<LandingContainer className="my-28">
			<SectionHeader
				title={aboutPage.culture.title}
				description={aboutPage.culture.description}
				titleGradient="blue"
				className="mb-20"
			/>
			<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-12 md:gap-x-4 md:gap-y-20 mx-auto w-full">
				{aboutPage?.culture?.culture?.map((i) => (
					<div className="icons-card--no-border-card space-y-7 flex flex-col items-center md:items-start justify-center mx-auto">
						<img
							alt="Canopy 2 [White] 10 2"
							loading="lazy"
							decoding="async"
							data-nimg={1}
							className={`transition-opacity opacity-100 ${i.width || 'w-16'} ${i.height || 'h-16'}`}
							src={i.img}
							style={{ color: 'transparent' }}
						/>
						<div className="flex w-full flex-col">
							<div className="text-card items-center text-center justify-center md:items-start md:text-left md:justify-start">
								<div className="flex flex-col items-center text-center justify-center md:items-start md:text-left md:justify-start">
									<div className="flex flex-col space-y-3 items-center text-center justify-center md:items-start md:text-left md:justify-start">
										<div className="relative">
											<h6 className="w-full transition-colors text-xl font-medium md:w-full text-black">
												{i.title}
											</h6>
										</div>
										<div className="text-sm text-black">
											<p>{i.description}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</LandingContainer>
	);
};

export default IntractCulture;
