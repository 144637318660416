import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import InputText from '@/components/element/inputs/InputText';
import { useRewardStore } from '../hooks/useRewardStore';
import CriteriaItem from './CriteriaItem';
import { useEffect } from 'react';
import SelectRewardType from './SelectRewardType';
import { cn } from '@/lib/utils';

const RewardDetails = () => {
	const { formFields, setFormFields, formErrors, setFormErrors } =
		useRewardStore();

	useEffect(() => {
		setFormErrors((prev: any) => ({
			...prev,
			name: '',
			description: '',
			eligibilityParams: '',
		}));
	}, [
		formFields.name,
		formFields.description,
		formFields.eligibilityParams,
		setFormErrors,
	]);

	return (
		<div className="mt-2 px-5 w-full">
			<div className="flex flex-col gap-5 w-full">
				<InputText
					label="Reward Name"
					placeholder="Enter reward name"
					className=""
					// subLabel="Name of the reward"
					subLabelClassName=" mt-2 font-thin"
					value={formFields.name}
					setValue={(e) =>
						setFormFields({
							...formFields,
							name: e,
						})
					}
					error={formErrors['name']}
					errorText={formErrors['name']}
					required
					disabled={formFields.editMode}
				/>
				<InputText //TODO: input field max limit ?
					label="Reward Description"
					placeholder="Describe the reward in brief. This will be visible to users."
					// subLabel="Describe the reward in brief. This will be visible to users."
					subLabelClassName="mt-2"
					className=""
					value={formFields.description}
					setValue={(e) =>
						setFormFields({ ...formFields, description: e })
					}
					error={formErrors['description']}
					required
					errorText={formErrors['description']}
					// disabled={formFields.editMode}
				/>
				<div className="space-y-1">
					<p className="text-sm font-medium">
						Condition For Reward Eligibility
					</p>
					{formFields.eligibilityParams.map((item, index) => (
						<CriteriaItem key={index} index={index} />
					))}
					{formErrors['eligibilityParams'] && (
						<p className={cn('text-xs  text-destructive mt-1')}>
							{formErrors['eligibilityParams']}
						</p>
					)}
				</div>

				{formFields?.editMode ? null : <SelectRewardType />}
			</div>
		</div>
	);
};

export default RewardDetails;
