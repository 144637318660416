import { useState } from 'react';
import { IAdminCampaigns } from '../types/admin.types';
import {
	getAdminCampaigns,
	getAdminCampaignsCount,
	getAdminEnterprises,
} from '../services/admin.service';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@/hooks/useDebounce';

export const useGetAdminCampaigns = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		campaignStatus: [],
		publishStatus: false,
		activeButNotPublished: false,
		fetchLoyaltyCampaigns: false,
	});
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState([]);

	const getSortOrder = () => {
		if (sorting && sorting.length > 0) {
			return sorting[0].desc ? 'desc' : 'asc';
		}
		return 'desc';
	};

	const { data, isLoading, refetch } = useQuery<IAdminCampaigns[]>({
		queryKey: [
			'admin',
			'campaigns',
			{
				pagination,
				filters,
				search,
				sorting,
			},
		],
		queryFn: () =>
			getAdminCampaigns({
				page: Number(pagination.pageIndex + 1) ?? 1,
				limit: Number(pagination.pageSize) ?? 10,
				search: search?.trim(),
				campaignStatus: filters.campaignStatus,
				publishStatus: filters.publishStatus,
				activeButNotPublished: filters.activeButNotPublished,
				sortingId:
					sorting && sorting.length > 0 ? sorting[0].id : 'createdAt',
				sortingOrder: getSortOrder(),
				fetchLoyaltyCampaigns: filters.fetchLoyaltyCampaigns || false,
			}),
	});

	const { data: count, isLoading: isCountLoading } = useQuery<number>({
		queryKey: [
			'admin',
			'campaigns',
			{
				filters,
				search,
			},
		],
		queryFn: () =>
			getAdminCampaignsCount({
				search: search,
				campaignStatus: filters.campaignStatus,
				publishStatus: filters.publishStatus,
				activeButNotPublished: filters.activeButNotPublished,
			}),
	});

	return {
		search,
		setSearch,
		filters,
		setFilters,
		results: data || [],
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading: isLoading,
		refetch,
		count,
	};
};
