import { Button } from '@/components/ui/button';
import { DialogFooter } from '@/components/ui/dialog';

import { useLoyaltyTasksNavigate } from '../../hooks/tasks/useLoyaltyTasksNavigate';
import { LoyaltyTaskCreateSteps } from '../../types/loyalty.enums';
import Spinner from '@/components/element/loading/Spinner';
import { useLoyaltyTasks } from '../../hooks/tasks/useLoyaltyTasks';
import { TaskStatus } from '@/features/campaigns/types/tasks.enums';

const AddLoyaltyTaskNavigation = () => {
	const { step, isUpdate, task } = useLoyaltyTasks();
	const { saveAsDraft, moveBack, moveNext, isLoading, publishTask } =
		useLoyaltyTasksNavigate();

	return (
		<DialogFooter className=" border-t py-3 px-4">
			<div className="w-full flex justify-between">
				{!isUpdate ? (
					<Button
						variant="outline"
						onClick={() => saveAsDraft()}
						disabled={isLoading?.publish || isLoading?.draft}
					>
						{isLoading?.draft && <Spinner className="me-2" />}
						Save as Draft
					</Button>
				) : (
					<div></div>
				)}
				<div className="flex space-x-2">
					{step !== LoyaltyTaskCreateSteps.TaskParams && (
						<Button
							className="px-8"
							variant="outline"
							onClick={() => moveBack()}
						>
							Back
						</Button>
					)}
					{(isUpdate && task.status === TaskStatus.Active) ||
					step === LoyaltyTaskCreateSteps.TaskDuration ? (
						<Button
							className="px-8"
							onClick={() => publishTask()}
							disabled={
								isLoading?.publish ||
								isLoading?.draft ||
								task?.isDisabled
							}
						>
							{isLoading?.publish && <Spinner className="me-2" />}
							<span>
								{isUpdate && task.status === TaskStatus.Active
									? 'Update Task'
									: 'Publish Task'}
							</span>
						</Button>
					) : (
						<Button
							className="px-8"
							onClick={() => moveNext()}
							disabled={task?.isDisabled}
						>
							Next
						</Button>
					)}
				</div>
			</div>
		</DialogFooter>
	);
};

export default AddLoyaltyTaskNavigation;
