import { ITaskTemplates } from '@/features/campaigns/types';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { ILoyaltyTask } from '../types/loyalty.type';
import { LoyaltyTaskCreateSteps } from '../types/loyalty.enums';
import { deepCopy } from '@/utils/parsers';
import { MetricBasedXpFunction } from '@/features/campaigns/types/tasks.enums';

interface LoyaltyTaskProviderState {
	task: ILoyaltyTask;
	setTask: Dispatch<SetStateAction<ILoyaltyTask>>;
	showAddTask: boolean;
	setShowAddTask: (showAddTask: boolean) => void;
	selectedSectionId: string;
	setSelectedSectionId: (selectedSectionId: string) => void;
	template: ITaskTemplates;
	setTemplate: Dispatch<SetStateAction<ITaskTemplates>>;
	errors: any;
	setErrors: any;
	isDirty: boolean;
	setIsDirty: (isDirty: boolean) => void;
	step: LoyaltyTaskCreateSteps;
	setStep: (step: LoyaltyTaskCreateSteps) => void;
	isUpdate: boolean;
	setIsUpdate: (isUpdate: boolean) => void;
	editTask: (action: 'EDIT_METRIC_BASED_XP', value: any) => void;
}

export const LoyaltyTaskContext = createContext<LoyaltyTaskProviderState>({
	task: null,
	setTask: () => {},
	showAddTask: false,
	setShowAddTask: () => {},
	selectedSectionId: '',
	setSelectedSectionId: () => {},
	template: null,
	errors: {},
	setErrors: () => {},
	isDirty: false,
	setIsDirty: () => {},
	step: LoyaltyTaskCreateSteps.TaskParams,
	setStep: () => {},
	setTemplate: () => {},
	isUpdate: false,
	setIsUpdate: () => {},
	editTask: () => {},
});

export function LoyaltyTaskProvider({ children }) {
	const [task, setTask] = useState<ILoyaltyTask>(null);
	const [errors, setErrors] = useState<any>({});
	const [showAddTask, setShowAddTask] = useState(false);
	const [template, setTemplate] = useState<ITaskTemplates>();
	const [selectedSectionId, setSelectedSectionId] = useState('');
	const [isDirty, setIsDirty] = useState(false);
	const [step, setStep] = useState(LoyaltyTaskCreateSteps.TaskParams);
	const [isUpdate, setIsUpdate] = useState(false);

	const editTask = (action: 'EDIT_METRIC_BASED_XP', value: any) => {
		setIsDirty(true);
		const _task = { ...task };

		const configMap = {
			[MetricBasedXpFunction.Linear]: {
				functionType: value,
				linearMetadata: { xpMultiplier: 1 },
			},
			[MetricBasedXpFunction.Tiered]: {
				functionType: value,
				tieredMetadata: {
					tiers: [{ minMetric: 0, maxMetric: 10, tierXp: 15 }],
				},
			},
			[MetricBasedXpFunction.Sigmoid]: {
				functionType: value,
				sigmoidMetadata: { optimalMetricValue: 10, rateOfIncrease: 0.5 },
			},
			[MetricBasedXpFunction.None]: {
				functionType: MetricBasedXpFunction.None,
			},
		};

		if (action === 'EDIT_METRIC_BASED_XP') {
			_task.metricBasedXpConfig = configMap[value] || {};
		}

		setTask(_task);
	};

	return (
		<LoyaltyTaskContext.Provider
			value={{
				task,
				setTask,
				showAddTask,
				setShowAddTask,
				selectedSectionId,
				setSelectedSectionId,
				template,
				errors,
				setErrors,
				isDirty,
				setIsDirty,
				step,
				setStep,
				setTemplate,
				isUpdate,
				setIsUpdate,
				editTask,
			}}
		>
			{children}
		</LoyaltyTaskContext.Provider>
	);
}
