import Blogs from '@/features/landing/components/homepage/Blogs';
import Customers from '@/features/landing/components/homepage/Customers';
import FAQs from '@/features/landing/components/homepage/FAQs';
import Features from '@/features/landing/components/homepage/Features';
import Hero from '@/features/landing/components/homepage/Hero';
import Hero2 from '@/features/landing/components/homepage/Hero2';
import Numbers from '@/features/landing/components/homepage/Numbers';
import Testimonials from '@/features/landing/components/homepage/Testimonials';
import ValueProps from '@/features/landing/components/homepage/ValueProps';
import Footer from '@/features/landing/components/layout/Footer';
import Navbar from '@/features/landing/components/layout/Navbar';
import LandingContainer from '@/features/landing/components/ui/LandingContainer';

const AuthLayout2 = ({ children }) => {
	return (
		<div className="m-0 p-0">
			<Navbar />
			<LandingContainer>
				<div className="flex flex-col lg:flex-row gap-36 w-full mb-8">
					<div className="w-full  xl:w-[40%] my-10 py-7 px-10 min-h-[38rem] lg:border lg:shadow-md lg:rounded-xl">
						{children}
					</div>
					<div className="w-full xl:w-[60%] lg:flex lg:items-center order-last lg:order-first max-h-[38rem]">
						<Hero style={'text-left !px-0'} />
					</div>
				</div>
				<div className="w-[5%]"></div>
			</LandingContainer>
			<Customers />
			<Numbers />
			<Features />
			<ValueProps />
			<Testimonials />
			<Blogs />
			<FAQs />
			<Footer />
		</div>
	);
};

export default AuthLayout2;
