import { Button } from '@/components/ui/button';
import WidgetSection from './WidgetSection';
import { SearchAllQuests } from '../SearchQuests';
import { useCreateWidget } from '@/features/admin/hooks/admin-cms-widgets/useCreateWidget';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

const WidgetSections = () => {
	const {
		details,
		showCampaignList,
		setShowCampaignList,
		addQuest,
		updateQuestsInWidgets,
		isSubmitting,
		sections,
	} = useCreateWidget();
	return (
		<div>
			<WidgetSection />

			<div className="flex items-center gap-4">
				<TooltipWrapper
					tooltip={
						!details?._id ? 'Select a widget first to add a quest' : ''
					}
					align="start"
				>
					<Button
						onClick={() => setShowCampaignList(true)}
						disabled={!details?._id || isSubmitting}
					>
						<span>+ Add a quest</span>
					</Button>
				</TooltipWrapper>
				<Button
					onClick={() => updateQuestsInWidgets()}
					variant="outline"
					disabled={isSubmitting}
				>
					{isSubmitting ? (
						<i className="bi-arrow-repeat animate-spin me-2"></i>
					) : null}
					<span>Save Changes</span>
				</Button>
			</div>

			<SearchAllQuests
				open={showCampaignList}
				setOpen={setShowCampaignList}
				onSelect={addQuest}
				searchQuests={sections}
				isLoyaltyWidget={details?.isLoyaltyWidget || false}
			/>
		</div>
	);
};

export default WidgetSections;
