import { defineChain } from 'viem';

export const cronosZkevmTestnet = defineChain({
	id: 282,
	name: 'Cronos ZkEVM Testnet',
	nativeCurrency: { name: 'zkTCRO', symbol: 'zkTCRO', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://testnet.zkevm.cronos.org'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Cronos ZkEVM Testnet Explorer',
			url: 'https://explorer.zkevm.cronos.org/testnet',
		},
	},
	testnet: true,
});
