import { defineChain } from 'viem';

export const seiNetwork = defineChain({
	id: 1329,
	name: 'Sei Network',
	nativeCurrency: { name: 'sei', symbol: 'sei', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://evm-rpc.sei-apis.com'],
		},
	},
	blockExplorers: {
		default: {
			name: 'SeiTrace',
			url: 'https://seitrace.com/?chain=pacific-1',
		},
	},
	testnet: false,
});
