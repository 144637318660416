import { defineChain } from 'viem';

export const viction = defineChain({
	id: 88,
	name: 'VIC',
	nativeCurrency: { decimals: 18, name: 'VIC', symbol: 'VIC' },
	rpcUrls: {
		default: {
			http: ['https://rpc.viction.xyz'],
			webSocket: ['wss://ws.viction.xyz'],
		},
	},
	blockExplorers: {
		default: {
			name: 'VIC',
			url: 'https://vicscan.xyz',
		},
	},
	testnet: false,
});
