import InputText from '@/components/element/inputs/InputText';
import InputWrapper from '@/components/element/inputs/InputWrapper';
import { QuillEditor } from '@/components/element/rich-text-editor/QuillEditor';
import { Switch } from '@/components/ui/switch';
import { useMemo, useState } from 'react';

const EventCustomReward = ({ reward, setReward }) => {
	const [show, setShow] = useState(false);

	useMemo(() => {
		if (reward.title || reward.description) {
			setShow(true);
		}
	}, [reward]);
	return (
		<div className="mt-16 mb-16">
			<div className="mb-3 flex justify-between items-start">
				<div>
					<div className="text-sm font-medium">
						<i className="bi-gift me-2"></i>
						Add Custom Reward Text
					</div>
					<div className="text-xs text-muted-foreground mt-1">
						If you want to highlight a custom reward for the event, you
						can add it here. We automatically add rewards from the
						campaigns that are added to the event.
					</div>
				</div>
				<div>
					<Switch checked={show} onCheckedChange={setShow} />
				</div>
			</div>
			{show && (
				<div className="space-y-5 mt-6">
					<InputText
						label="Reward Title"
						required={true}
						placeholder="Complete all tasks to earn this reward."
						value={reward?.title}
						inputClassName="bg-white"
						setValue={(e) =>
							setReward({
								...reward,
								title: e,
							})
						}
					/>
					{/* <InputText
						label="Reward Description"
						required={true}
						placeholder="All XP earned will be converted to Official Points at the end of the event."
						inputClassName="bg-white"
						value={reward?.description}
						setValue={(e) =>
							setReward({
								...reward,
								description: e,
							})
						}
					/> */}
					<InputWrapper
						label="Reward Description"
						className="mt-10"
						required
					>
						<QuillEditor
							value={reward?.description}
							setValue={(e: any) =>
								setReward({
									...reward,
									description: e,
								})
							}
							placeholder="Enter your custom reward text here"
							// error={details.rewardDescription}
							// errorText="Please enter a valid reward text"
						/>
					</InputWrapper>
				</div>
			)}
		</div>
	);
};

export default EventCustomReward;
