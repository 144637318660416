import { Label } from '@/components/ui/label';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import clsx from 'clsx';

const InputWrapper = ({
	children,
	labelClassName,
	descriptionClassName,
	description,
	className,
	label,
	tooltip,
	required,
}: {
	children: React.ReactNode;
	labelClassName?: string;
	descriptionClassName?: string;
	description?: string;
	className?: string;
	label: string;
	tooltip?: string;
	required?: boolean;
}) => {
	return (
		<div className={className}>
			<Label className={clsx('flex items-center mb-2', labelClassName)}>
				<span>{label}</span>
				{tooltip && (
					<TooltipProvider delayDuration={0}>
						<Tooltip>
							<TooltipTrigger className="ms-2">
								<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
							</TooltipTrigger>
							<TooltipContent>
								<div className="text-sm font-normal max-w-[400px]">
									{tooltip}
								</div>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				)}
				{required && <span className="text-red-400 ms-1">*</span>}
			</Label>
			{description && (
				<p
					className={clsx(
						'text-xs mb-4 text-muted-foreground',
						descriptionClassName,
					)}
				>
					{description}
				</p>
			)}
			<span>{children}</span>
		</div>
	);
};

export default InputWrapper;
