import { Media } from '@/components/element/media/Media';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { asset } from '@/config/asset-cdn';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { UserType } from '@/providers/AuthProvider';
import { TrackingEvents } from '@/types/tracking.type';
import { convertToTitleCase } from '@/utils/parsers';
import { useEffect, useState } from 'react';
import en from '../../../../locales/en.json';
import { useCreditBenefitsModal } from '../../hooks/useCreditBenefitsModal';
import { CreditRequestTypeEnum } from '../../types/credits.enums';
import { ICreditBenefit } from '../../types/credits.types';
import ApplyConfirmation from '../modals/ApplyConfirmation';

const BenefitsSection = ({
	getIsDisabled,
	renderTooltip,
	creditBenefits,
	isLoading,
	user,
}: {
	getIsDisabled: (action: string) => boolean;
	renderTooltip: (content: string | string[]) => JSX.Element;
	creditBenefits: ICreditBenefit[];
	isLoading: boolean;
	user: UserType;
}) => {
	const { intractCredits } = en;
	const section = intractCredits?.sections?.benefits;

	const [actualCreditBenefits, setActualCreditBenefits] =
		useState<ICreditBenefit[]>(creditBenefits);

	useEffect(() => {
		const getUpdatedBenefits = () =>
			queryClient.getQueryData<{ data: { consumeCredits: ICreditBenefit[] } }>(
				['credits', 'benefits'],
			)?.data?.consumeCredits || [];

		// Set initial data from cache if available
		const initialData = getUpdatedBenefits();
		if (initialData.length > 0) setActualCreditBenefits(initialData);

		// Subscribe to cache changes for reactivity
		const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
			if (
				JSON.stringify(event.query.queryKey) ===
				JSON.stringify(['credits', 'benefits'])
			) {
				const newCacheData = getUpdatedBenefits();
				setActualCreditBenefits(newCacheData);
			}
		});

		// Cleanup the subscription
		return () => unsubscribe();
	}, [creditBenefits]);

	const {
		isOpen,
		selectedBenefit,
		setSelectedBenefit,
		handleAction,
		handleSubmit,
		handleClose,
		getModalConfig,
		isApplyingForBenefit,
		formErrors,
		isSubmittingRequest,
	} = useCreditBenefitsModal({
		creditBenefits,
	});

	useEffect(() => {
		analytics.track(TrackingEvents.CreditsBenefitsViewed, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	}, [user]);

	return (
		<>
			<div className="flex flex-col rounded-xl p-6 border border-slate-200 ">
				<div className="w-[85%]">
					<h2 className="text-slate-700 self-stretch text-lg">
						{section?.title}
					</h2>
					<p className="text-slate-500 mb-2 self-stretch text-sm leading-5">
						{section?.subtitle}
					</p>
					<div className="bg-slate-100 rounded-lg p-4">
						<div className="grid grid-cols-[1.3fr_1.5fr] gap-x-0 gap-y-1">
							{Array.isArray(section?.list) &&
								section?.list?.map((benefit, index) => (
									<div
										key={index}
										className="flex items-center text-slate-500 gap-2"
									>
										<i className="bi-check-lg text-violet-500 flex-shrink-0" />
										<span className="text-sm leading-normal font-normal">
											{benefit}
										</span>
									</div>
								))}
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 mt-6">
					{Array.isArray(actualCreditBenefits) &&
						actualCreditBenefits?.map((benefit) => (
							<div
								key={benefit._id}
								className="py-4 px-3.5 border border-slate-200 rounded-lg"
							>
								<div className="flex flex-col h-full flex-1">
									{benefit?.icon?.startsWith('http') ? (
										<Media
											src={benefit.icon}
											altText={benefit.title}
											className="size-5 mb-2"
										/>
									) : (
										<i
											className={`bi bi-${benefit.icon} text-slate-700 text-xl leading-5 size-5 mb-2`}
										></i>
									)}

									<h3 className="text-slate-700 text-base font-normal mb-1">
										{benefit.title}
									</h3>
									<p className="text-slate-500 text-sm font-light mb-4">
										{benefit.description}
									</p>
									<div className="flex items-center justify-between mt-auto">
										<div className="flex items-center gap-1 bg-slate-100 rounded-full">
											<Media
												src={asset['credit-icon-primary']}
												altText="credit-icon"
												className="size-[22px]"
											/>
											<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
												{benefit.requestType ===
												CreditRequestTypeEnum.VerificationTwitter
													? `~${benefit.credits} Credits / 100 users`
													: benefit.credits}
											</span>
										</div>
										<TooltipWrapper
											tooltip={
												getIsDisabled(
													benefit.enterpriseCurrentRequestStatus,
												) &&
												// renderTooltip(
												// 	benefit?.tooltip?.content,
												// )
												renderTooltip(
													section?.optedInTooltip,
												)
											}
											align="end"
										>
											<Button
												className={`px-4 py-2 rounded-lg text-sm text-slate-700 `}
												variant="outline"
												disabled={getIsDisabled(
													benefit.enterpriseCurrentRequestStatus,
												)}
												onClick={() => handleAction(benefit)}
											>
												{convertToTitleCase(
													benefit.enterpriseCurrentRequestStatus ??
														benefit.baseCta,
												)}
												{getIsDisabled(
													benefit.enterpriseCurrentRequestStatus,
												) ? (
													<i className="bi-info-circle ms-2"></i>
												) : null}
											</Button>
										</TooltipWrapper>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
			<ApplyConfirmation
				isOpen={isOpen}
				selectedBenefit={selectedBenefit}
				setSelectedBenefit={setSelectedBenefit}
				onClose={() => handleClose({ track: true })}
				onSubmit={handleSubmit}
				getModalConfig={getModalConfig}
				isApplyingForBenefit={isApplyingForBenefit}
				formErrors={formErrors}
				isSubmittingRequest={isSubmittingRequest}
			/>
		</>
	);
};

export default BenefitsSection;
