import LandingContainer from '../ui/LandingContainer';
import { aboutPage } from '../../content/about.content';
import { useRouter } from '@/hooks/useRouter';

const WhyExists = () => {
	const { navigate } = useRouter();
	return (
		<LandingContainer>
			<div className="flex flex-col items-center content-image-hover gap-6 md:grid-cols-10 mt-20">
				<div className="md:col-span-5 md:col-start-5">
					<h3 className="text-4xl font-medium ">
						{aboutPage.whyWeExist.title}
					</h3>
				</div>
				<div className="space-y-14 md:col-span-10  md:space-y-36 mt-4">
					<div className="content-image-hover group gap-6 sm:grid-cols-10">
						<div className="sm:col-span-4"></div>
						<div className="max-w-xl text-xl text-zinc-500 sm:col-span-6">
							{aboutPage.whyWeExist.description.map((i) => (
								<>
									<p>
										<span className="font-">{i}</span>
									</p>
									<p>&nbsp;</p>
								</>
							))}
							<p>&nbsp;</p>
							<p
								className="font-bold italic cursor-pointer hover:underline"
								onClick={() => {
									navigate(aboutPage.whyWeExist.ctaLink);
								}}
							>
								{aboutPage.whyWeExist.cta}
							</p>
						</div>
					</div>
				</div>
			</div>
		</LandingContainer>
	);
};

export default WhyExists;
