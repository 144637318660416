import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
} from '@/components/ui/dialog';
import { CreditRequestStatusEnum } from '@/features/intract-credits/types/credits.enums';
import { Dispatch, SetStateAction } from 'react';

const RejectRequestDialog = ({
	open,
	setOpen,
	rowId,
	isSubmitting,
	rejectionReason,
	setRejectionReason,
	updateRequestStatus,
	errors,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	rowId: string;
	isSubmitting: boolean;
	rejectionReason: string;
	setRejectionReason: Dispatch<SetStateAction<string>>;
	updateRequestStatus: ({
		id,
		status,
		rejectionReason,
	}: {
		id: string;
		status: CreditRequestStatusEnum;
		rejectionReason: string;
	}) => void;
	errors: any;
}) => {
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between text-slate-900 font-medium">
						Reject Credit Request
					</DialogTitle>
				</DialogHeader>
				<div>
					<InputTextArea
						label="Add a detailed reason for rejecting the request"
						value={rejectionReason}
						setValue={(e) => setRejectionReason(e)}
						labelClassName="text-slate-600 mb-3"
						placeholder="Rejecting this request because..."
						error={!!errors.rejectionReason}
						errorText={errors.rejectionReason}
					/>
				</div>
				<DialogFooter className=" w-full  mt-3">
					<Button
						variant="outline"
						onClick={() => setOpen(false)}
						disabled={isSubmitting}
					>
						Cancel
					</Button>
					<Button
						disabled={isSubmitting}
						onClick={() =>
							updateRequestStatus({
								id: rowId,
								status: CreditRequestStatusEnum.Rejected,
								rejectionReason,
							})
						}
					>
						{isSubmitting ? (
							<i className="bi-arrow-clockwise animate-spin mr-2" />
						) : null}
						<span>Reject Request</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default RejectRequestDialog;
