import SideNav from '../side-nav/SideNav';
import DashboardMobileLayout from './DashboardMobileLayout';
import Header from './Header';
import { isMobile } from 'react-device-detect';
import BannerNotification from '../notifications/BannerNotification';

const DashboardLayout = ({ children }: { children: JSX.Element }) => {
	if (isMobile) {
		return <DashboardMobileLayout />;
	}
	return (
		<div className="flex min-h-screen flex-col ">
			<BannerNotification />
			<Header />
			<div className=" flex-1">
				<div className="flex-1 md:grid md:grid-cols-[220px_1fr] md:gap-6 lg:grid-cols-[260px_1fr] lg:gap-8">
					<SideNav />
					<main className="relative py-6 ">
						<div className="mx-auto w-full min-w-0 me-7">
							<div className="flex flex-col space-y-4 me-7">
								{children}
							</div>
						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export default DashboardLayout;
