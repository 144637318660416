import { Badge } from '@/components/ui/badge';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ReportTabs, Status } from '@/features/campaigns/types';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import CampaignActions from './actions/CampaignActions';
import { useEffect, useMemo } from 'react';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const CampaignHeader = () => {
	const { selectedTab, setSelectedTab, campaign, link, hasPowTasks } =
		useCampaignReport();

	const tabs = useMemo(() => {
		const tabs_ = [
			{
				label: 'Overview',
				value: ReportTabs.Overview,
			},
			{
				label: 'Analytics',
				value: ReportTabs.Analytics,
			},
			// {
			// 	label: 'Channels',
			// 	value: ReportTabs.Channels,
			// },
			{
				label: 'Leaderboard',
				value: ReportTabs.Leaderboard,
			},
		];
		if (hasPowTasks) {
			tabs_.push({
				label: 'Submissions',
				value: ReportTabs.Submissions,
			});
		}
		if (campaign?.status === Status.Completed) {
			tabs_.push({
				label: 'Winners',
				value: ReportTabs.Winners,
			});
		}
		return tabs_;
	}, [campaign, hasPowTasks]);

	useEffect(() => {
		analytics.track(TrackingEvents.QuestReportSectionViewed, {
			campaignId: campaign?._id,
			campaignName: campaign?.name,
			enterpriseId: campaign?.enterpriseId,
			enterpriseName: campaign?.enterpriseName,
			selectedTab: selectedTab,
		});
	}, [selectedTab, campaign]);

	return (
		<div>
			<div className="flex items-end space-x-3 justify-between">
				<div className="max-w-[65rem] flex items-center">
					<h2 className="text-2xl font-medium tracking-tight truncate flex-grow">
						{campaign?.name}
					</h2>
				</div>

				<Badge variant="secondary" className="border">
					<StatusBadge status={campaign?.status} />
				</Badge>
			</div>
			<p className="text-sm mb-3 mt-2 text-muted-foreground">
				{campaign?.status === Status.InReview
					? `In "Test", you can test the quest and once you are satisfied then you can make the quest public to your users`
					: `View your quest details and analytics here`}
			</p>
			<div className="flex items-end justify-between">
				<div className="space-y-1">
					<div className="flex">
						<Tabs value={selectedTab} className=" mt-4">
							<TabsList className={`grid  grid-cols-${tabs.length}`}>
								{tabs.map((i) => (
									<TabsTrigger
										value={i.value}
										key={i.value}
										onClick={() => setSelectedTab(i.value)}
									>
										{i.label}
									</TabsTrigger>
								))}
							</TabsList>
						</Tabs>
					</div>
				</div>
				<div className="flex items-center space-x-3">
					<CampaignActions />
				</div>
			</div>
		</div>
	);
};

export default CampaignHeader;
