export const getGASessionId = (
	maxAttempts = 5,
	delay = 200,
): Promise<string | null> => {
	return new Promise((resolve) => {
		let attempts = 0;

		const attemptGetSessionId = () => {
			// Find the GA4 cookie (starts with '_ga_')
			const cookies = document.cookie.split(';');
			const ga4Cookie = cookies.find((cookie) =>
				cookie.trim().startsWith('_ga_'),
			);

			if (!ga4Cookie) {
				attempts++;
				if (attempts < maxAttempts) {
					// Cookie not yet available; wait and try again
					setTimeout(attemptGetSessionId, delay);
				} else {
					// Max attempts reached, resolve with null
					resolve(null);
				}
				return;
			}

			// Split the cookie value
			const cookieValue = ga4Cookie.split('=')[1];
			const parts = cookieValue.split('.');

			const sessionId = parts.length > 2 ? parts[2] : null;
			resolve(sessionId || null);
		};

		attemptGetSessionId();
	});
};
