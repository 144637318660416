import { Dispatch, SetStateAction } from 'react';
import { IOnboardingSocials } from '../../hooks/useCommunityApproval';
import InputText from '@/components/element/inputs/InputText';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';
import { API_URL } from '@/config';
import { cn } from '@/lib/utils';
import { useConfirmEmail } from '../../hooks/useConfirmEmail';
import { useAuth } from '@/hooks/useAuth';

const SocialsSetup = ({
	socials,
	setSocials,
	errors,
	enterprise,
	isAuthenticating,
	setIsAuthenticating,
}: {
	socials: IOnboardingSocials;
	setSocials: Dispatch<SetStateAction<IOnboardingSocials>>;
	errors: {
		website: boolean;
		twitter: boolean;
		discord: boolean;
		email: boolean;
	};
	enterprise: any;
	isAuthenticating: {
		twitter: boolean;
		discord: boolean;
	};
	setIsAuthenticating: Dispatch<
		SetStateAction<{
			twitter: boolean;
			discord: boolean;
		}>
	>;
}) => {
	const { resendEmail, loading: isPending } = useConfirmEmail();
	const { user } = useAuth();

	return (
		<>
			<div className="flex flex-col my-8">
				<div className="flex items-center justify-between bg-white ">
					<div className="flex items-center">
						{/* <div className="h-10 w-10 border rounded-full flex items-center justify-center me-3">
							<i className="bi bi-twitter text-xl text-blue-500"></i>
						</div> */}
						<div className="">
							<div className="text-sm font-medium ">
								Connect Project Twitter
								<span className="text-destructive ms-1">*</span>
							</div>
							<div className="text-xs text-muted-foreground">
								Connect your project's Twitter to our platform.
							</div>
						</div>
					</div>
					{enterprise?.approvalData?.twitter?.twitterId ? (
						<Badge variant="outline" className="w-[100px] font-medium">
							<i className="bi-check-circle-fill text-green-500 me-1 py-1"></i>
							Completed
						</Badge>
					) : (
						<Button
							className={cn('px-6 w-[100px]')}
							size="sm"
							variant="outline"
							onClick={() => {
								setIsAuthenticating((p) => ({
									...p,
									twitter: true,
								}));
								window.location.href = `${API_URL}/api/v1/enterprise/approval/twitter`;
							}}
							disabled={isAuthenticating.twitter}
						>
							{isAuthenticating.twitter && (
								<Spinner className="me-2" />
							)}
							<span>
								{/* <i className="bi bi-twitter text-xs me-1 text-blue-500"></i> */}
								Connect
							</span>
						</Button>
					)}
				</div>
				{errors.twitter && (
					<p className={'text-xs  text-destructive text-right !mt-4'}>
						{"Please connect your project's Twitter account first"}
					</p>
				)}
			</div>

			<div className="flex flex-col my-8">
				<div className="flex items-center justify-between bg-white ">
					<div className="flex items-center">
						<div className="">
							<div className="text-sm font-medium ">
								Verify your email
								<span className="text-destructive ms-1">*</span>
							</div>
							<div className="text-xs text-muted-foreground">
								Confirm your email to access all features.
							</div>
						</div>
					</div>
					{user?.isEmailVerified ? (
						<Badge variant="outline" className="w-[100px] font-medium">
							<i className="bi-check-circle-fill text-green-500 me-1 py-1"></i>
							Completed
						</Badge>
					) : (
						<Button
							className={cn('px-6 w-[100px]')}
							size="sm"
							variant="outline"
							onClick={resendEmail}
							disabled={isPending}
						>
							{isPending && <Spinner className="me-2" />}
							<span>
								{/* <i className="bi-envelope-at-fill text-xs me-1 text-slate-600"></i> */}
								Resend
							</span>
						</Button>
					)}
				</div>
				{errors.email && (
					<p className={'text-xs  text-destructive text-right !mt-4'}>
						Please verify your email first
					</p>
				)}
			</div>

			<InputText
				label="Project Website"
				required={true}
				placeholder="intract.io"
				value={socials.website}
				setValue={(e) =>
					setSocials({
						...socials,
						website: e,
					})
				}
				prepend={
					<div className="ps-3 pe-2 flex items-center text-xs ">
						{/* <i className="bi bi-globe text-red-400 pe-2"></i> */}
						<span className="text-muted-foreground font-medium">
							https://
						</span>
					</div>
				}
				errorText="Please enter your valid website"
				error={errors.website}
				labelClassName="mt-8"
			/>
		</>
	);
};

export default SocialsSetup;
