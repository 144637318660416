import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import queryString from 'query-string';

export function useRouter() {
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const setQueryParam = useCallback(
		(key: string, value: string | null) => {
			const currentParams = queryString.parse(location.search);
			const updatedParams = value
				? { ...currentParams, [key]: value }
				: { ...currentParams };

			if (value === null) {
				delete updatedParams[key];
			}

			const search = queryString.stringify(updatedParams);
			navigate(`${location.pathname}?${search}`, { replace: true });
		},
		[location, navigate],
	);

	return useMemo(() => {
		return {
			pathname: location.pathname,
			query: {
				...queryString.parse(location.search),
				...params,
			},
			// match,
			location,
			navigate,
			params,
			setQueryParam,
		};
	}, [params, location, navigate, setQueryParam]);
}
