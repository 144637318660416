import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getCreditBenefits } from '../services/credits.service';
import { useRouter } from '@/hooks/useRouter';
import { CreditBaseCtaEnum } from '../types/credits.enums';

const useCreditsOverview = () => {
	const [selected, setSelected] = useState('benefits');
	const { setQueryParam, query } = useRouter();

	const getIsDisabled = (action: string) => {
		return (
			action === CreditBaseCtaEnum.Applied ||
			action === CreditBaseCtaEnum.OptedIn
		);
	};

	const renderTooltip = (content: string[] | string) => {
		// if (!Array.isArray(content)) return null;
		if (typeof content === 'string') {
			return <p className="text-slate-500">{content}</p>;
		}
		return (
			<ul className="space-y-2 list-disc pl-3 py-2">
				{content.map((text, index) => (
					<li key={index} className="text-slate-500">
						{text}
					</li>
				))}
			</ul>
		);
	};

	const { data, isLoading } = useQuery({
		queryKey: ['credits', 'benefits'],
		queryFn: getCreditBenefits,
	});

	const handleTabChange = (newTab: string) => {
		setSelected(newTab);
		setQueryParam('tab', newTab);
	};

	useEffect(() => {
		if (query?.tab) {
			setSelected(query?.tab as string);
		}
	}, [query, setSelected]);

	return {
		selected,
		setSelected,
		getIsDisabled,
		renderTooltip,
		creditBenefits: data?.data || [],
		isLoading,
		handleTabChange,
	};
};

export default useCreditsOverview;
