import { useState } from 'react';

import { ApiCreateSectionEnum } from '../types/task-apis.enum';
import { useConnectAPI } from './useConnectAPI';

export const useCreateTaskApiNavigation = () => {
	const { section, setSection, setIsDirty } = useConnectAPI();

	const moveForward = () => {
		if (section === ApiCreateSectionEnum.Details) {
			setSection(ApiCreateSectionEnum.Verify);
			return;
		}
	};

	const moveBack = () => {
		if (section === ApiCreateSectionEnum.Verify) {
			setSection(ApiCreateSectionEnum.Details);
			return;
		}
	};

	const saveDraft = () => {};

	return {
		moveForward,
		moveBack,
		forwardLabel: 'Next',
		saveDraft,
	};
};
