/* eslint-disable no-mixed-spaces-and-tabs */
import { Button } from '@/components/ui/button';
import { useEffect, useState } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { PayloadItem } from './PayloadItem';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { CustomHeaders } from './CustomHeaders';
import {
	ApiCredentialTypes,
	ApiDataEnums,
	ApiMethod,
	ApiValueKeys,
	DataPassingMethod,
} from '../types/task-apis.enum';
import InputText from '@/components/element/inputs/InputText';
import { Label } from '@/components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { CustomPayload } from './CustomPayload';
import InputTextArea from '@/components/element/inputs/InputTextArea';

const PayloadDetails = () => {
	const [items, setItems] = useState(1);
	const [headers, setHeaders] = useState(1);
	const [customPayload, setCustomPayload] = useState(1);

	const { formFields, setFormFields, formErrors, setFormErrors } = useConnectAPI();

	const handleCustomHeaders = (checked: boolean) => {
		if (headers === 0 && checked) setHeaders(1);
		setFormFields((prev) => ({
			...prev,
			isHeaderRequired: !!checked,
		}));
		setFormErrors((prev) => ({
			...prev,
			apiHeaders: '',
		}));
	};
	const handleCustomPayload = (checked: boolean) => {
		if (customPayload === 0 && checked) setCustomPayload(1);

		setFormFields((prev) => {
			const updatedApiPayload = prev.apiPayload.map((item) => ({
				...item,
				value: item.value === ApiDataEnums.ConstantValue ? '' : item.value,
			}));

			// Filter out apiConstantDataFields that were added from apiPayload
			const filteredConstantDataFields = checked
				? prev.apiConstantDataFields
				: prev.apiConstantDataFields.filter(
						(field) =>
							!prev.apiPayload.some(
								(payloadItem) =>
									payloadItem.key === field.key &&
									payloadItem.value === ApiDataEnums.ConstantValue,
							),
					);

			return {
				...prev,
				isCustomConstantPayloadRequired: !!checked,
				apiPayload: updatedApiPayload,
				apiConstantDataFields: filteredConstantDataFields,
			};
		});

		setFormErrors((prev) => ({
			...prev,
			apiConstantDataFields: '',
		}));

		// Reset customPayload count when unchecked
		if (!checked) {
			setCustomPayload((prevCount) =>
				Math.max(
					1,
					prevCount -
						formFields.apiPayload.filter(
							(item) => item.value === ApiDataEnums.ConstantValue,
						).length,
				),
			);
		}
	};
	const addPayloadItem = () => {
		if (items >= ApiValueKeys?.length) return;

		setFormFields((prev) => {
			const existingKeys = new Set(prev.apiPayload.map((item) => item.value));
			const newKey = ApiValueKeys.find(
				(apiKey) => !existingKeys.has(apiKey.value),
			);

			if (newKey) {
				const tempApiPayload = [
					...prev.apiPayload,
					{
						key: newKey.value,
						value: newKey.value,
						testValue: '',
					},
				];

				// Also update the item count in a single state update
				setItems((prevItems) => prevItems + 1);

				return {
					...prev,
					apiPayload: tempApiPayload,
				};
			}

			return prev;
		});
	};

	const addCustomHeader = () => {
		if (headers >= 7) return;
		setHeaders((prevItems: number) => {
			const tempApiHeaders = [...formFields.apiHeaders];
			tempApiHeaders.push({ key: 'Authorization', value: '' });
			setFormFields((prev: any) => ({
				...prev,
				apiHeaders: tempApiHeaders,
			}));
			return prevItems + 1;
		});
	};
	const addCustomPayload = () => {
		if (customPayload >= 7) return;
		setCustomPayload((prevItems: number) => {
			const tempApiConstantDataFields = [...formFields.apiConstantDataFields];

			// Find the highest number used in existing "constant var#" keys
			const highestNumber = tempApiConstantDataFields.reduce((max, field) => {
				const match = field.key.match(/^constant var#(\d+)$/);
				if (match) {
					const num = parseInt(match[1], 10);
					return num > max ? num : max;
				}
				return max;
			}, 0);

			// Generate a new unique key
			const newKey = `constant var#${highestNumber + 1}`;

			tempApiConstantDataFields.push({
				key: newKey,
				value: '',
				testValue: '',
			});

			setFormFields((prev: any) => ({
				...prev,
				apiConstantDataFields: tempApiConstantDataFields,
			}));
			return prevItems + 1;
		});
	};
	useEffect(() => {
		if (formFields?.apiHeaders?.length > 0) {
			setHeaders(formFields?.apiHeaders?.length);
		}
	}, [formFields?.apiHeaders?.length]);
	useEffect(() => {
		if (formFields?.apiPayload?.length > 0) {
			setItems(formFields?.apiPayload?.length);
		}
	}, [formFields?.apiPayload?.length]);
	useEffect(() => {
		if (formFields?.apiConstantDataFields?.length > 0) {
			setCustomPayload(formFields?.apiConstantDataFields?.length);
		}
	}, [formFields?.apiConstantDataFields?.length]);
	return (
		<div>
			<InputText
				label="API Endpoint"
				required
				placeholder="https://api.intract.io/api/v1/endpoint"
				className={cn(
					formErrors['apiEndpoint'] ? 'border-destructive' : '',
					'mt-2',
				)}
				value={formFields?.apiEndpoint}
				error={formErrors['apiEndpoint']}
				errorText={formErrors['apiEndpoint']}
				setValue={(e) =>
					setFormFields((prev: any) => ({ ...prev, apiEndpoint: e }))
				}
			/>

			<div className="grid gap-4 sm:grid-cols-2 my-5">
				<div className="grid gap-2">
					<Label>API method</Label>
					<Select
						defaultValue={formFields?.apiMethod}
						onValueChange={(newVal) =>
							setFormFields((prev: any) => ({
								...prev,
								apiMethod: newVal,
							}))
						}
						disabled={
							formFields?.apiCredentialsType ===
							ApiCredentialTypes.GraphQl
						}
					>
						<SelectTrigger aria-label="Area">
							<SelectValue placeholder="Select" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={ApiMethod?.Post}>Post</SelectItem>
							<SelectItem value={ApiMethod?.Get}>Get</SelectItem>
						</SelectContent>
					</Select>
				</div>
				<div className="grid gap-2">
					<Label>Payload method</Label>
					<Select
						defaultValue={formFields?.apiPayloadMethod}
						onValueChange={(newVal) =>
							setFormFields((prev: any) => ({
								...prev,
								apiPayloadMethod: newVal,
							}))
						}
						disabled={
							formFields?.apiCredentialsType ===
							ApiCredentialTypes.GraphQl
						}
					>
						<SelectTrigger>
							<SelectValue placeholder="Select" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={DataPassingMethod?.QueryParams}>
								Query Params
							</SelectItem>
							<SelectItem value={DataPassingMethod?.Body}>
								Body
							</SelectItem>
						</SelectContent>
					</Select>
				</div>
			</div>

			{formFields?.apiCredentialsType === ApiCredentialTypes.GraphQl &&
			formFields?.graphQlQuery ? (
				<InputTextArea
					label="GraphQL query"
					value={formFields.graphQlQuery}
					setValue={(newVal) =>
						setFormFields((prev: any) => ({
							...prev,
							graphQlQuery: newVal,
						}))
					}
					placeholder="Enter GraphQL query"
					className="mb-5"
					inputClassName="min-h-[200px]"
				/>
			) : null}
			<div>
				<div className="flex justify-between items-center">
					<div className="text-sm font-medium">Payload</div>
					{items < ApiValueKeys.length ? (
						<div className="flex justify-end gap-1">
							<Button
								className=""
								variant="ghost"
								size="sm"
								onClick={() => addPayloadItem()}
							>
								+ Add Payload
							</Button>
						</div>
					) : null}
				</div>
				<div className=" mb-1 mt-2">
					{formFields?.apiPayload?.length > 0 ? (
						formFields?.apiPayload?.map((_item: any, index: number) => (
							<div key={index}>
								<PayloadItem setItems={setItems} index={index} />
								{formErrors[formFields?.apiPayload?.[index]?.key] ? (
									<p className="mb-0 pt-1 text-right text-xs text-destructive">
										{
											formErrors[
												formFields?.apiPayload?.[index]?.key
											]
										}
									</p>
								) : null}
							</div>
						))
					) : (
						<></>
					)}
				</div>
			</div>
			<div className="mt-6">
				<div className="flex flex-row items-start space-x-3">
					<Checkbox
						className="mt-1"
						checked={formFields?.isCustomConstantPayloadRequired}
						onCheckedChange={(checked) =>
							handleCustomPayload(checked as boolean)
						}
					/>
					<div className="flex items-center justify-between w-full">
						<div className="space-y-0.5 leading-none">
							<div className="text-sm font-medium">
								Constant values
							</div>
							<div className="text-xs">
								You can add these constants not already mentioned
								above
							</div>
						</div>
						{formFields?.isCustomConstantPayloadRequired &&
						customPayload < 7 ? (
							<div className="flex justify-end">
								<Button
									variant="ghost"
									size="sm"
									className="flex items-center gap-1"
									onClick={() => addCustomPayload()}
								>
									+ Add Constants
								</Button>
							</div>
						) : null}
					</div>
				</div>
				{formFields?.isCustomConstantPayloadRequired ? (
					<div className="mb-5">
						<div className="pl-7">
							{formFields?.apiConstantDataFields?.length > 0 ? (
								formFields?.apiConstantDataFields?.map(
									(_item: any, index: number) => (
										<div className="" key={index}>
											<CustomPayload
												items={customPayload}
												setItems={setCustomPayload}
												index={index}
											/>
											{formErrors[
												formFields?.apiConstantDataFields?.[
													index
												]?.key
											] ? (
												<p className="mb-0 pt-1 text-right text-xs text-destructive">
													{
														formErrors[
															formFields
																?.apiConstantDataFields?.[
																index
															]?.key
														]
													}
												</p>
											) : null}
										</div>
									),
								)
							) : (
								<></>
							)}
						</div>
					</div>
				) : null}
			</div>
			<div className="mt-6">
				<div className="flex flex-row items-start space-x-3 mt-2">
					<Checkbox
						className="mt-1"
						checked={formFields?.isHeaderRequired}
						onCheckedChange={(checked) =>
							handleCustomHeaders(checked as boolean)
						}
					/>
					<div className="flex items-start justify-between w-full">
						<div className="space-y-0.5 leading-none">
							<div className="text-sm font-medium">Custom Headers</div>
							<div className="text-xs">
								You can use headers for additional authentication or
								to access private APIs.
							</div>
						</div>
						{formFields?.isHeaderRequired && headers < 7 ? (
							<div className="flex justify-end">
								<Button
									variant="ghost"
									size="sm"
									className="flex items-center gap-1"
									onClick={() => addCustomHeader()}
								>
									+ Add Header
								</Button>
							</div>
						) : null}
					</div>
				</div>
				{formFields?.isHeaderRequired ? (
					<div className="">
						<div className="">
							{formFields?.apiHeaders?.length > 0 ? (
								formFields?.apiHeaders?.map(
									(_item: any, index: number) => (
										<div className="pl-7" key={index}>
											<CustomHeaders
												items={headers}
												setItems={setHeaders}
												index={index}
											/>
											{formErrors[
												formFields?.apiHeaders?.[index]?.key
											] ? (
												<p className="mb-0 pt-1 text-right text-xs text-destructive">
													{
														formErrors[
															formFields?.apiHeaders?.[
																index
															]?.key
														]
													}
												</p>
											) : null}
										</div>
									),
								)
							) : (
								<></>
							)}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PayloadDetails;
