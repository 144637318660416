import { v4 as uuid } from 'uuid';
import { ICampaignAddReward, ITier } from '../../types';
import { validateTiers } from '@/utils/validation';

const useTieredWinners = ({
	reward,
	setReward,
}: {
	reward: ICampaignAddReward;
	setReward: (reward: ICampaignAddReward) => void;
}) => {
	const tiers = reward?.tiers;

	const updateTierValues = () => {
		const updatedTiers = [...(tiers || [])];
		const lastIndex = updatedTiers.length - 1;

		updatedTiers[lastIndex] = {
			...updatedTiers[lastIndex],
			to: reward?.numRewards || null,
		};

		setReward({
			...reward,
			tiers: validateTiers({ reward, updatedTiers }),
		});
	};

	const handleTierUpdate = (index: number, field: keyof ITier, value: string) => {
		const updatedTiers = [...(tiers || [])];
		const parsedValue =
			value === '' ? null : !isNaN(Number(value)) ? parseInt(value, 10) : null;

		if (field === 'to' && index < updatedTiers.length - 1) {
			updatedTiers[index + 1] = {
				...updatedTiers[index + 1],
				from: parsedValue !== null ? parsedValue + 1 : null,
			};
		}

		updatedTiers[index] = {
			...updatedTiers[index],
			[field]: parsedValue,
		};

		setReward({
			...reward,
			tiers: validateTiers({ reward, updatedTiers }),
		});
	};

	const addTier = () => {
		const newTiers = [...(tiers || [])];
		const lastTier = newTiers[newTiers.length - 1];

		newTiers[newTiers.length - 1] = {
			...lastTier,
			to: null,
		};

		const updatedTiers = [
			...newTiers,
			{
				_id: newTiers.length + 1,
				from: null,
				to: reward?.numRewards || null,
				rewardPerUser: null,
				tierName: `Tier ${newTiers.length + 1}`,
				errors: {},
			},
		];

		setReward({
			...reward,
			tiers: validateTiers({ reward, updatedTiers }),
		});
	};

	const popTier = () => {
		const newTiers = [...(tiers || [])].slice(0, -1);
		const lastIndex = newTiers.length - 1;

		if (newTiers.length > 0) {
			newTiers[lastIndex] = {
				...newTiers[lastIndex],
				to: reward?.numRewards || null,
			};
		}

		setReward({
			...reward,
			tiers: validateTiers({ reward, updatedTiers: newTiers }),
		});
	};

	return {
		handleTierUpdate,
		addTier,
		popTier,
		updateTierValues,
	};
};

export default useTieredWinners;
