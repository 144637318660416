import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter } from '@/components/ui/dialog';
import en from '../../../../../../locales/en.json';
import { Media } from '@/components/element/media/Media';
import { asset } from '@/config/asset-cdn';
import { DataExportTypeEnum } from '@/features/intract-credits/types/credits.enums';

const ConfirmExportDialog = ({
	open,
	onClose,
	onSubmit,
	isPending,
	leaderboardExportCredits,
}: {
	open: DataExportTypeEnum;
	onClose: () => void;
	onSubmit: () => void;
	isPending: boolean;
	leaderboardExportCredits: number;
}) => {
	const { intractCredits } = en;
	const { applyConfirmation } = intractCredits.modals;

	const dataExportType =
		open === DataExportTypeEnum.Leaderboard
			? 'leaderboardExport'
			: 'winnersExport';

	return (
		<Dialog open={!!open} onOpenChange={onClose}>
			<DialogContent className="w-[32rem] p-6 overflow-y-visible">
				<div>
					<h3 className="text-lg font-medium mb-2 text-slate-700 flex items-center">
						{applyConfirmation?.[dataExportType]?.title}
						<span className="flex items-center gap-1 bg-slate-100 rounded-full ms-2">
							<Media
								src={asset['credit-icon-primary']}
								altText="credit-icon"
								className="size-[22px]"
							/>
							<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
								{leaderboardExportCredits}
							</span>
						</span>
						&nbsp;?
					</h3>
					<p className={'text-sm font-light text-slate-600 mb-0'}>
						{leaderboardExportCredits}&nbsp;
						{applyConfirmation?.[dataExportType]?.subtitle}
					</p>
				</div>
				<DialogFooter className="w-full mt-3">
					<Button variant="outline" onClick={onClose} disabled={isPending}>
						Cancel
					</Button>
					<Button onClick={onSubmit} disabled={isPending}>
						{isPending ? (
							<i className="bi-arrow-clockwise animate-spin me-1"></i>
						) : null}
						<span>Continue</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmExportDialog;
