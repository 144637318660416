import { DataTable } from '@/components/element/data-table/DataTable';
import useManageTeam from '../../hooks/useManageTeam';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';

const TeamsTable = ({ setOpen }) => {
	const { data, loading, teamColumns } = useManageTeam({ setOpen });

	if (loading) {
		return <FullPageLoading />;
	}
	return (
		<div className="mt-10">
			<div>
				<DataTable
					columns={teamColumns}
					data={data}
					isLoading={loading}
					totalCount={data.length}
				/>
			</div>
		</div>
	);
};

export default TeamsTable;
