import { defineChain } from 'viem';

export const humanode = defineChain({
	id: 5234,
	name: 'eHMND',
	nativeCurrency: { decimals: 18, name: 'eHMND', symbol: 'eHMND' },
	rpcUrls: {
		default: {
			http: ['https://explorer-rpc-http.mainnet.stages.humanode.io'],
			webSocket: ['https://explorer-rpc-http.mainnet.stages.humanode.io'],
		},
	},
	blockExplorers: {
		default: {
			name: 'eHMND',
			url: 'https://humanode.subscan.io',
		},
	},
	testnet: false,
});
