import FullScreenCreateLayout from '@/components/layout/full-screen-create/FullScreenCreateLayout';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { Status } from '@/features/campaigns/types';
import EnterpriseApprovalProcess from '@/features/dashboard/components/verify/EnterpriseApprovalProcess';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useRouter } from '@/hooks/useRouter';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from './CampaignCreateNavigation';
import Sections from './CampaignCreateSection';
import { links } from '@/config/links';

const CampaignCreateLayout = ({ children, section }: any) => {
	const { id } = useParams();
	const {
		details,
		isInitalising,
		showLaunch,
		setShowLaunch,
		rewards,
		setDetails,
		isUpdate,
		campaign,
	} = useCreateCampaign();
	const [showBestPractices, setShowBestPractices] = useState(true);
	const { query } = useRouter();

	const navigate = useNavigate();

	const { enterprise } = useGetEnterprise();

	const [showApprovalCompletionDialog, setShowApprovalCompletionDialog] =
		useState(false);

	useEffect(() => {
		if (query.id) {
			setShowBestPractices(false);
		}
	}, [query]);

	if (isInitalising) {
		return <FullPageLoading />;
	}
	return (
		<FullScreenCreateLayout showGrid={section === 'tasks'}>
			<FullScreenCreateLayout.Header>
				<FullScreenCreateLayout.Title
					name={details?.name}
					placeholder={'Untitled Quest'}
					status={Status.Draft}
					lastSaved={
						isUpdate && campaign?.updatedAt
							? new Date(campaign.updatedAt)
							: null
					}
					onFeedback={() => {
						window.open(links.feedbackForm, '_blank');
					}}
					setName={(e) => {
						setDetails((prev) => ({ ...prev, name: e }));
					}}
					onBack={() => navigate('/app/campaign/quests')}
					enterpriseCreditsBalance={enterprise?.credits?.balanceCredits}
				/>
				<FullScreenCreateLayout.Sections>
					<Sections />
				</FullScreenCreateLayout.Sections>
			</FullScreenCreateLayout.Header>
			<FullScreenCreateLayout.Content>
				{children}
			</FullScreenCreateLayout.Content>
			<FullScreenCreateLayout.Navigation>
				<Navigation
					enterprise={enterprise}
					setShowApprovalCompletionDialog={setShowApprovalCompletionDialog}
					showBestPractices={showBestPractices}
					setShowBestPractices={setShowBestPractices}
				/>
			</FullScreenCreateLayout.Navigation>
			<EnterpriseApprovalProcess
				open={showApprovalCompletionDialog}
				setOpen={setShowApprovalCompletionDialog}
				enterpriseDetails={enterprise}
			/>
		</FullScreenCreateLayout>
	);
};

export default CampaignCreateLayout;
