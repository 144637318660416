import { Button } from '@/components/ui/button';
import { useRewardStore } from '../hooks/useRewardStore';
import { cn } from '@/lib/utils';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import useCreateNewRewards from '../hooks/useCreateNewRewards';
import RewardCard from './RewardCard';
import DotsDropdown from '@/components/element/dropdowns/DotsDropdown';
import { useConfirm } from '@/hooks/useConfirm';
import Spinner from '@/components/element/loading/Spinner';
import { IReward } from '../types/reward-store.type';

const RewardSections = ({
	rewardData,
	setShowAddSection,
	setCreateNewReward,
	setSteps,
	steps,
}) => {
	const {
		formFields,
		setFormFields,
		resetForm,
		setOpen,
		deleteSection,
		moveRewardSection,
		isMoving,
		rewardStore,
		setSection,
		section,
		resetRewardForm,
	} = useRewardStore();
	const { updateReward } = useCreateNewRewards({
		resetForm,
		setOpen,
		setSteps,
		steps,
	});
	useLockBodyScroll(false);

	const { show } = useConfirm();

	const sectionIndex = rewardStore.findIndex(
		(section) => section._id === rewardData?._id,
	);

	const openDeleteConfirmation = async (id: string, name: string) => {
		const confirm = await show({
			title: `Are you sure you want to delete this section?`,
			subtitle:
				rewardData?.rewards?.length > 0
					? 'There are rewards in this section. First delete the rewards to delete the section.'
					: 'This action cannot be undone.',
			confirmText: rewardData?.rewards?.length > 0 ? 'Ok' : 'Delete',
			cancelText: 'Cancel',
		});
		if (!confirm) {
			return;
		}

		if (rewardData?.rewards?.length > 0) {
			return;
		}
		deleteSection(id);
	};

	const sectionMutations = [
		{
			type: 'item',
			label: 'Edit Section',
			onClick: () => {
				setFormFields({
					...formFields,
					sectionId: rewardData?._id,
				});
				setSection({
					...section,
					name: rewardData?.name,
					description: rewardData?.description,
				});
				setShowAddSection(true);
			},
		},
		{
			type: 'item',
			label: 'Delete Section',
			onClick: () => openDeleteConfirmation(rewardData?._id, rewardData?.name),
		},
	];

	return (
		<div>
			<div className="mb-6">
				<div className="flex justify-between">
					<div className="w-full">
						<div className="text-base font-medium ">
							{rewardData?.name}
						</div>

						<div className="text-xs ">{rewardData?.description}</div>
					</div>
					<div className="flex items-center">
						<div className="flex space-x-2 me-3">
							<Button
								variant="outline"
								className="h-8"
								onClick={() => {
									setSteps(1);
									resetRewardForm({ sectionId: rewardData?._id });
									setCreateNewReward(true);
								}}
							>
								<i className="bi-plus-circle me-2"></i>New Reward
							</Button>
							<Button
								size="icon"
								variant="outline"
								className={cn(
									'size-8',
									rewardData?.index === 0
										? 'cursor-not-allowed'
										: '',
								)}
								onClick={() =>
									moveRewardSection(rewardData?._id, 'up')
								}
								disabled={
									isMoving?.down ||
									isMoving?.up ||
									rewardData?.index === 0
								}
							>
								{isMoving?.up ? (
									<Spinner size="sm" />
								) : (
									<i className="bi-chevron-up"></i>
								)}
							</Button>
							<Button
								size="icon"
								variant="outline"
								className="size-8"
								disabled={
									isMoving?.down ||
									isMoving?.up ||
									sectionIndex === rewardStore?.length - 1
								}
								onClick={() =>
									moveRewardSection(rewardData?._id, 'down')
								}
							>
								{isMoving?.down ? (
									<Spinner size="sm" />
								) : (
									<i className="bi-chevron-down"></i>
								)}
							</Button>
						</div>
						<DotsDropdown options={sectionMutations} />
					</div>
				</div>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
				{rewardData?.rewards?.length > 0 ? (
					rewardData?.rewards?.map((reward: IReward, idx: number) => (
						<RewardCard
							data={reward}
							updateReward={updateReward}
							setShowCreateNewReward={setCreateNewReward}
							sectionId={rewardData?._id}
							rewardIndex={idx}
							rewardLength={rewardData?.rewards?.length}
						/>
					))
				) : (
					<div className=" text-sm text-muted-foreground py-2 border-l pl-3">
						No rewards found in this section
					</div>
				)}
			</div>
		</div>
	);
};

export default RewardSections;
