import { useState } from 'react';
import {
	addEnterpriseUser,
	cancelInvite,
	deleteEnterpriseUser,
	getTeamData,
	resendInvite as resendInviteApi,
	updateRole,
} from '../services/manage-team';
import { AccountRoles, roleMap } from '../types/team.enums';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { Badge } from '@/components/ui/badge';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { formatDate } from '@/utils/parsers';
import { ToastAction } from '@/components/ui/toast';
import { useConfirm } from '@/hooks/useConfirm';
import { toast } from 'sonner';
import { useAuth } from '@/hooks/useAuth';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import InputSelect from '@/components/element/inputs/InputSelect';

export interface ITeam {
	fname: string;
	lname: string;
	email: string;
	role: string;
	createdAt: string;
	name: string;
	isInvited?: boolean;
	_id?: string;
}
const initialValues = {
	fname: '',
	lname: '',
	email: '',
	role: '',
	createdAt: '',
	name: '',
	isInvited: false,
};

const useManageTeam = ({ setOpen }) => {
	const [teamData, setTeamData] = useState<ITeam>();
	const [formErrors, setFormErrors] = useState<any>({});
	const [openAlert, setOpenAlert] = useState(false);
	const [loading, setLoading] = useState(false);

	const { show } = useConfirm();
	const { user } = useAuth();

	const teamQuery = useQuery({
		queryKey: ['team'],
		queryFn: getTeamData,
	});

	const resendInvite = async (id: string, name: string) => {
		try {
			await resendInviteApi(id);
			toast.success(`Invitation resent successfully to: ${name}`);
		} catch (err) {
			toast.error(err.message);
		}
	};
	const openDeleteConfirmation = async (
		id: string,
		isInvite: boolean,
		name: string,
	) => {
		const confirm = await show({
			title: isInvite ? 'Delete Invite' : 'Delete User',
			subtitle: isInvite
				? `Are you sure you want to delete this invite for ${name.trim()}?`
				: `Are you sure you want to delete this user (${name.trim()})?`,
			confirmText: 'Delete',
			cancelText: 'Cancel',
		});
		if (!confirm) {
			return;
		}

		deleteInvite(id, isInvite, name);
	};
	const deleteInvite = async (id: string, isInvite: boolean, name: string) => {
		try {
			setLoading(true);
			if (isInvite) {
				await cancelInvite(id);
				toast.success(`Invite deleted successfully for: ${name}`);
			} else {
				await deleteEnterpriseUser(id);
				toast.success('User deleted successfully');
			}
			queryClient.invalidateQueries({ queryKey: ['team'] });
		} catch (err) {
			if (err?.response?.data?.code === 'UNAUTHORIZED') {
				toast.error(`User can't be deleted as this is an admin account`);
			} else {
				handleErrorMessage(err);
			}
		} finally {
			setLoading(false);
		}
	};
	const resetForm = () => {
		setTeamData(initialValues);
		setFormErrors({});
		setOpen(false);
	};
	const verifyFormFields = () => {
		const error: any = {};
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		if (!teamData?.fname?.trim()) {
			error.fname = 'First name is required';
		}
		if (!teamData?.email?.trim()) {
			error.email = 'Email is required';
		}
		if (teamData?.email && !emailRegex.test(teamData?.email)) {
			error.email = 'Please enter a valid email address';
		}
		if (!teamData?.role?.trim()) {
			error.role = 'Role is required';
		}
		setFormErrors(error);
		return Object.keys(error).length === 0;
	};

	const updateRoleMutation = useMutation({
		mutationFn: (data: { userId: string; role: AccountRoles }) =>
			updateRole(data.userId, data.role),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['team'] });
			toast.success('Role updated successfully');
		},
		onError: (err) => {
			handleErrorMessage(err);
		},
	});

	const teamColumns: ColumnDef<ITeam>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className="px-1"
				/>
			),
			cell: ({ row }) => (
				<div className="py-1 px-1">
					{row?.original?.name}
					{row?.original?.isInvited ? (
						<Badge variant="secondary" className="text-xs ml-2">
							Invite sent
						</Badge>
					) : null}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'email',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Email" />
			),
			cell: ({ row }) => (
				<div className="min-w-[100px]">{row?.original?.email}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'role',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Role" />
			),
			cell: ({ row }) => {
				return (
					<InputSelect
						value={row?.original?.role?.toUpperCase()}
						options={Object.keys(roleMap).map((key) => ({
							label: roleMap[key],
							value: key?.toUpperCase(),
						}))}
						setValue={(newVal: AccountRoles) =>
							updateRoleMutation.mutateAsync({
								userId: row?.original?._id,
								role: newVal,
							})
						}
						className="flex items-center"
						disabled={updateRoleMutation.isPending}
					/>
				);
			},
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'createdAt',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Created On" />
			),
			cell: ({ row }) => <div>{formatDate(row?.original?.createdAt)}</div>,
			enableSorting: true,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => (
				<div className="space-x-2">
					{row?.original?.isInvited ? (
						<TooltipProvider delayDuration={0}>
							<Tooltip>
								<TooltipTrigger asChild>
									<Button
										size="icon"
										variant="secondary"
										onClick={() =>
											resendInvite(
												row?.original?._id,
												row?.original?.name,
											)
										}
									>
										<i className="bi bi-envelope"></i>
									</Button>
								</TooltipTrigger>
								<TooltipContent>
									<p>Resend Invite</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					) : null}

					{(row?.original?.isInvited &&
						(user.role === AccountRoles.Owner ||
							user.role === AccountRoles.Admin)) ||
					user.role === AccountRoles.Owner ? (
						<TooltipProvider delayDuration={0}>
							<Tooltip>
								<TooltipTrigger asChild>
									<Button
										size="icon"
										variant="secondary"
										onClick={() =>
											openDeleteConfirmation(
												row?.original?._id,
												row?.original?.isInvited,
												row?.original?.name,
											)
										}
									>
										<i className="bi bi-trash"></i>
									</Button>
								</TooltipTrigger>
								<TooltipContent>
									<p>
										{row?.original?.isInvited
											? 'Delete Invite'
											: 'Remove User'}
									</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					) : null}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
	];
	const handleAddTeamMember = async () => {
		try {
			if (!verifyFormFields()) return;

			setLoading(true);
			const newUser = await addEnterpriseUser({
				firstName: teamData?.fname?.trim(),
				lastName: teamData?.lname?.trim(),
				email: teamData?.email?.trim().toLowerCase(),
				role: teamData?.role,
			});

			toast.success(
				'Invite sent successfully, we have sent an email to the user with the login details',
			);

			const prevUsers: any = queryClient.getQueryData(['team']) || [];
			queryClient.setQueryData(['team'], {
				...prevUsers,
				invitations: [...prevUsers.invitations, newUser],
			});
			setTeamData(initialValues);
			setOpen(false);
		} catch (err) {
			console.log(err);
			if (err?.response?.data?.code === 'USER_ALREADY_EXISTS') {
				toast.error('User already exists');
			} else {
				handleErrorMessage(err);
			}
		} finally {
			setLoading(false);
		}
	};

	return {
		teamData,
		setTeamData,
		handleAddTeamMember,
		formErrors,
		setFormErrors,
		data:
			[
				...(teamQuery.data?.accounts?.map((i: any) => ({
					name: `${i.firstName} ${i.lastName ? i.lastName : ''}`,
					...i,
					role: roleMap[i.role],
				})) ?? []),
				...(teamQuery.data?.invitations?.map((i: any) => ({
					name: `${i.firstName} ${i.lastName ? i.lastName : ''}`,
					...i,
					role: roleMap[i.role],
					isInvited: true,
				})) ?? []),
			] || [],
		loading: teamQuery.isLoading || loading,
		resetForm,
		resendInvite,
		deleteInvite,
		teamColumns,
	};
};

export default useManageTeam;
