import { axios } from '@/lib/axios';
import {
	CreditRequestStatusEnum,
	CreditRequestTypeEnum,
	CreditRequestValueEnum,
} from '../types/credits.enums';

export const getCreditBenefits = async () => {
	const response = await axios.get('/credits/benefits');
	return response.data;
};

export const getCreditHistory = async () => {
	const response = await axios.get('/credits/history');
	return response.data;
};

export const creditRequests = async ({
	search,
	filters,
	page,
	limit,
}: {
	search: {
		value: string;
	};
	filters: any;
	page: number;
	limit: number;
}) => {
	const response = await axios.post(`/credits/requests-search`, {
		search,
		filters,
		page,
		limit,
	});
	return response.data;
};

export const createRequest = async ({
	requestType,
	details,
}: {
	requestType: CreditRequestTypeEnum;
	details?: {
		key: CreditRequestValueEnum;
		value: string;
	}[];
}) => {
	const response = await axios.post('/credits/requests', {
		requestType,
		details,
	});
	return response.data;
};
