export enum TokenSymbol {
	USDT = 'USDT',
	USDC = 'USDC',
}
export enum RewardType {
	Xp = 'XP',
	Gems = 'GEMS',
	TokenUSDT = 'USDT_TOKEN',
	TokenUSDC = 'USDC_TOKEN',
}
export enum EventTypes {
	Signup = 'SIGNUP',
	FirstQuestCreated = 'FIRST_QUEST_CREATED',
	TelegramGroupCreated = 'TELEGRAM_GROUP_CREATED',
	Other = 'OTHER',
}

export const RewardTypeOptions = [
	{
		value: RewardType.Xp,
		label: 'XPs',
	},
	// {
	// 	value: RewardType.Gems,
	// 	label: 'Gems',
	// },
	{
		value: RewardType.TokenUSDC,
		label: 'USDC Token',
	},
	{
		value: RewardType.TokenUSDT,
		label: 'USDT Token',
	},
];

export const RewardTokenOptions = [
	{
		value: TokenSymbol.USDT,
		label: 'USDT',
	},
	{
		value: TokenSymbol.USDC,
		label: 'USDC',
	},
];
