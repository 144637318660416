import { useEffect, useMemo, useState } from 'react';
import { Label } from '@/components/ui/label';
import Durationheader from './DurationHeader';
import moment from 'moment-timezone';
import { SelectTimeStamp } from './SelectTimeStamp';

const InputDuration = ({
	className,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	startImmediately,
	setStartImmediately,
	noDeadline,
	setNoDeadline,
	timezone,
	setTimezone,
	disableStartDate,
	disableEndTime,
	stopImmediately,
	setStopImmediately,
}: {
	className: string;
	startDate: Date;
	endDate: Date;
	setStartDate: (value: Date) => void;
	setEndDate: (value: Date) => void;
	startImmediately: boolean;
	setStartImmediately: (value: boolean) => void;
	noDeadline?: boolean;
	setNoDeadline?: (value: boolean) => void;
	timezone: string;
	setTimezone: (value: string) => void;
	disableStartDate?: boolean;
	disableEndTime?: boolean;
	stopImmediately?: boolean;
	setStopImmediately?: (value: boolean) => void;
}) => {
	const minStartTimestamp = useMemo(() => {
		if (startDate) {
			if (
				moment.tz(startDate, timezone).format('Do MMM YYYY') ===
				moment.tz(new Date(), timezone).format('Do MMM YYYY')
			) {
				return moment.tz(new Date(), timezone).format('hh:mm a');
			}
		}
		return '';
	}, [startDate, timezone]);

	const minEndTimestamp = useMemo(() => {
		if (startImmediately) {
			if (
				moment.tz(endDate, timezone).format('Do MMM YYYY') ===
				moment.tz(new Date(), timezone).format('Do MMM YYYY')
			) {
				return moment.tz(new Date(), timezone).format('hh:mm a');
			}
		} else {
			if (
				moment.tz(startDate, timezone).format('Do MMM YYYY') ===
				moment.tz(endDate, timezone).format('Do MMM YYYY')
			) {
				return moment.tz(startDate, timezone).format('hh:mm a');
			}
		}
	}, [startImmediately, endDate, timezone, startDate]);

	return (
		<div className={className}>
			<div className="mt-4 flex items-center">
				<Label className="font-normal me-2 min-w-[100px]">Start Date</Label>
				<div className="flex-grow">
					<SelectTimeStamp
						isStart={true}
						isContinous={startImmediately}
						setIsContinous={setStartImmediately}
						value={startDate}
						setValue={setStartDate}
						toDate={endDate ? endDate : undefined}
						fromDate={new Date()}
						timezone={timezone}
						minTimestamp={minStartTimestamp}
						disableStartDate={disableStartDate}
					/>
				</div>
			</div>
			<div className="mt-5 flex items-center">
				<Label className="font-normal me-2 min-w-[100px]">End Date</Label>
				<div className="flex-grow">
					<SelectTimeStamp
						isStart={false}
						isContinous={disableEndTime ? stopImmediately : noDeadline}
						setIsContinous={
							disableEndTime ? setStopImmediately : setNoDeadline
						}
						value={endDate}
						setValue={setEndDate}
						fromDate={startDate ? startDate : new Date()}
						timezone={timezone}
						minTimestamp={minEndTimestamp}
						stopImmediately={stopImmediately}
						disableEndTime={disableEndTime}
					/>
				</div>
			</div>
			{disableEndTime ? null : (
				<Durationheader timezone={timezone} setTimezone={setTimezone} />
			)}
		</div>
	);
};

export default InputDuration;
