import { useState } from 'react';
import { PeggedToken } from '../../types/dave.enum';
import { useMutation } from '@tanstack/react-query';
import { createCustomToken } from '../../services/dave.service';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { uploadFile } from '@/services/utility.service';
import { toast } from 'sonner';

interface CustomError extends Error {
	response?: {
		data?: {
			message?: string;
		};
	};
}

export interface IAddCustomToken {
	chainId: number;
	tokenSymbol: string;
	tokenAddress: string;
	tokenIcon: string;
	tokenImageFile: File;
	peggedTo: PeggedToken;
	imagePosition?: {
		x: number;
		y: number;
	};
}

const initialValue: IAddCustomToken = {
	chainId: 0,
	tokenSymbol: '',
	tokenAddress: '',
	tokenIcon: '',
	tokenImageFile: null,
	peggedTo: PeggedToken.ETH,
	imagePosition: {
		x: 0,
		y: 0,
	},
};
const useAddCustomToken = ({ setShow }) => {
	const [formFields, setFormFields] = useState<IAddCustomToken>(initialValue);
	const [formErrors, setFormErrors] = useState<any>({});

	const resetForm = () => {
		setFormFields(initialValue);
		setFormErrors({});
		setShow(false);
	};

	const addTokenMutation = useMutation({
		mutationFn: createCustomToken,
		onSuccess: async () => {
			queryClient.invalidateQueries({
				queryKey: ['dave', 'supported-tokens', formFields.chainId],
			});
			resetForm();
		},
		onError: (error) => {
			const customError = error as CustomError;
			handleErrorMessage(
				`${customError?.response?.data?.message || 'An unexpected error occurred'}. Please check the token address and try again.`,
			);
		},
	});
	const validateForm = () => {
		const errors: any = {};
		if (!formFields.tokenSymbol || !formFields.tokenSymbol.trim()) {
			errors.tokenSymbol = 'Token symbol is required';
		}
		if (!formFields.tokenAddress || !formFields.tokenAddress.trim()) {
			errors.tokenAddress = 'Token address is required';
		}
		if (!formFields.tokenIcon || !formFields.tokenImageFile) {
			errors.tokenIcon = 'Token icon is required';
		}
		if (!formFields.chainId) {
			errors.chainId = 'Chain ID is required';
		}
		if (!formFields.peggedTo) {
			errors.peggedTo = 'This token should be pegged to ETH, USD or BTC';
		}
		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};

	const addNewCustomToken = () => {
		if (!validateForm()) return;

		if (formFields.tokenImageFile && formFields.tokenIcon.includes('blob:')) {
			toast.promise(
				Promise.all([
					uploadFile(formFields.tokenImageFile).then((res) => {
						setFormFields((prev) => {
							return {
								...prev,
								tokenIcon: res,
							};
						});
						addTokenMutation.mutateAsync({
							...formFields,
							tokenIcon: res,
						});
					}),
				]),
				{
					loading: 'Uploading token icon...',
				},
			);
		} else {
			addTokenMutation.mutateAsync({
				...formFields,
			});
		}
	};

	return {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		resetForm,
		addNewCustomToken,
		isInProgress: addTokenMutation.isPending,
	};
};

export default useAddCustomToken;
