import StatusBadge from '@/components/element/badges/StatusBadge';
import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import { Status } from '@/features/campaigns/types';

const CampaignFilters = ({
	search,
	filters,
	setSearch,
	setFilters,
}: {
	search: string;
	filters: {
		campaignStatus: string[];
		publishStatus: boolean;
		activeButNotPublished: boolean;
	};
	setSearch: any;
	setFilters: any;
}) => {
	return (
		<div className="flex items-center mt-10 space-x-4">
			<InputText
				value={search}
				setValue={setSearch as any}
				prepend={
					<i className="bi-search px-3 text-muted-foreground text-sm"></i>
				}
				placeholder="Search by project name or email"
				className="w-[250px] lg:w-[350px]"
			/>
			<FacetedFilter
				title="Approval Status"
				options={CampaignStatus}
				selectedValues={filters.campaignStatus}
				setSelectedValues={(values: any) =>
					setFilters((p) => ({ ...p, campaignStatus: values }))
				}
			/>

			<Button
				onClick={() => {
					setFilters((p: any) => ({
						...p,
						publishStatus: !p.publishStatus,
					}));
				}}
				className="h-9 border-dashed shadow-none"
				variant={filters.publishStatus ? 'default' : 'outline'}
			>
				+ Published Campaigns
			</Button>
			<Button
				onClick={() => {
					setFilters((p: any) => ({
						...p,
						activeButNotPublished: !p.activeButNotPublished,
					}));
				}}
				className="h-9 border-dashed shadow-none"
				variant={filters.activeButNotPublished ? 'default' : 'outline'}
			>
				+ Active but not published
			</Button>
		</div>
	);
};

export default CampaignFilters;

const CampaignStatus = [
	{
		value: Status.Active,
		label: <StatusBadge status={Status.Active} className="text-sm" />,
	},
	{
		value: Status.InReview,
		label: <StatusBadge status={Status.InReview} className="text-sm" />,
	},
	{
		value: Status.Completed,
		label: <StatusBadge status={Status.Completed} className="text-sm" />,
	},
	{
		value: Status.Draft,
		label: <StatusBadge status={Status.Draft} className="text-sm" />,
	},
	{
		value: Status.Scheduled,
		label: <StatusBadge status={Status.Scheduled} className="text-sm" />,
	},
	{
		value: Status.Paused,
		label: <StatusBadge status={Status.Paused} className="text-sm" />,
	},
];

const OnboardingStatus = [
	{ value: 'published', label: 'Published' },
	{ value: 'not-published', label: 'Not Published' },
];
