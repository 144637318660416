import { useAuth } from '@/hooks/useAuth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { registerWithEmailAndPassword } from '../services/auth.service';

import { API_URL } from '@/config';
import { useRouter } from '@/hooks/useRouter';
import analytics from '@/lib/analytics';
import { getUtmUtil, removeUtmParams } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import * as z from 'zod';

const userAuthSchema = z.object({
	name: z
		.string()
		.min(2, { message: 'Name must be at least 2 characters long' })
		.optional(),
	email: z.string().email({ message: 'Invalid email address' }),
	password: z
		.string()
		.min(8, { message: 'Password must be at least 8 characters long' }),
	referralCode: z.string().optional(),
});

type FormData = z.infer<typeof userAuthSchema>;

export const useRegister = () => {
	const { query } = useRouter();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: zodResolver(userAuthSchema),
		defaultValues: {
			referralCode: query.referralCode as string,
		},
	});
	const { login } = useAuth();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDiscordLoading, setIsDiscordLoading] = useState(false);
	const [isGoogleLoading, setIsGoogleLoading] = useState(false);

	async function onSubmit(data: FormData) {
		setIsLoading(true);
		try {
			const params = await getUtmUtil();
			const user = await registerWithEmailAndPassword({
				name: data?.name || data.email?.split('@')[0],
				email: data.email.toLowerCase(),
				password: data.password,
				referralCode: data.referralCode,
				...params,
			});
			analytics.track(TrackingEvents.Signup, {
				loginMethod: 'email',
				email: data.email,
				userId: user?._id,
			});
			// analytics.register(user?._id, {});
			await login(data.email, data.password);
			setIsLoading(false);
			removeUtmParams();
		} catch (err) {
			console.log(err);
			setIsLoading(false);
			handleErrorMessage(err);
		}
	}

	async function loginWithDiscord() {
		setIsDiscordLoading(true);
		window.location.href = `${API_URL}/api/v1/auth/discord?referralCode=${query.referralCode}`;
	}

	async function loginWithGoogle() {
		setIsGoogleLoading(true);
		window.location.href = `${API_URL}/api/v1/auth/google?referralCode=${query.referralCode}`;
	}

	return {
		handleSubmit,
		register,
		errors,
		isLoading,
		onSubmit,
		loginWithDiscord,
		loginWithGoogle,
		isDiscordLoading,
		isGoogleLoading,
	};
};
