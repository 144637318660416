import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { asset } from '@/config/asset-cdn';
import { updateTriggerFlags } from '@/features/auth/services/auth.service';
import { useRouter } from '@/hooks/useRouter';
import { handleErrorMessage } from '@/utils/notifications';
import { useEffect, useMemo, useState } from 'react';
import en from '../../../locales/en.json';
import { Media } from '../media/Media';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { UserType } from '@/providers/AuthProvider';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';

type TriggerFlag =
	| 'showSignupCreditsModal'
	| 'showFirstLaunchCampaignModal'
	| 'showClaimCreditsModal';

const CreditsRewarded = ({
	open,
	setOpen,
	triggerFlags,
	refetchUser,
	user,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	triggerFlags: {
		showSignupCreditsModal: boolean;
		showFirstLaunchCampaignModal: boolean;
		showClaimCreditsModal: boolean;
	};
	refetchUser: () => void;
	user: UserType;
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { refetch: refetchEnterprise } = useGetEnterprise();

	const _triggerFlag: TriggerFlag = useMemo(() => {
		if (!triggerFlags) return null;

		if (triggerFlags.showClaimCreditsModal) return 'showClaimCreditsModal';
		if (triggerFlags.showFirstLaunchCampaignModal)
			return 'showFirstLaunchCampaignModal';
		if (triggerFlags.showSignupCreditsModal) return 'showSignupCreditsModal';

		return null;
	}, [triggerFlags]);

	// Close modal if there's no valid trigger flag
	useEffect(() => {
		if (!_triggerFlag && open) {
			setOpen(false);
		}
	}, [_triggerFlag, open, setOpen]);

	const { intractCredits } = en;
	const creditsRewarded = intractCredits?.modals[_triggerFlag];

	const { navigate } = useRouter();

	const handleClose = async () => {
		setIsSubmitting(true);
		try {
			await updateTriggerFlags({
				flags: {
					credits: {
						[_triggerFlag]: false,
					},
				},
			});
			refetchUser();
			refetchEnterprise();
		} catch (error) {
			console.error(error);
			handleErrorMessage('Error while updating trigger flags');
		} finally {
			setIsSubmitting(false);
			setOpen(false);
		}
	};

	useEffect(() => {
		analytics.track(TrackingEvents.CreditsRewardedViewed, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	}, []);

	if (!_triggerFlag || !creditsRewarded) return null;

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-[500px]">
				<Media
					src={asset['credit-icon-primary']}
					altText="credit-icon"
					className="mb-2 size-24"
				/>
				<DialogHeader>
					<DialogTitle className="text-lg leading-6 font-medium text-slate-700">
						{creditsRewarded?.title}
					</DialogTitle>
					<DialogDescription className="text-sm leading-5 text-slate-500">
						{creditsRewarded?.subtitle}
					</DialogDescription>
				</DialogHeader>
				<DialogFooter className="mt-1 w-full">
					<Button
						variant="outline"
						onClick={() => handleClose()}
						disabled={isSubmitting}
					>
						{creditsRewarded?.buttons?.ok}
					</Button>
					<Button
						onClick={async () => {
							await handleClose();
							navigate('/app/credits?openNux=true');
							analytics.track(TrackingEvents.GoToCreditsClicked, {
								userId: user?._id,
								enterpriseId: user?.enterpriseId,
							});
						}}
						disabled={isSubmitting}
					>
						{creditsRewarded?.buttons?.goToCredits}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CreditsRewarded;
