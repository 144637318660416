import React from 'react';
import { Button } from '@/components/ui/button';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import {
	ChevronLeft,
	ChevronRight,
	ChevronsLeft,
	ChevronsRight,
} from 'lucide-react';

interface PaginationComponentProps {
	currentPage: number;
	totalPages: number;
	totalCount: number;
	pageSize: number;
	onPageChange: (page: number) => void;
	onPageSizeChange: (pageSize: number) => void;
	pageSizeOptions: number[];
	isLoading: boolean;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
	currentPage,
	totalPages,
	totalCount,
	pageSize,
	onPageChange,
	onPageSizeChange,
	pageSizeOptions,
	isLoading,
}) => {
	if (totalCount === 0) {
		return null; // Don't render pagination if there's no data
	}
	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center space-x-2">
				<p className="text-sm font-medium">Data per page</p>
				<Select
					value={pageSize.toString()}
					onValueChange={(value) => onPageSizeChange(Number(value))}
					disabled={isLoading}
				>
					<SelectTrigger className="h-8 w-[70px]">
						<SelectValue placeholder={pageSize} />
					</SelectTrigger>
					<SelectContent side="top">
						{pageSizeOptions.map((size) => (
							<SelectItem key={size} value={size.toString()}>
								{size}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>

			<div className="flex items-center space-x-2">
				<Button
					variant="outline"
					size="icon"
					onClick={() => onPageChange(1)}
					disabled={currentPage === 1 || isLoading}
				>
					<ChevronsLeft className="h-4 w-4" />
				</Button>
				<Button
					variant="outline"
					size="icon"
					onClick={() => onPageChange(currentPage - 1)}
					disabled={currentPage === 1 || isLoading}
				>
					<ChevronLeft className="h-4 w-4" />
				</Button>

				<span className="text-sm font-medium">
					Page {currentPage} of {totalCount}
				</span>

				<Button
					variant="outline"
					size="icon"
					onClick={() => onPageChange(currentPage + 1)}
					disabled={currentPage === totalCount || isLoading}
				>
					<ChevronRight className="h-4 w-4" />
				</Button>
				<Button
					variant="outline"
					size="icon"
					onClick={() => onPageChange(totalCount)}
					disabled={currentPage === totalCount || isLoading}
				>
					<ChevronsRight className="h-4 w-4" />
				</Button>
			</div>
		</div>
	);
};

export default PaginationComponent;
