import CreditsRewarded from '@/components/element/dialog/CreditsRewarded';
import GlobalLayout from '@/components/layout/GlobalLayout';
import { AppError } from '@/components/layout/error/SentryErrorFallback';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import CampaignRoutes from '@/features/campaigns/routes/campaigns.route';
import CommunitySettingRoutes from '@/features/community-settings/routes/community-settings.route';
import ImportedContracts from '@/features/customise-hub/routes/imported-contracts.page';
import ImportedTokens from '@/features/customise-hub/routes/imported-tokens.page';
import DashboardLayout from '@/features/dashboard/components/layout/DashboardLayout';
import DaveRoutes from '@/features/dave/routes/dave.route';
import EventsRoutes from '@/features/events/routes/events.route';
import GettingStartedPage from '@/features/getting-started/pages/GettingStartedPage';
import GettingStartedRoutes from '@/features/getting-started/routes/getting-started.route';
import IntegrationRoutes from '@/features/integrations/routes/integrations.route';
import IntractCreditsRoutes from '@/features/intract-credits/routes/credits.route';
import KOLMarketplaceRoutes from '@/features/kol-marketplace/routes/kol-marketplace.route';
import LeaderboardRoutes from '@/features/leaderboard/routes/leaderboard.route';
import LoyaltyCampaignsRoute from '@/features/loyalty-campaigns/routes/loyalty-campaigns.route';
import NFTContractRoutes from '@/features/nft-contracts/routes/nft-contracts.route';
import { PartnershipsRoutes } from '@/features/partnerships/routes/partnerships.route';
import ExternalAPIs from '@/features/profile/routes/external-apis.page';
import ProfileRoutes from '@/features/profile/routes/profile.route';
import RewardStoreRoute from '@/features/reward-store/routes/reward-store.route';
import TaskAPiRoutes from '@/features/task-apis/routes/task-apis.route';
import { useAuth } from '@/hooks/useAuth';
import { useRouter } from '@/hooks/useRouter';
import { SearchProvider } from '@/providers/SearchProvider';
import * as Sentry from '@sentry/react';
import { Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function App() {
	const [open, setOpen] = useState(false);

	const location = useLocation();
	const { user, refetchUser } = useAuth();
	const { query, setQueryParam, navigate } = useRouter();

	useEffect(() => {
		const triggerFlags = user?.triggerFlags;
		if (triggerFlags?.credits && typeof triggerFlags?.credits === 'object') {
			const shouldOpen =
				triggerFlags?.credits &&
				Object.values(triggerFlags?.credits).some((value) => value === true);
			setOpen(shouldOpen);
		}
	}, [user?.triggerFlags]);

	if (location.pathname.includes('new') || location.pathname.includes('update')) {
		return (
			<Sentry.ErrorBoundary fallback={AppError} showDialog>
				<Suspense fallback={<FullPageLoading />}>
					<Outlet />
				</Suspense>
			</Sentry.ErrorBoundary>
		);
	}
	return (
		<Sentry.ErrorBoundary fallback={AppError} showDialog>
			<SearchProvider>
				<DashboardLayout>
					<>
						<Suspense fallback={<FullPageLoading />}>
							<Outlet />
						</Suspense>
						<CreditsRewarded
							open={open}
							setOpen={setOpen}
							triggerFlags={{ ...user?.triggerFlags?.credits }}
							refetchUser={refetchUser}
							user={user}
						/>
					</>
				</DashboardLayout>
			</SearchProvider>
		</Sentry.ErrorBoundary>
	);
}

export const protectedRoutes = [
	{
		path: '/app',
		element: (
			<GlobalLayout>
				<App />
			</GlobalLayout>
		),
		children: [
			{
				path: '',
				element: <Navigate_ />,
			},
			{
				path: 'leaderboard/*',
				element: <LeaderboardRoutes />,
			},
			{
				path: 'campaign/quests/*',
				element: <CampaignRoutes />,
			},
			{
				path: 'campaign/loyalty/*',
				element: <LoyaltyCampaignsRoute />,
			},
			{
				path: 'campaign/events/*',
				element: <EventsRoutes />,
			},
			{
				path: 'tools/task-apis/*',
				element: <TaskAPiRoutes />,
			},
			{
				path: 'tools/your-contracts/nfts/*',
				element: <NFTContractRoutes />,
			},
			{
				path: 'tools/your-contracts/imported-contracts/*',
				element: <ImportedContracts />,
			},
			{
				path: 'tools/your-contracts/imported-tokens/*',
				element: <ImportedTokens />,
			},
			{
				path: 'tools/dave/*',
				element: <DaveRoutes />,
			},
			{
				path: 'tools/auth-tokens/*',
				element: <ExternalAPIs />,
			},
			{
				path: 'integrations/*',
				element: <IntegrationRoutes />,
			},
			{
				path: 'profile/*',
				element: <ProfileRoutes />,
			},
			{
				path: 'boosters/kol-marketplace/*',
				element: <KOLMarketplaceRoutes />,
			},
			{
				path: 'boosters/partnerships/*',
				element: <PartnershipsRoutes />,
			},
			{
				path: 'campaign/reward-store/*',
				element: <RewardStoreRoute />,
			},
			{
				path: 'home',
				element: <GettingStartedRoutes />,
			},
			{
				path: 'getting-started',
				element: <GettingStartedPage />,
			},
			{
				path: 'community-settings/*',
				element: <CommunitySettingRoutes />,
			},
			{
				path: 'credits/*',
				element: <IntractCreditsRoutes />,
			},
			{
				path: '*',
				element: <Navigate_ />,
			},
		],
	},
	{
		path: '*',
		element: <Navigate_ />,
	},
];

function Navigate_() {
	const location = useLocation();
	const searchParams = location.search;
	return <Navigate to={`/app/home${searchParams}`} />;
}
