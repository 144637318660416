import { DataTable } from '@/components/element/data-table/DataTable';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect } from 'react';
import useGetCreditsRequests from '../../hooks/useGetCreditsRequests';
import {
	CreditRequestBaseTypeEnum,
	CreditRequestStatusEnum,
	CreditRequestTypeEnum,
} from '../../types/credits.enums';
import { Media } from '@/components/element/media/Media';
import { asset } from '@/config/asset-cdn';
import { convertToTitleCase } from '@/utils/parsers';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import StatusBadge from '@/components/element/badges/StatusBadge';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export interface ICreditsHistory {
	_id: string;
	requestType: CreditRequestTypeEnum;
	status: CreditRequestStatusEnum;
	creditsBalance: number;
	userEmail: string;
	baseType: CreditRequestBaseTypeEnum;
	lockedCredits: number;
	consumedCredits: number;
	createdAt: Date;
	userDetails: {
		avatar?: string;
	};
	rejectedReason?: string;
}

const HistorySection = ({ user }) => {
	const { creditHistory, isLoading, totalCount, pagination, setPagination } =
		useGetCreditsRequests();

	useEffect(() => {
		analytics.track(TrackingEvents.CreditsHistoryViewed, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	}, [user]);

	return (
		<div>
			<DataTable
				data={creditHistory}
				columns={creditHistoryColumns}
				totalCount={totalCount}
				isLoading={isLoading}
				isServerSide={true}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</div>
	);
};

export default HistorySection;

const creditHistoryColumns: ColumnDef<ICreditsHistory>[] = [
	{
		accessorKey: 'userEmail',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Team member" />
		),
		cell: ({ row }) => {
			const userDetails = row.original?.userDetails;
			const userEmail = row.original?.userEmail;
			return (
				<div className="p-1 max-w-[30rem] truncate text-slate-700 text-sm font-normal flex items-center">
					<Avatar className="size-6 me-1 border-[0.5px] rounded-full">
						<AvatarImage
							src={userDetails?.avatar}
							alt={userEmail?.split('@')[0]}
						/>
						<AvatarFallback name={userEmail?.split('@')[0]} />
					</Avatar>
					{userEmail}
				</div>
			);
		},
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'status',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Request Status" />
		),
		cell: ({ row }) => {
			const tooltipContent =
				row.original?.status === CreditRequestStatusEnum.Rejected
					? row.original?.rejectedReason
					: row.original?.status === CreditRequestStatusEnum.Pending
						? `These credits are in a locked state. They'll get credited back if your request is rejected.`
						: null;
			return (
				<div className="flex items-center gap-1">
					{tooltipContent ? (
						<TooltipWrapper
							tooltip={tooltipContent}
							align="start"
							triggerClassName="flex items-center gap-1"
						>
							<StatusBadge status={row?.original?.status} />
							<i className="bi bi-info-circle text-slate-700 text-xs"></i>
						</TooltipWrapper>
					) : (
						<StatusBadge status={row?.original?.status} />
					)}
				</div>
			);
		},
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'credits',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Credits" />
		),
		cell: ({ row }) => {
			const status = row.original?.status;
			const baseType = row.original?.baseType;

			const credits =
				status === CreditRequestStatusEnum.Pending
					? row.original?.lockedCredits
					: row.original?.consumedCredits ?? null;

			return credits !== null && credits !== undefined ? (
				<div className="flex items-center text-slate-700 text-sm font-normal">
					{status === CreditRequestStatusEnum.Approved ? (
						<>
							{baseType === CreditRequestBaseTypeEnum.Consume
								? '-'
								: '+'}
						</>
					) : null}
					<Media
						src={asset['credit-icon-primary']}
						altText="credit-icon"
						className="size-[16px] me-1 ms-1"
					/>
					{credits as number}&nbsp;Credits
				</div>
			) : (
				<span className="">N/A</span>
			);
		},
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'requestType',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Action" />
		),
		cell: ({ row }) => (
			<div className="flex items-center text-slate-700 text-sm font-normal">
				{convertToTitleCase(row.original?.requestType)}
			</div>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'createdAt',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Date" />
		),
		cell: ({ row }) => (
			<div className="text-slate-700 text-sm font-normal">
				{new Date(row?.original?.createdAt).toLocaleDateString()}
			</div>
		),
		enableSorting: false,
		enableHiding: false,
	},
];
